import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  unshETHAmount:'',
  sushiSwapLPAmount:'',
  USHAmount:'',

  sushiSwapLPDuration:30,
  USHDuration:30,
  "unshETHDuration":90,

  BNBunshETHAmount:'',
  BNBUSHAmount:'',
  BNBpancakeSwapLPAmount:'',

  "unshETH (BNB)Duration":90,
  BNBUSHDuration:30,
  BNBpancakeSwapLPDuration:30,

  "unshETH (Arbitrum)Duration": 90,
  "unshETH (Dual ARB)Duration": 90
};

export const stakeSlice = createSlice({
  name: 'stake',
  initialState,
  reducers: {
    setAmount: (state,action) => {
      state[`${action.payload.name}Amount`] = action.payload.amount;
    },
    setDuration: (state,action) => {
      // console.log("cond", parseInt(action.payload.duration) >= 0)
      // console.log("name", action.payload.name)
      if(parseInt(action.payload.duration) >= 0) state[`${action.payload.name}Duration`] = action.payload.duration;
      // console.log("state", state.stake)
      // console.log(state[`${action.payload.name}Duration`]);
    }
  },
});

export const {setAmount, setDuration} = stakeSlice.actions;
export const selectStake = (state) => state.stake;

export default stakeSlice.reducer;
