import React, { HTMLAttributes } from 'react';

export const GradientCard: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...rest }) => {
  return (
    <div className="rounded-3xl flex-1 bg-gradientContainer p-[1px]">
      <div className={`rounded-3xl bg-gradientContainerContent p-6 lg:p-10 backdrop-blur-[7.5px] ${className}`}>
        {children}
      </div>
    </div>
  );
};
