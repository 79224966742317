import React from 'react';

import { Typography } from '@/components/shared';

import { DiscordIcon, GitHubIcon, TwitterIcon } from './icons';

type LinkSection = {
  title: string;
  links: { title: string; link: string }[];
};

const footerItems: LinkSection[] = [
  {
    title: 'How To',
    links: [
      { title: 'Mint unshETH with ETH', link: 'https://docs.unsheth.xyz/how-to-mint-unsheth-with-eth' },
      { title: 'Mint unshETH with LSDs', link: 'https://docs.unsheth.xyz/how-to-mint-unsheth-with-lsds' },
      { title: 'Swap LSD to LSD', link: 'https://docs.unsheth.xyz/how-to-swap-lsd-to-lsd' },
      { title: 'Swap LSD to ETH', link: 'https://docs.unsheth.xyz/how-to-swap-lsd-to-eth' },
      { title: 'Swap ETH to LSD', link: 'https://docs.unsheth.xyz/how-to-swap-eth-to-lsd' },
      { title: 'Lock Tokens for vdUSH', link: 'https://docs.unsheth.xyz/how-to-lock-tokens-for-vdush' },
      { title: 'Staking unshETH', link: 'https://docs.unsheth.xyz/how-to-stake-unsheth' },
      { title: 'Withdraw unshETH', link: 'https://docs.unsheth.xyz/how-to-withdraw-unsheth' },
      { title: 'Bridge USH/unshETH', link: 'https://docs.unsheth.xyz/how-to-bridge-ush-unsheth' },
    ],
  },
  {
    title: 'Support',
    links: [
      { title: 'Blog', link: 'https://mirror.xyz/0x749Dc728A68265e74754Ad8e6cc2649BD42a8f8a' },
      { title: 'Documentation', link: 'https://docs.unsheth.xyz/' },
      { title: 'Media Kit', link: 'https://docs.unsheth.xyz/media-kit' },
    ],
  },
  {
    title: 'Audits',
    links: [
      { title: 'Certik', link: 'https://unsheth.xyz/v2-audit.pdf' },
      { title: 'Paladin', link: 'https://paladinsec.co/projects/unsheth/' },
    ],
  },
  {
    title: 'Integrations',
    links: [
      { title: 'Minting unshETH', link: 'https://docs.unsheth.xyz/minting-unsheth' },
      { title: 'Swapping LSDs', link: 'https://docs.unsheth.xyz/swap' },
    ],
  },
];

export const Footer: React.FC = () => {
  return (
    <footer className="border-t-[1px] border-[#353245] bg-slate-950">
      <div className="flex flex-col gap-[50px] px-4 py-14 md:flex-row md:gap-0 md:py-14 md:justify-between max-w-container mx-auto">
        <section className="flex flex-col gap-4 text-left">
          <div className="flex items-center gap-5 text-lightGray">
            <a href="https://discord.gg/5tZXASh5P3" className="hover:text-lightGray/70 transition-all">
              <DiscordIcon className="w-8 h-8" />
            </a>
            <a href="https://twitter.com/unsheth_xyz" className="hover:text-lightGray/70 transition-all">
              <TwitterIcon className="w-8 h-8" />
            </a>
            <a href="https://github.com/UnshETH?type=source" className="hover:text-lightGray/70 transition-all">
              <GitHubIcon className="w-8 h-8" />
            </a>
          </div>
          <div className="mt-11 md:mt-auto">
            <Typography variant="footerBody" as="h5" className="text-footerGray" weight="bold">
              &copy; 2023 unshETH.
              <br />
              All rights reserved.
            </Typography>
          </div>
          <a href="https://app.termly.io/document/privacy-policy/08d6370a-a4fb-4fdc-a7c4-aa07187de335" rel="noreferrer">
            <Typography
              variant="footerBody"
              as="span"
              className="text-footerGray underline transition-all hover:text-footerGray/70"
              weight="bold"
            >
              Privacy Policy
            </Typography>
          </a>
        </section>
        {footerItems.map(({ title, links }) => (
          <section className="flex flex-col gap-4 text-left" key={title}>
            <Typography variant="footerTitle" as="h5" className="text-lightGray" weight="black">
              {title}
            </Typography>
            {links.map(({ link, title }) => (
              <a href={link} rel="noreferrer" key={link}>
                <Typography
                  variant="footerBody"
                  as="span"
                  className="text-footerGray hover:text-footerGray/70 transition-all"
                >
                  {title}
                </Typography>
              </a>
            ))}
          </section>
        ))}
      </div>
    </footer>
  );
};
