import React, { HTMLAttributes } from 'react';
import clsx from 'classnames';

import { Typography } from '..';

type Variant = 'gradient' | 'flat' | 'text';
type Size = 'big' | 'small';

const btnStyles: Record<Variant, string> = {
  gradient: 'bg-gradientPrimary hover:opacity-70 text-white',
  flat: 'bg-heavyBlue hover:opacity-70 text-mainCTAs',
  text: 'bg-transparent hover:opacity-70 text-mainCTAs',
};

const spacingStyles: Record<Size, string> = {
  big: 'px-12 h-buttonBg',
  small: 'px-6 h-button',
};

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  block?: boolean;
  size?: Size;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'flat',
  block = false,
  size = 'small',
  disabled = false,
  className,
  children,
  ...rest
}) => {
  const baseClass = 'inline-flex items-center justify-center rounded-lg transition-all';
  return (
    <button
      className={clsx(
        baseClass,
        btnStyles[variant],
        variant !== 'text' && spacingStyles[size],
        disabled && 'opacity-40 cursor-not-allowed hover:opacity-40',
        block && 'w-full',
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      <Typography as="span" variant="actionCta" weight={size === 'big' ? 'bold' : 'medium'}>
        {children}
      </Typography>
    </button>
  );
};
