import web3 from 'web3';
export default function calcOutput(input, vaultBalances, totalSupply, redeemFee) {
    if(!input) input = 0;
    if(!totalSupply.data) return;
    let ratio = input / parseFloat(web3.utils.fromWei(totalSupply.data._hex,'ether'));;

    let outputs = {};
    
    Object.keys(vaultBalances).map((v)=>{
        if(!vaultBalances[v].data) return;
        else {
            outputs[v] = ratio * parseFloat(vaultBalances[v].data.formatted) * ((10000 - parseFloat(redeemFee.data))/10000)
        }
    });

    return outputs;
}
    