import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction, useProvider } from 'wagmi';
import contractAddresses from '../../../contract_addresses';
import unshETHZapABI from '../../../ABI/unshETHZap.json';
import { useSelector } from 'react-redux';
import {
  selectArgs,
  selectFunctionName,
  selectInput,
  selectMsgValue,
  resetAll,
  selectGasLimit,
} from '../../../redux/deposit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBalanceWatch } from '../../../redux/watch';
import { ethers } from 'ethers';
import { Button } from '../../shared';

export default function Submit({ balances }) {
  const dispatch = useDispatch();
  const amount = useSelector(selectInput);
  const value = useSelector(selectMsgValue);
  const args = useSelector(selectArgs);
  const functionName = useSelector(selectFunctionName);
  const gasLimit = useSelector(selectGasLimit);
  const provider = useProvider();

  const [depositTxHash, setDepositTxHash] = useState(null);
  const depositWait = useWaitForTransaction({ hash: depositTxHash });

  let bal = balances.ETH.data ? parseFloat(balances.ETH.data.formatted) : 0;

  let overrides = {
    // gasLimit: functionName === 'mint_rETH' ? 800_000 : 600_000,
  };
  if (value) {
    overrides.value = value;
  }

  //Deposit Prep
  const { config } = usePrepareContractWrite({
    address: contractAddresses.unshETHZap,
    abi: unshETHZapABI,
    functionName,
    args,
    overrides,
    onError(err) {
      console.log({ err });
      if (err.reason.includes('Deposit exceeds')) {
        toast.error(err.reason);
      }
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Deposit Failed!');
    },
    onSuccess(data, err) {
      dispatch(setBalanceWatch('ETH'));
      dispatch(setBalanceWatch('unshETH'));
      setDepositTxHash(data.hash);
      toast.info('Deposit Tx Submitted!');
      dispatch(resetAll());
    },
  });

  let amountIncorrect = !amount || parseFloat(amount) <= 0;

  let notEnoughBal = parseFloat(amount) > bal;

  let depositDisabled = notEnoughBal || amountIncorrect || !write;
  let depositButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !depositDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = async () => {
    try {
      // Create an instance of the contract using the ABI and contract address
      const contract = new ethers.Contract(contractAddresses.unshETHZap, unshETHZapABI, provider);

      // Estimate gas for the specific function with the given arguments
      const gasEstimate = await contract.estimateGas[functionName](...args);

      console.log({ gasEstimate });
    } catch (err) {
      console.log(err);
    }
    write();
  };

  return (
    <>
      <div className="flex justify-center text-center w-full mt-8 mb-4">
        {depositWait.isLoading ? (
          <Button disabled={true} variant="gradient" block size="big">
            Depositing...
          </Button>
        ) : (
          <Button disabled={depositDisabled} onClick={onClick} variant="gradient" block size="big">
            {notEnoughBal ? 'Insufficient Balance' : 'Deposit'}
          </Button>
        )}
      </div>
    </>
  );
}
