import contractAddresses from "../contract_addresses";
import LSDVaultABI from "../ABI/LSDVault.json";
import { erc20ABI, useContractRead } from "wagmi";
import { ethId } from "../constants";

export function useTotalUnsheth() {
  //used to keep track of all the assets during withdrawing
  let totalSupplyUnsheth = useContractRead({
    abi: erc20ABI, 
    address: contractAddresses.unshETH,
    functionName: 'totalSupply', 
    args:[], 
    watch:false, 
    scopeKey:'totalUnsheth',
    chainId:ethId,
    onSettled: (err) => {
        console.log('total unsheth' + err);
    }
  });

  let redeemFee = useContractRead({
    abi: LSDVaultABI, 
    address: contractAddresses.LSDVault,
    functionName: 'redeemFee', 
    args:[], 
    watch:false, 
    scopeKey:'redeemFee',
    chainId:ethId,
    onSettled: (err) => {
        console.log('total redeem' + err);
    }
  });

  return {totalSupplyUnsheth,redeemFee};
}
