import { Button } from '../../shared';
import { Link} from 'react-router-dom';


export default function SubmitOnBNB() {

  return (
    <>
      <div className="flex justify-center text-center w-full mt-8 mb-4">
        <Link to="https://pancakeswap.finance/swap?outputCurrency=0x0Ae38f7E10A43B5b2fB064B42a2f4514cbA909ef" target="_blank">
          <Button className="w-full py-8 !rounded-full mt-[-20px]">
            Buy unshETH on Pancake Swap
          </Button>
        </Link>
      </div>
    </>
  );
}
