import { useDispatch } from 'react-redux';
import { setInput, setOutput } from '../../redux/deposit';
import { setCurrent, selectItems } from '../../redux/dropdown';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Button } from '../shared';

export default function Item({ name, initials, imageUrl, balance, buttonType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const items = useSelector(selectItems);

  //upon click of the button navigate to the page = deposit page, set the amount to the balance, and set the current chosen to the index
  const onClick = () => {
    if (buttonType === 'Migrate') {
      navigate('/migrate');
    } else if (buttonType === 'Deposit') {
      dispatch(setInput(''));
      dispatch(setOutput(''));
      // dispatch(setCurrent(initials));
      navigate(`/deposit#${initials}`);
    } else {
      navigate('/stake');
    }
  };

  let initials2 = initials;
  if (initials.startsWith('BNB') || initials.startsWith('ARB')) {
    initials2 = initials.substring(3);
  }

  if (initials === 'unshETHV1' && balance.data && parseFloat(balance.data.formatted) === 0) {
    return null;
  }

  return (
    <tr className="flex flex-row w-full py-2 items-center text-left">
      {/*token*/}
      <td className="whitespace-nowrap md:px-6 pb-4 text-sm text-white w-1/2">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" />
          </div>
          <div className="ml-4">
            <Typography variant="bodyMd" className="text-white" weight="medium">
              {initials2}
            </Typography>
            <Typography variant="bodySm" className="text-lightGray">
              {name}
            </Typography>
          </div>
        </div>
      </td>

      {/*balance*/}
      <td className="whitespace-nowrap pb-4 text-sm text-white w-1/4">
        <div className="">
          {balance.isLoading ? (
            <Typography className="text-lightGray" weight="medium">
              Loading...
            </Typography>
          ) : !balance.data ? (
            <Typography className="text-white" weight="medium">
              0
            </Typography>
          ) : (
            <Typography className="text-white" weight="medium">
              {parseFloat(parseFloat(balance.data.formatted).toFixed(5))}
            </Typography>
          )}
        </div>
      </td>

      {/*convert button*/}
      <td className="whitespace-nowrap pb-4 text-sm text-white w-1/4">
        {buttonType && (
          <div className="text-right">
            {/*button for conversion*/}
            {(buttonType === 'Deposit' ? items[initials] : true) && balance.data && (
              <Button onClick={onClick}>{buttonType}</Button>
            )}
          </div>
        )}
      </td>
    </tr>
  );
}
