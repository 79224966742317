import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNetwork } from 'wagmi';

import { Typography } from '@/components/shared';
import { ethId } from '@/constants';

// import Claim from './Claim';
import { Connect } from './Connect';

type AppRoute = { label: string; pathname: string };

export const Navbar: React.FC = () => {
  const { pathname } = useLocation();
  const { chain } = useNetwork();
  const chainId = chain ? chain.id : ethId;
  const appRoutes: AppRoute[] = [];

  appRoutes.push({ label: 'Deposit', pathname: '/deposit' });
  if (chainId === ethId) appRoutes.push({ label: 'Swap', pathname: '/swap' });
  appRoutes.push({ label: 'vdUSH', pathname: '/vdUSH' });
  appRoutes.push({ label: 'Stake/Earn', pathname: '/stake' });
  appRoutes.push({ label: 'Farms', pathname: '/farms' });
  appRoutes.push({ label: 'Portfolio', pathname: '/portfolio' });
  if (chainId === ethId) appRoutes.push({ label: 'Withdraw', pathname: '/withdraw' });
  appRoutes.push({ label: 'Bridge', pathname: '/bridge' });
  appRoutes.push({ label: 'Governance', pathname: 'https://governance.unsheth.xyz' });

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          {/* <Claim /> */}

          <div className="md:max-w-container py-3 flex items-center justify-between mx-auto h-20 px-4">
            <Link to="/">
              <img className="block h-14 w-auto" src="/unshETH.png" alt="Your Company v2" />
            </Link>
            <div className="hidden flex-1 lg:flex h-14 items-center gap-6 px-6">
              {appRoutes.map((appRoute) => (
                <Link
                  key={appRoute.pathname}
                  to={appRoute.pathname}
                  target={appRoute.label !== 'Governance' ? '_self' : '_blank'}
                >
                  <Typography
                    weight="medium"
                    className={`text-lightGray ${
                      pathname !== appRoute.pathname && 'opacity-50'
                    } hover:opacity-70 transition-all`}
                    variant="bodySm"
                  >
                    {appRoute.label}
                  </Typography>
                </Link>
              ))}
            </div>
            <div className="flex items-center gap-6">
              <Connect />
              <Disclosure.Button>
                <div className="block lg:hidden text-lightGray">
                  {open ? (
                    <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-8 w-8" aria-hidden="true" />
                  )}
                </div>
              </Disclosure.Button>
            </div>
          </div>
          <Disclosure.Panel className="p-4 grid gap-2">
            {appRoutes.map((appRoute) => (
              <Link key={appRoute.pathname} to={appRoute.pathname}>
                <Typography
                  weight="medium"
                  className={`text-lightGray ${pathname !== appRoute.pathname && 'opacity-50'}`}
                >
                  {appRoute.label}
                </Typography>
              </Link>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
