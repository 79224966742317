import { createSlice } from '@reduxjs/toolkit';
import { ethId,arbId } from '../constants';

let items = {
  'ARBUSH': { name: 'ARBUSH', initials: 'ARBUSH', imageUrl:'USH.svg', chainId:arbId, buy:'https://swap.defillama.com/?chain=arbitrum&from=0x0000000000000000000000000000000000000000&to=0x51a80238b5738725128d3a3e06ab41c1d4c05c74'},
  'ARBcamelotLP': { name: 'Camelot LP (USH-ETH)', initials: 'ARBcamelotLP', imageUrl:'camelot.png', chainId:arbId, mint:'https://app.camelot.exchange/liquidity/?token1=0x51A80238B5738725128d3a3e06Ab41c1d4C05C74&token2=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&type=v2&position=lp' },
};


let week = 7 * 24 * 60 * 60;

let endDuration = (((Math.floor((Math.floor(Date.now()/1000) + 53 * week)/week)*week) - Date.now()/1000)/week);

export const initialState = {
  items,
  current: 'USH',
  chainId:ethId,
  ARBUSHInput: '',
  ARBcamelotLPInput: '',
  loading: false,
  duration: endDuration,
  maxDuration: endDuration,
  contract_address: '',
  amountOut: '',
  minAmountOut: '',
  abi: '',
  args: [],
  functionName: '',
  inputDisabled: false,
};

export const ARBvdUSHSlice = createSlice({
  name: 'ARBvdUSH',
  initialState,
  reducers: {
    setCurrent: (state,action) => {
      state.current = action.payload;
    },
    setInput: (state,action) => {
      state[`${action.payload.asset}Input`] = action.payload.value;
    },
    setDuration : (state, action) => {
      state.duration = action.payload;
    },
    setLoading : (state, action) => {
      state.loading = action.payload;
    },
    setCalc: (state, action) => {
      state.contract_address = action.payload.contract_address;
      state.amountOut = action.payload.amountOut;
      state.minAmountOut = action.payload.minAmountOut;
      state.abi = action.payload.abi;
      state.args = action.payload.args;
      state.functionName = action.payload.functionName;
      state.inputDisabled = false;
      state.loading = false;
    },
    setCalculating: (state, action) => {
      state.inputDisabled = true;
      state.loading = true;
      state.output = '';
      state.contract_address = '';
      state.amountOut = '';
      state.minAmountOut = '';
      state.abi = '';
      state.args = [];
      state.functionName = '';
    },
    resetAll: (state, action) => {
      state.ARBUSHInput = '';
      state.ARBcamelotLPInput = '';
      state.output = '';
      state.loading = false;
      state.contract_address = '';
      state.amountOut = '';
      state.minAmountOut = '';
      state.abi = '';
      state.args = [];
      state.functionName = '';
      state.inputDisabled = false;
    }
  },
});

export const {
  setCurrent, 
  setInput,
  setDuration,
  setLoading,
  setCalculating,
  setCalc,
  resetAll,
} = ARBvdUSHSlice.actions;

export const selectItems = (state) => state.ARBvdUSH.items;
export const selectCurrent = (state) => state.ARBvdUSH.current;
export const selectARBUSHInput = (state) => state.ARBvdUSH.ARBUSHInput;
export const selectARBcamelotLPInput = (state) => state.ARBvdUSH.ARBcamelotLPInput;
export const selectDuration = (state) => state.ARBvdUSH.duration;
export const selectLoading = (state) => state.ARBvdUSH.loading;
export const selectContractAddress = (state) => state.ARBvdUSH.contract_address;
export const selectAmountOut = (state) => state.ARBvdUSH.amountOut;
export const selectMinAmountOut = (state) => state.ARBvdUSH.minAmountOut;
export const selectAbi = (state) => state.ARBvdUSH.abi;
export const selectArgs = (state) => state.ARBvdUSH.args;
export const selectFunctionName = (state) => state.ARBvdUSH.functionName;
export const selectInputDisabled = (state) => state.ARBvdUSH.inputDisabled;
export const selectMaxDuration = (state) => state.vdUSH.maxDuration;

export default ARBvdUSHSlice.reducer;