import { Fragment, useEffect, useMemo } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Item from './item';
import Chosen from './chosen';
import { useSelector } from 'react-redux'
import { selectItems } from '../../../../../redux/BNBvdUSH';

export default function Dropdown({asset}) {
  const items = useSelector(selectItems);
  
  let arr = Object.keys(items).map((initial)=> {
    return items[initial];
  });
  
  return (
    <Menu as="div" className="text-left bg-surface-default cursor-default focus:outline-none overflow-hidden rounded-r-lg border border-slate-600  h-full items-center border-l-0">
      <Chosen asset={asset}/>
      
    </Menu>
  )
}
