import contractAddresses from "../contract_addresses";
import { useBalance, useContractRead, useNetwork } from "wagmi";
import { ethId, arbId, API_HOST } from "../constants";
import { createContext, useContext } from "react";
import VDUSHABI from '../ABI/vdUSH.json';
import VDUSHFarmABI from '../ABI/vdUSHFarm.json';
import ANKRFarmABI from '../ABI/ANKRFarm.json';
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { selectVDUSHWatch } from "../redux/watch";
import { useEffect, useState } from "react";
import { PricesContext } from "./Prices";
import { BalancesContext } from "./Balances";
import {ethers} from "ethers";
import VDUSH from "../components/vdUSH";


export const VDUSHContext = createContext();

export function VDUSHProvider({ children }) {
  const account = useAccount();
  let vdUSHWatch = useSelector(selectVDUSHWatch);
  const [VDUSHData, setVDUSHData] = useState({});

  const prices = useContext(PricesContext);
  const balances = useContext(BalancesContext);


  let {chain} = useNetwork();
  let chainId = chain ? chain.id : arbId;


  let watch = chainId === ethId;

  let enabled = true;


  // Define the URL where you want to fetch the VDUSH data
  const VDUSHApiUrl = `${API_HOST}/vdush`; // Replace this with the actual API URL
  // const VDUSHApiUrl = "http://localhost:3001/VDUSH"; // Replace this with the actual API URL

  useEffect(() => {
    async function fetchVDUSHData() {
      try {
        const response = await fetch(VDUSHApiUrl);
        const data = await response.json();
        setVDUSHData(data);
      } catch (error) {
        console.log("Error fetching VDUSH data:", error);
      }
    }
    fetchVDUSHData();
  }, []); // The empty array `[]` ensures that the effect only runs once on component mount

  let data = {
    VDUSHData,
    totalVDUSH: useContractRead({enabled,watch:enabled &&vdUSHWatch.includes('my_locked'), address: contractAddresses.vdUSHFarm, functionName: 'getVdUshTotalSupplyInFarm', args: [], chainId: ethId, abi: VDUSHFarmABI, scopeKey:'totalVDUSH'}),
    totalUSHLocked: useBalance({ enabled,watch:enabled &&vdUSHWatch.includes('my_locked'),token: contractAddresses.USH, chainId: ethId, scopeKey:'totalUSHLocked',address: contractAddresses.vdUSH}),
    total8020BPTLocked: useBalance({enabled,watch:enabled &&vdUSHWatch.includes('my_locked'), token: contractAddresses['8020BPT'], chainId: ethId,  scopeKey:'total8020Locked',address: contractAddresses.vdUSH}),
    totalsushiSwapLPLocked: useBalance({enabled,watch:enabled &&vdUSHWatch.includes('my_locked'), token: contractAddresses['sushiSwapLP'], chainId: ethId,scopeKey:'totalSushiSwapLPVD',address: contractAddresses.vdUSH}),
    boost8020BPT: useContractRead({ address: contractAddresses.vdUSH, functionName: 'bpt_boost_weight', args: [], chainId: ethId, abi: VDUSHABI, enabled,watch:enabled &&false, scopeKey:'boost8020'}),
    boostsushiSwapLP: useContractRead({ address: contractAddresses.vdUSH, functionName: 'pool2_boost_weight', args: [], chainId: ethId, abi: VDUSHABI, enabled,watch:enabled &&false, scopeKey:'boostSushi'}),
    my_locked: useContractRead({enabled,watch:enabled &&vdUSHWatch.includes('my_locked'), abi: VDUSHABI,  address: contractAddresses.vdUSH, functionName: 'locked', args: [account.address], chainId: ethId, scopeKey:'myLocked'}), 
    alreadyStaking: useContractRead({ enabled,watch:enabled && vdUSHWatch.includes('alreadyStaking'), abi: VDUSHFarmABI,  address: contractAddresses.vdUSHFarm, functionName: 'isInMatrix', args: [account.address], chainId: ethId, scopeKey:'myStakingStatus'}),
    earnedUSH: useContractRead({ enabled,watch:enabled && vdUSHWatch.includes('claimed_ush'), abi: VDUSHFarmABI,  address: contractAddresses.vdUSHFarm, functionName: 'earned', args: [account.address], chainId: ethId, scopeKey:'myEarnedGovFarm'}),
    sushiWeightedAmount : useContractRead({enabled, abi: VDUSHABI, address:contractAddresses.vdUSH, functionName: 'weighted_amount', args: [0,ethers.utils.parseEther('1'),0], chainId: ethId, scopeKey:'sushiWeightedAmount'}),
    bptWeightedAmount : useContractRead({enabled, abi: VDUSHABI, address: contractAddresses.vdUSH, functionName: 'weighted_amount', args: [ethers.utils.parseEther('1'),0,0], chainId: ethId, scopeKey:'bptWeightedAmount'}),
    USHWeightedAmount : useContractRead({enabled, abi: VDUSHABI, address: contractAddresses.vdUSH, functionName: 'weighted_amount', args: [0,0,ethers.utils.parseEther('1')], chainId: ethId, scopeKey:'USHWeightedAmount'}),
    earnedANKR: useContractRead({ enabled, watch: enabled && vdUSHWatch.includes('claimed_ankr'), abi: ANKRFarmABI, address: contractAddresses.ankrFarm, functionName: 'earned', args: [account.address], chainId: ethId, scopeKey: 'myEarnedAnkrFarm' }),
//    earnedETHx: useContractRead({ enabled, watch: enabled && vdUSHWatch.includes('claimed_ethx'), abi: ANKRFarmABI, address: contractAddresses.ethxFarm, functionName: 'earned', args: [account.address], chainId: ethId, scopeKey: 'myEarnedEthxFarm' }),
//    ankrPerSecond: useContractRead({ enabled,watch:enabled && vdUSHWatch.includes('ankrPerSecond'), abi: ANKRFarmABI,  address: contractAddresses.ankrFarm, functionName: 'rewardPerSecond', args: [], chainId: ethId, scopeKey:'ankrPerSecond'}),
  }

  //get the ratio of the users locked ush to the total locked ush
  data.myVDUSHLockedRatio = balances && balances.vdUSH.data && data.totalVDUSH.data && parseFloat(balances.vdUSH.data.formatted)/(parseFloat(data.totalVDUSH.data)/1e18);

  if(data.my_locked.data && prices.USH && prices['80USH20unshETH'] && prices.sushiSwapLP){
    //get the amount of locked ush and bnb
    let my8020BPTLocked = parseFloat((parseFloat(data.my_locked.data.amountA)/1e18).toFixed(2));
    let mySushiLocked = parseFloat((parseFloat(data.my_locked.data.amountB)/1e18.toFixed(2)));
    let myUSHLocked = parseFloat((parseFloat(data.my_locked.data.amountC)/1e18.toFixed(2)));

    //get the tvl of the user if the data is available
    let my8020BPTTVL = my8020BPTLocked*prices['80USH20unshETH'];
    let mysushiSwapLPLocked = mySushiLocked*prices.sushiSwapLP;
    let myUSHTVL = myUSHLocked*prices.USH;

    //get the total tvl of the user
    data.myTVL = my8020BPTTVL+mysushiSwapLPLocked+myUSHTVL;

    //get the individual tvl ratio for the each asset for the user
    data.my8020BPTLPTVLRatio = my8020BPTLocked*prices['80USH20unshETH']/data.myTVL;
    data.mysushiSwapLPTVLdRatio = mySushiLocked*prices.sushiSwapLP/data.myTVL;
    data.myUSHTVLRatio = myUSHLocked*prices.USH/data.myTVL;

    //get the ratio of the users tvl vs total tvl
    if(VDUSHData && VDUSHData.TVL) {
      data.myTVLRatio = data.myTVL/VDUSHData.TVL;
    }
  }

  // if(data.ankrPerSecond.data && prices['ANKR']) {
  //   let ankrUSDPerSec = parseFloat(data.ankrPerSecond.data)/1e18*prices.ankr;
  //
  //   if(VDUSHData && VDUSHData.TVL) {
  //     //calculate the apr for the ankr farm
  //     data.ankrAPR = (ankrUSDPerSec * 60 * 60 * 24 * 30* 12)/VDUSHData.TVL * 100;
  //   }
  // }

  if(data.myTVLRatio && data.myVDUSHLockedRatio) {
    //get the ratio of the users tvl vs total tvl
    data.aprRatio = data.myVDUSHLockedRatio/data.myTVLRatio;
  }
  
  //calculate the apr ratio for sushi swap lp and 80/20 bpt
  if(data.USHWeightedAmount.data && data.sushiWeightedAmount.data && data.bptWeightedAmount.data && prices['80USH20unshETH'] && prices.sushiSwapLP && prices.USH) {
    data.sushiTVLRatio = prices.sushiSwapLP/VDUSHData.TVL;
    data.bptTVLRatio = prices['80USH20unshETH']/VDUSHData.TVL;
    data.USHTVLRatio = prices.USH/VDUSHData.TVL;

    data.sushiVDUSHRatio = (parseFloat(data.sushiWeightedAmount.data))/(parseFloat(data.totalVDUSH.data));
    data.bptVDUSHRatio = (parseFloat(data.bptWeightedAmount.data))/(parseFloat(data.totalVDUSH.data));
    data.USHVDUSHRatio = (parseFloat(data.USHWeightedAmount.data))/(parseFloat(data.totalVDUSH.data));


    data.sushiAPRRatio = data.sushiVDUSHRatio/data.sushiTVLRatio;
    data.bptAPRRatio = data.bptVDUSHRatio/data.bptTVLRatio;
    data.USHAPRRatio = data.USHVDUSHRatio/data.USHTVLRatio;
  }

  //calculate the ratio of totalUSHLocked and total8020BPTLocked and totalsushiSwapLPLocked to the total tvl of the farm, make sure to multiply by the prices
  if(data.total8020BPTLocked.data && data.totalUSHLocked.data && data.totalsushiSwapLPLocked.data && VDUSHData && VDUSHData.TVL) {
    data.total8020BPTTVLRatio = (parseFloat(data.total8020BPTLocked.data.formatted))*prices['80USH20unshETH']/VDUSHData.TVL;
    data.totalUSHTVLRatio = (parseFloat(data.totalUSHLocked.data.formatted))*prices.USH/VDUSHData.TVL;
    data.totalsushiSwapLPTVLRatio = (parseFloat(data.totalsushiSwapLPLocked.data.formatted))*prices.sushiSwapLP/VDUSHData.TVL;
  }

  return (
    <VDUSHContext.Provider value={data}>
      {children}
    </VDUSHContext.Provider>
  );
}
