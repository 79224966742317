import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  balances: [],
  allowances: [],
  pools : [],
  vdUSH: [],
};

export const watchSlice = createSlice({
  name: 'watch',
  initialState,
  reducers: {
    setVDUSHWatch: (state, action) => {
      let vdUSH = state.vdUSH;
      vdUSH.push(action.payload);
      state.vdUSH = vdUSH;
    },

    setBalanceWatch: (state, action) => {
      let balances = state.balances;
      balances.push(action.payload);
      state.balances = balances;
    },
    setAllowanceWatch: (state, action) => {
      let allowances = state.allowances;
      allowances.push(action.payload);
      state.allowances = allowances;
    },
    setPoolWatch: (state, action) => {
      let pools = state.pools;
      pools.push(action.payload);
      state.pools = pools;
    }
  },
});

export const { setBalanceWatch, setAllowanceWatch, setPoolWatch, setVDUSHWatch } = watchSlice.actions;
export const selectBalanceWatch = state => state.watch.balances;
export const selectAllowanceWatch = state => state.watch.allowances;
export const selectPoolWatch = state => state.watch.pools;
export const selectVDUSHWatch = state => state.watch.vdUSH;

export default watchSlice.reducer;
