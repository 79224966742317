import { useContext } from 'react';
import { BNBVDUSHContext } from '../../../context-providers/BNBVDUSH';
import { ExternalFarmsContext } from '../../../context-providers/ExternalFarms';
import { PricesContext } from '../../../context-providers/Prices';
import { Typography } from '../../shared';

function numberWithCommas(number) {
  try {
    // Convert the number to a string
    let numberString = number.toFixed(2).toString();

    // Use a regular expression to add commas as thousands separators
    let numberWithCommas = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Return the resulting string
    return numberWithCommas;
  } catch (error) {
    console.log({ number, error });
    return number;
  }
}

export default function TotalUSH() {
  let { totalBNBpancakeSwapLPLocked, totalBNBUSHLocked, totalBNBVDUSH } = useContext(BNBVDUSHContext);

  let { bnbFarms } = useContext(ExternalFarmsContext);
  let prices = useContext(PricesContext);

  function convert(num) {
    if (!num) return 0;
    if (!num.data) return 0;
    if (num.data && num.data.formatted) {
      num = parseFloat(parseFloat(num.data.formatted).toFixed(2));
    } else if (num.data) {
      num = parseFloat((parseFloat(num.data) / 1e18).toFixed(2));
    } else {
      num = 0;
    }
    return num;
  }

  //calculate the tvls
  let bnbTVL = 0;
  if (prices['USH']) {
    bnbTVL = parseFloat(convert(totalBNBUSHLocked)) * parseFloat(prices['USH']);
  }

  let pancakeTVL = 0;
  if (prices['BNBpancakeSwapLP']) {
    pancakeTVL = parseFloat(convert(totalBNBpancakeSwapLPLocked)) * parseFloat(prices['BNBpancakeSwapLP']);
  }

  const stats = [
    { name: 'Total BNB vdUSH in Farm', value: convert(totalBNBVDUSH) },
    { name: 'Total BNB USH Locked', value: convert(totalBNBUSHLocked), tvl: bnbTVL },
    {
      name: 'Total Pancake Swap V2 LP (USH-BNB) Locked',
      value: convert(totalBNBpancakeSwapLPLocked),
      apr: bnbFarms[1].aprBase,
      tvl: pancakeTVL,
    },
  ];

  return (
    <>
      {stats.map((stat) => (
        <div key={stat.name} className="px-4 py-4 sm:px-6 lg:px-8 grid gap-1 h-fit">
          <Typography variant="bodyXs" className="text-lightGray" weight="medium">
            {stat.name}
          </Typography>
          <Typography variant="bodyLg" className="text-white" weight="bold">
            {numberWithCommas(stat.value)}
          </Typography>
          {stat.tvl ? (
            <Typography variant="bodyXs" className="text-lightGray">
              ${numberWithCommas(stat.tvl)}
            </Typography>
          ) : null}
        </div>
      ))}
    </>
  );
}
