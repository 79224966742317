import { Menu } from '@headlessui/react'
import { useDispatch } from 'react-redux';
import { resetAll, setOutputChosen } from '../../../../redux/swap';

export default function Item({item,balance}) {
    const dispatch = useDispatch();
    let {name, initials, imageUrl} = item;

    //onclick set the current in state.dropdown to index
    const onClick = () => {
        dispatch(setOutputChosen(initials));
        dispatch(resetAll());
    }

    let shortened = initials;

    return (
        <Menu.Item>
            <button onClick={onClick} className="rounded-lg flex items-center hover:bg-surface-subdued h-full py-4 hover:bg-slate-500 w-full" id="headlessui-combobox-button-321" type="button" tabIndex="-1" aria-haspopup="true" aria-expanded="false">
                <div className="flex w-full px-4 justify-between items-center rounded-l-lg">
                    <div className="flex min-w-full items-center justify-between">
                        <div className="flex items-center space-x-3 w-full">
                            <div className="flex" id="network-icon-1-ETH"><img src={imageUrl} alt="ETH" className="w-8 h-8 rounded-full object-cover min-w-[2rem]"/></div>
                            <div className="flex flex-col items-center truncate text-left">
                                <div className="text-sm font-medium text-white text-left w-full">{shortened}</div>
                                <div className="text-sm text-gray-400 text-left w-full">{name}</div>
                            </div>
                            <div className="flex-1 items-right truncate text-right">
                                {/* <div className="text-sm font-medium text-gray-300 w-full">Bal</div> */}
                                <div className="text-sm font-medium text-white w-full truncate">
                                    {(balance.isLoading || !balance.data) ? '' : parseFloat(parseFloat(balance.data.formatted).toFixed(5))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </button>
        </Menu.Item>
    )
}
