import { useContext } from 'react';
import { VDUSHContext } from '../../../context-providers/VDUSH';
import { BalancesContext } from '../../../context-providers/Balances';
import { ExternalFarmsContext } from '../../../context-providers/ExternalFarms';
import { Typography } from '../../shared';

function numberWithCommas(number) {
  try {
    // Convert the number to a string
    let numberString = number.toFixed(2).toString();

    // Use a regular expression to add commas as thousands separators
    let numberWithCommas = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Return the resulting string
    return numberWithCommas;
  } catch (error) {
    console.log({ number, error });
    return number;
  }
}

export default function TotalUSH() {
  let { my_locked } = useContext(VDUSHContext);
  let balances = useContext(BalancesContext);
  let { ethFarms } = useContext(ExternalFarmsContext);

  let duration;
  let myUSHLocked = 0;
  let myBalancerLPLocked = 0;
  let mySushiLocked = 0;

  let ush = 0;

  if (my_locked.data && parseFloat(my_locked.data.end) != 0) {
    duration = new Date(parseFloat(my_locked.data.end) * 1000).toLocaleString();
    myBalancerLPLocked = parseFloat((parseFloat(my_locked.data.amountA) / 1e18).toFixed(2));
    mySushiLocked = parseFloat(parseFloat(my_locked.data.amountB) / (1e18).toFixed(2));
    myUSHLocked = parseFloat(parseFloat(my_locked.data.amountC) / (1e18).toFixed(2));
  }
  if (balances.vdUSH.data) {
    ush = parseFloat(parseFloat(balances.vdUSH.data.formatted).toFixed(2));
  }

  const stats = [
    { name: 'My vdUSH', value: ush },
    { name: 'My USH Locked', value: myUSHLocked, duration },
    { name: 'My 8020BPT Locked', value: myBalancerLPLocked, duration, apr: ethFarms[2] && ethFarms[2].aprBase },
    { name: 'My sushiSwapLP Locked', value: mySushiLocked, duration, apr: ethFarms[1].aprBase },
  ];

  return (
    <>
      {stats.map((stat) => (
        <div key={stat.name} className="px-4 py-4 sm:px-6 lg:px-8 grid gap-1 h-fit">
          <Typography variant="bodyXs" className="text-lightGray" weight="medium">
            {stat.name}
          </Typography>
          <Typography variant="bodyLg" className="text-white" weight="bold">
            {numberWithCommas(parseFloat(stat.value.toFixed(2)))}
          </Typography>
          {stat.duration && (
            <Typography variant="bodyXs" className="text-lightGray">
              locked until {stat.duration}
            </Typography>
          )}
          {/* {stat.apr &&
            <div className="mt-2 text-xs text-gray-500">Swap APR: {stat.apr} </div>
          } */}
        </div>
      ))}
    </>
  );
}
