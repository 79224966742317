import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction, useAccount } from 'wagmi';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import contract_addresses from '../../../../contract_addresses';
import { BNBVDUSHContext } from '../../../../context-providers/BNBVDUSH';
import { BalancesContext } from '../../../../context-providers/Balances';
import VDUSHFarmABI from '../../../../ABI/vdUSHFarm.json';
import { setVDUSHWatch } from '../../../../redux/watch';
import { Button } from '../../../shared';

export default function Submit3() {
  let account = useAccount();
  const { alreadyStaking, earnedBNBUSH } = useContext(BNBVDUSHContext);
  const dispatch = useDispatch();
  const [lockTxHash, setSwapTxHash] = useState(null);
  const balances = useContext(BalancesContext);
  const lockWait = useWaitForTransaction({ hash: lockTxHash });
  const isStaking = alreadyStaking.data ? alreadyStaking.data : false;

  let ushAmount = earnedBNBUSH.data ? parseFloat(earnedBNBUSH.data[0]) / 1e18 : 0;
  let vdushAmount = earnedBNBUSH.data ? parseFloat(earnedBNBUSH.data[1]) / 1e18 : 0;

  if (!isStaking) {
    ushAmount = 0;
    vdushAmount = 0;
  }

  //Lock Prep
  const { config } = usePrepareContractWrite({
    address: contract_addresses.BNBvdUSHFarm,
    abi: VDUSHFarmABI,
    functionName: 'passGoAndCollect',
    args: [account.address],
    onError: (err) => {
      console.log({ err });
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Claim Failed!');
    },
    onSuccess(data, err) {
      console.log({ data, err });
      setSwapTxHash(data.hash);
      dispatch(setVDUSHWatch('bnb_claimed_ush'));
      toast.info('Claim Tx Submitted!');
    },
  });

  let lockDisabled = !write;
  let lockButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !lockDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    write();
  };

  return (
    <div className="flex-flex-row w-full">
      <div className="flex flex-row justify-end items-center">
        <span className="text-sm text-left  text-gray-200 w-full">USH</span>
        <div className="w-full text-right mr-1">
          <span className="text-sm text-gray-400">{parseFloat(parseFloat(ushAmount).toFixed(5))}</span>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center">
        <span className="text-sm text-left text-gray-200 w-full">vdUSH</span>
        <div className="w-full text-right mr-1">
          <span className="text-sm text-gray-400">{parseFloat(parseFloat(vdushAmount).toFixed(5))}</span>
        </div>
      </div>

      <div className="mt-8 flex justify-center text-center gap-4 w-full mb-4">
        {lockWait.isLoading ? (
          <Button variant="gradient" size="big" block disabled={true}>
            Claiming...
          </Button>
        ) : (
          <Button variant="gradient" size="big" block disabled={lockDisabled} onClick={onClick}>
            Claim
          </Button>
        )}
      </div>
    </div>
  );
}
