// import Unstake from './unstake';
import Title from './title';
import Output from './output';
import Submit from './Submit';
import Claim from './Claim';
import { GradientCard } from '../../v2';

export default function Pool() {
  return (
    <GradientCard>
      <Title />

      <div className="flex relative h-16 mt-8">
        <Output />
      </div>

      <div className="flex justify-center mt-8">
        <Submit />
      </div>

      <div className="border-t border-slate-700 my-4"></div>

      <div className="flex mt-2 w-full">
        <Claim /> 
      </div>
    </GradientCard>
  );
}
