import { toast } from 'react-toastify';
import {
  usePrepareContractWrite,
  useContractWrite,
  erc20ABI,
  useWaitForTransaction,
  useAccount,
  useNetwork,
  useContractRead,
} from 'wagmi';
import contractAddresses from '../../contract_addresses';
import { useSelector } from 'react-redux';
import web3 from 'web3';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAmount, selectAmount, selectToken, selectChainFrom, selectChainTo, selectChains } from '../../redux/bridge';
import { ethId, ethChainId, bnbChainId, arbChainId } from '../../constants';
import { ethers } from 'ethers';
import OFTCoreABI from '../../ABI/OFTCore.json';
import { setAllowanceWatch, setBalanceWatch } from '../../redux/watch';
import { Switch } from '@headlessui/react';
import Airdrop from './airdrop';
import Address from './address';
import { Button } from '../shared';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Submit({ allowances, balanceFrom }) {
  const { chain } = useNetwork();
  let chainId = chain ? chain.id : ethId;
  const dispatch = useDispatch();
  const account = useAccount();
  const [enabled, setEnabled] = useState(false);
  const [enabledAddress, setEnabledAddress] = useState(false);
  const [addressInput, setAddressInput] = useState('');

  const [displayErr, setDisplayErr] = useState(null);

  const amount = useSelector(selectAmount);

  let chains = useSelector(selectChains);
  let chainFrom = useSelector(selectChainFrom);
  let chainTo = useSelector(selectChainTo);
  let token = useSelector(selectToken);

  let { lzId } = chains[chainTo];

  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const [bridgeTxHash, setBridgeTxHash] = useState(null);

  const approvalWait = useWaitForTransaction({ hash: approvalTxHash });
  const bridgeWait = useWaitForTransaction({ hash: bridgeTxHash });

  const airdrop_amount = chains[chainTo].airdrop_amount;

  let bal = balanceFrom.data ? parseFloat(balanceFrom.data.formatted) : 0;

  let approvalRequired =
    allowances[token + chainFrom + 'Proxy'] && allowances[token + chainFrom + 'Proxy'].data !== undefined
      ? parseInt(allowances[token + chainFrom + 'Proxy'].data) < amount * 1e18
      : false;

  let contractAddress =
    token === 'USH' && chainFrom === 'ETH'
      ? 'USHProxy'
      : token === 'USH' && chainFrom === 'BNB'
      ? 'BNBUSH'
      : token === 'USH' && chainFrom === 'ARB'
      ? 'ARBUSH'
      : token === 'unshETH' && chainFrom === 'ETH'
      ? 'unshETHProxy'
      : token === 'unshETH' && chainFrom === 'BNB'
      ? 'BNBunshETH'
      : token === 'unshETH' && chainFrom === 'ARB'
      ? 'ARBunshETH'
      : '';
  function isEthereumAddress(address) {
    const pattern = /^(0x)?[0-9a-fA-F]{40}$/;
    return pattern.test(address);
  }

  let address = enabledAddress ? addressInput.toLowerCase() : account && account.address ? account.address : '';
  if (!isEthereumAddress(address)) {
    console.log('not an address');
    address = null;
  }

  let adapterParams = address
    ? ethers.utils.solidityPack(
        ['uint16', 'uint', 'uint', 'address'],
        [2, 200000, ethers.utils.parseEther(enabled ? airdrop_amount : '0'), address],
      )
    : [];

  let args =
    address && amount
      ? [
          account.address, //owner of token
          lzId, //which chain to send  to
          ethers.utils.solidityPack(['address'], [address]), //abi.encodePacked version of the address to send USH too
          web3.utils.toHex(web3.utils.toWei(amount, 'ether')), //the amount of Token to send
          account.address, //the refund address if something goes wrong
          '0x0000000000000000000000000000000000000000', //The ZRO Payment Address
          adapterParams, //Adapter Params
        ]
      : [];

  let quoteArgs = [];
  if (amount && address) {
    quoteArgs = [
      lzId, // Which chain to send to
      ethers.utils.solidityPack(['address'], [address]), // Correctly encode the address to bytes
      web3.utils.toHex(web3.utils.toWei(amount, 'ether')), // The amount of Token to send
      false, // Use Zro
      adapterParams, //ethers.utils.toUtf8Bytes('') // Adapter Params
    ];
  }

  let sendFee = useContractRead({
    abi: OFTCoreABI,
    address: contractAddresses[contractAddress],
    functionName: 'estimateSendFee',
    args: quoteArgs,
    watch: false,
    scopeKey: 'estimateSendFee',
    chainId,
    onError(err) {
      console.log({ amount });
      // console.log(web3.utils.toHex(web3.utils.toWei(amount, 'ether')))
      console.log({ chainId });
      console.log({ quoteArgs });
      console.log(err);
    },
  });

  console.log(args);
  if (sendFee.data) console.log(sendFee.data[0].toString());
  //Bridge Prep
  const { config } = usePrepareContractWrite({
    address: contractAddresses[contractAddress],
    abi: OFTCoreABI,
    functionName: 'sendFrom',
    args,
    overrides: {
      // gasLimit: chainId === ethId ? 1500000 : chainId === bnbId ? 300000 : 300000,
      value: sendFee.data ? sendFee.data[0] : 0,
    },
    onError(error) {
      console.log(error);
      try {
        if (error.data && error.data.message.toString().includes(`insufficient funds for gas * price + value`)) {
          setDisplayErr(`Not Enough Funds For Gas`);
        } else if (error.message.includes('not enough native for fees')) {
          setDisplayErr(`Not Enough Funds For Gas`);
        }
      } catch (err) {
        console.log({ lzId, err });
      }
    },
    onSuccess(data) {
      setDisplayErr(null);
    },
  });

  //Approval Prep
  const approvePrepare = usePrepareContractWrite({
    address: contractAddresses[token],
    abi: erc20ABI,
    functionName: 'approve',
    args: [contractAddresses[token + 'Proxy'], '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'],
  });

  const approveWrite = useContractWrite({
    ...approvePrepare.config,
    onError(err) {
      toast.error('Approval Failed!');
    },
    onSuccess(data, err) {
      setApprovalTxHash(data.hash);
      dispatch(setAllowanceWatch(token + chainFrom + 'Proxy'));
      toast.info('Approval Tx Submitted!');
      //dispatch events to clear the input and output elements in redux state.bridge
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Bridging Failed!');
    },
    onSuccess(data, err) {
      setBridgeTxHash(data.hash);
      console.log({ data, err });
      toast.info('Bridging Tx Submitted!');
      dispatch(setAmount(''));
      dispatch(setBalanceWatch(chains[chainTo].addOn + token));
      dispatch(setBalanceWatch(chains[chainFrom].addOn + token));
    },
  });

  let amountIncorrect = !amount || parseFloat(amount) <= 0;

  let notEnoughBal = parseFloat(amount) > bal;

  let approveButtonClass = `block w-1/2 rounded-lg bg-gradient-to-r py-3 px-4 font-medium text-white shadow from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  let bridgeDisabled = notEnoughBal || amountIncorrect || !write || !sendFee.data;
  let bridgeButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !bridgeDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    if (approvalRequired) {
      approveWrite.write();
      return;
    } else {
      write();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-center w-full mt-4 mb-4">
      <Airdrop enabled={enabled} setEnabled={setEnabled} />

      <div className="border border-gray-500 flex flex-row items-center justify-center text-center w-1/2 mb-4"></div>

      <Address
        enabledAddress={enabledAddress}
        setEnabledAddress={setEnabledAddress}
        addressInput={addressInput}
        setAddressInput={setAddressInput}
      />

      {approvalWait.isLoading ? (
        <Button variant="gradient" size="big" block disabled={true}>
          Approving...
        </Button>
      ) : approvalRequired ? (
        <Button variant="gradient" size="big" block disabled={!approveWrite.write} onClick={onClick}>
          Approve
        </Button>
      ) : bridgeWait.isLoading ? (
        <Button variant="gradient" size="big" block disabled={true}>
          Bridging...
        </Button>
      ) : (
        <Button variant="gradient" size="big" block disabled={bridgeDisabled} onClick={onClick}>
          {displayErr ? displayErr : notEnoughBal ? 'Insufficient Balance' : 'Bridge'}
        </Button>
      )}
    </div>
  );
}
