import Headers from './Headers';
import Lock from './Lock';
import Pool from './Pool';
import Withdraw from './Withdraw';
import { arbId, ethId } from '../../constants';
import { useNetwork } from 'wagmi';

export default function VDUSH(){
    let {chain} = useNetwork();

    let chainId = chain ? chain.id : ethId;

    return arbId === chainId && (
        <>
            <Headers />

            <div className="flex justify-center text-center gap-4 w-full mb-6 mt-6">
                <Withdraw />
            </div>

            <div className=' mx-auto grid grid-cols-1 md:flex md:flex-row justify-end gap-20'>
                <div className="w-full mx-6 justify-end mx-auto sm:text-center lg:items-center lg:px-0 lg:text-left ">
                    <Lock />
                </div>
                <div className="w-full mx-6 justify-end mx-auto sm:text-center lg:items-center lg:px-0 lg:text-left ">
                    <Pool />
                </div>
            </div>

            <div className='mb-16'>

            </div>
        </>
    )
}