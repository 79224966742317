import {
    setInputAmount,
    selectOutputChosen,
    selectInputChosen, 
    setCalcSwap,
    selectInputDisabled,
    selectInputAmount,
    resetAll, 
    calculatingSwap
} from '../../../redux/swap';

import { useDispatch, useSelector } from 'react-redux';
import calcSwap from '../../../actions/calcSwap';
import { useProvider } from 'wagmi';
import { useRef, useState } from 'react';
import { setLoading } from '../../../redux/vdUSH';

export default function InputAmount() {
    const provider = useProvider();
    const dispatch = useDispatch();
    const amount = useSelector(selectInputAmount);
    const inputChosen = useSelector(selectInputChosen);
    const outputChosen = useSelector(selectOutputChosen);
    const inputDisabled = useSelector(selectInputDisabled);
    const outputAmount = useSelector(selectInputAmount);

    const debounceRef = useRef(null);

    const onChange = async (e) => {
        let inputAmount = e.target.value;
        console.log({inputAmount});
        console.log({inputDisabled});
        if(inputDisabled) return;

        console.log({inputAmount});
        if(inputAmount === '') {
            dispatch(resetAll());
            if (debounceRef.current) clearTimeout(debounceRef.current);
            return;
        }
        
        dispatch(setInputAmount(inputAmount));
        dispatch(setLoading());

        // Clear the existing debounce timer, if any
        if (debounceRef.current) clearTimeout(debounceRef.current);

        // Set a new debounce timer
        debounceRef.current = setTimeout(async () => {
            await swap(inputAmount);
        }, 500); // 1000 milliseconds (1 seconds) debounce time

        let swap = async (inputAmount) => {
            if(parseFloat(inputAmount) === 0) {
                if(outputAmount) dispatch(resetAll());
                return;
            }
            else {
                dispatch(calculatingSwap());
                try {
                    let output = await calcSwap({inputAmount, inputChosen, outputChosen, provider});
                    if (output === null) {
                        dispatch(resetAll());
                    } else {
                        dispatch(setCalcSwap(output))
                    }
                } catch (err) {
                    console.log(err);
                    dispatch(resetAll());
                }
            }
        }
    };

    return (
        <div className="flex flex-1 text-left items-center relative ">
            <input onChange={onChange.bind(this)} placeholder={'Enter Amount'} className="bg-slate-800 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500" type="number" inputMode="decimal" value={amount} />
        </div>
    );
}