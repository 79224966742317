export default {
    "claim": "0x3Df4376566ec07eC4FB992B7b16D9E4A60bd7DbB",
    "reshETHVault": "0x39D4442D8D2D3Bc1861c6f9E612654fecdC1BF1d",
    "mpETH": "0x48AFbBd342F64EF8a9Ab1C143719b63C2AD81710",
    "vETH": "0x4bc3263eb5bb2ef7ad9ab6fb68be80e43b43801f",
    "woETH": "0xDcEe70654261AF21C44c093C300eD3Bb97b78192",

    "darknet": "0x5fecdd6fE7BC9a406B6D0246D74B555A33678C7A",

    "LSDRegistry": "",
    "LSDRegistryV1": "0xA857904691bbdEca2e768B318B5f6b9bfA698b7C",

    "LSDVault": "0x51A80238B5738725128d3a3e06Ab41c1d4C05C74",
    "LSDVaultV1": "0xE76Ffee8722c21b390eebe71b67D95602f58237F",

    "unshETHV1": "0x846982C0a47b0e9f4c13F3251ba972Bb8D32a8cA",
    "unshETH": "0x0Ae38f7E10A43B5b2fB064B42a2f4514cbA909ef",
    "unshETHProxy": "0x35f899CE6cC304AeDFDB7835f623A30473b26457",

    "USH": "0xe60779cc1b2c1d0580611c526a8df0e3f870ec48",
    "USHProxy": "0xA8b326Ca02650Ac968C554d6C534412e49c92BC4",

    "USH-farm": "0xf728dB9182e7c3a9dFfbD71f9506d04f129Ac9C8",

    "unshETH-farm": "0x33890B88F98a9D511678954AD8DB0510B6953Cfc",
    "unshETH-farmV1": "0x954d5088d88291146ce58270add820e809ff3d7e",

    "sushi-farm": "0x5153b553d8ae3cbbb5ac97f5e4c8e5776d30ee09",


    "frxETH": "0x5E8422345238F34275888049021821E8E08CAa1f",

    "sfrxETH": "0xac3e018457b222d93114458476f3e3416abbe38f",
    "rETH": "0xae78736cd615f374d3085123a210448e74fc6393",
    "wstETH": "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0",
    "ankrETH": "0xE95A203B1a91a908F9B9CE46459d101078c2c3cb",
    "swETH": "0xf951E335afb289353dc249e82926178EaC7DEd78",
    "cbETH": "0xbe9895146f7af43049ca1c1ae358b0541ea49704",
    "WETH": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    "ETHx": "0xA35b1B31Ce002FBF2058D22F30f95D405200A15b",

    "8020BPT": "0xd689abc77b82803f22c49de5c8a0049cc74d11fd",

    "rETHRouter": "0x16D5A408e807db8eF7c578279BEeEe6b228f1c1C",

    "USDT": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    "USDC": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",

    "univ3quoter": "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    "sushiSwapLP": "0xAAF448d30F01b429FB6e7F9AF6A8FF66e694F312",

    "unshETHZap": "0x3FB65c616358581f1C22090aCef985b0E7eF2Ae0",

    "WBNB": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",

    "SGReceiver": "0x00EFE5523fb4C78FC0e1eec26d916310027f25B1",

    "BNBStargateRouter": "0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8",
    "ARBStargateRouter": "0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614",

    "StargateRouter": "0x8731d54E9D02c286767d56ac03e8037C07e01e98",

    "sgETH": "0x72E2F4830b9E45d52F80aC08CB2bEC0FeF72eD9c",
    "ARBsgETH": "0x915A55e36A01285A14f05dE6e81ED9cE89772f8e",

    "ARBunshETH": "0x0ae38f7e10a43b5b2fb064b42a2f4514cba909ef",
    "ARBUSH": "0x51a80238b5738725128d3a3e06ab41c1d4c05c74",
    "ARBunshETH-farm": "0x9eFB28060e0c4Ea2538a2B5Ede883E86219182B2",
    "ARBunshETH-dualfarm": "0x34f969E2c9D7f8bf1E537889949390d4a756270A",

    "BNBStargateFee": "0xCa46058a5682B13c44F4Dd2558aFDEbf3A28f41f",
    "ARBStargateFee": "0xB1641D94684225B72F97E52b2b02Ad07F7bA9089",

    "BNBETH": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    "BNBUSDT": "0x55d398326f99059ff775485246999027b3197955",

    "BNBunshETH": "0x0Ae38f7E10A43B5b2fB064B42a2f4514cbA909ef",
    "BNBUSH": "0x91d6d6aF7635B7b23A8CED9508117965180e2362",

    "BNBSGSender": "0xa7ecda11b64deb294776fc03c92e611ea63a73be",
    "ARBSGSender": "0x5ba96b2BE2b4e88fa51506198ccfAAE57Fb6f246",

    "BNBPancakeSwapRouter": "0x10ED43C718714eb63d5aA57B78B54704E256024E",

    "BNBpancake-farm": "0x73F3BF38C6bdBc69042049c022080D14885Ff32D",
    "BNBUSH-farm": "0xa974c41748e8464Baf978e3268CDdb0d6A1fdBDA",
    "BNBunshETH-farm": "0x2C8a4058DB744808FFFA97E29c8E1b7cBF7AAd01",
    "BNBpancakeSwapLP": "0xC9C33A4Aa8fb9F50579EE4d89Fb8a45EECCD6f0a",
    'camelotLP': '0x855F1b323FdD73AF1e2C075C1F422593624eD0DD',

    "vdAMM": '0x35636b85b68c1b4a216110fb3a5fb447a99db14a',
    "vdUSH": '0xd027ef82db658805c9ba8053196cd6ed1dd407e4',
    "BNBvdUSH": '0x953212fBA33c20763400370A0481C5129AE752d9',
    "ARBvdUSH": '0x69E3877a2A81345BAFD730e3E3dbEF74359988cA',
    "vdUSHFarm": '0x78d5cff035484d220e5b068efefb3a3ae495d640',
    'BNBvdUSHFarm': '0x9eFB28060e0c4Ea2538a2B5Ede883E86219182B2',
    'ARBvdUSHFarm': '0xd6eE2f6586A60d997356da434AA6E0461AA43dB5',
    'ARBcamelotLP': '0x855f1b323fdd73af1e2c075c1f422593624ed0dd',
    'ankrFarm': '0xEbb95E5b6840717D4b6c548B7ceF5450A940CFa4',
    'ArbFarm': '0x28189ED7ee43aEb65B9adEeb456B2480297221CE',
    'ANKR': '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
    'mavFarm': "0x7041C9d1AfF0661a6c3ba8d68871C944628B0327",
    'mavPool': "0x4CB8105616f63d64F75ef61024fB6882097Be3c1"
}