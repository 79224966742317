import {
  setInputAmount,
  selectOutputChosen,
  selectInputChosen,
  selectInputDisabled,
  setCalcSwap,
  resetAll,
  calculatingSwap,
} from '../../../redux/swap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import calcSwap from '../../../actions/calcSwap';
import { useProvider } from 'wagmi';
import { useContext } from 'react';
import { BalancesContext } from '../../../context-providers/Balances';
import { calculateSpendableAmount } from '../../../actions/calcSpendableAmount';
import { toast } from 'react-toastify';
import { Typography } from '../../shared';

export default function InputBalance() {
  const provider = useProvider();
  const dispatch = useDispatch();
  const balances = useContext(BalancesContext);

  const inputChosen = useSelector(selectInputChosen);
  const outputChosen = useSelector(selectOutputChosen);

  const inputDisabled = useSelector(selectInputDisabled);
  let bal = balances[inputChosen];

  let swap = async (inputAmount) => {
    dispatch(calculatingSwap());
    if (parseFloat(inputAmount) === 0) {
      dispatch(resetAll());
    } else {
      try {
        if (inputChosen === 'ETH') {
          inputAmount = await calculateSpendableAmount(inputAmount, provider);
        }
        let output = await calcSwap({ inputAmount, inputChosen, outputChosen, provider });
        if (output === null) {
          dispatch(resetAll());
        } else {
          dispatch(setCalcSwap(output));
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
        dispatch(resetAll());
      }
    }
  };

  const onClick = async () => {
    if (inputDisabled) return;
    let inputAmount = bal.data.formatted;
    dispatch(setInputAmount(inputAmount));
    await swap(inputAmount);
  };

  return (
    <div className={`text-right mr-1`}>
      {bal.isLoading ? (
        <Typography as="span" variant="bodyXs" className="text-lightGray">
          Balance: Loading...
        </Typography>
      ) : !bal.data ? (
        <Typography as="span" variant="bodyXs" className="text-lightGray">
          Balance: 0
        </Typography>
      ) : (
        <div className="flex gap-1">
          <Typography as="span" variant="bodyXs" className="text-lightGray">
            Balance: {parseFloat(parseFloat(bal.data.formatted).toFixed(5))}
          </Typography>
          <Typography
            as="span"
            variant="bodyXs"
            className="text-mediumBlue cursor-pointer"
            onClick={onClick}
            weight="medium"
          >
            Max
          </Typography>
        </div>
      )}
    </div>
  );
}
