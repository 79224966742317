import contract_addresses from '../../contract_addresses';
import LSDVaultAbi from '../../ABI/LSDVault.json';
import vdAMMABI from '../../ABI/vdAMM.json';
import zapABI from '../../ABI/unshETHZap.json';
import { ethers } from 'ethers';
import {univ3_quoteExactInputSingle} from './univ3quoter';
import Decimal from 'decimal.js';
import { tolerance_to_mint_bps } from '../../constants';

export async function pickPathId(ethAmountIn, provider, darknet){
    
    let {should_buy, weth_lsd_amount_out, eligible_lsd_bought, lsd_to_deposit, depositFee, protocolFee} = await shouldBuy(ethAmountIn, provider, darknet);
  //  console.log({should_buy, weth_lsd_amount_out, eligible_lsd_bought, lsd_to_deposit, depositFee, protocolFee});

    let pathId;

    if(!should_buy){
        if(lsd_to_deposit === 'wstETH') pathId = 0;
        if(lsd_to_deposit === 'sfrxETH') pathId = 1;   
        if(lsd_to_deposit === 'rETH') pathId = 6;
        if(lsd_to_deposit === 'WETH') pathId = 7;
        if(lsd_to_deposit === 'ankrETH') pathId = 8;
        if(lsd_to_deposit === 'swETH') pathId = 9;
        if(lsd_to_deposit === 'ETHx') pathId = 11;
    }
    else {
        if(lsd_to_deposit === 'cbETH') pathId = 2;
        if(lsd_to_deposit === 'rETH') pathId = 3;
        if(lsd_to_deposit === 'wstETH') pathId = 4;
        if(lsd_to_deposit === 'sfrxETH') pathId = 5;
        if(lsd_to_deposit === 'swETH') pathId = 10;
    }

    return { depositFee, protocolFee, lsd_to_deposit, pathId, weth_lsd_amount_out, eligible_lsd_bought, should_buy }
}

export function isMintable(lsd){
    return lsd !== 'cbETH'
}

export function shouldOnlyMint(lsd) {
    return lsd === 'ankrETH' || lsd === 'ETHx';
}

export async function pickLSDToDeposit(ethAmountIn, darknet, provider, lsdsToSkip) {
    let lsds = ['sfrxETH', 'wstETH', 'cbETH', 'rETH', 'WETH', 'ankrETH', 'swETH', 'ETHx'].filter(lsd => !lsdsToSkip.includes(lsd));

    let lsdToDeposit = 'sfrxETH'; // default case, to handle edge case when deposits are disabled

    const lsdVault = new ethers.Contract(contract_addresses.LSDVault, LSDVaultAbi, provider);

    let effectiveDistance = 0;
    let newEffectiveDistance;
    try {
        const results = await Promise.all(lsds.map(async (lsd) => {
            try {
            let darknetRate = darknet[lsd].data;
    
            let lsdAmountIn = (parseFloat(ethAmountIn) / 1e18) / (parseFloat(darknetRate) / 1e18);
            lsdAmountIn = ethers.utils.parseEther(Math.floor(parseFloat(lsdAmountIn.toString())).toString(), 'wei');
            // lsdAmountIn = ethers.utils.parseEther(lsdAmountIn.toString(), 'wei');
    
            let [distanceToTargetInEthTerms, distanceToCapInEthTerms, targetAmount, weightCap, effectiveCap, lsdBalance] = await Promise.all([
                lsdVault.remainingRoomToTargetInEthTerms(contract_addresses[lsd], ethAmountIn),
                lsdVault.remainingRoomToCapInEthTerms(contract_addresses[lsd], ethAmountIn),
                lsdVault.getTargetAmount(contract_addresses[lsd], lsdAmountIn),
                lsdVault.getWeightCap(contract_addresses[lsd], lsdAmountIn),
                lsdVault.getEffectiveCap(contract_addresses[lsd], ethAmountIn),
                lsdVault.getCombinedVaultBalance(contract_addresses[lsd])
            ]);
            
            let darknetRateFloat = parseFloat(darknetRate) / 1e18;
            console.log({darknetRateFloat});
    
            let weightedDistanceToTarget = parseFloat(distanceToTargetInEthTerms)/(parseFloat(targetAmount)*darknetRateFloat);
            let weightedDistanceToCap = parseFloat(distanceToCapInEthTerms)/(parseFloat(weightCap)*darknetRateFloat);
    
            if(weightedDistanceToTarget < weightedDistanceToCap) {
                newEffectiveDistance = weightedDistanceToTarget;
            }
            else {
                newEffectiveDistance = weightedDistanceToCap;
            }
    
            console.log({
                lsd,
                distanceToCapInEthTerms: parseFloat(distanceToCapInEthTerms)/1e18,
                distanceToTargetInEthTerms: parseFloat(distanceToTargetInEthTerms)/1e18,
                targetAmount: parseFloat(targetAmount)/1e18,
                weightCap: parseFloat(weightCap)/1e18,
                ethAmountIn: parseFloat(ethAmountIn)/1e18,
                lsdAmountIn: parseFloat(lsdAmountIn)/1e18,
                effectiveCap: parseFloat(effectiveCap)/1e18,
            });
    
            let endBalanceInETH = parseFloat(lsdBalance.add(lsdAmountIn)/1e18)*darknetRateFloat;
    
            if(endBalanceInETH > parseFloat(effectiveCap)/1e18){
                newEffectiveDistance = 0;
            }
    
            return {
                lsd,
                effectiveDistance: newEffectiveDistance
            };
            } catch (err) {
            console.log(err);
            return null;
            }
        }));
    
        for (const result of results) {
            if (result && result.effectiveDistance > effectiveDistance) {
            effectiveDistance = result.effectiveDistance;
            lsdToDeposit = result.lsd;
            }
        }
    }
    catch(err){
        console.log(err);
    }
    
    console.log("lsd to deposit: ", lsdToDeposit);
    return lsdToDeposit;
}
  

//provider should use chainId = ethId
export async function shouldBuy(ethAmountIn, provider, darknet){
    try {
        let lsd_to_deposit = await pickLSDToDeposit(ethAmountIn, darknet, provider, ['rETH']);
        // console.log("Picked LSD to Deposit!!!", lsd_to_deposit);

        let eth_lsd_minted;
        let eligible_lsd_bought;
        let weth_lsd_amount_out;

        eth_lsd_minted = Math.floor(ethAmountIn / (parseFloat(darknet[lsd_to_deposit].data)/1e18));

        let is_mintable = isMintable(lsd_to_deposit);
        let should_only_mint = shouldOnlyMint(lsd_to_deposit);


        if(lsd_to_deposit === 'sfrxETH') {
            eligible_lsd_bought = await univ3_quoteExactInputSingle(contract_addresses.WETH, contract_addresses.frxETH, ethAmountIn, provider);
            weth_lsd_amount_out = ethers.utils.parseEther(Math.floor((parseFloat(eligible_lsd_bought)/1e18) / (parseFloat(darknet[lsd_to_deposit].data)/1e18)).toString(),'wei');
        } 
        else if(lsd_to_deposit === 'WETH'){
            eligible_lsd_bought = ethers.BigNumber.from(ethAmountIn.toString());
            weth_lsd_amount_out = ethers.BigNumber.from(ethAmountIn.toString());
        }
        else if(should_only_mint){
            eligible_lsd_bought = eth_lsd_minted;
            weth_lsd_amount_out = eth_lsd_minted;
        }
        else {
            eligible_lsd_bought = await univ3_quoteExactInputSingle(contract_addresses.WETH, contract_addresses[lsd_to_deposit], ethAmountIn, provider);
            weth_lsd_amount_out = eligible_lsd_bought;
        }

        let should_buy;

        if(!is_mintable){
            should_buy = true;
        }
        else if(should_only_mint){
            should_buy = false;
        }
        else{
            should_buy = (parseFloat(weth_lsd_amount_out) * (10000-tolerance_to_mint_bps)/10000 > parseFloat(eth_lsd_minted));
        }

        if(!should_buy) {
            weth_lsd_amount_out = ethers.BigNumber.from(new Decimal(eth_lsd_minted.toString()).toFixed(0));
        }

        //calculate the deposit fee and protocol fee from the vdamm 
        let vdamm = new ethers.Contract(contract_addresses.vdAMM, vdAMMABI, provider);
        let zap = new ethers.Contract(contract_addresses.unshETHZap, zapABI, provider);

        let [vdAmmDepositFee, vdAmmProtocolFee] = await vdamm.getDepositFee(weth_lsd_amount_out, contract_addresses[lsd_to_deposit]);

        let zapProtocolFeeBps = await zap.zapProtocolFeeBps();
        let zapProtocolFee = weth_lsd_amount_out.mul(zapProtocolFeeBps).div(10000);

        let depositFee = vdAmmDepositFee.add(zapProtocolFee);
        let protocolFee = vdAmmProtocolFee.add(zapProtocolFee);

        // console.log(zapProtocolFeeBps.toString(), weth_lsd_amount_out.toString(), zapProtocolFee.toString());
        
        return { should_buy, weth_lsd_amount_out, eligible_lsd_bought, lsd_to_deposit, depositFee, protocolFee }
    }
    catch(err){
        console.log(err);
        throw err;
    }
}