import { API_HOST } from "@/constants";
import React, { createContext, useState, useEffect } from "react";

export const PricesContext = createContext();

export function PricesProvider({ children }) {
  const [PricesData, setPricesData] = useState({});
  const PricesApiUrl = `${API_HOST}/prices`;

  useEffect(() => {
    async function fetchPricesData() {
      try {
        const response = await fetch(PricesApiUrl);
        const data = await response.json();
        Object.keys(data).forEach(key => {
          data[key] = parseFloat(data[key]);
        });
        setPricesData(data);
      } catch (error) {
        console.error("Error fetching Prices data:", error);
      }
    }
      fetchPricesData();
  }, []);

  return (
    <PricesContext.Provider value={PricesData}>
      {children}
    </PricesContext.Provider>
  );
}
