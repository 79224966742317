import Input from './input';
import Output from './output';
import Submit from './submit';
import Balance from './balance';
import Switch from './switch';
import { useDispatch, useSelector } from 'react-redux';
import { selectChainFrom, selectChainTo, selectChains, selectToken } from '../../redux/bridge';
import { useContext } from 'react';
import { BalancesContext } from '../../context-providers/Balances';
import { AllowancesContext } from '../../context-providers/Allowances';
import { Typography } from '../shared';
import { GradientCard } from '../v2';

export default function Bridge() {
  let balances = useContext(BalancesContext);
  let allowances = useContext(AllowancesContext);
  let token = useSelector(selectToken);
  let chains = useSelector(selectChains);
  let chainIn = useSelector(selectChainFrom);
  let chainOut = useSelector(selectChainTo);

  let balanceFrom = balances[chains[chainIn].addOn + token];
  let balanceTo = balances[chains[chainOut].addOn + token];

  return (
    <div className="mt-4 mx-auto sm:max-w-xl sm:text-center lg:items-center lg:px-0 lg:text-left ">
      <GradientCard>
        <div className="sm:flex-auto">
          <Typography as="h1" variant="bodyLg" className="text-white" weight="black">
            Bridge
          </Typography>
        </div>
        <div className="mt-2">
          <div className="space-y-3">
            <div>
              <div className="flex flex-col relative">
                <div className="flex relative h-16">
                  <Input balances={balances} />
                </div>

                <Balance bal={balanceFrom} showMax={true} />

                <Switch />

                <div className="flex relative h-16">
                  <Output />
                </div>

                <Balance bal={balanceTo} />
              </div>
            </div>
          </div>
          <Submit balanceFrom={balanceFrom} allowances={allowances} />
          <Typography variant="bodyXs" className="text-white/60 text-center">
            Transactions through the bridge will take longer than native blockchain transactions. (Estimated upto 5
            minutes)
          </Typography>
          <div className="border-t border-slate-700 py-2 mt-4">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between">
                <Typography variant="bodySm" className="text-white/60">
                  Powered By:
                </Typography>
                <img src={`/layerzero.svg`} alt="Powered by" className="h-6" />
              </div>
            </div>
          </div>
        </div>
      </GradientCard>
    </div>
  );
}
