import Dropdown from './dropdown';
import InputAmount from './inputAmount';
import InputBalance from '../Balances/input';
import { selectCurrent, selectItems } from '../../../../redux/vdUSH';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { VDUSHContext } from '../../../../context-providers/VDUSH';
import { ExternalFarmsContext } from '../../../../context-providers/ExternalFarms';
import {Tooltip} from 'react-tooltip';

export default function Input({asset}) {
    let items = useSelector(selectItems);
    let {ethFarms} = useContext(ExternalFarmsContext);


    let {buy,mint, name} = items[asset];

    let buyLink = buy;
    let mintLink = mint;
  
    let {
        boostsushiSwapLP,
        boost8020BPT,
    } = useContext(VDUSHContext);

    let boost = 1;
    if(asset === 'sushiSwapLP' && boostsushiSwapLP.data){
        boost = parseFloat(boostsushiSwapLP.data)/1e18;
    } else if(asset === '8020BPT'){
        boost = parseFloat(boost8020BPT.data)/1e18;;
    }

    let apr = '';
    let text = '';
    if(asset === 'USH' && ethFarms && ethFarms[0]){
        apr = ethFarms[0].apr;
        text = 'vdUSH 1 Year Lock APR: ' + ethFarms[0].aprReward + '<br/>';
    }
    if(asset === 'sushiSwapLP' && ethFarms && ethFarms[1]){
        apr = ethFarms[1].apr;
        text = 'vdUSH 1 Year Lock APR: ' + ethFarms[1].aprReward + '<br/>' + 'Swap Fee APR: ' + ethFarms[1].aprBase;
    }
    if(asset === '8020BPT' && ethFarms && ethFarms[2]){
        apr = ethFarms[2].apr;
        text = 'vdUSH 1 Year Lock APR: ' + ethFarms[2].aprReward + '<br/>' + 'Swap Fee APR: ' + ethFarms[2].aprBase;
    }

    let rand = Math.random();

    return (
        <>  
<div className='flex justify-between mb-2 mr-2 items-center'>
                <p>
                    <span className="text-xs font-semibold text-gray-200 text-right"  data-tooltip-id={asset+rand}  data-tooltip-html={text}> APR: {apr}</span>
                    {text && <Tooltip id={asset+rand} style={{zIndex:100, textAlign:'left',fontSize:'12px'}} place='bottom'/>}
                </p>
                {buyLink &&
                    <a href={buyLink} target="_blank" rel="noreferrer" className="text-xs font-semibold text-blue-300 text-right">Buy {name}</a>
                }
                {mintLink &&
                    <a href={mintLink} target="_blank" rel="noreferrer" className="text-xs font-semibold text-blue-300 text-right">Mint {name}</a>
                }
            </div>
            <div className="flex relative h-16">
                <InputAmount asset={asset}/>
                <Dropdown asset={asset}/>
            </div>
            <div className={`flex justify-between relative h-4`}>
                <div>
                    <span className="text-xs font-semibold text-gray-300 text-left">Bonus USH Multiplier: {boost}x</span>
                </div>
                <InputBalance asset={asset}/>
            </div>
        </>
    )
}
