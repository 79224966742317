import { useContext } from 'react';
import { ARBVDUSHContext } from '../../../context-providers/ARBVDUSH';
import { BalancesContext } from '../../../context-providers/Balances';
import { ExternalFarmsContext } from '../../../context-providers/ExternalFarms';
import { Typography } from '../../shared';

function numberWithCommas(number) {
  try {
    // Convert the number to a string
    let numberString = number.toFixed(2).toString();

    // Use a regular expression to add commas as thousands separators
    let numberWithCommas = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Return the resulting string
    return numberWithCommas;
  } catch (error) {
    console.log({ number, error });
    return number;
  }
}

export default function MyUSH() {
  let { arb_my_locked } = useContext(ARBVDUSHContext);
  let balances = useContext(BalancesContext);

  let duration;
  let myUSHLocked = 0;
  let myARBcamelotLPLocked = 0;

  let ush = 0;

  if (arb_my_locked.data && parseFloat(arb_my_locked.data.end) != 0) {
    duration = new Date(parseFloat(arb_my_locked.data.end) * 1000).toLocaleString();
    myARBcamelotLPLocked = parseFloat(parseFloat(arb_my_locked.data.amountB) / (1e18).toFixed(2));
    myUSHLocked = parseFloat(parseFloat(arb_my_locked.data.amountC) / (1e18).toFixed(2));
  }
  if (balances.ARBvdUSH.data) {
    ush = parseFloat(parseFloat(balances.ARBvdUSH.data.formatted).toFixed(2));
  }

  const stats = [
    { name: 'My ARB vdUSH', value: ush },
    { name: 'My ARB USH Locked', value: myUSHLocked, duration },
    { name: 'My Camelot LP (USH-ETH) Locked', value: myARBcamelotLPLocked, duration },
  ];

  return (
    <>
      {stats.map((stat) => (
        <div key={stat.name} className="px-4 py-4 sm:px-6 lg:px-8 grid gap-1 h-fit">
          <Typography variant="bodyXs" className="text-lightGray" weight="medium">
            {stat.name}
          </Typography>
          <Typography variant="bodyLg" className="text-white" weight="bold">
            {numberWithCommas(parseFloat(stat.value.toFixed(2)))}
          </Typography>
          {stat.duration && (
            <Typography variant="bodyXs" className="text-lightGray">
              locked until {stat.duration}
            </Typography>
          )}
          {/* {stat.apr &&
            <div className="mt-2 text-xs text-gray-500">Swap APR: {stat.apr} </div>
          } */}
        </div>
      ))}
    </>
  );
}
