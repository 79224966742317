import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  input: '',
  output: {
    WETH:0,
    sfrxETH:0,
    rETH:0,
    wstETH:0,
    cbETH:0,
    ankrETH:0,
    swETH:0,
    ETHx: 0,
  },
};

export const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    setInput: (state,action) => {
      state.input = action.payload;
    },
    setOutput: (state,action) => {
      state.output = action.payload;
    }
  },
});

export const {setInput, setOutput} = withdrawSlice.actions;
export const selectInput = (state) => state.withdraw.input;
export const selectOutput = (state) => state.withdraw.output;

export default withdrawSlice.reducer;
