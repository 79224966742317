import { Typography } from '@/components/shared/Typography';
import { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import "react-tooltip/dist/react-tooltip.css";
import { ARBVDUSHContext } from '../../context-providers/ARBVDUSH';
import { BNBVDUSHContext } from '../../context-providers/BNBVDUSH';
import { ExternalFarmsContext } from '../../context-providers/ExternalFarms';
import { PoolsContext } from '../../context-providers/Pools';
import { ProjectsContext } from '../../context-providers/Projects';
import { VDUSHContext } from '../../context-providers/VDUSH';
import {PricesContext} from "@/context-providers/Prices";

export default function TVL() {

  let prices = useContext(PricesContext);
  const ethPrice = prices['ETH'];
  let projects = useContext(ProjectsContext);
  let { poolSum, poolText } = useContext(PoolsContext)
  let { externalFarms } = useContext(ExternalFarmsContext);
  let { VDUSHData } = useContext(VDUSHContext);
  let { BNBVDUSHData } = useContext(BNBVDUSHContext);
  let { ARBVDUSHData } = useContext(ARBVDUSHContext);

  let sum = 0;
  let ethAmount = 0;

  for (const p of projects) {
    try {
      if (p.balance) {
        if (p.price) {
          sum += (parseFloat(p.balance) * p.price);
        }
        else {
          sum += (parseFloat(p.balance) * ethPrice);
        }
        ethAmount += (parseFloat(p.balance)) * p.price / ethPrice;
      }
    }
    catch (e) {
      console.log(e);
    }
  }


  function numberWithCommas(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Use a regular expression to add commas as thousands separators
    let numberWithCommas = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the resulting string
    return numberWithCommas;
  }

  const formattedUSD = (num) => {
    try {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }
    catch (e) {
      return num;
    }
  }

  poolText = "<h6 class='text-center'>unshETH </h6>" + numberWithCommas(parseFloat(ethAmount).toFixed(2)) + ' ETH' + ': ' + formattedUSD(sum) + '</br><hr/>'

  poolText += "<hr/><h6 class='text-center'>Partner Farms </h6>"
  let externalTVL = 0;
  for (const p of externalFarms) {
    try {
      if (p.tvl) {
        externalTVL += (parseFloat(p.tvl));
        poolText +=
          p.type + ': ' + formattedUSD(parseFloat(p.tvl).toFixed(2)) + '</br>';
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  poolText += "<hr/><h6 class='text-center'>vdUSH </h6>"


  if (VDUSHData && VDUSHData.TVL) {
    externalTVL += (parseFloat(VDUSHData.totalTVL));
    poolText +=
      'Total vdUSH' + ': ' + formattedUSD(parseFloat(VDUSHData.totalTVL).toFixed(2)) + '</br>';
  }

  if (BNBVDUSHData && BNBVDUSHData.TVL) {
    externalTVL += (parseFloat(BNBVDUSHData.totalTVL));
    poolText +=
      'Total BNB vdUSH' + ': ' + formattedUSD(parseFloat(BNBVDUSHData.totalTVL).toFixed(2)) + '</br>';
  }

  if (ARBVDUSHData && ARBVDUSHData.TVL) {
    externalTVL += (parseFloat(ARBVDUSHData.totalTVL));
    poolText +=
      'Total ARB vdUSH' + ': ' + formattedUSD(parseFloat(ARBVDUSHData.totalTVL).toFixed(2)) + '</br>';
  }




  return (
    <>
      <div className='flex gap-2' data-tooltip-html={poolText} data-tooltip-id="tvl">
        <Typography variant="bodySm" className='text-mediumBlue' weight='bold'>TVL:</Typography>
        <Typography variant="bodySm" className='text-lightGray' weight='bold'>
          {formattedUSD(parseFloat((sum + poolSum + externalTVL)).toFixed(2))}
        </Typography>
      </div>
      {poolText && <Tooltip style={{ zIndex: 100 }} id="tvl" place='bottom' />}
    </>
  );
}
