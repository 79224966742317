import { useContext } from "react"
import { APRContext } from "../../../context-providers/APR"
import { ExternalFarmsContext } from "../../../context-providers/ExternalFarms";
import { VDUSHContext } from "../../../context-providers/VDUSH"
import { Tooltip } from 'react-tooltip';

export default function Title() {
  let aprs = useContext(APRContext);
  let {VDUSHData, aprRatio, total8020BPTTVLRatio, totalsushiSwapLPTVLRatio, my8020BPTLPTVLRatio, mysushiSwapLPTVLdRatio, myUSHTVLRatio} = useContext(VDUSHContext);
  let {ethFarms} = useContext(ExternalFarmsContext);
  let apr = aprs && aprs.vdush ? parseFloat(aprs.vdush) : 0;
  let aprANKR = aprs && aprs.vdushANKR ? parseFloat(aprs.vdushANKR) : 0;

  const formattedUSD = (num) => {
    try {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }
    catch (e) {
      return num;
    }
  }

  //calculate apr for the total
  let totalApr = parseFloat(apr) + parseFloat(aprANKR);
  let text = `vdUSH Farm APR: ${apr}%<br/>`;
  if(ethFarms[1] && ethFarms[2] && (totalsushiSwapLPTVLRatio || total8020BPTTVLRatio)){
    totalApr += parseFloat(ethFarms[1].aprBase) * totalsushiSwapLPTVLRatio;
    text += `sushiSwapLP Swap APR: ${(parseFloat(ethFarms[1].aprBase) * totalsushiSwapLPTVLRatio).toFixed(2)}%<br/>`;
    text += `8020BPT Swap APR: ${(parseFloat(ethFarms[2].aprBase) * total8020BPTTVLRatio).toFixed(2)}%<br/>`;
    text += `ANKR Farm APR: ${aprANKR}%<br/>`;
    totalApr += parseFloat(ethFarms[2].aprBase) * total8020BPTTVLRatio;
  }
  let rand = Math.random();

  //calculate apr for the user
  let myApr = parseFloat(apr*aprRatio);
  let myText = `vdUSH Farm APR: ${myApr.toFixed(2)}%<br/>`;
  if(ethFarms[1] && ethFarms[2] && (mysushiSwapLPTVLdRatio || my8020BPTLPTVLRatio)){
    myApr += parseFloat(ethFarms[1].aprBase) * mysushiSwapLPTVLdRatio;
    myText += `sushiSwapLP Swap APR: ${(parseFloat(ethFarms[1].aprBase) * mysushiSwapLPTVLdRatio).toFixed(2)}%<br/>`;
    myText += `8020BPT Swap APR: ${(parseFloat(ethFarms[2].aprBase) * my8020BPTLPTVLRatio).toFixed(2)}%<br/>`;
    myApr += parseFloat(ethFarms[2].aprBase) * my8020BPTLPTVLRatio;
  }
  myText += `ANKR Farm APR: ${(aprANKR*aprRatio).toFixed(2)}%<br/>`;
  myApr += parseFloat(aprANKR*aprRatio);
  let rand2 = Math.random();

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-lg font-semibold text-white text-left">vdUSH Farm</h1>
        <h1 className="text-sm font-semibold text-gray-300 text-left" data-tooltip-id={rand}  data-tooltip-html={text}>Average APR: {totalApr.toFixed(2)}% </h1>
        {text && <Tooltip id={rand} style={{zIndex:100, textAlign:'left',lineHeight:'25px',fontSize:'12px'}} place='bottom'/>}
      </div>
      <div className="flex flex-row justify-between">
        {VDUSHData && VDUSHData.TVL &&
          <h1 className="text-sm font-semibold text-gray-300 text-left">TVL: {formattedUSD(VDUSHData.TVL)} </h1>
        }
        {(aprRatio && apr) ? 
          <>
            <h1 className="text-sm font-semibold text-gray-300 text-left" data-tooltip-id={rand2}  data-tooltip-html={myText}>My APR: {(myApr).toFixed(2)}% </h1>
            {myText && <Tooltip id={rand2} style={{zIndex:100, textAlign:'left',lineHeight:'25px',fontSize:'12px'}} place='bottom'/>}
          </>
        : null}
      </div>
      
      {/* <h1 className="text-sm font-semibold text-gray-300 text-right">Effective APR: {apr}% </h1> */}
      <div className="flex flex-row justify-end items-center">
      </div>
    </>
  )
}