import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction } from 'wagmi';
import contractAddresses from '../../contract_addresses';
import LSDVaultABI from '../../ABI/LSDVault.json';
import { useSelector } from 'react-redux';
import { selectInput } from '../../redux/withdraw';
import web3 from 'web3';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setInput, setOutput } from '../../redux/withdraw';
import { setAllowanceWatch, setBalanceWatch } from '../../redux/watch';
import { Button } from '../shared';

export default function Submit({ allowances, balances }) {
  const dispatch = useDispatch();
  const amount = useSelector(selectInput);
  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const [withdrawTxHash, setWithdrawTxHash] = useState(null);

  const approvalWait = useWaitForTransaction({ hash: approvalTxHash });
  const withdrawWait = useWaitForTransaction({ hash: withdrawTxHash });

  let bal = balances['unshETH'].data ? parseFloat(balances['unshETH'].data.formatted) : 0;

  let approvalRequired =
    allowances['unshETHToLSDVault'].data !== undefined
      ? parseInt(allowances['unshETHToLSDVault'].data) < amount * 1e18
      : true;

  //Withdraw Prep
  const { config } = usePrepareContractWrite({
    address: contractAddresses.LSDVault,
    abi: LSDVaultABI,
    functionName: approvalRequired ? '' : 'exit',
    args: [amount ? web3.utils.toHex(web3.utils.toWei(amount, 'ether')) : 0],
    overrides: {
      gasLimit: 600000,
    },
  });

  //Approval Prep
  const approvePrepare = usePrepareContractWrite({
    address: contractAddresses.unshETH,
    abi: erc20ABI,
    functionName: 'approve',
    args: [contractAddresses.LSDVault, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'],
  });

  const approveWrite = useContractWrite({
    ...approvePrepare.config,
    onError(err) {
      toast.error('Approval Failed!');
    },
    onSuccess(data, err) {
      setApprovalTxHash(data.hash);
      dispatch(setAllowanceWatch('unshETHToLSDVault'));
      console.log({ data, err });
      toast.info('Approval Tx Submitted!');
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Withdrawal Failed!');
    },
    onSuccess(data, err) {
      setWithdrawTxHash(data.hash);
      console.log({ data, err });
      toast.info('Withdrawal Tx Submitted!');
      dispatch(setInput(''));
      dispatch(
        setOutput({
          sfrxETH: 0,
          rETH: 0,
          wstETH: 0,
          cbETH: 0,
          WETH: 0,
          ankrETH: 0,
          swETH: 0,
          ETHx: 0,
        }),
      );
      dispatch(setBalanceWatch('unshETH'));
      dispatch(setBalanceWatch('sfrxETH'));
      dispatch(setBalanceWatch('rETH'));
      dispatch(setBalanceWatch('wstETH'));
      dispatch(setBalanceWatch('cbETH'));
      dispatch(setBalanceWatch('WETH'));
      dispatch(setBalanceWatch('ankrETH'));
      dispatch(setBalanceWatch('swETH'));
      dispatch(setBalanceWatch('ETHx'));
    },
  });

  let amountIncorrect = !amount || parseFloat(amount) <= 0;

  let notEnoughBal = parseFloat(amount) > bal;

  let approveButtonClass = `block w-1/2 rounded-lg bg-gradient-to-r py-3 px-4 font-medium text-white shadow from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  let withdrawDisabled = notEnoughBal || amountIncorrect || !write;
  let withdrawButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !withdrawDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    if (approvalRequired) {
      approveWrite.write();
      return;
    } else {
      write();
    }
  };

  return (
    <div className="flex justify-center text-center w-full mt-8 mb-4">
      {approvalWait.isLoading ? (
        <Button variant="gradient" size="big" block disabled={true}>
          Approving...
        </Button>
      ) : approvalRequired ? (
        <Button variant="gradient" size="big" block disabled={!approveWrite.write} onClick={onClick}>
          Approve
        </Button>
      ) : withdrawWait.isLoading ? (
        <Button variant="gradient" size="big" block disabled={true}>
          Withdrawing...
        </Button>
      ) : (
        <Button variant="gradient" size="big" block disabled={withdrawDisabled} onClick={onClick}>
          {notEnoughBal ? 'Insufficient Balance' : 'Withdraw'}
        </Button>
      )}
    </div>
  );
}
