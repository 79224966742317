import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction } from 'wagmi';
import contractAddresses from '../../../../contract_addresses';
import { useSelector } from 'react-redux';
import {
  resetAll,
  selectArgs,
  selectFunctionName,
  selectUSHInput,
  selectSushiInput,
  select8020BPTInput,
  selectContractAddress,
  selectAbi,
} from '../../../../redux/vdUSH';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAllowanceWatch, setBalanceWatch, setVDUSHWatch } from '../../../../redux/watch';
import { AllowancesContext } from '../../../../context-providers/Allowances';
import { BalancesContext } from '../../../../context-providers/Balances';
import { VDUSHContext } from '../../../../context-providers/VDUSH';
import APR from './apr';
import { Button } from '../../../shared';

export default function Submit() {
  const allowances = useContext(AllowancesContext);
  const balances = useContext(BalancesContext);
  const { my_locked } = useContext(VDUSHContext);
  const dispatch = useDispatch();

  const amountUSH = useSelector(selectUSHInput);
  const amountsushiSwapLP = useSelector(selectSushiInput);
  const amount8020BPT = useSelector(select8020BPTInput);

  const functionName = useSelector(selectFunctionName);
  const args = useSelector(selectArgs);
  const contract_address = useSelector(selectContractAddress);
  const abi = useSelector(selectAbi);

  let increaseStake = false;
  if (my_locked.data && parseFloat(my_locked.data.end) != 0) {
    increaseStake = true;
  }

  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const [lockTxHash, setSwapTxHash] = useState(null);

  const approvalWait = useWaitForTransaction({ hash: approvalTxHash });
  const lockWait = useWaitForTransaction({ hash: lockTxHash });

  let balUSH = balances['USH'].data ? parseFloat(balances['USH'].data.formatted) : 0;
  let bal8020BPT = balances['8020BPT'].data ? parseFloat(balances['8020BPT'].data.formatted) : 0;
  let balSushiSwapLP = balances['sushiSwapLP'].data ? parseFloat(balances['sushiSwapLP'].data.formatted) : 0;

  let allowUSH = allowances['USHvdUSH'].data ? parseFloat(allowances['USHvdUSH'].data) / 1e18 : 0;
  let allow8020BPT = allowances['8020BPTvdUSH'].data ? parseFloat(allowances['8020BPTvdUSH'].data) / 1e18 : 0;
  let allowSushiSwapLP = allowances['sushiSwapLPvdUSH'].data
    ? parseFloat(allowances['sushiSwapLPvdUSH'].data) / 1e18
    : 0;

  let currentApproval = {
    asset: '',
    contract: '',
  };

  if (parseFloat(amountUSH) > 0 && parseFloat(amountUSH) > allowUSH) {
    currentApproval.asset = 'USH';
    currentApproval.contract = contractAddresses.vdUSH;
  } else if (parseFloat(amount8020BPT) > 0 && parseFloat(amount8020BPT) > allow8020BPT) {
    currentApproval.asset = '8020BPT';
    currentApproval.contract = contractAddresses.vdUSH;
  } else if (parseFloat(amountsushiSwapLP) > 0 && parseFloat(amountsushiSwapLP) > allowSushiSwapLP) {
    currentApproval.asset = 'sushiSwapLP';
    currentApproval.contract = contractAddresses.vdUSH;
  }

  let approvalRequired = currentApproval.asset !== '' && currentApproval.contract !== '';

  //Lock Prep
  const { config } = usePrepareContractWrite({
    address: contract_address,
    abi,
    functionName: approvalRequired ? '' : functionName,
    args,
    overrides: {
      // gasLimit: 600000
    },
    onError: (err) => {
      console.log({ functionName, args, err });
    },
  });

  //Approval Prep
  const approvePrepare = usePrepareContractWrite({
    address: contractAddresses[currentApproval.asset],
    abi: erc20ABI,
    functionName: 'approve',
    args: [currentApproval.contract, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'],
    onError: (err) => {
      console.log(`err is ${err}`);
    },
  });

  const approveWrite = useContractWrite({
    ...approvePrepare.config,
    onError(err) {
      toast.error('Approval Failed!');
    },
    onSuccess(data, err) {
      setApprovalTxHash(data.hash);
      dispatch(setAllowanceWatch(`${currentApproval.asset}vdUSH`));
      console.log({ data, err });
      toast.info('Approval Tx Submitted!');
      // dispatch(resetAll());
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Lock Failed!');
    },
    onSuccess(data, err) {
      console.log({ data, err });
      dispatch(setVDUSHWatch('my_locked'));
      dispatch(setBalanceWatch('vdUSH'));
      dispatch(setBalanceWatch('8020BPT'));
      dispatch(setBalanceWatch('sushiSwapLP'));
      dispatch(setBalanceWatch('USH'));
      setSwapTxHash(data.hash);
      toast.info('Lock Tx Submitted!');
      dispatch(resetAll());
    },
  });

  let notEnoughBal = amountUSH > balUSH || amount8020BPT > bal8020BPT || amountsushiSwapLP > balSushiSwapLP;

  let approveButtonClass = `block w-1/2 rounded-lg bg-gradient-to-r py-3 px-4 font-medium text-white shadow from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  let lockDisabled = notEnoughBal || !write;
  let lockButtonClass = `block w-3/4 rounded-lg h-full bg-gradient-to-r ${
    !lockDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    if (approvalRequired) {
      approveWrite.write();
      return;
    } else {
      write();
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center text-center gap-4 w-full mb-4">
        {approvalWait.isLoading ? (
          <Button variant="gradient" block size="big">
            Approving...
          </Button>
        ) : approvalRequired ? (
          <Button variant="gradient" block size="big" disabled={!approveWrite.write} onClick={onClick}>
            Approve{' ' + currentApproval.asset}
          </Button>
        ) : lockWait.isLoading ? (
          <Button variant="gradient" block size="big" disabled={true}>
            Locking...
          </Button>
        ) : (
          <Button variant="gradient" block size="big" disabled={lockDisabled} onClick={onClick}>
            {notEnoughBal ? 'Insufficient Balance' : increaseStake ? 'Increase Lock' : 'Lock'}
          </Button>
        )}
        <APR />
      </div>
    </>
  );
}
