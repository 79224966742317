import MaxAPY from './max_apy';
import ProjectRatios from './project_ratios';
import TVL from './tvl';
import Volume from './volume';

export default function Dominance() {
  return (
    // grid grid-cols-1 md:grid-cols-3
    <div className="">
      <div className="py-1 px-1 md:py-4 md:px-4 max-w-full md:max-w-container mx-auto">
        <div className="flex gap-2 justify-center mb-6 flex-wrap">
          <TVL />
          <MaxAPY />
          <Volume />
        </div>
        <div className="flex flex-wrap justify-center">
          <ProjectRatios />
        </div>
      </div>
    </div>
  );
}
