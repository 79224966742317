import { useSelector } from 'react-redux';
import {
  selectLoading,
  selectMinAmountUnsheth,
  selectFunctionName,
  selectDepositFee,
  selectLSDOut,
} from '../../redux/deposit';
import { useContext } from 'react';
import { PriceAndConversionsContext } from '../../context-providers/PriceAndConversions';
import { Typography } from '../shared';

export default function MinAmount() {
  const minAmountUnsheth = useSelector(selectMinAmountUnsheth);
  const loading = useSelector(selectLoading);
  const { newConversions } = useContext(PriceAndConversionsContext);
  const functionName = useSelector(selectFunctionName);
  const lsdOut = useSelector(selectLSDOut);

  const depositFee = useSelector(selectDepositFee);

  const depositPercentage = (parseFloat(depositFee) / (parseFloat(lsdOut) + parseFloat(depositFee))) * 100;

  const divider =
    newConversions.unshETH.data && functionName !== 'deposit_lsd' ? parseFloat(newConversions.unshETH.data) / 1e18 : 1;

  return !loading && minAmountUnsheth ? (
    <div>
      <Typography variant="bodyXs" className="text-white/60">
        Min Amount: {(minAmountUnsheth / divider).toFixed(5)} unshETH
      </Typography>
      <Typography variant="bodyXs" className="text-white/60">
        Deposit Fee: {depositPercentage.toFixed(2)}%
      </Typography>
    </div>
  ) : (
    <div></div>
  );
}
