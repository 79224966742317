import {useState} from 'react';
import {toast} from 'react-toastify';
import { usePrepareContractWrite, useContractWrite,useWaitForTransaction, erc20ABI } from 'wagmi';
import web3 from 'web3';
import contractAddresses from '../../../contract_addresses';
import {useDispatch, useSelector} from 'react-redux';
import {setDuration, setAmount} from '../../../redux/stake';
import { useEffect, useContext } from 'react';
import moment from 'moment';
import {BlockTimestampContext} from '../../../context-providers/BlockTimestamp';
import { setAllowanceWatch, setBalanceWatch, setPoolWatch } from '../../../redux/watch';



export default function StakeCommunal({balances, pool}) {
  let {max, name, asset, contractAddress, abi, allowance, expired, finalDate, timeText, depositOff } = pool;

  let currDate = useContext(BlockTimestampContext);
  const [stakeInput, setStakeInput] = useState();

  //use the selector to get the state.stake
  let dispatch = useDispatch();
  let duration = useSelector(state => state.stake[`${name}Duration`]);

  let setDurationInput = ((input)=>{
    console.log("TYPEOF:", typeof input)
    console.log("CURRENT DURATION:", duration, typeof duration)
    console.log("NEW DURATION:", input, typeof input)
    dispatch(setDuration({name, duration:input}))
   });

  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const [stakeTxHash, setStakeTxHash] = useState(null);

  const approvalWait = useWaitForTransaction({hash:approvalTxHash});
  const stakeWait = useWaitForTransaction({hash:stakeTxHash});

  let bal = balances[asset];

  let approvalRequired = allowance.data !== undefined ? (parseInt(allowance.data) < stakeInput*1e18) : true;
  let amountIncorrect = !stakeInput||  parseFloat(stakeInput) <= 0;

  let formattedBal = bal.data ? parseFloat(bal.data.formatted) : 0;
  let notEnoughBal = parseFloat(stakeInput) > formattedBal; 

  const maxStake = () => {
    setStakeInput(bal.data.formatted);
  }
  const onStakeChange = (e) => {
    setStakeInput(e.target.value);
  }
  const durationChange = (e) => {
    setDurationInput(parseInt(e.target.value));
  }

  //Approval Prep
  const approvePrepare = usePrepareContractWrite({
    address: contractAddresses[asset],
    abi: erc20ABI,
    functionName: 'approve',
    args: [
        pool.contractAddress,
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    ],
    onError: (err) => {
        console.log({err});
    }
  });

  const approveWrite = useContractWrite({...approvePrepare.config,
      onError(err) {
          console.log({err});
          toast.error('Approval Failed!');
      },
      onSuccess(data,err) {
          setApprovalTxHash(data.hash);
          dispatch(setPoolWatch(name+'allowance'));
          toast.info('Approval Tx Submitted!');
      }
  });

  //prepare the staking
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi,
    functionName: 'stakeLocked',
    overrides: {
      // gasLimit:600000,
    },
    args: [
      stakeInput ? web3.utils.toHex(web3.utils.toWei(stakeInput, 'ether')) : 0, //amount
      web3.utils.toHex(duration * 86400), //seconds
    ],
    onError(err) {
      console.log({err});
      // toast.error('Staking Failed!');
    }
  })

  const { write } = useContractWrite({...config,
    onError(err) {
      console.log({err});
      toast.error('Staking Failed!');
    },
    onSuccess(data,err) {
      dispatch(setBalanceWatch(asset));
      dispatch(setPoolWatch(name + 'staked'));
      setStakeTxHash(data.hash);
      setStakeInput('');
      toast.info('Stake Tx Submitted!');
    }
  });

  const onClick = () => {
    if(approvalRequired) {
      approveWrite.write();
      return;
    }
    else {
      write();
    }
  }

  let approveButtonClass = `relative min-w-[130px] h-full bg-gradient-to-r rounded-r-lg from-blue-500 to-cyan-600 py-3 px-4 font-medium text-white shadow hover:from-blue-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  let stakeDisabled = notEnoughBal || amountIncorrect || expired || !write;

  let stakeButtonClass = `block w-[${!notEnoughBal ? '130px' : '250px'}] ${notEnoughBal && 'text-xs'} h-full bg-gradient-to-r ${!stakeDisabled ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700' : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'} rounded-r-lg  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;


  return (
    <div className={`flex flex-col mt-2`}>

      <div className="flex flex-row justify-between items-center">
        <span className="text-sm text-gray-200 w-full">Unstaked {asset}</span>
        <div className="w-full rounded-full text-right mr-1">
        {(bal.isLoading) ? <span className="text-sm text-gray-400">Loading...</span> : 
                (!bal.data) ? <span className="text-sm text-gray-400">0</span> :
              <>
              <span className="text-sm text-gray-400">{parseFloat(parseFloat(bal.data.formatted).toFixed(5))}</span>
              <span onClick={maxStake} className='text-sm text-blue-500 cursor-pointer'> Max </span>
              </>
          }
        </div>
      </div>
      <div className="relative pt-1">
        <div className="flex mb-2 mt-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 text-gray-300">
              Duration: {duration} {timeText}
            </span>
          </div>
        </div>
        <input type="range"
          className=" form-range w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
          onChange={durationChange}
          min={timeText === 'Hours' ? 24 : 1} max={max} step="1" value={duration}
        />
      </div>

      <div className="flex flex-row justify-between items-center mt-4 h-[50px]">

          <input value={stakeInput} min={0} onChange={onStakeChange} className="bg-slate-800 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500" type="number" placeholder="Enter Amount" />
          {approvalWait.isLoading ? 
            <button disabled={true} className={approveButtonClass}>Approving...</button>
          :
            approvalRequired ?
              <button disabled={!approveWrite.write} onClick={onClick} className={approveButtonClass}>Approve</button>
            :
              stakeWait.isLoading ?
                <button disabled={true} className={stakeButtonClass}> Staking...</button>
              :
                <button disabled={stakeDisabled} onClick={onClick} className={stakeButtonClass}> 
                  {notEnoughBal ? 'Insufficient Balance' : 'Stake'}
                </button>
            }
      </div>
    </div>
  )
}