import {useContext} from 'react';
import {PoolsContext} from '../../context-providers/Pools';
import { ExternalFarmsContext } from '../../context-providers/ExternalFarms';
import { Tooltip } from 'react-tooltip'
import { Typography } from '@/components/shared'

export default function APR() {
  let {pools} = useContext(PoolsContext);
  let {externalFarms, ethFarms, bnbFarms, arbFarms} = useContext(ExternalFarmsContext);

  let max_apy=0;
  let minTVL =10000;

  let text = '';
  text+= "<h6 class='text-center'>unshETH </h6>"

  for(let pool of pools) {
    if(pool.name === 'unshETH' || pool.name === 'unshETH (BNB)' || pool.name === 'unshETH (Arbitrum)' || pool.name === 'unshETH (Dual ARB)') {
      if(!pool.expired && pool.tvl > minTVL && pool.apy && max_apy < parseFloat(pool.apy)){
        max_apy = parseFloat(pool.apy);
      }
      if(pool.apy > 0) text += pool.name + ': ' + pool.apy + '% </br>';
    }
  }

  text += '<hr/>';
  text+= "<h6 class='text-center'>Partner Farms </h6>"

  for(let farm of externalFarms){
    if(farm.apr && farm.tvl > minTVL && max_apy < parseFloat(farm.apr)){
      max_apy = parseFloat(farm.apr);
    }
    if(parseFloat(farm.apr) > 0) text += farm.type + ': ' + farm.apr + ' </br>';
  }

  text+= "<hr/><h6 class='text-center'>vdUSH </h6>"

  for(let farm of ethFarms){
    if(farm.tvl > minTVL) {
      if(farm.apr && max_apy < parseFloat(farm.apr)){
        max_apy = parseFloat(farm.apr);
      }
      if(parseFloat(farm.apr) > 0) text += farm.type + ': ' + farm.apr + ' </br>';
    }
  }

  for(let farm of bnbFarms){
    if(farm.tvl > minTVL){
      if(farm.apr && max_apy < parseFloat(farm.apr)){
        max_apy = parseFloat(farm.apr);
      }
      if(parseFloat(farm.apr) > 0) text += farm.type + ': ' + farm.apr + ' </br>';
    }
  }

  for(let farm of arbFarms){
    if(farm.tvl > minTVL) {
      if(farm.apr && farm.tvl > minTVL && max_apy < parseFloat(farm.apr)){
        max_apy = parseFloat(farm.apr);
      }
      if(parseFloat(farm.apr) > 0) text += farm.type + ': ' + farm.apr + ' </br>';
    }
  }



  return (
    <div className='flex gap-2'  data-tooltip-id="maxapr"  data-tooltip-html={text}>
      <Typography as="span" variant="bodySm" className='text-mediumBlue' weight='bold'>Max APR:</Typography>
      <Typography as="span" variant="bodySm" className='text-lightGray' weight='bold'>
        {parseFloat(max_apy).toFixed(2)}%
      </Typography>
      {text && <Tooltip id="maxapr" style={{zIndex:100, textAlign:'left'}} place='bottom'/>}
    </div>
  )
}