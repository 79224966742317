import Item from './item';
import { ethId, bnbId, arbId } from '../../constants';
import { BalancesContext } from '../../context-providers/Balances';
import { useContext } from 'react';
import { Typography } from '../shared';
import { GradientCard } from '../v2';

const projects = [
  [
    { name: 'unshETH', initials: 'unshETH', imageUrl: 'unshETH.svg', buttonType: 'Stake', chainId: ethId },
    { name: 'unshETH', initials: 'USH', imageUrl: 'USH.svg', buttonType: 'Stake', chainId: ethId },
    { name: 'Ethereum', initials: 'ETH', imageUrl: 'eth.svg', buttonType: 'Deposit', chainId: ethId },
    { name: 'Wrapped Ethereum', initials: 'WETH', imageUrl: 'weth.png', buttonType: 'Deposit', chainId: ethId },
    { name: 'Frax', initials: 'sfrxETH', imageUrl: 'frxETH.png', buttonType: 'Deposit', chainId: ethId },
    { name: 'Rocket Pool', initials: 'rETH', imageUrl: 'rETH.png', buttonType: 'Deposit', chainId: ethId },
    { name: 'Lido ', initials: 'wstETH', imageUrl: 'stETH.png', buttonType: 'Deposit', chainId: ethId },
    { name: 'Coinbase ', initials: 'cbETH', imageUrl: 'cbETH.png', buttonType: 'Deposit', chainId: ethId },
    { name: 'Ankr ', initials: 'ankrETH', imageUrl: 'ankrETH.svg', buttonType: 'Deposit', chainId: ethId },
    { name: 'Swell ', initials: 'swETH', imageUrl: 'swETH.png', buttonType: 'Deposit', chainId: ethId },
    { name: 'Ankr', initials: 'ANKR', imageUrl: 'ankr.png', buttonType: 'Deposit', chainId: ethId },
    { name: 'Tether', initials: 'USDT', imageUrl: 'usdt-bnb.png', buttonType: 'Deposit', chainId: ethId },
    { name: 'Stader ', initials: 'ETHx', imageUrl: 'ETHx.png', buttonType: 'Deposit', chainId: ethId }
  ],
  
  [
    { name: 'unshETH BNB', initials: 'BNBunshETH', imageUrl: 'unshETH.svg', buttonType: 'Stake', chainId: bnbId },
    { name: 'unshETH BNB', initials: 'BNBUSH', imageUrl: 'ush.png', buttonType: 'Stake', chainId: bnbId },
    { name: 'BNB', initials: 'BNBBNB', imageUrl: 'bnb-bnb.svg', buttonType: 'Deposit', chainId: bnbId }
  ],

  [
    { name: 'unshETH Arbitrum', initials: 'ARBunshETH', imageUrl: 'unshETH.svg', chainId: arbId },
    { name: 'unshETH Arbitrum', initials: 'ARBUSH', imageUrl: 'ush.png', chainId: arbId },
    { name: 'Ethereum Arbitrum', initials: 'ARBETH', imageUrl: 'eth.svg', chainId: arbId }
  ],
];

export default function Portfolio() {
  let balances = useContext(BalancesContext);
  let batches = projects;

  return (
    <div className="mt-4 mx-auto sm:max-w-xl sm:text-center lg:items-center lg:px-0 lg:text-left mb-8">
      <GradientCard>
        <div className="sm:flex-auto">
          <Typography as="h1" variant="bodyLg" className="text-white" weight="black">
            Portfolio
          </Typography>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full rounded-lg px-4 mt-4">
            <thead className="w-full flex flex-col border-b border-slate-500">
              <tr className="flex flex-row">
                <th className="md:px-6 py-3.5 text-left text-sm font-semibold text-white w-1/2">
                  <Typography variant="bodyXs" weight="medium" className="text-white">
                    Token
                  </Typography>
                </th>
                <th className="py-3.5 text-left text-sm font-semibold text-white w-1/4">
                  <Typography variant="bodyXs" weight="medium" className="text-white">
                    Balance
                  </Typography>
                </th>
                <th className="py-3.5 text-sm font-semibold text-center text-white w-1/4">
                  <Typography variant="bodyXs" weight="medium" className="text-white text-right">
                    Action
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody className="flex flex-col items-center justify-between w-full my-4">
              {batches.map((batch, bI, arr) => (
                <>
                  {batch.map((p, i) => (
                    <Item
                      key={i}
                      balance={balances[p.initials]}
                      imageUrl={p.imageUrl}
                      initials = { p.initials }
                      price = { p.price }
                      buttonType={p.buttonType}
                      name={p.name}
                    />
                  ))}
                  {bI !== arr.length - 1 && (
                    <tr className="flex flex-row w-full">
                      <th className="py-3.5 text-white w-full">
                        <hr className="border-slate-500 border-1" style={{ width: '100%' }} />
                      </th>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </GradientCard>
    </div>
  );
}
