import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction } from 'wagmi';
import contractAddresses from '../../../contract_addresses';
import unshETHZapABI from '../../../ABI/unshETHZap.json';
import { useSelector } from 'react-redux';
import { selectItems, selectCurrent } from '../../../redux/dropdown';
import { resetAll, selectArgs, selectFunctionName, selectGasLimit, selectInput } from '../../../redux/deposit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setInput, setOutput } from '../../../redux/deposit';
import { setAllowanceWatch, setBalanceWatch } from '../../../redux/watch';
import { Button } from '../../shared';

export default function Submit({ allowances, balances }) {
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const current = useSelector(selectCurrent);
  const amount = useSelector(selectInput);
  const functionName = useSelector(selectFunctionName);
  const args = useSelector(selectArgs);
  const gasLimit = useSelector(selectGasLimit);
  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const [depositTxHash, setDepositTxHash] = useState(null);
  const [displayErr, setDisplayErr] = useState(null);

  const { initials } = items[current];

  const approvalWait = useWaitForTransaction({ hash: approvalTxHash });
  const depositWait = useWaitForTransaction({ hash: depositTxHash });

  let bal = balances[initials].data ? parseFloat(balances[initials].data.formatted) : 0;

  let approvalRequired =
    allowances[initials].data !== undefined ? parseInt(allowances[initials].data) < amount * 1e18 : true;

  //Deposit Prep
  const { config } = usePrepareContractWrite({
    address: contractAddresses.unshETHZap,
    abi: unshETHZapABI,
    functionName: approvalRequired ? '' : functionName,
    args,
    overrides: {
      /* Setting the gas limit for the transaction. */
      // gasLimit
    },
    onError(error) {
      console.log(error);
      try {
        if (error) {
          if (error.toString().includes(`insufficient funds for gas * price + value`)) {
            setDisplayErr(`Not Enough Funds For Gas`);
          }
          if (error.reason.includes('Deposit exceeds')) {
            toast.error(error.reason);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    onSuccess(data, err) {
      console.log({ data, err });
      setDisplayErr(null);
    },
  });

  //Approval Prep
  const approvePrepare = usePrepareContractWrite({
    address: contractAddresses[initials],
    abi: erc20ABI,
    functionName: 'approve',
    args: [contractAddresses.unshETHZap, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'],
  });

  const approveWrite = useContractWrite({
    ...approvePrepare.config,
    onError(err) {
      toast.error('Approval Failed!');
    },
    onSuccess(data, err) {
      setApprovalTxHash(data.hash);
      dispatch(setAllowanceWatch(initials));
      console.log({ data, err });
      toast.info('Approval Tx Submitted!');
      //dispatch events to clear the input and output elements in redux state.deposit
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Deposit Failed!');
    },
    onSuccess(data, err) {
      dispatch(setBalanceWatch(initials));
      dispatch(setBalanceWatch('unshETH'));
      setDepositTxHash(data.hash);
      toast.info('Deposit Tx Submitted!');
      dispatch(resetAll());
    },
  });

  let amountIncorrect = !amount || parseFloat(amount) <= 0;

  let notEnoughBal = parseFloat(amount) > bal;

  let approveButtonClass = `block w-1/2 rounded-lg bg-gradient-to-r py-3 px-4 font-medium text-white shadow from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  let depositDisabled = notEnoughBal || amountIncorrect || !write || displayErr != null;
  let depositButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !depositDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    if (approvalRequired) {
      approveWrite.write();
      return;
    } else {
      write();
    }
  };

  return (
    <>
      <div className="flex justify-center text-center w-full mt-8 mb-4">
        {approvalWait.isLoading ? (
          <Button variant="gradient" block size="big" disabled={true} className={approveButtonClass}>
            Approving...
          </Button>
        ) : approvalRequired ? (
          <Button
            variant="gradient"
            block
            size="big"
            disabled={!approveWrite.write}
            onClick={onClick}
            className={approveButtonClass}
          >
            Approve
          </Button>
        ) : depositWait.isLoading ? (
          <Button variant="gradient" block size="big" disabled={true} className={depositButtonClass}>
            Depositing...
          </Button>
        ) : (
          <Button
            variant="gradient"
            block
            size="big"
            disabled={depositDisabled}
            onClick={onClick}
            className={depositButtonClass}
          >
            {displayErr
              ? displayErr
              : amountIncorrect
              ? 'Enter Amount'
              : notEnoughBal && !amountIncorrect
              ? 'Insufficient Balance'
              : 'Deposit'}
          </Button>
        )}
      </div>
    </>
  );
}
