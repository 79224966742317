import { createContext, useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { PoolsContext } from "./Pools";
import { VDUSHContext } from "./VDUSH";
import { BNBVDUSHContext } from "./BNBVDUSH";
import { ARBVDUSHContext } from "./ARBVDUSH";
import { APRContext } from "./APR";
import { useSelector } from "react-redux";
import { selectMaxDuration } from "../redux/vdUSH";
import { PricesContext } from "./Prices";
import { useBalance } from "wagmi";
import contract_addresses from "../contract_addresses";
import { ethId, bnbId, arbId, API_HOST } from "../constants";

export const ExternalFarmsContext = createContext();

export function ExternalFarmsProvider({ children }) {
  const [externalFarms, setFarms] = useState([]);

  let {pools} = useContext(PoolsContext);
  let prices = useContext(PricesContext);
  let max_duration = useSelector(selectMaxDuration);
  let {VDUSHData, boostsushiSwapLP, boost8020BPT, sushiAPRRatio, bptAPRRatio, USHAPRRatio} = useContext(VDUSHContext);
  let {BNBVDUSHData, boostBNBpancakeSwapLP, pancakeRatio, BNBUSHRatio } = useContext(BNBVDUSHContext);
  let {ARBVDUSHData, boostARBcamelotLP, camelotRatio, ARBUSHRatio} = useContext(ARBVDUSHContext);

  let aprs = useContext(APRContext);

  let durationRatio = 53/53; //53 is the max lock time of the vdush farm

  let unshETH = pools.filter((pool) => pool.name === 'unshETH')[0];
  let BNBunshETH = pools.filter((pool) => pool.name === 'unshETH (BNB)')[0];
  let ARBunshETH = pools.filter((pool) => pool.name === 'unshETH (Arbitrum)')[0];
  let dualARBunshETH = pools.filter((pool) => pool.name === 'unshETH (Dual ARB)')[0];

  //get the tvl of the unshETH farm by getting the amount of unshETH in the farm by using the useBalance hook and multiplying it by the price of unshETH
  let unshETHTVL = 0;
  let unshETHAmount = useBalance({token:contract_addresses.unshETH, address: contract_addresses['unshETH-farm'], chainId:ethId, watch:false});
  if(prices['unshETH'] && unshETHAmount.data){
    unshETHTVL = (parseFloat(unshETHAmount.data.formatted) * parseFloat(prices['unshETH'])).toFixed(2);
  }

  //do it for bnb and arb
  let BNBunshETHTVL = 0;
  let BNBunshETHAmount = useBalance({token:contract_addresses.BNBunshETH, address: contract_addresses['BNBunshETH-farm'], chainId:bnbId, watch:false});
  if(prices['unshETH'] && BNBunshETHAmount.data){
    BNBunshETHTVL = (parseFloat(BNBunshETHAmount.data.formatted) * parseFloat(prices['unshETH'])).toFixed(2);
  }

  let ARBunshETHTVL = 0;
  let ARBunshETHAmount = useBalance({token:contract_addresses.ARBunshETH, address: contract_addresses['ARBunshETH-farm'], chainId:arbId, watch:false});
  if(prices['unshETH'] && ARBunshETHAmount.data){
    ARBunshETHTVL = (parseFloat(ARBunshETHAmount.data.formatted) * parseFloat(prices['unshETH'])).toFixed(2);
  }

  let dualARBunshETHTVL = 0;
  let dualARBunshETHAmount = useBalance({token:contract_addresses.ARBunshETH, address: contract_addresses['ARBunshETH-dualfarm'], chainId:arbId, watch:false});
  if(prices['unshETH'] && dualARBunshETHAmount.data){
    dualARBunshETHTVL = (parseFloat(dualARBunshETHAmount.data.formatted) * parseFloat(prices['unshETH'])).toFixed(2);
  }

  let unshETHFarms = [
    //ETH
    {
      protocolImg:'/unshETH.svg',
      protocol:'unshETH',
      protocolLink:'/',
      rewardToken:'USH',
      type:'unshETH',
      chain:'/eth.svg',
      tvl:unshETHTVL,
      actions:[
        {
          link:'/deposit',
          text:'Mint unshETH',
        },
        {
          link:'/stake',
          text:'Stake unshETH',
        },
      ],
      apr:unshETH.apy + '%',
      aprBase:(unshETH.aprs.reduce((a,b) => a + parseFloat(b.value), 0) - parseFloat(unshETH.aprs[0].value)).toFixed(2) + '%',
      aprReward:unshETH.aprs[0].value + '%',
    },
    //BNB
    {
      protocolImg:'/unshETH.svg',
      protocol:'unshETH',
      protocolLink:'/',
      rewardToken:'USH',
      type:'unshETH',
      chain:'/bnb-bnb.svg',
      tvl:BNBunshETHTVL,
      actions:[
        {
          link:'/deposit',
          text:'Mint unshETH',
        },
        {
          link:'/stake',
          text:'Stake unshETH',
        },
      ],
      apr:BNBunshETH.apy + '%',
      aprBase:(BNBunshETH.aprs.reduce((a,b) => a + parseFloat(b.value), 0) - parseFloat(BNBunshETH.aprs[0].value)).toFixed(2) + '%',
      aprReward:BNBunshETH.aprs[0].value + '%',
    },
    //arb
    {
      protocolImg:'/unshETH.svg',
      protocol:'unshETH',
      protocolLink:'/',
      rewardToken:'USH',
      type:'unshETH',
      chain:'/arb.png',
      tvl:ARBunshETHTVL,
      actions:[
        {
          link:'/deposit',
          text:'Mint unshETH',
        },
        {
          link:'/stake',
          text:'Stake unshETH',
        },
      ],
      apr:parseFloat(ARBunshETH.apy).toFixed(2) + '%',
      aprBase:(ARBunshETH.aprs.reduce((a,b) => a + parseFloat(b.value), 0) - parseFloat(ARBunshETH.aprs[0].value)).toFixed(2) + '%',
      aprReward:parseFloat(ARBunshETH.aprs[0].value).toFixed(2) + '%',
    },
    {
      protocolImg:'/unshETH.svg',
      protocol:'unshETH',
      protocolLink:'/',
      rewardToken:'USH | ARB',
      type:'unshETH',
      chain:'/arb.png',
      tvl:dualARBunshETHTVL,
      actions:[
        {
          link:'/deposit',
          text:'Mint unshETH',
        },
        {
          link:'/stake',
          text:'Stake unshETH',
        },
      ],
      apr:parseFloat(dualARBunshETH.apy).toFixed(2) + '%',
      aprBase:(dualARBunshETH.aprs.slice(2).reduce((a,b) => a + parseFloat(b.value), 0)).toFixed(2) + '%',
      aprReward:parseFloat(dualARBunshETH.aprs[0].value + dualARBunshETH.aprs[1].value).toFixed(2) + '%',
    },
  ];
  
  let ethFarms = [
    {
      protocolImg:'/USH.svg',
      protocol:'unshETH',
      tvl:VDUSHData.ushUSD,
      protocolLink:'',
      rewardToken:'USH | vdUSH | ANKR',
      type:'USH',
      chain:'/eth.svg',
      actions:[
        {
          link:'https://swap.defillama.com/?chain=ethereum&from=0x0000000000000000000000000000000000000000&to=0xe60779cc1b2c1d0580611c526a8df0e3f870ec48',
          text:'Buy on Aggregator'
        },
        {
          link:'/vdUSH',
          text:'Farm for vdUSH'
        },
      ],
      apr:USHAPRRatio && (parseFloat(aprs.vdush * durationRatio * USHAPRRatio + aprs.vdushANKR * durationRatio * USHAPRRatio)).toFixed(2) + '%',
      aprReward:USHAPRRatio && parseFloat(aprs.vdush*durationRatio * USHAPRRatio + aprs.vdushANKR *durationRatio * USHAPRRatio).toFixed(2) + '%',
      aprBase: '0%'
    },
  {
    protocolImg:'/sushiSwapLP.svg',
    protocol:'Sushi Swap',
    tvl:VDUSHData.sushiSwapLPUSD,
    protocolLink:'',
    rewardToken:'USH | vdUSH | ANKR',
    type:'Sushi Swap LP (USH-ETH)',
    chain:'/eth.svg',
    actions:[
      {
        link:'https://www.sushi.com/earn/eth:0xaaf448d30f01b429fb6e7f9af6a8ff66e694f312/add',
        text:'Mint on Sushi'
      },
      {
        link:'/vdUSH',
        text:'Farm for vdUSH'
      },
    ],
    apr:sushiAPRRatio && (parseFloat(aprs.vdush*durationRatio*sushiAPRRatio)+ parseFloat(aprs.sushi) + aprs.vdushANKR *durationRatio * sushiAPRRatio).toFixed(2) + '%',
    aprReward:sushiAPRRatio && parseFloat(aprs.vdush*durationRatio*sushiAPRRatio + aprs.vdushANKR *durationRatio * sushiAPRRatio).toFixed(2) + '%',
    aprBase:aprs.sushi+'%',
  }
];

let arbFarms = [
  {
    protocolImg:'/USH.svg',
    protocol:'unshETH',
    tvl:ARBVDUSHData.ARBushUSD,
    protocolLink:'',
    rewardToken:'USH | GRAIL | ARB',
    type:'Arbitrum USH',
    chain:'/arb.png',
    actions:[
      {
        link:'https://swap.defillama.com/?chain=arbitrum&from=0x0000000000000000000000000000000000000000&to=0x51a80238b5738725128d3a3e06ab41c1d4c05c74',
        text:'Buy on Aggregator'
      },
      {
        link:'/vdUSH',
        text:'Farm for vdUSH'
      }
    ],
    apr:ARBUSHRatio && (parseFloat(aprs.arbvdush*durationRatio * ARBUSHRatio)).toFixed(2) + '%',
    aprReward:ARBUSHRatio && parseFloat(aprs.arbvdush*durationRatio * ARBUSHRatio).toFixed(2) + '%',
    aprBase: '0%'
  },
  {
    protocolImg:'/camelot.png',
    protocol:'Camelot',
    tvl:ARBVDUSHData.ARBcamelotLPUSD,
    protocolLink:'https://camelot.exchange/',
    rewardToken:'USH | GRAIL | ARB',
    type:'Camelot LP (USH-ETH)',
    chain:'/arb.png',
    actions:[
      {
        link:'https://app.camelot.exchange/liquidity/?token1=0x51A80238B5738725128d3a3e06Ab41c1d4C05C74&token2=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&type=v2&position=lp',
        text:'Mint on Camelot'
      },
      {
        link:'/vdUSH',
        text:'Farm for vdUSH'
      }
    ],
    apr:((camelotRatio ? (parseFloat(aprs.arbvdush*durationRatio * camelotRatio)) : 0) + parseFloat(aprs.camelotLP)).toFixed(2) + '%',
    aprReward:camelotRatio && parseFloat(aprs.arbvdush*durationRatio * camelotRatio).toFixed(2) + '%',
    aprBase: parseFloat(aprs.camelotLP).toFixed(2) + '%',
  }
]

let bnbFarms = [
  {
    protocolImg:'/USH.svg',
    protocol:'unshETH',
    tvl:BNBVDUSHData.BNBushUSD,
    protocolLink:'',
    rewardToken:'USH & vdUSH',
    type:'BNB USH',
    chain:'/bnb-bnb.svg',
    actions:[
      {
        link:'https://swap.defillama.com/?chain=bsc&from=0x0000000000000000000000000000000000000000&to=0x91d6d6af7635b7b23a8ced9508117965180e2362',
        text:'Buy on Aggregator'
      },
      {
        link:'/vdUSH',
        text:'Farm for vdUSH'
      },
    ],
    apr:BNBUSHRatio && (parseFloat(aprs.bnbvdush)*durationRatio*BNBUSHRatio).toFixed(2) + '%',
    aprReward:BNBUSHRatio && (parseFloat(aprs.bnbvdush)*durationRatio*BNBUSHRatio).toFixed(2) + '%',
    aprBase: '0%'
  },
  {
    protocolImg:'/pancake.svg',
    protocol:'Pancake Swap',
    tvl:BNBVDUSHData.BNBpancakeSwapLPUSD,
    protocolLink:'https://pancakeswap.finance/',
    rewardToken:'USH & vdUSH',
    type:'Pancake Swap V2 LP (USH-BNB)',
    chain:'/bnb-bnb.svg',
    actions:[
      {
        link:'https://pancakeswap.finance/v2/pair/0x91d6d6aF7635B7b23A8CED9508117965180e2362/BNB',
        text:'Mint CakeLP'
      },
      {
        link:'/vdUSH',
        text:'Farm for vdUSH'
      },
    ],
    apr:pancakeRatio && (parseFloat(aprs.bnbvdush*durationRatio*pancakeRatio)+ parseFloat(aprs.pancake)).toFixed(2) + '%',
    aprReward:pancakeRatio && parseFloat(aprs.bnbvdush*durationRatio*pancakeRatio).toFixed(2) + '%',
    aprBase:aprs.pancake+'%',
  }
]

  // Define the URL where you want to fetch the APR data
  const aprApiUrl = `${API_HOST}/externalFarms`; // Replace this with the actual API URL

  useEffect(() => {
    async function fetchAprData() {
      try {
        const response = await fetch(aprApiUrl);
        const data = await response.json();
        setFarms(data.farms);
      } catch (error) {
        console.error("Error fetching APR data:", error);
      }
    }
    fetchAprData();
  }, []); // The empty array `[]` ensures that the effect only runs once on component mount

  return (
    <ExternalFarmsContext.Provider value={{unshETHFarms,ethFarms,externalFarms:externalFarms.slice(1),bnbFarms, arbFarms}}>
      {children}
    </ExternalFarmsContext.Provider>
  );
}
