import { useNetwork } from 'wagmi';
import { arbId, ethId } from '../../../constants';
import { Typography } from '../../shared';

export default function UnshethBalance({ balances }) {
  let { chain } = useNetwork();
  let chainId = chain ? chain.id : ethId;

  let bal = chainId === ethId ? balances.unshETH : balances.BNBunshETH;
  if (chainId === arbId) bal = balances.ARBunshETH;

  return (
    <Typography variant="bodyXs" as="span" className="text-white/60">
      {bal.isLoading
        ? 'Balance: Loading...'
        : !bal.data
        ? 'Balance: 0'
        : `Balance: ${parseFloat(parseFloat(bal.data.formatted).toFixed(5))}`}
    </Typography>
  );
}
