import { selectOutput } from '../../redux/withdrawV1';
import { useSelector } from 'react-redux';

export default function Output({vaultBalances}) {
  let outputs = useSelector(selectOutput);
  return (
    <>
    <div className="grid grid-cols-2 gap-4 w-full my-4">
      {Object.keys(vaultBalances).map((p, i) => (
        <div key={i} className={`text-gray-400 py-2 font-medium justify-center flex items-center`}>
          <div className="flex items-center">
            <div className="flex-shrink-0 h-full">
              <img className="h-8 w-8 rounded-full" src={p + '.png'} alt=""/>
            </div>
            <div className="ml-2">
              <div className="text-sm font-medium text-sky-300 text-left">
                {p}
              </div>
              <div className="text-sm font-medium text-white text-left">
                {outputs[p].toFixed(5)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    </>
  );
}
