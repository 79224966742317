import Pool from './Pool/index';
import { useNetwork } from 'wagmi';
import { ethId } from '../../constants';
import {BalancesContext} from "../../context-providers/Balances";
import { PoolsContext } from '../../context-providers/Pools';
import { useContext } from 'react';
import web3 from 'web3';

export default function Stake() {
  const { chain } = useNetwork()
  
  let {pools} = useContext(PoolsContext);
  let balances = useContext(BalancesContext);

  let chainId = chain ? chain.id : ethId;


  //filter pool by chain Id
  pools = pools.filter(pool => {
    //check that the user has assets within the pool and that the pool has expired
    let active = !(pool.expired && pool.noClaimsAndStake);
    if(!pool.periodFinish.data){
      console.log(pool.periodFinish);
      active = false;
    }
    // else {
    //   if(pool.finalDate <=  parseFloat(pool.periodFinish.data)) pool.finalDate = parseFloat(pool.periodFinish.data)
    // }

    if(pool.vdUSH){
      let {earned, fullStakedAmount} = pool;
      let sumEarned = 0;
      if(earned.data) {
        try{
          sumEarned = parseFloat(parseFloat(earned.data.reduce((a,b)=>{
            return a + parseFloat(web3.utils.fromWei(b._hex,'ether'));
          },0)).toFixed(2));
        }
        catch(e){
          console.log({e});
        }
      }
      if(fullStakedAmount === 0 && sumEarned === 0) active = false;
    }
    return pool.chainId === chainId && active
  });

  let className;
  if(pools.length === 1){
    className = 'grid md:grid-cols-1 gap-4 lg:gap-8 mb-8 max-w-2xl mx-auto px-2 sm:px-6 lg:px-8';
  } else {
    className = 'grid md:grid-cols-2 gap-4 lg:gap-8 mb-8';
  }

  return (
    <>
      <div className={className}>
        {pools.map((pool, index) => (
          <Pool key={index} pool={pool} balances={balances}/>
        ))}
      </div>
    </>
  )
}