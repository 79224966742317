import contractAddresses from "../contract_addresses";
import LSDVaultV1ABI from "../ABI/LSDVaultV1.json";
import { useContractRead } from "wagmi";
import { ethId } from "../constants";

export function useConversions() {
  //used to keep track of all the conversions between assets during depositing
  let conversions = {
    ETH: {data:1e18},
    wstETH: useContractRead({abi: LSDVaultV1ABI, address: contractAddresses.LSDVaultV1, functionName: 'getPrice', args:[contractAddresses.wstETH], watch:false, scopeKey:'cwstETH',chainId:ethId}),
    rETH: useContractRead({abi: LSDVaultV1ABI, address: contractAddresses.LSDVaultV1, functionName: 'getPrice', args:[contractAddresses.rETH], watch:false, scopeKey:'crETH',chainId:ethId}),
    cbETH: useContractRead({abi: LSDVaultV1ABI, address: contractAddresses.LSDVaultV1, functionName: 'getPrice', args:[contractAddresses.cbETH], watch:false, scopeKey:'ccbETH',chainId:ethId}),
    sfrxETH: useContractRead({abi: LSDVaultV1ABI, address: contractAddresses.LSDVaultV1, functionName: 'getPrice', args:[contractAddresses.sfrxETH], watch:false, scopeKey:'csfrxETH',chainId:ethId}),
  }

  return conversions;
}