import {useConversions} from './useConversions'; //TODO Update this
import {useConversions as useNewConversions} from './useNewConversions';
import {useContext, useMemo} from 'react';
import {ProjectsContext} from '../context-providers/Projects';

function useMemoizedConversions() {
    const conversions = useConversions();
    const newConversions = useNewConversions();
    return useMemo(() => {
        return {
            conversions,
            newConversions
        }
    }, []);
}

export function usePriceAndConversions() {
  
  let projects = useContext(ProjectsContext);

  //create a new map that uses the initials attribute in each item in the projects array and assigns the value as the array item
  let projectsMap = {}
  projects.forEach((item) => {projectsMap[item.initials] = item});

  //used to keep track of all the conversions between assets during depositing
  const {conversions, newConversions} = useMemoizedConversions();

  let OGpriceAndConversions = {conversions, newConversions};

  let hash = JSON.stringify(OGpriceAndConversions)
  return {OGpriceAndConversions, hash}
}
