import contractAddresses from "../contract_addresses";
import { useContractRead } from "wagmi";
import LSDVaultABI from "../ABI/LSDVault.json";
import { createContext, useContext } from "react";
import { ethId, bnbId } from "../constants";
import { DarknetContext } from "./Darknet";
import { VaultBalancesContext } from "./VaultBalances";
import {PricesContext} from "@/context-providers/Prices";

export const ProjectsContext = createContext();

export function ProjectsProvider({ children }) {
  let sum = 0;

  const darknet = useContext(DarknetContext);
  const prices = useContext(PricesContext);

  let {new_balances, old_balances} = useContext(VaultBalancesContext);

  //create a new balance mapping that adds up the old balances and the new balances
  let balances = {sfrxETH: 0, rETH: 0, wstETH:0, cbETH:0, WETH:0, ankrETH:0, swETH:0, ETHx:0};
  Object.keys(balances).map((key)=>{
    let darknetRatio = parseFloat(darknet[key].data)/1e18;
    if(new_balances[key] && new_balances[key].data) balances[key] += parseFloat(new_balances[key].data.formatted);
    if(old_balances[key] && old_balances[key].data) balances[key] += parseFloat(old_balances[key].data.formatted);
    sum+=balances[key]*darknetRatio;
  });

  function useTokenPrice(token) {
    return prices[token] || 0;
  }

  //TODO uncomment target
  let projects = [
    {
      name: "Wrapped Ethereum ",
      initials: "WETH",
      price: useTokenPrice('WETH'),
      target: useContractRead({scopeKey:'lsdConfigsWETH',chainId:ethId, abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'lsdConfigs', args:[contractAddresses.WETH], watch:false})
    },
    {
      name: "Frax ",
      initials: "sfrxETH",
      price: useTokenPrice('sfrxETH'),
      target: useContractRead({scopeKey:'lsdConfigssfrxETH',chainId:ethId, abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'lsdConfigs', args:[contractAddresses.sfrxETH], watch:false})
    },
    {
      name: "Rocket Pool ",
      initials: "rETH",
      price: useTokenPrice('rETH'),
      target: useContractRead({scopeKey:'lsdConfigssrETH',chainId:ethId, abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'lsdConfigs', args:[contractAddresses.rETH], watch:false})
    },
    {
      name: "Lido ",
      initials: "wstETH",
      price: useTokenPrice('wstETH'),
      target: useContractRead({scopeKey:'lsdConfigsswstETH',chainId:ethId, abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'lsdConfigs', args:[contractAddresses.wstETH], watch:false})
    },
    {
      name: "Coinbase ",
      initials: "cbETH",
      price: useTokenPrice('cbETH'),
      target: useContractRead({scopeKey:'lsdConfigsscbETH',chainId:ethId, abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'lsdConfigs', args:[contractAddresses.cbETH], watch:false})
    },
    {
      name: "Ankr ",
      initials: "ankrETH",
      price: useTokenPrice('ankrETH'),
      target: useContractRead({scopeKey:'lsdConfigsankrETH',chainId:ethId, abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'lsdConfigs', args:[contractAddresses.ankrETH], watch:false})
    },
    {
      name: "Swell ",
      initials: "swETH",
      price: useTokenPrice('swETH'),
      target: useContractRead({scopeKey:'lsdConfigsswETH',chainId:ethId, abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'lsdConfigs', args:[contractAddresses.swETH], watch:false})
    },
    {
      name: "Stader ",
      initials: "ETHx",
      price: useTokenPrice('ETHx'),
      target: useContractRead({scopeKey:'lsdConfigsETHx',chainId:ethId, abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'lsdConfigs', args:[contractAddresses.ETHx], watch:false})
    },
  ];

  projects = projects.map((project,i)=>{
    let darknetRatio = parseFloat(darknet[project.initials].data)/1e18;
    project.imageUrl = project.initials+'.png';
    if(project.initials === 'WETH') project.imageUrl = 'weth.png';
    project.balance = balances[project.initials];
    project.dominance = parseFloat((project.balance*darknetRatio)/sum*100).toFixed(2);
    project.ratio = project.dominance ? parseFloat(project.target) / parseFloat(project.dominance) : 1;
    return project;
  });

  // console.log(balances.ETH.data)
  return (
    <ProjectsContext.Provider value={projects}>
      {children}
    </ProjectsContext.Provider>
  );
}