
import contract_addresses from '../../contract_addresses';
import web3 from 'web3';    
import { ethers } from 'ethers';
import vdAMMABI from '../../ABI/vdAMM.json';
import zapABI from '../../ABI/unshETHZap.json';
import deposit from '../../redux/deposit';

function trimDecimal(number, decimalPlaces = 18) {
    const numberString = number.toString();
    const decimalIndex = numberString.indexOf('.');
    if (decimalIndex === -1 || numberString.length - decimalIndex - 1 <= decimalPlaces) {
      return numberString;
    }
    return number.toFixed(decimalPlaces);
}
  
export default async function calcETHLSD(initials, input, conversions, provider) {
    try {
        let conversion = conversions[initials].data;
        if(conversion) {
            let vdamm = new ethers.Contract(contract_addresses.vdAMM, vdAMMABI, provider);
            let zap = new ethers.Contract(contract_addresses.unshETHZap, zapABI, provider);
            
            console.log({
                asset:contract_addresses[initials]
            });
            let rate = await vdamm.darknetAddress();

            console.log({rate});

            let [vdAmmDepositFee, vdAmmProtocolFee] = await vdamm.getDepositFee(ethers.utils.parseEther(input), contract_addresses[initials]);
            let zapProtocolFeeBps = await zap.zapProtocolFeeBps();
            let zapProtocolFee = ethers.utils.parseEther(input).mul(zapProtocolFeeBps).div(10000);

            let depositFee = vdAmmDepositFee.add(zapProtocolFee);
            let protocolFee = vdAmmProtocolFee.add(zapProtocolFee);

            console.log({depositFee:depositFee.toString()});
    
            let output = parseFloat(conversion)/1e18 * parseFloat(input - parseFloat(depositFee)/1e18);
    
            console.log({output});
    
            output = trimDecimal(output);
    
            //calculate the deposit fee and protocol fee from the vdamm 
            output = output;
    
            return {
                depositFee,
                protocolFee,
                expectedOutput: output*1e18,
                minOutput: output*1e18,
                lsdOut: parseFloat(input - parseFloat(depositFee)/1e18)*1e18,
                args: [
                    contract_addresses[initials], //the LSD to Deposit
                    web3.utils.toHex(web3.utils.toWei(input.toString(), 'ether')), //the amount to deposit
                ],
                msgValue: 0,
                gasLimit: 300000,
                functionName: 'deposit_lsd'
            }
        }
        return null;
    }
    catch(err){
        console.log(err);
        throw new Error(err);
    }
}