import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WagmiConfig } from 'wagmi';
import ARBVDUSH from './components/ARBvdUSH';
import BNBVDUSH from './components/BNBvdUSH';
import Bridge from './components/Bridge';
import Deposit from './components/Deposit';
import Farms from './components/Farms';
import Landing from './components/Landing';
import Portfolio from './components/Portfolio';
import Stake from './components/Stake';
import Swap from './components/Swap';
import Withdraw from './components/Withdraw';
import WithdrawV1 from './components/WithdrawV1';
import VDUSH from './components/vdUSH';
import { APRProvider } from './context-providers/APR';
import { ARBVDUSHProvider } from './context-providers/ARBVDUSH';
import { AllowancesProvider } from './context-providers/Allowances';
import { BNBVDUSHProvider } from './context-providers/BNBVDUSH';
import { BalancesProvider } from './context-providers/Balances';
import { BlockTimestampProvider } from './context-providers/BlockTimestamp';
import { DarknetProvider } from './context-providers/Darknet';
import { ExternalFarmsProvider } from './context-providers/ExternalFarms';
import { PoolsProvider } from './context-providers/Pools';
import { PriceAndConversionsProvider } from './context-providers/PriceAndConversions';
import { PricesProvider } from './context-providers/Prices';
import { ProjectsProvider } from './context-providers/Projects';
// import { ReshProvider } from './context-providers/Resh';
import { VDAMMProvider } from './context-providers/VDAMM';
import { VDUSHProvider } from './context-providers/VDUSH';
import { VaultBalancesProvider } from './context-providers/VaultBalances';
import { getClientAndChains } from './wagmi';

import { Layout } from './components/v2';

function withClientAndChains(WrappedComponent) {
  return function ClientAndChainsWrapper(props) {
    const [isReady, setIsReady] = useState(false);
    const [clientAndChains, setClientAndChains] = useState(null);

    useEffect(() => {
      (async () => {
        const result = await getClientAndChains();
        setClientAndChains(result);
        setIsReady(true);
      })();
    }, []);

    if (!isReady) {
      return <div className="bg-gradient-to-b from-gradientDark via-gradientDark to-gradientTeal"></div>;
    }
    return <WrappedComponent {...props} {...clientAndChains} />;
  };
}

function App({ client, chains }) {
  return (
    <WagmiConfig client={client}>
      <RainbowKitProvider chains={chains} theme={darkTheme()}>
        <ToastContainer autoClose={5000} closeOnClick={true} pauseOnHover={true} draggable={true} theme={'dark'} />
        <Router>
          <BalancesProvider>
            <PricesProvider>
              {/*<ReshProvider>*/}
                <ARBVDUSHProvider>
                  <BNBVDUSHProvider>
                    <VDUSHProvider>
                      <VDAMMProvider>
                        <VaultBalancesProvider>
                          <APRProvider>
                            <AllowancesProvider>
                              <DarknetProvider>
                                <BlockTimestampProvider>
                                  <ProjectsProvider>
                                    <PoolsProvider>
                                      <PriceAndConversionsProvider>
                                        <ExternalFarmsProvider>
                                          {/* <Navbar />
                                          <Dominance /> */}
                                          <Layout>
                                            <Routes>
                                              <Route path="/" element={<Landing />} />
                                              <Route path="/deposit" element={<Deposit />} />
                                              <Route
                                                path="/vdUSH"
                                                element={
                                                  <>
                                                    <ARBVDUSH />
                                                    <BNBVDUSH />
                                                    <VDUSH />
                                                  </>
                                                }
                                              />

                                              <Route path="/swap" element={<Swap />} />

                                              <Route path="/stake" element={<Stake />} />

                                              <Route path="/farms" element={<Farms />} />

                                              <Route path="/portfolio" element={<Portfolio />} />
                                              <Route path="/bridge" element={<Bridge />} />
                                              <Route
                                                path="/withdraw"
                                                element={
                                                  <>
                                                    <Withdraw />
                                                    <br />
                                                    <br />
                                                    <WithdrawV1 />
                                                    <br />
                                                    <br />
                                                  </>
                                                }
                                              />
                                              <Route path="*" element={<Navigate to="/" />} />
                                            </Routes>
                                          </Layout>
                                        </ExternalFarmsProvider>
                                      </PriceAndConversionsProvider>
                                    </PoolsProvider>
                                  </ProjectsProvider>
                                </BlockTimestampProvider>
                              </DarknetProvider>
                            </AllowancesProvider>
                          </APRProvider>
                        </VaultBalancesProvider>
                      </VDAMMProvider>
                    </VDUSHProvider>
                  </BNBVDUSHProvider>
                </ARBVDUSHProvider>
              {/*</ReshProvider>*/}
            </PricesProvider>
          </BalancesProvider>
        </Router>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default withClientAndChains(App);
