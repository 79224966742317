import {
    selectInputChosen,
    selectOutputChosen,
    setInputChosen,
    setOutputChosen,
    resetAll
} from '../../../redux/swap';
import { useDispatch, useSelector } from 'react-redux';

export default function Switch() {
    const dispatch = useDispatch();
    const inputChosen = useSelector(selectInputChosen);
    const outputChosen = useSelector(selectOutputChosen);

    const onClick = () => {
        let tempInput = inputChosen;
        let tempOutput = outputChosen;

        dispatch(setInputChosen(tempOutput));
        dispatch(setOutputChosen(tempInput));
        dispatch(resetAll());
    }

    return (
        <svg onClick={onClick} className='text-gray-500 hover:text-white cursor-pointer' focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" height="24" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M27.6 20.6L24 24.2 24 4 22 4 22 24.2 18.4 20.6 17 22 23 28 29 22zM9 4L3 10 4.4 11.4 8 7.8 8 28 10 28 10 7.8 13.6 11.4 15 10z"></path>
        </svg>
    )
}
