import { ethers } from "ethers";
import contract_addresses from "../../contract_addresses";
import VDUSHABI from '../../ABI/vdUSH.json';
import { toast } from "react-toastify";

const WEEK = 604800;

export default async function calcvdUSH({myVDUSH, amountUSH, amount8020BPT, amountsushiSwapLP, duration, provider, my_locked, address}){
    try {
        duration = parseFloat(duration);
        if(!amount8020BPT) amount8020BPT = '0';
        if(!amountUSH) amountUSH = '0';
        if(!amountsushiSwapLP) amountsushiSwapLP = '0';

        amount8020BPT = amount8020BPT.toString();
        amountUSH = amountUSH.toString();
        amountsushiSwapLP = amountsushiSwapLP.toString();

        const vdUSH = new ethers.Contract(contract_addresses.vdUSH, VDUSHABI, provider);

        const weightedAmount = await vdUSH.weighted_amount(ethers.utils.parseEther(amount8020BPT), ethers.utils.parseEther(amountsushiSwapLP), (ethers.utils.parseEther(amountUSH)));

        let currentDuration = 0;
        //if we are already staking, set the duration based upon the lock end date
        if(my_locked.data && parseFloat(my_locked.data.end)!=0 ){
            let end = new Date(parseFloat(my_locked.data.end)*1000);
            //find the difference between now and the end date in terms of weeks
            currentDuration  = ((end.getTime() - Date.now())/1000/60/60/24/7);
        }

        let amountOut = ethers.utils.formatEther(weightedAmount) * duration / 53;
        let endTime = Math.ceil(WEEK * duration) + Math.floor(Date.now() / 1000);

        if(my_locked.data && parseFloat(my_locked.data.end)!=0){
            //if we are already staking, we want to increase the amount and time
            if(duration > currentDuration + 1){
                let extraVDUSHDueToTime = parseFloat(myVDUSH.data.formatted)*(Math.floor(duration-currentDuration)/currentDuration);
                amountOut = amountOut + extraVDUSHDueToTime;
            }
            else {
                endTime = 0;
            }
            
            return {
                contract_address: contract_addresses.vdUSH,
                amountOut,
                minAmountOut: amountOut,
                abi: VDUSHABI,
                args: [ethers.utils.parseEther(amount8020BPT), (ethers.utils.parseEther(amountsushiSwapLP)),(ethers.utils.parseEther(amountUSH)), endTime],
                functionName: 'increase_amount_and_time',
            }
        }
            
        return {
            contract_address: contract_addresses.vdUSH,
            amountOut,
            minAmountOut: amountOut,
            abi: VDUSHABI,
            args: [ethers.utils.parseEther(amount8020BPT), (ethers.utils.parseEther(amountsushiSwapLP)),(ethers.utils.parseEther(amountUSH)), endTime],
            functionName: 'create_lock',
        }
    }
    catch(err){
        console.log({err});
        toast.error(err.reason);
        return null;
    }
}
