import { createContext } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAccount, useBalance, useNetwork } from "wagmi";
import { arbId, bnbId, ethId } from "../constants";
import contractAddresses from "../contract_addresses";
import { selectBalanceWatch } from "../redux/watch";

export const BalancesContext = createContext();

export function BalancesProvider({ children }) {
  const account = useAccount();
  let balanceWatch = useSelector(selectBalanceWatch);


  let address = account.address;
  let enabled = address !== undefined;

  let location = useLocation();
  let watch = true;
  if (location.pathname === '/') {
    enabled = false;
    watch = false;
  }

  let { chain } = useNetwork();
  let chainId = chain ? chain.id : ethId;

  let balances = {
    ETH: useBalance({
      enabled, address,
      watch: watch && balanceWatch.includes('ETH') && chainId === ethId,
      scopeKey: 'eth', chainId: ethId
    }),

    WETH: useBalance({
      enabled, address, token: contractAddresses.WETH,
      watch: watch && balanceWatch.includes('WETH') && chainId === ethId,
      scopeKey: 'weth', chainId: ethId
    }),

    unshETHV1: useBalance({
      enabled, address, token: contractAddresses.unshETHV1,
      watch: watch && balanceWatch.includes('unshETHV1') && chainId === ethId,
      scopeKey: 'unshETH', chainId: ethId
    }),

    unshETH: useBalance({
      enabled, address, token: contractAddresses.unshETH,
      watch: watch && balanceWatch.includes('unshETH') && chainId === ethId,
      scopeKey: 'unshETH', chainId: ethId
    }),

    vdUSH: useBalance({
      enabled, address, token: contractAddresses.vdUSH,
      watch: watch && balanceWatch.includes('vdUSH') && chainId === ethId,
      scopeKey: 'vdUSH', chainId: ethId
    }),

    BNBvdUSH: useBalance({
      enabled, address, token: contractAddresses.BNBvdUSH,
      watch: watch && balanceWatch.includes('BNBvdUSH') && chainId === bnbId,
      scopeKey: 'BNBvdUSH', chainId: bnbId
    }),

    USH: useBalance({
      enabled, address, token: contractAddresses.USH,
      watch: watch && balanceWatch.includes('USH') && chainId === ethId,
      scopeKey: 'ush', chainId: ethId
    }),

    wstETH: useBalance({
      enabled, address, token: contractAddresses.wstETH,
      watch: watch && balanceWatch.includes('wstETH') && chainId === ethId,
      scopeKey: 'wstETH', chainId: ethId
    }),

    rETH: useBalance({
      enabled, address, token: contractAddresses.rETH,
      watch: watch && balanceWatch.includes('rETH') && chainId === ethId,
      scopeKey: 'rETH', chainId: ethId
    }),

    cbETH: useBalance({
      enabled, address, token: contractAddresses.cbETH,
      watch: watch && balanceWatch.includes('cbETH') && chainId === ethId,
      scopeKey: 'cbETH', chainId: ethId
    }),

    ankrETH: useBalance({
      enabled, address, token: contractAddresses.ankrETH,
      watch: watch && balanceWatch.includes('ankrETH') && chainId === ethId,
      scopeKey: 'ankrETH', chainId: ethId
    }),

    ANKR: useBalance({
      enabled, address, token: contractAddresses.ANKR,
      watch: watch && balanceWatch.includes('ankr') && chainId === ethId,
      scopeKey: 'ankr', chainId: ethId
    }),

    swETH: useBalance({
      enabled, address, token: contractAddresses.swETH,
      watch: watch && balanceWatch.includes('swETH') && chainId === ethId,
      scopeKey: 'swETH', chainId: ethId
    }),

    ETHx: useBalance({
      enabled, address, token: contractAddresses.ETHx,
      watch: watch && balanceWatch.includes('ETHx') && chainId === ethId,
      scopeKey: 'ETHx', chainId: ethId
    }),

    '8020BPT': useBalance({
      enabled, address, token: contractAddresses['8020BPT'],
      watch: watch && balanceWatch.includes('8020BPT') && chainId === ethId,
      scopeKey: '8020BPT', chainId: ethId
    }),

    sfrxETH: useBalance({
      enabled, address, token: contractAddresses.sfrxETH,
      watch: watch && balanceWatch.includes('sfrxETH') && chainId === ethId,
      scopeKey: 'sfrxETH', chainId: ethId
    }),

    sushiSwapLP: useBalance({
      enabled, address, token: contractAddresses.sushiSwapLP,
      watch: watch && balanceWatch.includes('sushiSwapLP') && chainId === ethId,
      scopeKey: 'sushiSwapLP', chainId: ethId
    }),

    USDT: useBalance({
      enabled, address, token: contractAddresses['USDT'],
      watch: watch && balanceWatch.includes('USDT') && chainId === ethId,
      scopeKey: 'usdt', chainId: ethId
    }),

    BNBBNB: useBalance({
      enabled, address,
      watch: watch && balanceWatch.includes('BNBBNB') && chainId === bnbId,
      scopeKey: 'bnB', chainId: bnbId
    }),

    BNBETH: useBalance({
      enabled, address, token: contractAddresses['BNBETH'],
      watch: watch && balanceWatch.includes('BNBETH') && chainId === bnbId,
      scopeKey: 'bnbeth', chainId: bnbId
    }),

    BNBUSDT: useBalance({
      enabled, address, token: contractAddresses['BNBUSDT'],
      watch: watch && balanceWatch.includes('BNBUSDT') && chainId === bnbId,
      scopeKey: 'bnbusht', chainId: bnbId
    }),

    BNBunshETH: useBalance({
      enabled, address, token: contractAddresses['BNBunshETH'],
      watch: watch && balanceWatch.includes('BNBunshETH') && chainId === bnbId,
      scopeKey: 'bnbunsheth', chainId: bnbId
    }),

    BNBUSH: useBalance({
      enabled, address, token: contractAddresses['BNBUSH'],
      watch: watch && balanceWatch.includes('BNBUSH') && chainId === bnbId,
      scopeKey: 'bnbush', chainId: bnbId
    }),

    BNBpancakeSwapLP: useBalance({
      enabled, address, token: contractAddresses['BNBpancakeSwapLP'],
      watch: watch && balanceWatch.includes('BNBpancakeSwapLP') && chainId === bnbId,
      scopeKey: 'BNBpancakeSwapLP', chainId: bnbId
    }),

    ARBETH: useBalance({
      enabled, address,
      watch: watch && balanceWatch.includes('ARBETH') && chainId === arbId,
      scopeKey: 'arbeth', chainId: arbId
    }),

    ARBunshETH: useBalance({
      enabled, address, token: contractAddresses['ARBunshETH'],
      watch: watch && balanceWatch.includes('ARBunshETH') && chainId === arbId,
      scopeKey: 'ARBunshETH', chainId: arbId
    }),

    ARBUSH: useBalance({
      enabled, address, token: contractAddresses['ARBUSH'],
      watch: watch && balanceWatch.includes('ARBUSH') && chainId === arbId,
      scopeKey: 'ARBUSH', chainId: arbId
    }),

    ARBvdUSH: useBalance({
      enabled, address, token: contractAddresses['ARBvdUSH'],
      watch: watch && balanceWatch.includes('ARBvdUSH') && chainId === arbId,
      scopeKey: 'ARBvdUSH', chainId: arbId
    }),

    ARBcamelotLP: useBalance({
      enabled, address, token: contractAddresses['ARBcamelotLP'],
      watch: watch && balanceWatch.includes('ARBcamelotLP') && chainId === arbId,
      scopeKey: 'ARBcamelotLP', chainId: arbId
    }),
  }

  return (
    <BalancesContext.Provider value={balances}>
      {children}
    </BalancesContext.Provider>
  );
}