import React, { PropsWithChildren } from 'react';

import { Navbar, Footer } from '@/components/v2';
import Dominance from '@/components/Dominance';

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col bg-gradientBackground bg-no-repeat bg-right overflow-x-hidden">
      <Navbar />
      <Dominance />
      <div className="flex-1 flex-grow max-w-container mx-auto pt-4 pb-12 w-full px-4 md:px-0">{children}</div>
      <Footer />
    </div>
  );
};
