import { setInput, resetAll, selectInputDisabled, setCalculating, setCalc, selectDuration, selectARBUSHInput,selectARBcamelotLPInput, selectAmountOut } from '../../../../redux/ARBvdUSH';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useProvider } from 'wagmi';
import { useContext, useRef } from 'react';
import calcARBvdUSH from '../../../../actions/calcARBvdUSH';
import { ARBVDUSHContext } from '../../../../context-providers/ARBVDUSH';
import { BalancesContext } from '../../../../context-providers/Balances';

export default function InputAmount({asset}) {
    const provider = useProvider();
    const account = useAccount();
    const dispatch = useDispatch();
    const {arb_my_locked} = useContext(ARBVDUSHContext);
    const balances = useContext(BalancesContext);
    let myARBVDUSH = balances.ARBvdUSH;

    let amountARBUSH = useSelector(selectARBUSHInput);
    let amountARBcamelotLP = useSelector(selectARBcamelotLPInput);
    const outputAmount = useSelector(selectAmountOut);

    const inputDisabled = useSelector(selectInputDisabled);
    const duration = useSelector(selectDuration);

    let disabled = false;

    const debounceRef = useRef(null);

    let amount = '';
    if(asset === 'ARBUSH') amount = amountARBUSH;
    else if(asset === 'ARBcamelotLP') amount = amountARBcamelotLP;

    const onChange = async (e) => {
        let inputAmount = e.target.value;
        if(inputDisabled) return;

        dispatch(setInput({asset,value:inputAmount}));

        if(asset === 'ARBUSH') amountARBUSH = inputAmount;
        else if(asset === 'ARBcamelotLP') amountARBcamelotLP = inputAmount;
        
        // Clear the existing debounce timer, if any
        if (debounceRef.current) clearTimeout(debounceRef.current);

        // Set a new debounce timer
        debounceRef.current = setTimeout(async () => {
            await lock();
        }, 500); // 1000 milliseconds (1 seconds) debounce time

        let lock = async () => {
            console.log({inputAmount});
            if(parseFloat(inputAmount) === 0) {
                if(outputAmount) dispatch(resetAll());
                return;
            }
            else {
                console.log('hello');
                dispatch(setCalculating());
                try {
                    let output = await calcARBvdUSH({myARBVDUSH, amountARBUSH, amountARBcamelotLP, duration, provider, arb_my_locked, address: account.address});
                    console.log({output});
                    if (output === null) {
                        dispatch(resetAll());
                    } else {
                        dispatch(setCalc(output))
                    }
                } catch (err) {
                    console.log(err);
                    dispatch(resetAll());
                }
            }
        }
    };

    return (
        <div className="flex flex-1 text-left items-center relative ">
            <input onChange={onChange.bind(this)} disabled={disabled} placeholder={disabled ? "Loading..." : 0} className="bg-slate-800 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500" type="number" inputMode="decimal" value={amount} />
        </div>
    );
}