import { ethers } from "ethers";

const WEEK = 604800;

// Retrieve the current gas price from the provider
async function getCurrentGasPrice(provider) {
    try {
      const gasPriceWei = await provider.getGasPrice();
      return gasPriceWei;
    } catch (error) {
      console.error(`Failed to retrieve gas price: ${error.message}`);
    }
  }
  
// Example values
  
  // Calculate the amount the user can actually spend
export async function calculateSpendableAmount(bal, provider, gasLimit = 500000) {
    try {
      // Get current gas price in Wei
      const gasPriceWei = await getCurrentGasPrice(provider);
  
      // Convert balance to Wei
      const userBalanceWei = ethers.utils.parseEther(bal.toString());
  
      // Calculate total gas fees in Wei
      const gasFeesWei = gasPriceWei.mul(2).mul(gasLimit);
  
      console.log(`gasFeeWei: ${gasFeesWei.toString()}`);
  
      // Calculate amount user can actually spend in Wei (balance minus gas fees)
      const spendableAmountWei = userBalanceWei.sub(gasFeesWei);
  
      // Convert spendable amount to ETH
      const spendableAmountETH = ethers.utils.formatEther(spendableAmountWei);
  
      //if spendable amount is negative, return 0
      if (spendableAmountETH < 0) {
        throw new Error('Insufficient funds to cover gas fees')
      }
  
      console.log(`Amount user can actually spend: ${spendableAmountETH} ETH`);
      return spendableAmountETH;
    } catch (error) {
      console.log(`Failed to calculate spendable amount: ${error.message}`);
      throw new Error(error.message);
    }
  }