import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  input: '',
  output: '',
  maximize: 'principal',
  args: [],
  functionName: '',
  minAmountUnsheth: '',
  msgValue: 0,
  gasLimit: 0,
  protocolFee: '',
  depositFee: '',
  lsdOut:'',
  inputDisabled: false,
  loading: false,
};

export const depositSlice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    setInput: (state,action) => {
      state.input = action.payload;
    },
    setOutput: (state,action) => {
      state.output = action.payload;
    },
    setArgs: (state, action) => {
      state.args = action.payload;
    },
    setFunctionName: (state, action) => {
      state.functionName = action.payload;
    },
    setMsgValue: (state, action) => {
      state.msgValue = action.payload;
    },
    setMinAmountUnsheth: (state, action) => {
      state.minAmountUnsheth = action.payload;
    },
    setGasLimit: (state, action) => {
      state.gasLimit = action.payload;
    },
    setLoading : (state, action) => {
      state.loading = action.payload;
    },
    setInputDisabled: (state,action) => {
      state.inputDisabled = action.payload;
    },
    setProtocolFee: (state, action) => {
      state.protocolFee = action.payload;
    },
    setDepositFee: (state, action) => {
      state.depositFee = action.payload;
    },
    setLSDOut: (state, action) => {
      state.lsdOut = action.payload;
    },
    setCalc: (state, action) => {
      let {lsdOut, expectedOutput, msgValue, args, functionName, minOutput, gasLimit, depositFee, protocolFee} = action.payload;
      state.output = expectedOutput/1e18;
      state.args = args;
      state.functionName = functionName;
      state.minAmountUnsheth = minOutput/1e18;
      state.msgValue = msgValue;
      state.gasLimit = gasLimit;
      state.protocolFee = protocolFee;
      state.depositFee = depositFee;
      state.lsdOut = lsdOut;
    },
    resetAll: (state, action) => {
      state.input = '';
      state.output = '';
      state.args = [];
      state.functionName = '';
      state.msgValue = 0;
      state.minAmountUnsheth = '';
      state.gasLimit = 0;
      state.loading = false;
      state.inputDisabled = false;
      state.protocolFee = '';
      state.depositFee = '';
      state.lsdOut = '';
    }
  }
});

export const {setInput, setOutput, setMaximize, setArgs, setFunctionName, setMsgValue, resetAll, setMinAmountUnsheth, setGasLimit, setLoading,setInputDisabled, setDepositFee, setProtocolFee, setLSDOut, setCalc} = depositSlice.actions;
export const selectInput = (state) => state.deposit.input;
export const selectOutput = (state) => state.deposit.output;
export const selectArgs = (state) => state.deposit.args;
export const selectFunctionName = (state) => state.deposit.functionName;
export const selectMsgValue = (state) => state.deposit.msgValue;
export const selectMinAmountUnsheth = (state) => state.deposit.minAmountUnsheth;
export const selectGasLimit = (state) => state.deposit.gasLimit;
export const selectLoading = (state) => state.deposit.loading;
export const selectInputDisabled = (state) => state.deposit.inputDisabled;
export const selectProtocolFee = (state) => state.deposit.protocolFee;
export const selectDepositFee = (state) => state.deposit.depositFee;
export const selectLSDOut = (state) => state.deposit.lsdOut;

export default depositSlice.reducer;
