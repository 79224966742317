import { useContext } from 'react';
import { BalancesContext } from '../../../context-providers/Balances';

export default function Output() {
    const balances = useContext(BalancesContext);
    let amount = balances.vdUSH.data ? balances.vdUSH.data.formatted : 0;

    return (
        <>
            <div className="flex flex-1 text-left items-center relative ">
                <input disabled={true} className="bg-slate-800 border-r-0 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default cursor-default text-white" type="number" inputMode="decimal" value={amount && `${parseFloat(parseFloat(amount).toFixed(3))}`} />
            </div>
            <div className="text-left bg-surface-default cursor-none focus:outline-none overflow-hidden rounded-r-lg border border-slate-600  h-full items-center border-l-0">
                <button className="flex items-center max-w-[200px] hover:bg-surface-subdued h-full cursor-default" id="headlessui-combobox-button-321" type="button" tabIndex="-1" aria-haspopup="true" aria-expanded="false">
                    <div className="flex w-full px-4 justify-between items-center rounded-l-lg">
                        <div className="flex min-w-full items-center justify-between">
                            <div className="flex items-center space-x-3">
                                <div className="relative" id="network-icon-1-ETH"><img src="/vdush.svg" alt="ETH" className="w-8 h-8 rounded-full object-cover"/></div>
                                <div className="shrink items-center truncate">
                                    <div className=" text-slate-100 text-lg font-medium">vdUSH</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </>
    )
}
