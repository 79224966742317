import { Menu } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { selectToken, selectItems } from '../../../redux/bridge';

export default function Chosen() {
  const items = useSelector(selectItems);
  const current = useSelector(selectToken);
  let {initials, imageUrl} = items[current];

  if(initials.startsWith('BNB')) initials = initials.slice(3);

  const width = initials === 'unshETH' ? 'w-8' : 'w-8';

  return (
    <Menu.Button className="flex items-center max-w-[200px] hover:bg-surface-subdued h-full">
        <div className="flex w-full px-4 justify-between items-center rounded-l-lg h-full">
            <div className="flex min-w-full items-center justify-between">
                <div className="flex w-full items-center space-x-3">
                    <div className="relative" id="network-icon-1-ETH"><img src={imageUrl} alt="ETH" className={width + " h-8 rounded-full object-cover"}/></div>
                    <div className="shrink items-center truncate">
                        <div className=" text-slate-100 text-lg font-medium truncate">{initials}</div>
                    </div>
                </div>
                <div className="flex-1 flex">
                    <div className="text-slate-400 pl-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </Menu.Button>
  )
}
