import contractAddresses from "../contract_addresses";
import { useContractRead, erc20ABI, useAccount, useNetwork } from "wagmi";
import { ethId, bnbId, arbId } from "../constants";
import { createContext } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAllowanceWatch } from "../redux/watch";

export const AllowancesContext = createContext();
let abi = erc20ABI;

export function AllowancesProvider({ children }) {
  let allowanceWatch = useSelector(selectAllowanceWatch);
  const account = useAccount();
  let address = account.address;
  let enabled = address !== undefined;
  const location = useLocation();
  let {chain} = useNetwork();
  let chainId = chain ? chain.id : ethId;

  let watch = true;
  if(location.pathname === '/' || location.pathname === '/portfolio'){
    watch = false;
    enabled = false;
  }

  let allowances = {
    ETH: {data: Infinity},
    ARBETH: {data: Infinity},

    //unsheth to migrate
    unshETHV1ToZap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.unshETHV1, functionName: 'allowance', 
    args:[address, contractAddresses.unshETHZap], 
    watch: watch && allowanceWatch.includes('unshETHV1ToZap') && chainId === ethId,
    scopeKey:'allowUnshETHZap',chainId:ethId}),
    
    //Unsheth to Exit
    unshETHV1ToLSDVault: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.unshETHV1, functionName: 'allowance', args:[address, contractAddresses.LSDVaultV1],
		watch: watch && allowanceWatch.includes('unshETHV1ToLSDVault') && chainId === ethId,
		scopeKey:'unshETHV1ToLSDVault',chainId:ethId}),

    unshETHToLSDVault: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.unshETH, functionName: 'allowance', args:[address, contractAddresses.LSDVault],
		watch: watch && allowanceWatch.includes('unshETHToLSDVault') && chainId === ethId,
		scopeKey:'unshETHToLSDVault',chainId:ethId}),

    //V1 LSD Allowances for the LSD Vault
    wstETHV1: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.wstETH, functionName: 'allowance', args:[address, contractAddresses.LSDVaultV1],
		watch: watch && allowanceWatch.includes('wstETHV1') && chainId === ethId,
		scopeKey:'allowwstETH',chainId:ethId}),

    rETHV1: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.rETH, functionName: 'allowance', args:[address, contractAddresses.LSDVaultV1],
		watch: watch && allowanceWatch.includes('rETHV1') && chainId === ethId,
		scopeKey:'allowrETH',chainId:ethId}),

    cbETHV1: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.cbETH, functionName: 'allowance', args:[address, contractAddresses.LSDVaultV1],
		watch: watch && allowanceWatch.includes('cbETHV1') && chainId === ethId,
		scopeKey:'allowcbETH',chainId:ethId}),

    sfrxETHV1: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.sfrxETH, functionName: 'allowance', args:[address, contractAddresses.LSDVaultV1],
		watch: watch && allowanceWatch.includes('sfrxETHV1') && chainId === ethId,
		scopeKey:'allowsfrxETH',chainId:ethId}),
    
    //V2 LSD Allowances for the unshethZap
    wstETH: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.wstETH, functionName: 'allowance', args:[address, contractAddresses.unshETHZap],
		watch: watch && allowanceWatch.includes('wstETH') && chainId === ethId,
		scopeKey:'allowwstETHZap',chainId:ethId}),

    ETHx: useContractRead({
      enabled: enabled && chainId === ethId, abi, address: contractAddresses.ETHx, functionName: 'allowance', args: [address, contractAddresses.unshETHZap],
      watch: watch && allowanceWatch.includes('ETHx') && chainId === ethId,
      scopeKey: 'allowETHx', chainId: ethId
    }),

    rETH: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.rETH, functionName: 'allowance', args:[address, contractAddresses.unshETHZap],
		watch:  watch && allowanceWatch.includes('rETH') && chainId === ethId,
		scopeKey:'allowrETHZap',chainId:ethId}),

    cbETH: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.cbETH, functionName: 'allowance', args:[address, contractAddresses.unshETHZap],
		watch: watch && allowanceWatch.includes('cbETH') && chainId === ethId,
		scopeKey:'allowcbETHZap',chainId:ethId}),

    sfrxETH: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.sfrxETH, functionName: 'allowance', args:[address, contractAddresses.unshETHZap],
		watch: watch && allowanceWatch.includes('sfrxETH') && chainId === ethId,
		scopeKey:'allowsfrxETHZap',chainId:ethId}),

    WETH: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.WETH, functionName: 'allowance', args:[address, contractAddresses.unshETHZap],
    watch: watch && allowanceWatch.includes('WETH') && chainId === ethId,
    scopeKey:'allowWETHZap',chainId:ethId}),

    ankrETH: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.ankrETH, functionName: 'allowance', args:[address, contractAddresses.unshETHZap],
    watch: watch && allowanceWatch.includes('ankrETH') && chainId === ethId,
    scopeKey:'allowankrETHZap',chainId:ethId}),

    swETH: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.swETH, functionName: 'allowance', args:[address, contractAddresses.unshETHZap],
    watch: watch && allowanceWatch.includes('swETH') && chainId === ethId,
    scopeKey:'allowswETHZap',chainId:ethId}),

    //BNB Allowances for the SG Sender
    BNBBNB: {data: Infinity},
    BNBETH:  useContractRead({enabled: enabled && chainId === bnbId, abi, address: contractAddresses.BNBETH, functionName: 'allowance', args:[address, contractAddresses["BNBSGSender"]],
		watch: watch && allowanceWatch.includes('BNBETH') && chainId === bnbId,
		scopeKey:'allowbnbETH',chainId:bnbId}),

    BNBUSDT: useContractRead({enabled: enabled && chainId === bnbId, abi, address: contractAddresses.BNBUSDT, functionName: 'allowance', args:[address, contractAddresses["BNBSGSender"]],
		watch: watch && allowanceWatch.includes('BNBUSDT') && chainId === bnbId,
		scopeKey:'allowbnbUSDT',chainId:bnbId}),

    //Unsheth to be spent by Proxy
    unshETHETHProxy: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.unshETH, functionName: 'allowance', args:[address, contractAddresses["unshETHProxy"]],
		watch: watch && allowanceWatch.includes('unshETHETHProxy') && chainId === ethId,
		scopeKey:'unshethProxyAllowance',chainId:ethId}),
    unshETHBNBProxy : {data: Infinity},

    //USH to be spent by Proxy
    USHETHProxy: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.USH, functionName: 'allowance', args:[address, contractAddresses["USHProxy"]],
		watch: watch && allowanceWatch.includes('USHETHProxy') && chainId === ethId,
		scopeKey:'ushProxyAllowance',chainId:ethId}),
    USHBNBProxy : {data: Infinity},

    //Swap Allowances
    ETHSwap : {data: Infinity},

    unshETHSwap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.wstETH, functionName: 'allowance', args:[address, contractAddresses.vdAMM],
		watch: watch && allowanceWatch.includes('unshETHSwap') && chainId === ethId,
		scopeKey:'allowunshETHSwap',chainId:ethId}),

    WETHSwap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.WETH, functionName: 'allowance', args:[address, contractAddresses.vdAMM],
    watch: watch && allowanceWatch.includes('WETHSwap') && chainId === ethId,
    scopeKey:'allowWETHSwap',chainId:ethId}),

    wstETHSwap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.wstETH, functionName: 'allowance', args:[address, contractAddresses.vdAMM],
		watch: watch && allowanceWatch.includes('wstETHSwap') && chainId === ethId,
		scopeKey:'allowwstETHSwap',chainId:ethId}),

    rETHSwap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.rETH, functionName: 'allowance', args:[address, contractAddresses.vdAMM],
		watch:  watch && allowanceWatch.includes('rETHSwap') && chainId === ethId,
		scopeKey:'allowrETHSwap',chainId:ethId}),

    cbETHSwap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.cbETH, functionName: 'allowance', args:[address, contractAddresses.vdAMM],
		watch: watch && allowanceWatch.includes('cbETHSwap') && chainId === ethId,
		scopeKey:'allowcbETHSwap',chainId:ethId}),

    sfrxETHSwap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.sfrxETH, functionName: 'allowance', args:[address, contractAddresses.vdAMM],
		watch: watch && allowanceWatch.includes('sfrxETHSwap') && chainId === ethId,
		scopeKey:'allowsfrxETHSwap',chainId:ethId}),

    ankrETHSwap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.ankrETH, functionName: 'allowance', args:[address, contractAddresses.vdAMM],
    watch: watch && allowanceWatch.includes('ankrETHSwap') && chainId === ethId,
    scopeKey:'allowankrETHSwap',chainId:ethId}),

    ETHxSwap: useContractRead({
      enabled: enabled && chainId === ethId, abi, address: contractAddresses.ETHx, functionName: 'allowance', args: [address, contractAddresses.vdAMM],
      watch: watch && allowanceWatch.includes('ETHxSwap') && chainId === ethId,
      scopeKey: 'allowETHxSwap', chainId: ethId
    }),

    swETHSwap: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.swETH, functionName: 'allowance', args:[address, contractAddresses.vdAMM],
    watch: watch && allowanceWatch.includes('swETHSwap') && chainId === ethId,
    scopeKey:'allowswETHSwap',chainId:ethId}),

    //vdUSH Allowance
    USHvdUSH: useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses.USH, functionName: 'allowance', 
    args:[address, contractAddresses.vdUSH], 
    watch: watch && allowanceWatch.includes('USHvdUSH') && chainId === ethId,
    scopeKey:'allowUSHvdUSh',chainId:ethId}),

    '8020BPTvdUSH': useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses['8020BPT'], functionName: 'allowance',
    args:[address, contractAddresses.vdUSH],
    watch: watch && allowanceWatch.includes('8020BPTvdUSH') && chainId === ethId,
    scopeKey:'allow8020BPTvdUSH',chainId:ethId}),

    'sushiSwapLPvdUSH': useContractRead({enabled: enabled && chainId === ethId, abi, address: contractAddresses['sushiSwapLP'], functionName: 'allowance',
    args:[address, contractAddresses.vdUSH],
    watch: watch && allowanceWatch.includes('sushiSwapLPvdUSH') && chainId === ethId,
    scopeKey:'allowsushiSwapLPvdUSH',chainId:ethId}),

    'BNBUSHvdUSH': useContractRead({enabled: enabled && chainId === bnbId, abi, address: contractAddresses['BNBUSH'], functionName: 'allowance',
    args:[address, contractAddresses.BNBvdUSH],
    watch: watch && allowanceWatch.includes('BNBUSHvdUSH') && chainId === bnbId,
    scopeKey:'allowBNBUSHvdUSH',chainId:bnbId}),

    'BNBpancakeSwapLPvdUSH': useContractRead({enabled: enabled && chainId === bnbId, abi, address: contractAddresses['BNBpancakeSwapLP'], functionName: 'allowance',
    args:[address, contractAddresses.BNBvdUSH],
    watch: watch && allowanceWatch.includes('BNBpancakeSwapLPvdUSH') && chainId === bnbId,
    scopeKey:'allowBNBpancakeSwapLPvdUSH',chainId:bnbId}),

    'ARBUSHvdUSH': useContractRead({enabled: enabled && chainId === arbId, abi, address: contractAddresses['ARBUSH'], functionName: 'allowance',
    args:[address, contractAddresses.ARBvdUSH],
    watch: watch && allowanceWatch.includes('ARBUSHvdUSH') && chainId === arbId,
    scopeKey:'allowARBUSHvdUSH',chainId:arbId}),

    'ARBcamelotLPvdUSH': useContractRead({enabled: enabled && chainId === arbId, abi, address: contractAddresses['ARBcamelotLP'], functionName: 'allowance',
    args:[address, contractAddresses.ARBvdUSH],
    watch: watch && allowanceWatch.includes('ARBcamelotLPvdUSH') && chainId === arbId,
    scopeKey:'allowARBcamelotLPvdUSH',chainId:arbId}),
  }

  return (
    <AllowancesContext.Provider value={allowances}>
      {children}
    </AllowancesContext.Provider>
  );
}