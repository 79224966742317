import { useSelector } from 'react-redux';
import { selectChainTo, selectChains } from '../../redux/bridge';
import { Switch } from '@headlessui/react';
import { Typography } from '../shared';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Airdrop({ enabled, setEnabled }) {
  let chains = useSelector(selectChains);
  let chainTo = useSelector(selectChainTo);

  const airdrop_amount = chains[chainTo].airdrop_amount;
  const airdrop_asset = chains[chainTo].airdrop_asset;

  return (
    <div className="flex flex-col w-full items-center mb-8">
      <Typography variant="bodyMd" className="text-white/80 mb-2" weight="bold">
        Airdrop {airdrop_amount} {airdrop_asset} to your address?
      </Typography>
      <div className="flex justify-between">
        <Typography variant="bodySm" className="text-white/80 mx-4" weight="medium">
          No
        </Typography>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(
            enabled ? 'bg-cyan-700' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
        <Typography variant="bodySm" className="text-white/80 mx-4" weight="medium">
          Yes
        </Typography>
      </div>
    </div>
  );
}
