import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction, useAccount } from 'wagmi';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVDUSHWatch } from '../../../../redux/watch';
import contract_addresses from '../../../../contract_addresses';
import { ARBVDUSHContext } from '../../../../context-providers/ARBVDUSH';
import VDUSHFarmABI from '../../../../ABI/vdUSHFarm.json';
import { Button } from '../../../shared';

export default function Submit2() {
  const { alreadyStaking } = useContext(ARBVDUSHContext);
  let account = useAccount();

  const dispatch = useDispatch();
  const [lockTxHash, setSwapTxHash] = useState(null);
  const lockWait = useWaitForTransaction({ hash: lockTxHash });

  const isStaking = alreadyStaking.data ? alreadyStaking.data : false;

  //Lock Prep
  const { config } = usePrepareContractWrite({
    address: contract_addresses.ARBvdUSHFarm,
    abi: VDUSHFarmABI,
    functionName: 'enterMatrix',
    args: [account.address],
    onError: (err) => {
      // console.log({err});
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Earn Tx Failed!');
    },
    onSuccess(data, err) {
      console.log({ data, err });
      setSwapTxHash(data.hash);
      dispatch(setVDUSHWatch('arb_alreadyStaking'));
      toast.info('Earn Tx Submitted!');
    },
  });

  let lockDisabled = !write || isStaking;
  let lockButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !lockDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    write();
  };

  return (
    <>
      <div className="flex justify-center text-center gap-4 w-full mb-4">
        {lockWait.isLoading ? (
          <Button variant="gradient" size="big" block disabled={true}>
            Earning...
          </Button>
        ) : (
          <Button variant="gradient" size="big" block disabled={lockDisabled} onClick={onClick}>
            {isStaking ? 'Already Earning' : 'Start Earning'}
          </Button>
        )}
      </div>
    </>
  );
}
