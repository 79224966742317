import { useEffect, useState } from "react";
import { usePriceAndConversions as useOriginalPriceAndConversions } from "../hooks/usePriceAndConversions";
import { createContext } from "react";

export const PriceAndConversionsContext = createContext();

export function PriceAndConversionsProvider({ children }) {
  const {hash, OGpriceAndConversions} = useOriginalPriceAndConversions();
  const [priceAndConversions, setPriceAndConversions] = useState(OGpriceAndConversions);

  useEffect(() => {
    // console.log('updating');
    setPriceAndConversions(OGpriceAndConversions);
  }, [hash]);

  return (
    <PriceAndConversionsContext.Provider value={priceAndConversions}>
      {children}
    </PriceAndConversionsContext.Provider>
  );
}
