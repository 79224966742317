import { useContext, useEffect } from 'react';
import Input from './input';
import Output from './output';
import SubmitLSD from './Submit/submitLSD';
import SubmitETH from './Submit/submitETH';
import SubmitBNB from './Submit/submitOnBNB';
import SubmitARB from './Submit/submitOnARB';
import ChosenBalance from './Balances/chosen';
// import Maximize from './Balances/maximize';
import UnshethBalance from './Balances/unsheth';
import { useSelector } from 'react-redux';
import { selectCurrent, selectItems, setCurrent } from '../../redux/dropdown';
import { PriceAndConversionsContext } from '../../context-providers/PriceAndConversions';

import { useNetwork } from 'wagmi';
import { ethId, bnbId, arbId, } from '../../constants';
import MinAmount from './min_out';
import { BalancesContext } from '../../context-providers/Balances';
import { DarknetContext } from '../../context-providers/Darknet';
import { AllowancesContext } from '../../context-providers/Allowances';
import { Tooltip } from 'react-tooltip';
import { PoolsContext } from '../../context-providers/Pools';
import { Typography } from '../shared';
import { GradientCard } from '../v2';
import { useDispatch } from 'react-redux';

export default function Deposit() {
  const dispatch = useDispatch();


  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash.length > 1) {
      const defaultToken = hash.slice(1);
      dispatch(setCurrent(defaultToken));
    }
  }, [])

  let { chain } = useNetwork();
  const items = useSelector(selectItems);
  const current = useSelector(selectCurrent);
  let { newConversions } = useContext(PriceAndConversionsContext);
  let darknet = useContext(DarknetContext);

  let allowances = useContext(AllowancesContext);
  let balances = useContext(BalancesContext);
  let { pools } = useContext(PoolsContext);

  const { initials } = items[current];

  let chainId = chain ? chain.id : ethId;

  let pool;
  if(chainId === ethId){
    pool = pools.find(pool => pool.name === 'unshETH');
  } else if (chainId === bnbId){
    pool = pools.find(pool => pool.name === 'unshETH (BNB)');
  } else if (chainId === arbId){
    pool = pools.find(pool => pool.name === 'unshETH (Dual ARB)');
  }

  let { aprs, apy } = pool;

  let poolText = aprs
    .map(({ name, value }) => {
      return name + ': ' + value + '%</br>';
    })
    .reduce((text, a) => {
      return text + a;
    });

  return (
    <div className="mt-4 mx-auto sm:max-w-xl sm:text-center lg:items-center lg:px-0 lg:text-left mb-20">
      <GradientCard>
        <div className="flex flex-row justify-between items-center relative py-4">
          <Typography as="h2" variant="bodyLg" weight="bold" className="text-white text-left">
            Deposit
          </Typography>
          <div className="absolute top-1/2 right-0 text-right -translate-y-1/2">
            {
              <Typography
                as="p"
                data-tooltip-id={'deposittooltip'}
                data-tooltip-html={poolText}
                variant="bodyXs"
                className="text-white/60"
                weight="medium"
              >
                APR: {apy}%{' '}
              </Typography>
            }
            <a href={'/stake'}>
              <Typography as="span" variant="bodyXs" className="text-mediumBlue" weight="medium">
                Stake unshETH
              </Typography>
            </a>
          </div>
          {poolText && <Tooltip id={'deposittooltip'} style={{ zIndex: 1000 }} place="bottom" />}
        </div>
        <div className="mt-2">
          <div className="space-y-3">
            <div>
              <div className="flex flex-col relative">
                <div className="flex relative h-16">
                  <Input balances={balances} conversions={newConversions} darknet={darknet} />
                </div>

                <ChosenBalance balances={balances} conversions={newConversions} darknet={darknet} />

                <div className="flex relative h-4 mt-2 mb-5 w-full items-center">
                  {/* Arrow Pointing Down */}
                  <svg
                    className="h-5 w-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>

                <div className="flex relative h-16">
                  <Output />
                </div>

                <div className="flex justify-between py-1 items-center">
                  <MinAmount />
                  <UnshethBalance balances={balances} />
                </div>
              </div>
            </div>
          </div>
          {(chainId === arbId || chainId === bnbId )&& (
            <div className="flex flex-col gap-3 text-center text-white">
              <p>Switch to Ethereum to mint unshETH</p>
              <p>OR</p>
          </div>
            )}
          {chainId === ethId && initials === 'ETH' && <SubmitETH balances={balances} />}
          {chainId === ethId && initials !== 'ETH' && <SubmitLSD balances={balances} allowances={allowances} />}
          {chainId === bnbId && <SubmitBNB />}
          {chainId === arbId && <SubmitARB />}
          {(chainId === bnbId || chainId === arbId) && (
            <div className="flex justify-center">
              <Typography as="p" variant="bodySm" className="text-white/60 text-center">
                Transactions through the bridge will take longer than native blockchain transactions. (Estimated up to
                10 minutes)
              </Typography>
            </div>
          )}
        </div>
      </GradientCard>
    </div>
  );
}
