import { setInput, setOutput } from '../../../redux/withdrawV1';
import { useDispatch } from 'react-redux';
import calcOutput from '../calcOutput';
import { Typography } from '../../shared';

export default function UnshethV1Balance({ balances, totalSupplyUnshethV1, vaultBalances }) {
  const dispatch = useDispatch();
  let bal = balances['unshETHV1'];

  let disabled = false;
  if (!totalSupplyUnshethV1.data) {
    disabled = true;
  }
  Object.keys(vaultBalances).map((v) => {
    if (!vaultBalances[v].data) {
      disabled = true;
    }
  });

  const onClick = () => {
    if (disabled) return;
    let amount = calcOutput(parseFloat(bal.data.formatted), vaultBalances, totalSupplyUnshethV1);
    dispatch(setInput(bal.data.formatted));
    dispatch(setOutput(amount));
  };

  return (
    <div className={`w-full text-right`}>
      {bal.isLoading ? (
        <Typography variant="bodyXs" as="span" className="text-lightGray">
          Balance: Loading...
        </Typography>
      ) : !bal.data ? (
        <Typography variant="bodyXs" as="span" className="text-lightGray">
          Balance: 0
        </Typography>
      ) : (
        <div className="flex items-center justify-end">
          <Typography variant="bodyXs" as="span" className="text-lightGray">
            Balance: {parseFloat(parseFloat(bal.data.formatted).toFixed(5))}
          </Typography>
          <Typography variant="bodyXs" as="span" className="text-mediumBlue cursor-pointer" onClick={onClick}>
            Max
          </Typography>
        </div>
      )}
    </div>
  );
}
