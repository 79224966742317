import { useContext } from "react"
import { APRContext } from "../../../context-providers/APR"
import { ExternalFarmsContext } from "../../../context-providers/ExternalFarms";
import { BNBVDUSHContext } from "../../../context-providers/BNBVDUSH"
import { Tooltip } from 'react-tooltip';

export default function Title() {
  let aprs = useContext(APRContext);
  let {BNBVDUSHData, aprRatio, totalPancakeTVLRatio, myBNBpancakeSwapLPTVLRatio} = useContext(BNBVDUSHContext);
  let apr = aprs && aprs.bnbvdush ? parseFloat(aprs.bnbvdush) : 0;
  let {bnbFarms} = useContext(ExternalFarmsContext);

  const formattedUSD = (num) => {
    try {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }
    catch (e) {
      return num;
    }
  }

  //calculate apr for the total
  let totalApr = parseFloat(apr);
  let text = `vdUSH Farm APR: ${apr}%<br/>`;
  if(bnbFarms[1] && ( totalPancakeTVLRatio)){
    totalApr += parseFloat(bnbFarms[1].aprBase) * totalPancakeTVLRatio;
    text += `PancakeLP Swap APR: ${(parseFloat(bnbFarms[1].aprBase) * totalPancakeTVLRatio).toFixed(2)}%<br/>`;
  }
  let rand = Math.random();

  //calculate apr for the user
  let myApr = parseFloat(apr*aprRatio);
  let myText = `vdUSH Farm APR: ${myApr.toFixed(2)}%<br/>`;
  if(bnbFarms[1] && ( myBNBpancakeSwapLPTVLRatio)){
    myApr += parseFloat(bnbFarms[1].aprBase) * myBNBpancakeSwapLPTVLRatio;
    myText += `BNBpancakeSwapLP Swap APR: ${(parseFloat(bnbFarms[1].aprBase) * myBNBpancakeSwapLPTVLRatio).toFixed(2)}%<br/>`;
  }
  let rand2 = Math.random();

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-lg font-semibold text-white text-left">BNB vdUSH Farm</h1>
        <h1 className="text-sm font-semibold text-gray-300 text-left" data-tooltip-id={rand}  data-tooltip-html={text}>Average APR: {totalApr.toFixed(2)}% </h1>
        {text && <Tooltip id={rand} style={{zIndex:100, textAlign:'left',fontSize:'12px'}} place='bottom'/>}
      </div>
      <div className="flex flex-row justify-between">
        {BNBVDUSHData &&
          <h1 className="text-sm font-semibold text-gray-300 text-left">TVL: {formattedUSD(BNBVDUSHData.TVL)} </h1>
        }
        {(aprRatio && apr) ? 
          <>
            <h1 className="text-sm font-semibold text-gray-300 text-left" data-tooltip-id={rand2}  data-tooltip-html={myText}>My APR: {myApr.toFixed(2)}% </h1>
            {myText && <Tooltip id={rand2} style={{zIndex:100, textAlign:'left',lineHeight:'25px',fontSize:'12px'}} place='bottom'/>}
          </>
          : null
        }
      </div>
      
      {/* <h1 className="text-sm font-semibold text-gray-300 text-right">Effective APR: {apr}% </h1> */}
      <div className="flex flex-row justify-end items-center">
      </div>
    </>
  )
}