import { ethers } from "ethers";

export const shanghaiV1 = 1680307199 * 1000;
export const shanghaiV2 = 1681617599 * 1000;
export const ethId = process.env.REACT_APP_DEVELOPMENT_ETH === 'true' ? 1337 : 1; //for Wagmi.sh
export const arbId = process.env.REACT_APP_DEVELOPMENT_ARB === 'true' ? 1338 : 42161; //for Wagmi.sh
export const bnbId = process.env.REACT_APP_DEVELOPMENT_BNB === 'true' ? 1339 : 56; //for Wagmi.sh
export const bnbChainId = 102; //For Stargate
export const ethChainId = 101; //For Stargate
export const arbChainId = 110; //For Stargate
export const _stargateSlippageBps = 50;
export const _pancakeSlippageBps = 30;
export const ethPoolId = 2;
export const bnbPoolId = 2;
export const ETHethPoolId = 13;
export const ETHarbPoolId = 13;


//for calculating the deposit output
export const bnb_usdt_decimals = 18;
export const eth_usdt_decimals = 6;
export const _zapSlippageBps = 30;
export const _bnbToAddToLzQuote = ethers.utils.parseEther("0.05");
export const tolerance_to_mint_bps = 10;
export const rETH_mint_tolerance = 10;
export const vdamm_tolerance = 10;

export const API_HOST = "https://api.unsheth.xyz";
// export const API_HOST = "http://localhost:3001";
