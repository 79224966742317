import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import { ethId, bnbId } from '../../constants';
import { selectChains, selectChainFrom, selectChainTo, setChainIn, setChainOut } from '../../redux/bridge';
import { useDispatch } from 'react-redux';
import DropdownChain from './dropdownChains';

export default function Switch() {
  const { switchNetwork } = useSwitchNetwork();
  const chains = useSelector(selectChains);
  const chainIn = useSelector(selectChainFrom);
  const chainOut = useSelector(selectChainTo);
  const dispatch = useDispatch();

  const { chain } = useNetwork();
  let chainId = chain ? chain.id : ethId;

  useEffect(() => {
    if (chains[chainIn].id != chainId) {
      //find the chain in the chains array

      let arr = Object.keys(chains).map((initial) => {
        return chains[initial];
      });

      let new_chain_in = arr.find((chain) => chain.id === chainId);
      if (chainOut === new_chain_in.initials) {
        //if the chain in is the same as the chain out, switch the chain out
        let new_chain_out = arr.find((chain) => chain.id !== chainId);
        dispatch(setChainOut(new_chain_out.initials));
      }
      dispatch(setChainIn(new_chain_in.initials));
    }
  }, [chainId]);

  const switchChain = async () => {
    switchNetwork(chains[chainOut].id);
    let temp = chainIn;
    dispatch(setChainIn(chainOut));
    dispatch(setChainOut(temp));
  };

  return (
    <div className="flex justify-center md:my-10">
      <div className="flex flex-col md:flex-row items-center relative mb-5 text-gray-400 py-2 rounded-md">
        <DropdownChain isFromChain={true} />

        <svg
          onClick={switchChain}
          className="h-8 w-8 md:-rotate-90 hover:text-white cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>

        <DropdownChain isFromChain={false} />
      </div>
    </div>
  );
}
