import contractAddresses from "../contract_addresses";
import DarknetABI from "../ABI/Darknet.json";
import { useContractRead } from "wagmi";
import { ethId } from "../constants";
import { createContext } from "react";

export const DarknetContext = createContext();

export function DarknetProvider({ children }) {

  let darknet = {
    ETH: {data:1e18},
    WETH: useContractRead({abi: DarknetABI, address: contractAddresses.darknet, functionName: 'checkPrice', args:[contractAddresses.WETH], watch:false, scopeKey:'darknetWETH',chainId:ethId}),
    wstETH: useContractRead({abi: DarknetABI, address: contractAddresses.darknet, functionName: 'checkPrice', args:[contractAddresses.wstETH], watch:false, scopeKey:'darknetWstETH',chainId:ethId}),
    rETH: useContractRead({abi: DarknetABI, address: contractAddresses.darknet, functionName: 'checkPrice', args:[contractAddresses.rETH], watch:false, scopeKey:'darknetRETH',chainId:ethId}),
    cbETH: useContractRead({abi: DarknetABI, address: contractAddresses.darknet, functionName: 'checkPrice', args:[contractAddresses.cbETH], watch:false, scopeKey:'darknetcbETH',chainId:ethId}),
    sfrxETH: useContractRead({abi: DarknetABI, address: contractAddresses.darknet, functionName: 'checkPrice', args:[contractAddresses.sfrxETH], watch:false, scopeKey:'darknetsfrxETH',chainId:ethId}),
    ankrETH: useContractRead({abi: DarknetABI, address: contractAddresses.darknet, functionName: 'checkPrice', args:[contractAddresses.ankrETH], watch:false, scopeKey:'darknetankrETH',chainId:ethId}),
    swETH: useContractRead({abi: DarknetABI, address: contractAddresses.darknet, functionName: 'checkPrice', args:[contractAddresses.swETH], watch:false, scopeKey:'darknetswETH',chainId:ethId}),
    ETHx: useContractRead({abi: DarknetABI, address: contractAddresses.darknet, functionName: 'checkPrice', args:[contractAddresses.ETHx], watch:false, scopeKey:'darknetETHx',chainId:ethId}),
  }

  return (
    <DarknetContext.Provider value={darknet}>
      {children}
    </DarknetContext.Provider>
  );
}
