
export default function Title() {
  return (
    <>
    <div className="flex flex-row justify-between items-center">
      <h1 className="text-lg font-semibold text-white text-left">Lock for ARB vdUSH</h1>
    </div>
    <div className="flex flex-row justify-end items-center">
     </div>
    </>
  )
}