import { configureStore } from '@reduxjs/toolkit';
import uiReducer from './ui.js';
import dropdownReducer from './dropdown.js';
import depositReducer from './deposit.js';
import migrateReducer from './migrate.js';
import withdrawReducer from './withdraw.js';
import withdrawV1Reducer from './withdrawV1.js';
import stakeReducer from './stake.js';
import bridgeReducer from './bridge.js';
import watchReducer from './watch.js';
import vdUSHReducer from './vdUSH.js';
import bnbvdUSHReducer from './BNBvdUSH.js';
import arbvdUSHReducer from './ARBvdUSH.js';
import swapReducer from './swap.js';

export const store = configureStore({
  reducer: {
    ARBvdUSH: arbvdUSHReducer,
    BNBvdUSH: bnbvdUSHReducer,
    swap: swapReducer,
    vdUSH: vdUSHReducer,
    watch: watchReducer,
    bridge: bridgeReducer,
    withdraw: withdrawReducer,
    withdrawV1: withdrawV1Reducer,
    migrate: migrateReducer,
    ui: uiReducer,
    dropdown: dropdownReducer,
    deposit: depositReducer,
    stake: stakeReducer
  },
});
