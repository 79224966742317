import { ethId,bnbId, arbId } from "../../constants";
import calcETHLSD from './lsd';
import calcETHETH from './eth';
import calcBNB from './bnb';
import calcARB from './arb';

export default async function calcDeposit({chainId, eth_provider,provider, bnb_provider, arb_provider, initials, input, conversions, darknet, address}) {
    try {
        if(chainId === ethId && initials !== 'ETH'){
            return await calcETHLSD(initials, input, conversions,provider);
        }
        if(chainId === ethId && initials === 'ETH'){
            return await calcETHETH({input, eth_provider, darknet});
        }
        if(chainId === bnbId) {
            return await calcBNB({input, initials, provider, address, eth_provider, darknet});
        }
        if(chainId === arbId){
            return await calcARB({input, initials, provider: eth_provider, address, arb_provider, darknet});
        }
    }
    catch(err){
        console.log(err);
    }
    return null;
}