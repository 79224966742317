import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import { coinbaseWallet, injectedWallet, metaMaskWallet, okxWallet, rainbowWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets'
import { configureChains, createClient, mainnet } from 'wagmi'

import { providers } from 'ethers'
import { arbitrum, bsc, localhost } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { ids } from './alchemy.js'

const maxValidProviders = 1;

async function testAlchemyProvider(apiKey) {
  const provider = new providers.JsonRpcProvider(`https://eth-mainnet.g.alchemy.com/v2/${apiKey}`);
  try {
    await provider.getBlockNumber();
    return true;
  } catch (error) {
    console.error(`API key ${apiKey} failed with error: ${error.message}`);
    return false;
  }
}

async function createValidProviders(apiKeys) {
  let validProviders = [];

  const testProvider = async (apiKey) => {
    if (await testAlchemyProvider(apiKey)) {
      return alchemyProvider({ apiKey });
    } else {
      return null;
    }
  };

  let i = 0;
  while (validProviders < maxValidProviders) {
    let apiKey = apiKeys[i];
    let provider = await testProvider(apiKey);
    if (provider) validProviders.push(provider);
    i++;
  }

  return validProviders;
}


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

let client;
let chains;

async function init() {
  let shuffled = shuffleArray(ids);
  let other_providers = await createValidProviders(shuffled);
  let providers = [...other_providers, (alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }))]

  providers.push(publicProvider());

  let arr = [];
  arr.push(process.env.REACT_APP_BINANCE_RPC_URL);

  bsc.rpcUrls.default.http = arr;
  arbitrum.rpcUrls.default.http = [process.env.REACT_APP_ARBITRUM_RPC_URL];

  const { chains: chainsResult, provider, webSocketProvider } = configureChains(
    [
      // localhost,
      process.env.REACT_APP_DEVELOPMENT_ETH ? localhost : mainnet,
      bsc,
      process.env.REACT_APP_DEVELOPMENT_ARB ? {
        id: 1338,
        name: 'Arbitrum Fork',
        network: 'ARB',
        nativeCurrency: {
          decimals: 18,
          name: 'Arbitrum ETH',
          symbol: 'AETH',
        },
        rpcUrls: {
          default: { http: ['http://localhost:8546'] },
        }
      } : arbitrum,
    ],
    providers,
    { pollingInterval: 20000 },
  );


  chains = chainsResult;

  // const {connectors} = getDefaultWallets({
  //   appName: 'Unsheth',
  //   projectId: 'a275609390c068f8a7f6b1180de8b894',
  //   chains,
  // });

  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        injectedWallet({
          // projectId: 'a275609390c068f8a7f6b1180de8b894',
          chains
        }),
        // getDefaultWallets({
        //     appName: 'Unsheth',
        //     projectId: 'a275609390c068f8a7f6b1180de8b894',
        //     chains,
        //   }),
        metaMaskWallet({
          projectId: 'a275609390c068f8a7f6b1180de8b894',
          chains,
        }),
        okxWallet({
          projectId: 'a275609390c068f8a7f6b1180de8b894',
          chains,
        }),
        rainbowWallet({
          projectId: 'a275609390c068f8a7f6b1180de8b894',
          chains,
        }),
        coinbaseWallet({
          projectId: 'a275609390c068f8a7f6b1180de8b894',
          chains,
        }),
        walletConnectWallet({
          projectId: 'a275609390c068f8a7f6b1180de8b894',
          chains,
        })
      ],
    },
  ]);

  client = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
  });
}

const ready = init();

async function getClientAndChains() {
  await ready;
  return { client, chains };
}

export { getClientAndChains }
