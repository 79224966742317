
import {ethers} from 'ethers';
import contract_addresses from '../../contract_addresses';
import VDAMMABI from '../../ABI/vdAMM.json';
import {vdamm_tolerance} from '../../constants';
import { toast } from 'react-toastify';

export default async function swap({inputAmount, inputChosen, outputChosen, provider}){
    try {
        const vdAMM = new ethers.Contract(contract_addresses.vdAMM, VDAMMABI, provider);

        //convert to addresses
        let inputAddress = contract_addresses[inputChosen === 'ETH' ? 'WETH' : inputChosen];
        let outputAddress = contract_addresses[outputChosen === 'ETH' ? 'WETH' : outputChosen];
        
        let inputAmountWei = ethers.utils.parseEther(inputAmount.toString());
    
        //get the expected amount and the fees
        let [outputAmount, baseFee, dynamicFee, protocolFee] = await vdAMM.swapLsdToLsdCalcs(inputAmountWei, inputAddress, outputAddress);
    
        let minAmountOut = outputAmount.mul(10000 - vdamm_tolerance).div(10000);
    
        //base case LSD to LSD
        let functionName = 'swapLsdToLsd';
        let msgValue = '';
    
        let args = [
            inputAmountWei,
            inputAddress,
            outputAddress,
            minAmountOut
        ]
    
        if(inputChosen === 'ETH'){
            functionName = 'swapEthToLsd';
            args = [
                outputAddress,
                minAmountOut
            ]
            msgValue = inputAmountWei;
        }
    
        else if(outputChosen === 'ETH'){
            functionName = 'swapLsdToEth';
            args = [
                inputAmountWei,
                inputAddress,
                minAmountOut
            ]
        }
    
        let outputs = {
            outputAmount: ethers.utils.formatEther(outputAmount),
            fees: [
                {
                    name: 'Base Fee',
                    value: ethers.utils.formatEther(baseFee)
                },
                {
                    name: 'Dynamic Fee',
                    value: ethers.utils.formatEther(dynamicFee)
                },
            ],
            minAmountOut: ethers.utils.formatEther(minAmountOut),
            msgValue,
            args,
            functionName
        }
        return outputs;
    }
    catch(err){
        toast.error(err.reason);
        return null;
    }
}
