import { selectInput, setCalc, setInput, setOutput, setArgs, setFunctionName, setMsgValue, setMinAmountUnsheth, setGasLimit, resetAll, selectInputDisabled,setLoading, setInputDisabled, setProtocolFee, setDepositFee, setLSDOut} from '../../../redux/deposit';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrent, selectItems } from '../../../redux/dropdown';
import calcDeposit from '../../../actions/calcDeposit';
import { useAccount, useNetwork, useProvider } from 'wagmi';
import { bnbId, ethId, arbId } from '../../../constants';
import { toast } from 'react-toastify';
import { useContext, useRef, useState } from 'react';

export default function InputAmount({conversions, darknet}) {
    const provider = useProvider();
    const account = useAccount();
    const {chain} = useNetwork();
    const dispatch = useDispatch();
    const amount = useSelector(selectInput);
    const items = useSelector(selectItems);
    const current = useSelector(selectCurrent);

    // const inputDisabled = useSelector(selectInputDisabled);
    const {initials} = items[current];
    const eth_provider = useProvider({
        chainId:ethId
    });
    const bnb_provider = useProvider({
        chainId:bnbId
    });
    const arb_provider = useProvider({
        chainId:arbId
    });
    

    let chainId = chain ? chain.id : ethId;
    let disabled = false;

    if(conversions[initials] && darknet[initials]){
        if(!conversions[initials].data){
            disabled = true;
        }
        if(!darknet[initials].data){
            disabled = true;
        }
    }

    let [loading, setLocalLoading] = useState(false);

    const debounceRef = useRef(null);

    const onChange = async (e) => {
        let input = e.target.value;
        if(loading) {
            return;
        }
        if(input === '') {
            dispatch(resetAll());
            if (debounceRef.current) clearTimeout(debounceRef.current);
            return;
        }
        
        dispatch(setInput(input));
        dispatch(setLoading(true));
        dispatch(setOutput(''));
        dispatch(setMinAmountUnsheth(''));



        // Clear the existing debounce timer, if any
        if (debounceRef.current) clearTimeout(debounceRef.current);

        // Set a new debounce timer
        debounceRef.current = setTimeout(async () => {
            setLocalLoading(true);
            if(parseFloat(input) === 0) {
                dispatch(setOutput('0'));
            }
            else {
                try {
                    let output = await calcDeposit({chainId, initials, input, arb_provider, bnb_provider, eth_provider, conversions, darknet,  provider, address: account.address});
                    if (output === null) {
                        dispatch(resetAll());
                    } else {
                        dispatch(setCalc(output));
                    }
                } catch (err) {
                    console.log(err);
                    dispatch(resetAll());
                }
            }
            dispatch(setInputDisabled(false));
            dispatch(setLoading(false));
            setLocalLoading(false);
        }, 300); // 300 milliseconds debounce time
    };

    return (
        <div className="flex flex-1 text-left items-center relative ">
            <input onChange={onChange.bind(this)} disabled={disabled} placeholder={disabled ? "Loading..." : 'Enter Amount'} className="bg-slate-800 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500" type="number" inputMode="decimal" value={amount} />
        </div>
    );
}