import Dropdown from './dropdown';
import InputAmount from './inputAmount';

export default function Input({balances, conversions, darknet}) {
    return (
        <>
            <InputAmount conversions={conversions} darknet={darknet}/>
            <Dropdown balances={balances}/>
        </>
    )
}
