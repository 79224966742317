import { useDispatch, useSelector } from "react-redux";
import { useContext, useRef } from "react";
import { useAccount, useProvider } from "wagmi";
import { BalancesContext } from "../../../../context-providers/Balances";
import {
  setInput,
  resetAll,
  selectInputDisabled,
  setCalculating,
  setCalc,
  selectDuration,
  selectARBUSHInput,
  selectARBcamelotLPInput,
  selectAmountOut,
} from "../../../../redux/ARBvdUSH";
import calcARBvdUSH from "../../../../actions/calcARBvdUSH";
import { ARBVDUSHContext } from "../../../../context-providers/ARBVDUSH";

export default function InputBalance({ asset }) {
  const provider = useProvider();
  const account = useAccount();
  const dispatch = useDispatch();
  const balances = useContext(BalancesContext);
  const { arb_my_locked } = useContext(ARBVDUSHContext);
  let myARBVDUSH = balances.ARBvdUSH;

  let amountARBUSH = useSelector(selectARBUSHInput);
  let amountARBcamelotLP = useSelector(selectARBcamelotLPInput);
  const outputAmount = useSelector(selectAmountOut);

  const inputDisabled = useSelector(selectInputDisabled);
  const duration = useSelector(selectDuration);

  const debounceRef = useRef(null);

  let bal = balances[asset];

  const handleMax = () => {
    if (inputDisabled) return;
    const amount = bal.data.formatted;
    dispatch(setInput({ asset, value: amount }));
    const newAmountARBUSH =
      asset === "ARBUSH" ? amount.toString() : amountARBUSH;
    const newAmountARBcamelotLP =
      asset === "ARBcamelotLP" ? amount.toString() : amountARBcamelotLP;
    if (debounceRef.current) clearTimeout(debounceRef.current);

    // Set a new debounce timer
    debounceRef.current = setTimeout(async () => {
      await lock();
    }, 500); // 1000 milliseconds (1 seconds) debounce time

    let lock = async () => {
      console.log({ amount });
      if (parseFloat(amount) === 0) {
        if (outputAmount) dispatch(resetAll());
        return;
      } else {
        console.log("hello");
        dispatch(setCalculating());
        try {
          let output = await calcARBvdUSH({
            myARBVDUSH,
            amountARBUSH: newAmountARBUSH,
            amountARBcamelotLP: newAmountARBcamelotLP,
            duration,
            provider,
            arb_my_locked,
            address: account.address,
          });
          console.log({ output });
          if (output === null) {
            dispatch(resetAll());
          } else {
            dispatch(setCalc(output));
          }
        } catch (err) {
          console.log(err);
          dispatch(resetAll());
        }
      }
    };
  };

  return (
    <div className={`text-right mr-1`}>
      {bal.isLoading ? (
        <span className="text-sm text-gray-400">Balance: Loading...</span>
      ) : !bal.data ? (
        <span className="text-sm text-gray-400">Balance: 0</span>
      ) : (
        <div>
          <span className="text-sm text-gray-400">
            Balance: {parseFloat(parseFloat(bal.data.formatted).toFixed(3))}
          </span>
          &nbsp;
          <span
            className="text-sm text-blue-500 cursor-pointer"
            onClick={handleMax}
          >
            Max
          </span>
        </div>
      )}
    </div>
  );
}
