import { useContext } from "react"
import { APRContext } from "../../../context-providers/APR"
import { ExternalFarmsContext } from "../../../context-providers/ExternalFarms";
import { ARBVDUSHContext } from "../../../context-providers/ARBVDUSH"
import { Tooltip } from 'react-tooltip';

export default function Title() {
  let aprs = useContext(APRContext);
  let {ARBVDUSHData, aprRatio, totalCamelotTVLRatio, myARBcamelotLPTVLRatio} = useContext(ARBVDUSHContext);
  let apr = aprs && aprs.arbvdush ? parseFloat(aprs.arbvdush) : 0;
  let aprARB = aprs && aprs.arbvdush ? parseFloat(aprs.vdushARB) : 0;
  let {arbFarms} = useContext(ExternalFarmsContext);

  const formattedUSD = (num) => {
    try {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }
    catch (e) {
      return num;
    }
  }

  //calculate apr for the total
  let totalApr = parseFloat(apr) + parseFloat(aprARB);
  let text = `vdUSH Farm APR: ${apr}%<br/>`;
  if(arbFarms[1] && ( totalCamelotTVLRatio)){
    totalApr += parseFloat(arbFarms[1].aprBase) * totalCamelotTVLRatio;
    text += `ARBcamelotLP Swap APR: ${(parseFloat(arbFarms[1].aprBase) * totalCamelotTVLRatio).toFixed(2)}%<br/>`;
    text += `ARB Farm APR: ${aprARB}%<br/>`;
  }
  let rand = Math.random();

  //calculate apr for the user
  let myApr = parseFloat(apr*aprRatio);
  let myText = `vdUSH Farm APR: ${myApr.toFixed(2)}%<br/>`;
  if(arbFarms[1] && ( myARBcamelotLPTVLRatio)){
    myApr += parseFloat(arbFarms[1].aprBase) * myARBcamelotLPTVLRatio;
    myText += `ARBcamelotLP Swap APR: ${(parseFloat(arbFarms[1].aprBase) * myARBcamelotLPTVLRatio).toFixed(2)}%<br/>`;
  }
  myText += `ARB Farm APR: ${(aprARB*aprRatio).toFixed(2)}%<br/>`;
  myApr += parseFloat(aprARB*aprRatio);
  let rand2 = Math.random();

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-lg font-semibold text-white text-left">ARB vdUSH Farm</h1>
        <h1 className="text-sm font-semibold text-gray-300 text-left" data-tooltip-id={rand}  data-tooltip-html={text}>Average APR: {totalApr.toFixed(2)}% </h1>
        {text && <Tooltip id={rand} style={{zIndex:100, textAlign:'left',fontSize:'12px'}} place='bottom'/>}
      </div>
      <div className="flex flex-row justify-between">
        {ARBVDUSHData &&
          <h1 className="text-sm font-semibold text-gray-300 text-left">TVL: {formattedUSD(ARBVDUSHData.TVL)} </h1>
        }
        {(aprRatio) ?
          <>
            <h1 className="text-sm font-semibold text-gray-300 text-left" data-tooltip-id={rand2}  data-tooltip-html={myText}>My APR: {myApr.toFixed(2)}% </h1>
            {myText && <Tooltip id={rand2} style={{zIndex:100, textAlign:'left',lineHeight:'25px',fontSize:'12px'}} place='bottom'/>}
          </>
          : null
        }
      </div>
      
      {/* <h1 className="text-sm font-semibold text-gray-300 text-right">Effective APR: {apr}% </h1> */}
      <div className="flex flex-row justify-end items-center">
      </div>
    </>
  )
}