import { useEffect, useState } from "react";
import { useProvider, useBlockNumber } from "wagmi";
import { createContext } from "react";

export const BlockTimestampContext = createContext();

export function BlockTimestampProvider({ children }) {
  const provider = useProvider();
  let [blockTimestamp, setBlockTimestamp] = useState(Date.now())

  // useEffect( ()=>{
  //   let func = async () => {
  //     setInterval(async ()=>{
  //       const blockInfo = await provider.getBlock('latest')
  //       console.log(blockInfo.timestamp);
  //       setBlockTimestamp(blockInfo.timestamp*1000);
  //     }, 20000)
  //   }
  //   func();
  // },[]);

  return (
    <BlockTimestampContext.Provider value={blockTimestamp}>
      {children}
    </BlockTimestampContext.Provider>
  );
}