// import Dropdown from './dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { selectAmount, setAmount } from '../../redux/bridge';
import Dropdown from './dropdown';

export default function Input({balances}) {
    const dispatch = useDispatch();
    const amount = useSelector(selectAmount);

    const onChange = (e) => {
        try{
            dispatch(setAmount(e.target.value));
        }
        catch(e) {
            console.log(e);
        }
    }

    return (
        <>
            <div className="flex flex-1 text-left items-center relative ">
                <input onChange={onChange.bind(this)} disabled={false} placeholder={'Enter Amount'} className="bg-slate-800 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500" type="number" inputMode="decimal" value={amount} />
            </div>
            <Dropdown balances={balances}/>
        </>
    )
}
    