import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { Button } from '@/components/shared';

export const Connect: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const account = useAccount();
  const [allowClick, setAllowClick] = useState(false);

  //if the user is on the landing page and the account has now been setup navigate to /deposit
  useEffect(() => {
    if (!allowClick && location.pathname === '/' && account && account.status === 'connected') {
      // navigate('/deposit');
    }
    if (location.pathname === '/' && account && account.status === 'connected') {
      setAllowClick(true);
    }
  }, [allowClick, location.pathname, account, navigate]);

  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button variant="text" onClick={openConnectModal}>
                    Connect Wallet
                  </Button>
                );
              }

              if (chain.unsupported) {
                return (
                  <Button onClick={openChainModal} variant="text" disabled>
                    Wrong network
                  </Button>
                );
              }

              return (
                <div className="flex gap-2">
                  <Button variant="flat" onClick={openChainModal} className="!px-3">
                    <div className="flex items-center gap-2">
                      {chain.hasIcon && (
                        <div
                          className="w-4 h-4 rounded-full overflow-hidden"
                          style={{ background: chain.iconBackground }}
                        >
                          {chain.iconUrl && (
                            <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} className="w-4 h-4" />
                          )}
                        </div>
                      )}
                      {chain.name}
                      <ChevronDownIcon className="w-4 h-4" />
                    </div>
                  </Button>

                  <Button onClick={openAccountModal} variant="flat" className="!px-3">
                    <span className="inline-flex items-center gap-3">
                      {account.displayName}
                      <ChevronDownIcon className="w-4 h-4" />
                    </span>
                  </Button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
