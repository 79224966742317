import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction, useAccount } from 'wagmi';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBalanceWatch, setVDUSHWatch } from '../../../redux/watch';
import contract_addresses from '../../../contract_addresses';
import { BNBVDUSHContext } from '../../../context-providers/BNBVDUSH';
import VDUSHABI from '../../../ABI/vdUSH.json';
import moment from 'moment';
import { useEffect } from 'react';
import { useProvider } from 'wagmi';
import { BlockTimestampContext } from '../../../context-providers/BlockTimestamp';
import { Button } from '../../shared';

export default function Submit() {
  const { bnb_my_locked } = useContext(BNBVDUSHContext);
  const blockTime = useContext(BlockTimestampContext);
  const provider = useProvider();

  const dispatch = useDispatch();
  const [withdrawTxHash, setWithdrawTxHash] = useState(null);
  let withdrawActive = false;
  const withdrawWait = useWaitForTransaction({ hash: withdrawTxHash });

  let timeFromNow = null;

  if (bnb_my_locked.data && parseFloat(bnb_my_locked.data.end) !== 0) {
    let { amountA, amountB, amountC } = bnb_my_locked.data;
    //check to make sure at least one of the amounts is greater than 0, make sure to parseFloat each one first
    if (parseFloat(amountA) > 0 || parseFloat(amountB) > 0 || parseFloat(amountC) > 0) {
      const endTime = parseFloat(bnb_my_locked.data.end) * 1000;
      //get current time using the block timestamp from the provider
      if (endTime > blockTime) {
        const diff = endTime - blockTime;
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        timeFromNow = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      } else {
        timeFromNow = null;
        withdrawActive = true;
      }
    } else {
      timeFromNow = null;
      withdrawActive = false;
    }
  } else {
    timeFromNow = null;
    withdrawActive = false;
  }

  //withdraw Prep
  const { config } = usePrepareContractWrite({
    address: contract_addresses.BNBvdUSH,
    abi: VDUSHABI,
    functionName: 'withdraw',
    args: [],
    onError: (err) => {
      // console.log({err});
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Earn Tx Failed!');
    },
    onSuccess(data, err) {
      console.log({ data, err });
      setWithdrawTxHash(data.hash);
      dispatch(setVDUSHWatch('bnb_my_locked'));
      dispatch(setBalanceWatch('BNBvdUSH'));
      dispatch(setBalanceWatch('BNBpancakeSwapLP'));
      dispatch(setBalanceWatch('BNBUSH'));
      toast.info('Earn Tx Submitted!');
    },
  });

  let withdrawDisabled = !write || !withdrawActive;
  let withdrawButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !withdrawDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    write();
  };

  return (
    (timeFromNow || withdrawActive) && (
      <>
        {withdrawWait.isLoading ? (
          <Button variant="gradient" size="big" block disabled={true}>
            Withdrawing...
          </Button>
        ) : (
          <Button variant="gradient" size="big" block disabled={withdrawDisabled} onClick={onClick}>
            {timeFromNow ? 'Withdraw available ' + timeFromNow : 'Withdraw'}
          </Button>
        )}
      </>
    )
  );
}
