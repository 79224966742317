import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction, useNetwork } from 'wagmi';
import web3 from 'web3';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBalanceWatch } from '../../../redux/watch';
import { arbId, ethId } from '../../../constants';
import { Typography, Button } from '../../shared';

export default function ClaimPool({ pool }) {
  let dispatch = useDispatch();
  let { contractAddress, abi, earned, type, rewardAsset, vdUSH, asset } = pool;
  const [claimTxHash, setClaimTxHash] = useState(null);
  const claimWait = useWaitForTransaction({ hash: claimTxHash });
  const [gasLimit, setGasLimit] = useState(null);
  let { chain } = useNetwork();
  let chainId = chain ? chain.id : ethId;

  let overrides = {};
  if (gasLimit) overrides.gasLimit = gasLimit;

  //prepare the claiming
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi,
    functionName: 'getReward',
    args: [],
    overrides,
    onSuccess(data, err) {
      if (!gasLimit && data && data.request && data.request.gasLimit) {
        let multiplier = 200;
        if (chainId === arbId) multiplier = 300;
        setGasLimit(data.request.gasLimit.mul(multiplier).div(100));
      }
    },
    onError(err) {
      console.log({ err });
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log({ err });
      toast.error('Claim Failed!');
    },
    onSuccess(data, err) {
      setClaimTxHash(data.hash);
      dispatch(setBalanceWatch(rewardAsset));
      toast.info('Claim Tx Submitted!');
    },
  });

  const onClaim = () => {
    write();
  };

  let ushEarned;
  let arbEarned;

  ushEarned = earned.data ? parseFloat(web3.utils.fromWei(earned.data[0]._hex, 'ether')) : 0;

  if(pool.rewardAsset2 === 'ARB') {
     arbEarned = earned.data ? parseFloat(web3.utils.fromWei(earned.data[1]._hex, 'ether')) : 0;
  }

  let disabled = !write || (ushEarned === 0 && arbEarned === 0);

  return (
    <div className="flex flex-col mb-4">
      <div className="flex flex-row justify-between items-center">
        <Typography variant="bodySm" className="text-lightGray" weight="medium">
          Claim USH
        </Typography>
        <Typography variant="bodySm" className="text-lightGray" weight="medium">
          {ushEarned}
        </Typography>
      </div>
      {pool.rewardAsset2 === 'ARB' && (
        <div className="flex flex-row justify-between items-center">
        <Typography variant="bodySm" className="text-lightGray" weight="medium">
          Claim ARB
        </Typography>
        <Typography variant="bodySm" className="text-lightGray" weight="medium">
          {arbEarned}
        </Typography>
      </div>
      )}
      <div className="flex flex-row justify-between items-center mt-4 h-[50px]">
        {claimWait.isLoading ? (
          <Button disabled={disabled} onClick={onClaim} variant="gradient" block size="big">
            Claiming...
          </Button>
        ) : (
          <Button disabled={disabled} onClick={onClaim} variant="gradient" block size="big">
            Claim
          </Button>
        )}
      </div>
    </div>
  );
}
