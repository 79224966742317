import { Menu } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { selectItems } from '../../../../../redux/ARBvdUSH';

export default function Chosen({asset}) {
  const items = useSelector(selectItems);
  let {initials, imageUrl} = items[asset];

  return (
    <Menu.Button className="flex items-center hover:bg-surface-subdued h-full">
        <div className="flex w-full px-4 justify-between items-center rounded-l-lg h-full">
            <div className="flex min-w-full items-center justify-between">
                <div className="flex w-full items-center space-x-3">
                    <div className="relative" id="network-icon-1-ETH"><img src={imageUrl} alt="ETH" className="w-8 h-8 rounded-full object-cover min-w-[2rem]"/></div>
                    <div className="shrink items-center truncate">
                        <div className="text-slate-100 text-lg font-medium truncate">{initials}</div>
                    </div>
                </div>
            </div>
        </div>
    </Menu.Button>
  )
}