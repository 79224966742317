import StakeCommunal from './stakeCommunal';
import Unstake from './unstake';
import Claim from './claim';
import Title from './title';

import { GradientCard } from '../../v2';

export default function Pool({ balances, pool }) {
  return (
    <GradientCard>
      <Title pool={pool} />
      {pool.type === 'Communal' && !pool.depositOff ? (
        <StakeCommunal balances={balances} pool={pool} />
      ) : (
        <>
          {pool.vdUSH && (
            <div className="flex flex-row justify-center items-center mt-4 mb-12 h-[50px]">
              <a href="/vdUSH" className="text-gray-300 hover:text-white">
                🥳 Lock {pool.asset} for vdUSH 🥳
              </a>
            </div>
          )}
        </>
      )}
      {!pool.depositOff && <div className="border-t border-slate-700 my-4"></div>}
      <Unstake balances={balances} pool={pool} />
      <div className="border-t border-slate-700 my-4"></div>
      <Claim balances={balances} pool={pool} />
      {pool.poweredBy && (
        <div className="border-t border-slate-700 py-2">
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <p className="text-gray-400">Powered By:</p>
              <img src={`/${pool.poweredBy}`} alt="Powered by" className="h-6" />
            </div>
          </div>
        </div>
      )}
    </GradientCard>
  );
}
