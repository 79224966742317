import { useSelector } from 'react-redux';
import { selectAmountOut, selectBNBUSHInput, selectLoading,
 selectBNBpancakeSwapLPInput
} from '../../../../redux/BNBvdUSH';
import { useContext, useState } from 'react';
import { BNBVDUSHContext } from '../../../../context-providers/BNBVDUSH';
import { PricesContext } from '../../../../context-providers/Prices';
import { APRContext } from '../../../../context-providers/APR';
import { ExternalFarmsContext } from '../../../../context-providers/ExternalFarms';
import { Tooltip } from 'react-tooltip';

export default function APR() {

    const { BNBVDUSHData, totalBNBVDUSH, bnb_my_locked} = useContext(BNBVDUSHContext);
    const {bnbFarms} = useContext(ExternalFarmsContext);

    const amountBNBUSH = useSelector(selectBNBUSHInput);
    const amountBNBpancakeSwapLP = useSelector(selectBNBpancakeSwapLPInput);
    let amount = useSelector(selectAmountOut);
    const prices = useContext(PricesContext);
    let aprs = useContext(APRContext);
    const loading = useSelector(selectLoading);


    //calcualte the total tvl of the amounts being locked
    let myTVL = 0;
    if(amountBNBUSH > 0 && prices['USH']){
        myTVL += parseFloat(amountBNBUSH) * prices['USH'];
    }
    if(amountBNBpancakeSwapLP > 0 && prices['BNBpancakeSwapLP']){
        myTVL += parseFloat(amountBNBpancakeSwapLP) * prices['BNBpancakeSwapLP'];
    }
    //calculate the ratio my tvl vs the tvl of the entire pool
    let tvl_ratio = 0;
    if(myTVL && BNBVDUSHData.TVL){
        tvl_ratio = myTVL / BNBVDUSHData.TVL;
    }

    //calculate the ratio of the amount vs the total vdUSH
    let amount_ratio = 0;
    if(amount > 0 && totalBNBVDUSH.data){
        amount_ratio = amount / (parseFloat(totalBNBVDUSH.data)/1e18);
    }

    //calculate the apr ratio
    let aprRatio = 0;
    if(amount_ratio && tvl_ratio){
        aprRatio = amount_ratio / tvl_ratio;
    }

    //calculate the apr
    let apr = 0;
    if(aprs && aprRatio && !loading){
        apr = parseFloat(aprs['bnbvdush'] * aprRatio).toFixed(2);
    }
    else {
        apr = 0;
    }

    //get the percentages that each token contributes to the total tvl
    let ushPercent = 0;
    let pancakePercent = 0;
    if(myTVL && prices){
        ushPercent = parseFloat(amountBNBUSH * prices['USH'] / myTVL);
        pancakePercent = parseFloat(amountBNBpancakeSwapLP * prices['BNBpancakeSwapLP'] / myTVL);
    }

    let text = '';
    //calculate the apr by multiplying the apr of each token by the percentage of the total tvl that token represents
    let aprBase = 0;
    if(bnbFarms[1] && (pancakePercent)){
        aprBase += parseFloat(bnbFarms[1].aprBase) * pancakePercent;
        text = `BNBpancakeSwapLP Swap APR: ${(parseFloat(bnbFarms[1].aprBase) * pancakePercent).toFixed(2)}%<br/>`;
    }

    let rand = Math.random();

    text = 'BNB vdUSH Farm APR: ' + apr + '%<br/>' + text;

    return (bnb_my_locked.data && parseFloat(bnb_my_locked.data.end) === 0 && apr) ? (
        <>
            <p className='text-gray-400' data-tooltip-id={rand}  data-tooltip-html={text}>
                Effective APR: {(parseFloat(apr) + parseFloat(aprBase)).toFixed(2)}%
            </p>
            {text && <Tooltip id={rand} style={{zIndex:100,lineHeight:'25px', textAlign:'left',fontSize:'12px'}} place='bottom'/>}
        </>
    ) : null
}
