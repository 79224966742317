import { Menu } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { selectOutputItems, selectOutputChosen } from '../../../../redux/swap';

export default function Chosen() {
  const items = useSelector(selectOutputItems);
  const current = useSelector(selectOutputChosen);
  let {initials, imageUrl} = items[current];

  return (
    <Menu.Button className="flex items-center w-[180px] hover:bg-surface-subdued h-full">
        <div className="flex w-full px-4 justify-between items-center rounded-l-lg h-full">
            <div className="flex min-w-full items-center justify-between">
                <div className="flex w-full items-center space-x-3">
                    <div className="relative" id="network-icon-1-ETH"><img src={imageUrl} alt="ETH" className="w-8 h-8 rounded-full object-cover min-w-[2rem]"/></div>
                    <div className="shrink items-center truncate">
                        <div className="text-slate-100 text-lg font-medium truncate">{initials}</div>
                    </div>
                </div>
                <div className="flex-1 flex">
                    <div className="text-slate-400 pl-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </Menu.Button>
  )
}
