import Input from './input';
import Output from './output';
import Submit from './submit';
import UnshethV1Balance from './Balances/unshethv1';
import { useTotalUnshethV1 } from '../../hooks/useTotalUnshethV1';
import { useContext } from 'react';
import { BalancesContext } from '../../context-providers/Balances';
import { AllowancesContext } from '../../context-providers/Allowances';
import { VaultBalancesContext } from '../../context-providers/VaultBalances';
import { useNetwork } from 'wagmi';
import { ethId } from '../../constants';
import { GradientCard } from '../v2';
import { Typography } from '../shared';

export default function Withdraw() {
  let allowances = useContext(AllowancesContext);
  let balances = useContext(BalancesContext);
  let { old_balances } = useContext(VaultBalancesContext);
  let { chain } = useNetwork();

  let vaultBalances = old_balances;

  let totalSupplyUnshethV1 = useTotalUnshethV1();

  if (balances.unshETHV1.data && parseFloat(balances.unshETHV1.data.formatted) === 0) {
    return null;
  }
  let chainId = chain ? chain.id : ethId;

  return (
    chainId === ethId && (
      <div className="mt-4 mx-auto sm:max-w-xl sm:text-center lg:items-center lg:px-0 lg:text-left ">
        <GradientCard>
          <Typography as="h1" variant="bodyLg" className="text-white" weight="black">
            Withdraw V1
          </Typography>
          <div className="mt-2">
            <div className="space-y-3">
              <div>
                <div className="flex flex-col relative">
                  <div className="flex relative h-16">
                    <Input
                      balances={balances}
                      totalSupplyUnshethV1={totalSupplyUnshethV1}
                      vaultBalances={vaultBalances}
                    />
                  </div>

                  <div>
                    <UnshethV1Balance
                      balances={balances}
                      totalSupplyUnshethV1={totalSupplyUnshethV1}
                      vaultBalances={vaultBalances}
                    />
                  </div>

                  <div className="flex relative h-4 mt-2 mb-5 w-full items-center">
                    <svg
                      className="h-5 w-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>

                  <div className="flex relative rounded-lg border border-slate-600 ">
                    <Output vaultBalances={vaultBalances} />
                  </div>
                </div>
              </div>
            </div>
            <Submit balances={balances} allowances={allowances} />
          </div>
        </GradientCard>
      </div>
    )
  );
}
