import {
  setInput,
  resetAll,
  selectInputDisabled,
  setCalculating,
  setCalc,
  selectDuration,
  select8020BPTInput,
  selectUSHInput,
  selectSushiInput,
  selectAmountOut,
} from "../../../../redux/vdUSH";

import { useDispatch, useSelector } from "react-redux";
import { useProvider, useAccount } from "wagmi";
import { useContext, useRef } from "react";
import calcvdUSH from "../../../../actions/calcvdUSH";
import { VDUSHContext } from "../../../../context-providers/VDUSH";
import { BalancesContext } from "../../../../context-providers/Balances";

export default function InputBalance({ asset }) {
  const balances = useContext(BalancesContext);
  const provider = useProvider();
  const account = useAccount();
  const dispatch = useDispatch();
  const { my_locked } = useContext(VDUSHContext);
  let myVDUSH = balances.vdUSH;

  let amountUSH = useSelector(selectUSHInput);
  let amountsushiSwapLP = useSelector(selectSushiInput);
  let amount8020BPT = useSelector(select8020BPTInput);
  const outputAmount = useSelector(selectAmountOut);

  let bal = balances[asset];
  const inputDisabled = useSelector(selectInputDisabled);
  const duration = useSelector(selectDuration);

  const debounceRef = useRef(null);

  const handleMax = () => {
    if (inputDisabled) return;
    const amount = bal.data.formatted;
    dispatch(setInput({ asset, value: amount }));
    const newAmountUSH = asset === "USH" ? amount.toString() : amountUSH;
    const newAmount8020BPT =
      asset === "8020BPT" ? amount.toString() : amount8020BPT;
    const newAmountsushiSwapLP =
      asset === "sushiSwapLP" ? amount.toString() : amountsushiSwapLP;

    // Clear the existing debounce timer, if any
    if (debounceRef.current) clearTimeout(debounceRef.current);

    // Set a new debounce timer
    debounceRef.current = setTimeout(async () => {
      await lock();
    }, 500); // 1000 milliseconds (1 seconds) debounce time

    let lock = async () => {
      if (parseFloat(amount) === 0) {
        if (outputAmount) dispatch(resetAll());
        return;
      } else {
        dispatch(setCalculating());
        try {
          let output = await calcvdUSH({
            myVDUSH,
            amountUSH: newAmountUSH,
            amount8020BPT: newAmount8020BPT,
            amountsushiSwapLP: newAmountsushiSwapLP,
            duration,
            provider,
            my_locked,
            address: account.address,
          });
          if (output === null) {
            dispatch(resetAll());
          } else {
            dispatch(setCalc(output));
          }
        } catch (err) {
          console.log(err);
          dispatch(resetAll());
        }
      }
    };
  };

  return (
    <div className={`text-right mr-1`}>
      {bal.isLoading ? (
        <span className="text-sm text-gray-400">Balance: Loading...</span>
      ) : !bal.data ? (
        <span className="text-sm text-gray-400">Balance: 0</span>
      ) : (
        <div>
          <span className="text-sm text-gray-400">
            Balance: {parseFloat(parseFloat(bal.data.formatted).toFixed(2))}
          </span>
          &nbsp;
          <span
            className="text-sm text-blue-500 cursor-pointer"
            onClick={handleMax}
          >
            Max
          </span>
        </div>
      )}
    </div>
  );
}
