import { createSlice } from '@reduxjs/toolkit';
import { ethId,bnbId, arbId, ethChainId, bnbChainId, arbChainId } from '../constants';

const initialState = {
  amount: '', // Represents both input and output amount
  token: 'unshETH', // 'USH' or 'unshETH'
  items: {
    'USH': { name: 'USH', initials: 'USH', imageUrl:'ush.png'},
    'unshETH': { name: 'unshETH', initials: 'unshETH', imageUrl:'unshETH.svg'},
  },
  chains: {
    'ETH': { name: 'Ethereum',addOn:'',lzId:ethChainId, initials: 'ETH',id:ethId, imageUrl:'eth.svg', airdrop_amount: '0.005', airdrop_asset:'ETH'},
    'BNB': { name: 'BNB Chain',addOn:'BNB',lzId:bnbChainId, initials: 'BNB',id:bnbId, imageUrl:'bnb-bnb.svg', airdrop_amount: '0.02', airdrop_asset:'BNB'},
    'ARB': { name: 'Arbitrum',addOn:'ARB',lzId:arbChainId, initials: 'ARB',id:arbId, imageUrl:'arb.png',  airdrop_amount: '0.005', airdrop_asset:'ETH'},
  },
  chain_in: 'ETH',
  chain_out: 'BNB',
};

export const bridgeSlice = createSlice({
  name: 'bridge',
  initialState,
  reducers: {
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setChainOut: (state, action) => {
      state.chain_out = action.payload;
    },
    setChainIn: (state, action) => {
      state.chain_in = action.payload;
    },
    resetBridge: (state) => {
      state.amount = '';
    }
  },
});

export const { setAmount, setToken, resetBridge, setChainIn, setChainOut } = bridgeSlice.actions;
export const selectAmount = (state) => state.bridge.amount; // Selects both input and output amount
export const selectToken = (state) => state.bridge.token;
export const selectItems = (state) => state.bridge.items;
export const selectChainTo = (state) => state.bridge.chain_out;
export const selectChainFrom = (state) => state.bridge.chain_in;
export const selectChains = (state) => state.bridge.chains;

export default bridgeSlice.reducer;
