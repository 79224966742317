// import Dropdown from './dropdown';
import { selectInput, setInput, selectOutput } from '../../redux/withdraw';
import { useDispatch, useSelector } from 'react-redux';
import calcOutput from './calcOutput';
import { useEffect } from 'react';
import contract_addresses from '../../contract_addresses';
import { Button } from '../shared';

export default function Cheaper() {
  const dispatch = useDispatch();
  const amount = useSelector(selectInput);
  const output = useSelector(selectOutput);

  //when the amount changes I want to call this api https://apiv5.paraswap.io/prices/?srcToken=0x0ae38f7e10a43b5b2fb064b42a2f4514cba909ef&destToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&amount=250000000000000000000&srcDecimals=18&destDecimals=18&side=SELL&network=1 with the amount changed based on the input
  //and then I want to save that amount and compare it to the weighted amount of the other tokens that could be sold at that time
  useEffect(() => {
    // async function getPrice(tokenIn, amount){
    //   let data = await fetch(`https://apiv5.paraswap.io/prices/?srcToken=${tokenIn}&destToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&amount=${parseFloat(amount)*1e18}&srcDecimals=18&destDecimals=18&side=SELL&excludeDirectContractMethods=false&network=1&otherExchangePrices=true&partner=paraswap.io&userAddress=0x0000000000000000000000000000000000000000`);
    //   let json = await data.json();
    //   console.log({json});
    //   let {destUSD, destAmount} = json.priceRoute;
    //   return {destUSD, destAmount};
    // }
    // async function getCheaper(){
    //   try {
    //     let {destUSD, destAmount} = await getPrice(contract_addresses.unshETH, amount);
    //     console.log({destUSD,destAmount});
    //     //for each of the key in output, call getPrice, the tokenIn is the key and the amount is the value
    //     let sum = 0;
    //     for(let key in output){
    //       console.log({key, value:output[key]});
    //       let {destUSD, destAmount} = await getPrice(contract_addresses[key], output[key]);
    //       console.log({key, destAmount});
    //       sum+=parseFloat(destAmount);
    //     }
    //     //calculate the gwei cost of a withdrawal that is 400,000 gas, get the price of gas in gwei
    //     //multiply the two together and add it to the sum
    //     let gas = 400000;
    //     let gasPrice = 100;
    //     sum += gas * gasPrice;
    //     console.log({sum, destAmount, diff : (destAmount - sum)/destAmount*100})
    //   }
    //   catch(err){
    //     console.log({err});
    //     try {
    //       let {destUSD, destAmount} = await getPrice(contract_addresses.unshETH, 1);
    //       console.log({destUSD,destAmount});
    //     }
    //     catch(err){
    //     }
    //   }
    // }
    // if(amount){
    //     getCheaper();
    // }
    // console.log({amount, output});
  }, [amount]);

  let approveButtonClass = `block w-1/2 rounded-lg bg-gradient-to-r py-3 px-4 font-medium text-white shadow from-blue-600 to-cyan-700  hover:from-blue-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  return (
    <div className="w-full mt-8 mb-4">
      <a href="https://swap.defillama.com/?chain=ethereum&from=0x0ae38f7e10a43b5b2fb064b42a2f4514cba909ef&to=0x0000000000000000000000000000000000000000">
        <Button variant="gradient" block size="big">
          Swap unshETH on LlamaSwap
        </Button>
      </a>
    </div>
  );
}
