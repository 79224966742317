import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectMonitor } from "../redux/swap";
import { API_HOST } from "@/constants";

export const VDAMMContext = createContext();

export function VDAMMProvider({ children }) {
  const [VDAMMData, setVDAMMData] = useState({});
  const monitor = useSelector(selectMonitor);

  // Define the URL where you want to fetch the VDAMM data
  const VDAMMApiUrl = `${API_HOST}/vdamm`; // Replace this with the actual API URL
  // const VDAMMApiUrl = "http://localhost:3001/vdamm"; // Replace this with the actual API URL

  useEffect(() => {
    async function fetchVDAMMData() {
      try {
        const response = await fetch(VDAMMApiUrl);
        const data = await response.json();

        const response2 = await fetch(VDAMMApiUrl+"/userRankings");
        const data2 = await response2.json();

        setVDAMMData({...data, ...data2});
      } catch (error) {
        console.error("Error fetching VDAMM data:", error);
      }
    }
    if(monitor){
      setInterval(()=>{
        console.log('fetching VDAMM data');
        fetchVDAMMData();
      },1000*10)
    }
    else {
      fetchVDAMMData();
    }
  }, [monitor]); // The empty array `[]` ensures that the effect only runs once on component mount

  return (
    <VDAMMContext.Provider value={VDAMMData}>
      {children}
    </VDAMMContext.Provider>
  );
}
