import {
  setInput,
  setCalc,
  setOutput,
  setArgs,
  setFunctionName,
  setMsgValue,
  setMinAmountUnsheth,
  setGasLimit,
  resetAll,
  setLoading,
  selectInputDisabled,
  setInputDisabled,
  setDepositFee,
  setProtocolFee,
  setLSDOut,
} from '../../../redux/deposit';
import { useDispatch } from 'react-redux';
import { selectCurrent, selectItems } from '../../../redux/dropdown';
import { useSelector } from 'react-redux';
import calcDeposit from '../../../actions/calcDeposit';
import { useAccount, useNetwork, useProvider } from 'wagmi';
import { ethId, bnbId, arbId } from '../../../constants';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { VaultBalancesContext } from '../../../context-providers/VaultBalances';
import { calculateSpendableAmount } from '../../../actions/calcSpendableAmount';
import { Typography } from '../../shared';
const ethers = require('ethers');

export default function ChosenBalance({ balances, conversions, darknet }) {
  const account = useAccount();
  const provider = useProvider();
  const eth_provider = useProvider({
    chainId: ethId,
  });
  const bnb_provider = useProvider({
    chainId: bnbId,
  });
  const arb_provider = useProvider({
    chainId: arbId,
  });

  let { chain } = useNetwork();
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const current = useSelector(selectCurrent);
  const inputDisabled = useSelector(selectInputDisabled);
  const { new_balances } = useContext(VaultBalancesContext);
  let vaultBalances = new_balances;
  const { initials } = items[current];
  let bal = balances[initials];

  let chainId = chain ? chain.id : ethId;

  const onClick = async () => {
    if (inputDisabled) return;

    let input = bal.data.formatted;
    try {
      if (initials === 'ETH') {
        input = await calculateSpendableAmount(input, provider);
      }
      dispatch(setLoading(true));
      dispatch(setInput(input));
      dispatch(setOutput(''));
      dispatch(setInputDisabled(true));
      let output = await calcDeposit({
        chainId,
        initials,
        input,
        conversions,
        darknet,
        vaultBalances,
        bnb_provider,
        arb_provider,
        eth_provider,
        provider,
        address: account.address,
      });
      if (output === null) {
        dispatch(resetAll());
      } else {
        dispatch(setCalc(output));
      }
    } catch (err) {
      toast.error(err.message);
      dispatch(resetAll());
    }

    dispatch(setInputDisabled(false));
    dispatch(setLoading(false));
  };

  return (
    <div className="py-1 text-right">
      {bal.isLoading ? (
        <Typography as="span" variant="bodyXs" className="text-white/60">
          Balance: Loading...
        </Typography>
      ) : !bal.data ? (
        <Typography as="span" variant="bodyXs" className="text-white/60">
          Balance: 0
        </Typography>
      ) : (
        <div className="flex items-center gap-1 justify-end">
          <Typography as="span" variant="bodyXs" className="text-white/60">
            Balance: {parseFloat(parseFloat(bal.data.formatted).toFixed(5))}
          </Typography>
          <Typography
            as="span"
            variant="bodyXs"
            onClick={onClick}
            className="text-mediumBlue cursor-pointer"
            weight="bold"
          >
            Max
          </Typography>
        </div>
      )}
    </div>
  );
}
