import { _zapSlippageBps, _pancakeSlippageBps, _stargateSlippageBps } from "../../constants";
import { ethChainId } from "../../constants";
import contract_addresses from "../../contract_addresses";
import StargateRouterABI from '../../ABI/StargateRouter.json';
import { ethers } from 'ethers';
import { pickPathId } from "./pickPathId";

// *************************************************************************
let _dstChainId = ethChainId;
let _functionType = 1;
// *************************************************************************

const minimum_lz_quote = ethers.utils.parseEther("0.01");

function divideBy(bigAmount, exponent=18) {
    const str = Math.floor(ethers.utils.formatUnits(bigAmount, exponent));
    return ethers.BigNumber.from(str);
}

function multiplyAndDivideBigNumbers(a, b) {
  // Convert the BigNumber values to FixedNumber with 18 decimal places
  const aFixed = ethers.FixedNumber.fromValue(a, 18);
  const bFixed = ethers.FixedNumber.fromValue(b, 18);

  // Multiply the two FixedNumber values
  const product = aFixed.mulUnsafe(bFixed);

  // Define the divisor 10^18 as a FixedNumber
  const divisor = ethers.FixedNumber.fromValue("1000000000000000000", 18);

  // Divide the product by 10^18
  const quotientFixed = product.divUnsafe(divisor);

  // Convert the result to a human-readable string with 18 decimal places
  const resultString = quotientFixed.toString();

  // Convert the result from string to BigNumber
  const resultBigNumber = ethers.utils.parseUnits(resultString, 18);

  return resultBigNumber;
}

export default async function calcARB({input, provider, address, arb_provider, darknet}) {

    const amountIn = ethers.utils.parseEther(input);

    const expected_amount_eth = ethers.utils.parseEther(input);

    const min_amount_sgETH =  expected_amount_eth.mul(10000-_stargateSlippageBps).div(10000);

    let { lsd_to_deposit, pathId, weth_lsd_amount_out, eligible_lsd_bought, should_buy, depositFee, protocolFee } = await pickPathId(min_amount_sgETH, provider, darknet);

    const min_amount_unshethZap = should_buy ? eligible_lsd_bought.mul(10000-_zapSlippageBps).div(10000) : weth_lsd_amount_out;

    const expected_amount_unsheth = multiplyAndDivideBigNumbers(weth_lsd_amount_out.sub(depositFee), darknet[lsd_to_deposit].data);
    let min_amount_unsheth = multiplyAndDivideBigNumbers((weth_lsd_amount_out.sub(depositFee)).mul(10000 - (should_buy ? _zapSlippageBps : 0)).div(10000), darknet[lsd_to_deposit].data)
    
    console.log({min_amount_unshethZap: min_amount_unshethZap.toString()});

    console.log({expected_amount_unsheth:expected_amount_unsheth.toString()});

    console.log({min_amount_unsheth});
    console.log({_zapSlippageBps});
    console.log({min_amount_sgETH: min_amount_sgETH.toString()});

    const _transferAndCallPayload = ethers.utils.defaultAbiCoder.encode(["address", "uint256", "uint256"], [address, min_amount_unshethZap, pathId]);

    let {quoteLzFee, dstGasForCall, dstNativeAmount} = await getLayerZeroInfo(amountIn, _transferAndCallPayload, address, min_amount_unsheth, arb_provider, provider);

    let msg_value;
    let lz_fee_to_use = quoteLzFee.gt(minimum_lz_quote) ? quoteLzFee : minimum_lz_quote;

    msg_value = amountIn.add(lz_fee_to_use);

    let params;
    let _gasLimit = 1000000;

    params = [amountIn, min_amount_sgETH, min_amount_unshethZap, dstGasForCall, dstNativeAmount, pathId];

    console.log(
        {amountIn: amountIn.toString(), min_amount_sgETH: min_amount_sgETH.toString(), min_amount_unshethZap:min_amount_unshethZap.toString(), dstGasForCall, dstNativeAmount:dstNativeAmount.toString(), pathId}
    )
    console.log({msgValue: msg_value.toString()});
    // _gasLimit = await sgSender.estimateGas.mint_unsheth_with_bnb(...params, {value: msg_value});
    return { lsdOut: weth_lsd_amount_out,expectedOutput: expected_amount_unsheth, msgValue: msg_value, args: params, functionName: 'mint_unsheth', minOutput: min_amount_unsheth, gasLimit: _gasLimit, depositFee, protocolFee, }
}


async function getLayerZeroInfo(amountETH, _transferAndCallPayload, address, unshethAmount, arb_provider, provider) {
    try {
        const dstGasForCall = 1000000;

        const dstNativeAmount = ethers.utils.parseEther("0.0015");

        const _lzTxParams =  {
            dstGasForCall: dstGasForCall,
            dstNativeAmount: dstNativeAmount,
            dstNativeAddr: ethers.utils.defaultAbiCoder.encode(["address"], [contract_addresses.SGReceiver])
        }
    
        const quoteLzFeeParams = [_dstChainId, _functionType, address, _transferAndCallPayload, _lzTxParams];

        const stargateRouter = new ethers.Contract(contract_addresses.ARBStargateRouter, StargateRouterABI, arb_provider);
        let [quoteLzFee] = await stargateRouter.quoteLayerZeroFee(...quoteLzFeeParams);

        let lzFeeReductionPercent = 100;

        quoteLzFee = quoteLzFee.mul(lzFeeReductionPercent).div(100);

        return {
            quoteLzFee,
            dstGasForCall: dstGasForCall,
            dstNativeAmount: dstNativeAmount,
            dstNativeAddr:ethers.utils.defaultAbiCoder.encode(["address"], [contract_addresses.SGReceiver])
        }
    }
    catch(err){
        console.log(err);
        throw new Error(err);
    }
}

