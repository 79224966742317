import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction, useAccount } from 'wagmi';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import contract_addresses from '../../../../contract_addresses';
import { ARBVDUSHContext } from '../../../../context-providers/ARBVDUSH';
import { BalancesContext } from '../../../../context-providers/Balances';
import VDUSHFarmABI from '../../../../ABI/ARBvdUSHFarm.json';
import ArbFarmABI from '../../../../ABI/ArbFarm.json';
import { setVDUSHWatch } from '../../../../redux/watch';
import { Button } from '../../../shared';

export default function Submit3() {
  let account = useAccount();
  const { alreadyStaking, earnedARBUSH, isBlocked, earnedARB } = useContext(ARBVDUSHContext);
  const dispatch = useDispatch();
  const [lockTxHash, setSwapTxHash] = useState(null);
  const [ARBLockTxHash, setARBSwapTxHash] = useState(null);
  const balances = useContext(BalancesContext);
  const lockWait = useWaitForTransaction({ hash: lockTxHash });
  const ARBLockWait = useWaitForTransaction({ hash: ARBLockTxHash });
  const isStaking = alreadyStaking.data ? alreadyStaking.data : false;

  console.log({ isBlocked });
  let ushAmount = earnedARBUSH.data ? parseFloat(earnedARBUSH.data[0]) / 1e18 : 0;
  let vdushAmount = earnedARBUSH.data ? parseFloat(earnedARBUSH.data[1]) / 1e18 : 0;
  let grailAmount = earnedARBUSH.data ? parseFloat(earnedARBUSH.data[2]) / 1e18 : 0;
  let xgrailAmount = earnedARBUSH.data ? parseFloat(earnedARBUSH.data[3]) / 1e18 : 0;
  let arbAmount = earnedARB.data ? parseFloat(earnedARB.data) / 1e18 : 0;

  if (!isStaking) {
    ushAmount = 0;
    vdushAmount = 0;
    arbAmount = 0;
  }

  //Lock Prep
  const { config } = usePrepareContractWrite({
    address: contract_addresses.ARBvdUSHFarm,
    abi: VDUSHFarmABI,
    functionName: 'passGoAndCollect',
    args: [account.address],
    onError: (err) => {
      console.log({ err });
    },
  });

  //Lock Prep
  const arbPrepare = usePrepareContractWrite({
    address: contract_addresses.ArbFarm,
    abi: ArbFarmABI,
    functionName: 'claim',
    args: [account.address],
    onError: (err) => {
      console.log({ err });
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Claim Failed!');
    },
    onSuccess(data, err) {
      console.log({ data, err });
      setSwapTxHash(data.hash);
      dispatch(setVDUSHWatch('arb_claimed_ush'));
      toast.info('Claim Tx Submitted!');
    },
  });

  const ARBWrite = useContractWrite({
    ...arbPrepare.config,
    onError(err) {
      console.log(err);
      toast.error('Claim Failed!');
    },
    onSuccess(data, err) {
      console.log({ data, err });
      setARBSwapTxHash(data.hash);
      dispatch(setVDUSHWatch('claimed_arb'));
      toast.info('Claim Tx Submitted!');
    },
  });

  let lockDisabled = !write && isBlocked.data === false;
  let lockButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !lockDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  let ARBlockDisabled = !ARBWrite.write;
  let ARBlockButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
      !ARBlockDisabled
          ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
          : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;


  const onClick = () => {
    write();
  };

  const onClickARB = () => {
    ARBWrite.write();
  };

  return (
    <div className="flex-flex-row w-full">
      <div className="flex flex-row justify-end items-center">
        <span className="text-sm text-left  text-gray-200 w-full">USH</span>
        <div className="w-full text-right mr-1">
          <span className="text-sm text-gray-400">{parseFloat(parseFloat(ushAmount).toFixed(5))}</span>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center">
        <span className="text-sm text-left text-gray-200 w-full">vdUSH</span>
        <div className="w-full text-right mr-1">
          <span className="text-sm text-gray-400">{parseFloat(parseFloat(vdushAmount).toFixed(5))}</span>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center">
        <span className="text-sm text-left  text-gray-200 w-full">Grail</span>
        <div className="w-full text-right mr-1">
          <span className="text-sm text-gray-400">{parseFloat(parseFloat(grailAmount).toFixed(10))}</span>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center">
        <span className="text-sm text-left text-gray-200 w-full">xGRAIL</span>
        <div className="w-full text-right mr-1">
          <span className="text-sm text-gray-400">{parseFloat(parseFloat(xgrailAmount).toFixed(10))}</span>
        </div>
      </div>

      <div className="mt-8 flex justify-center text-center gap-4 w-full mb-4">
        {lockWait.isLoading ? (
          <Button variant="gradient" size="big" block disabled={true}>
            Claiming...
          </Button>
        ) : (
          <Button variant="gradient" size="big" block disabled={lockDisabled} onClick={onClick}>
            {isBlocked.data === true ? 'Blocked' : 'Claim'}
          </Button>
        )}
      </div>
      <div className="border-t border-slate-700 py-2 mt-4">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <p className="text-gray-400">Supported By:</p>
            <img src={`/camelot.svg`} alt="Powered by" className="h-6" />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center">
        <span className="text-sm text-left text-gray-200 w-full">ARB</span>
        <div className="w-full text-right mr-1">
          <span className="text-sm text-gray-400">{parseFloat(parseFloat(arbAmount).toFixed(5))}</span>
        </div>
      </div>
      <div className="mt-8 flex justify-center text-center gap-4 w-full mb-4">
        {ARBLockWait.isLoading ? (
            <Button variant="gradient" size="big" block disabled={true}>
              Claiming ARB...
            </Button>
        ) : (
            <Button variant="gradient" size="big" block disabled={ARBlockDisabled} onClick={onClickARB}>
              Claim ARB
            </Button>
        )}
      </div>
    </div>
  );
}
