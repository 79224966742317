import { setInput, resetAll, setLoading, selectLoading, selectInputDisabled, setCalculating, setCalc, selectDuration, select8020BPTInput, selectUSHInput, selectSushiInput, selectAmountOut } from '../../../../redux/vdUSH';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useNetwork, useProvider } from 'wagmi';
import { useContext, useRef, useState } from 'react';
import calcvdUSH from '../../../../actions/calcvdUSH';
import { VDUSHContext } from '../../../../context-providers/VDUSH';
import { BalancesContext } from '../../../../context-providers/Balances';

export default function InputAmount({asset}) {
    const provider = useProvider();
    const account = useAccount();
    const dispatch = useDispatch();
    const {my_locked} = useContext(VDUSHContext);
    const balances = useContext(BalancesContext);
    let myVDUSH = balances.vdUSH;

    let amountUSH = useSelector(selectUSHInput);
    let amountsushiSwapLP = useSelector(selectSushiInput);
    let amount8020BPT = useSelector(select8020BPTInput);
    const outputAmount = useSelector(selectAmountOut);

    const inputDisabled = useSelector(selectInputDisabled);
    const duration = useSelector(selectDuration);

    let disabled = false;

    const debounceRef = useRef(null);

    let amount = '';
    if(asset === 'USH') amount = amountUSH;
    else if(asset === '8020BPT') amount = amount8020BPT;
    else if(asset === 'sushiSwapLP') amount = amountsushiSwapLP;

    const onChange = async (e) => {
        let inputAmount = e.target.value;
        if(inputDisabled) return;

        dispatch(setInput({asset,value:inputAmount}));

        if(asset === 'USH') amountUSH = inputAmount;
        else if(asset === '8020BPT') amount8020BPT = inputAmount;
        else if(asset === 'sushiSwapLP') amountsushiSwapLP = inputAmount;
        
        // Clear the existing debounce timer, if any
        if (debounceRef.current) clearTimeout(debounceRef.current);

        // Set a new debounce timer
        debounceRef.current = setTimeout(async () => {
            await lock();
        }, 500); // 1000 milliseconds (1 seconds) debounce time

        let lock = async () => {
            if(parseFloat(inputAmount) === 0) {
                if(outputAmount) dispatch(resetAll());
                return;
            }
            else {
                dispatch(setCalculating());
                try {
                    let output = await calcvdUSH({myVDUSH, amountUSH, amount8020BPT, amountsushiSwapLP, duration, provider,my_locked, address: account.address});
                    if (output === null) {
                        dispatch(resetAll());
                    } else {
                        dispatch(setCalc(output))
                    }
                } catch (err) {
                    console.log(err);
                    dispatch(resetAll());
                }
            }
        }
    };

    return (
        <div className="flex flex-1 text-left items-center relative ">
            <input onChange={onChange.bind(this)} disabled={disabled} placeholder={disabled ? "Loading..." : 0} className="bg-slate-800 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500" type="number" inputMode="decimal" value={amount} />
        </div>
    );
}