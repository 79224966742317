import { useNavigate, Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { Typography, Button } from '../shared';

export default function Example() {
  const navigate = useNavigate();
  const account = useAccount();

  //function that triggers a click on a button that is the child of a div which is the child of an element with id headlessui-menu-button-:r0:
  const clickConnect = () => {
    console.log('called');
    //scroll to the top of the page
    window.scrollTo(0, 0);

    //if the account is active, navigate to /convert
    if (account && account.status === 'connected') {
      navigate('/deposit');
    } else {
      const parentElement = document.getElementById('connectButton');
      const div = parentElement.querySelector('div');
      const button = div.querySelector('button');
      button.click();
    }
  };

  const clickConnectTrade = () => {
    //scroll to the top of the page
    window.scrollTo(0, 0);

    //if the account is active, navigate to /convert
    if (account && account.status === 'connected') {
      navigate('/deposit');
    } else {
      const parentElement = document.getElementById('connectButton');
      const div = parentElement.querySelector('div');
      const button = div.querySelector('button');
      button.click();
    }
  };

  const clickConnectEarn = () => {
    //scroll to the top of the page
    window.scrollTo(0, 0);

    //if the account is active, navigate to /convert
    if (account && account.status === 'connected') {
      navigate('/stake');
    } else {
      const parentElement = document.getElementById('connectButton');
      const div = parentElement.querySelector('div');
      const button = div.querySelector('button');
      button.click();
    }
  };

  const handleLearnMore = () => {
    window.location.href = 'https://docs.unsheth.xyz/';
  };

  const partners = [
    {name:'Arbitrum', logo:'/Partners/Arbitrum.png',link:'https://arbitrum.io/'},
    {name:'BNB Chain', logo:'/Partners/BNBChain.png',link:'https://bnbchain.org'},
    // {name: 'Lido', logo: '/Partners/lido.svg', link: 'https://lido.fi/'},
    {name: 'Coinbase', logo: '/Partners/Coinbase.svg', link: 'https://www.coinbase.com/'},
    // {name: 'Frax', logo: '/Partners/Frax.svg', link: 'https://frax.finance/', showText:true},
    // {name: 'Rocket Pool', logo: '/Partners/Rocketpool.svg', link: 'https://www.rocketpool.net/', showText:true},
    {name: 'ANKR', logo: '/Partners/Ankr.svg', link: 'https://www.ankr.com/'},
    {name: 'Swell', logo: '/Partners/Swell.svg', link: 'https://swell.finance/'},
    {name: 'Aura', logo: '/Partners/Aura.png', link: 'https://aura.finance/', showText:true},
    {name: 'Layer Zero', logo: '/Partners/LayerZero.png', link: 'https://layerzero.network/'},
    {name: 'Maverick', logo:'/Partners/Maverick.svg',link:'https://mav.xyz'},
    {name: 'Timeswap', logo: '/Partners/Timeswap.svg',link:'https://timeswap.io'},
    {name: 'Savvy', logo: '/Partners/savvy.png',link:'https://savvydefi.io/'},
    {name: 'Zero Liquid',logo:'/Partners/ZeroLiquid.svg',link:'https://zeroliquid.xyz'},
    {name: 'Sushi Swap', logo: '/Partners/Sushi.svg', link:'https://sushi.com', showText:true},
    {name: 'Pancake Swap', logo: '/Partners/PancakeSwap.svg', link:'https://pancakeswap.finance'},
    {name: 'Camelot', logo:'/Partners/Camelot.svg',link:'https://camelot.exchange/'},
    {name: 'Rodeo Finance', logo: '/Partners/Rodeo.svg',link:'https://rodeo.finance/',showText:true},
    {name: 'Unidex', logo: '/Partners/unidex.svg',link:'https://unidex.exchange/'},
  ]

  return (
    <div className="w-full">
      <div className="w-full text-center mt-6 mb-12 relative">
        <img src="/images/landing/alien.png" alt="mint" className="z-10 hidden md:block absolute left-[45px] h-[360px] w-auto object-contain" />

        <Typography
          variant="headingXxl"
          weight="black"
          className="mb-4 text-transparent bg-clip-text bg-gradientText text-center"
          as="h2"
        >
          unshETH your ETH!
        </Typography>
        <Typography as="p" variant="headingLg" className="text-white/80">
        Decentralization through incentivization
        </Typography>
        <div className="w-full md:w-[311px] grid gap-4 mx-auto mb-[120px] md:mb-20 mt-8">
        <Button variant="gradient" block size="big" onClick={clickConnect}>
         Launch App
        </Button>
        <Button block size="big" onClick={handleLearnMore}>
          Learn more
        </Button>
            <img src="/images/landing/pepe_unsheth.png" alt="mint" className="z-10 hidden md:block absolute top-0 right-[-110px] h-[360px] w-auto object-contain" />
        </div>
      </div>

    

      <div className="flex flex-col md:flex-row gap-6 w-full mb-[120px] md:mb-[200px]">
        <div className="rounded-3xl flex-1 bg-gradientBorder p-[1px] relative">
          <div className=" p-6 md:p-12 relative bg-gradientDark rounded-3xl overflow-hidden">
            <Typography variant="headingLg" weight="black" className="text-white mb-6 md:mb-10">
              Mint unshETH
              <br />
              with your LSDs & ETH
            </Typography>
            <Button variant="gradient" size="big" onClick={clickConnectTrade}>
               Mint
            </Button>
            <div className="absolute right-0 bottom-0 poin pointer-events-none">
              <img src="/images/landing/card.png" alt="card" />
            </div>
            <div className="flex absolute top-0 right-0 w-[140px] h-full md:w-[185px] flex items-end justify-end">
              <img src="/images/landing/cuteush.png" alt="stake" className="w-full h-auto object-fit w-[80%] h-[80%]" />
            </div>
            
          </div>
          <div className="absolute bottom-full right-1/3 h-[40px] hidden md:block w-[500px] border-dashed border-mainCTAs/30 border-r-[2px] border-t-[2px] rounded-tr-3xl" />
          <div className="absolute top-[110%] right-1/2 h-[80px] hidden md:block w-[500px] border-dashed border-mainCTAs/30 border-r-[2px] border-b-[2px] rounded-br-3xl" />
        </div>
        <div className="rounded-3xl flex-1 bg-gradientBorder p-[1px] relative">
          <div className=" p-6 md:p-12 relative bg-gradientDark rounded-3xl overflow-hidden">
            <Typography variant="headingLg" weight="black" className="text-white mb-6 md:mb-10" onClick={clickConnectEarn}>
              Stake unshETH
              <br />
              to earn USH
            </Typography>
            <Button variant="gradient" size="big" onClick={clickConnectEarn}>
              Stake
            </Button>
            <div className="absolute right-0 bottom-0 pointer-events-none">
              <img src="/images/landing/card.png" alt="card" />
            </div>
            <div className="absolute top-1/2 right-0 w-[140px] md:w-[185px] -translate-y-1/2 pointer-events-none">
              <img src="/images/landing/stake_unsheth.png" alt="stake" className="w-full" />
            </div>
          </div>
          <div className="absolute bottom-full left-2/3 h-[60px] hidden md:block w-[500px] border-dashed border-mainCTAs/30 border-l-[2px] border-t-[2px] rounded-tl-3xl" />
          <div className="absolute top-[115%] left-1/4 h-[80px] hidden md:block w-[500px] border-dashed border-mainCTAs/30 border-l-[2px] border-b-[2px] rounded-bl-3xl" />
        </div>
      </div>

      <Typography variant="headingLg" className="text-lightGray" style={{textAlign:'center'}} weight="medium" as="h2">
          Audits & Metrics
      </Typography>

      <div className="rounded-3xl bg-gradientBorder p-[1px] mt-2">
        <div className="p-6 md:px-8 md:pt-12 md:pb-14 bg-gradientDark rounded-3xl flex flex-col md:flex-row justify-between items-center gap-4">
          <a className="mx-10 flex items-center flex-col" href="https://skynet.certik.com/projects/unsheth">
            <img className="h-10" src="/certik.png" alt="certik"></img>
          </a>
          <a className="mx-10 flex items-center flex-col" href="https://paladinsec.co/projects/unsheth">
            <img className="h-10" src="/paladin.svg" alt="paladin"></img>
          </a>
          <a className="mx-10 flex items-center flex-col" href="https://dune.com/unsheth/unshethxyz">
            <span className="inline-flex items-center gap-4">
              <img className="h-10" src="/dune.svg" alt="dune"></img>
              <Typography variant="bodyMd" className="text-lightGray" weight="medium">
                Dune Dashboard
              </Typography>
            </span>
          </a>
        </div>
      </div>

      <Typography variant="headingLg" className="text-lightGray mt-12" style={{textAlign:'center'}} weight="medium" as="h2">
          Partners
      </Typography>
      
      <div className="rounded-3xl p-[1px] mt-2 bg-gradientBorder">

        <div className="p-6 md:px-8 md:pt-12 md:pb-14  bg-gradientDark rounded-3xl grid sm:grid-cols-2 md:grid-cols-4 items-center gap-12">
          {partners.map(({link,logo,name,showText}) => (
            <a className="mx-10 flex flex-col items-center" href={link}>
            {showText ?
            <span className="inline-flex items-center gap-4">
              <img className="max-w-full max-h-14" src={logo} alt={name}></img>
              <Typography variant="bodyMd" className="text-lightGray" weight="medium">
                {name}
              </Typography>
            </span>
            :
            <img className="max-w-full max-h-14" src={logo} alt={name}></img>
            }
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
