import { i } from 'mathjs';
import web3 from 'web3';

export default function getPoolAPY(pool, ushPrice, arbPrice, ethPrice) {
  let {rewardRates, totalLiquidityLocked, asset, rewardAsset2, aprs, lock_max_multiplier, totalCombinedWeight} = pool;

  //Get USH Emitted Per Year
  let USHPerYear = rewardRates.data ? parseFloat(web3.utils.fromWei(rewardRates.data[0]._hex,'ether')) * 86400 * 365 : 0;

  let dollarPerYearReward = USHPerYear * ushPrice;

  //get the total locked amount within the pool;
  let totalLocked = totalLiquidityLocked.data ? parseFloat(web3.utils.fromWei(totalLiquidityLocked.data._hex,'ether')) : 0;

  //calculate the amount of dollars invested in the pool
  let dollarPerYearInvested = 0;

  if(asset === 'unshETH' || asset === 'BNBunshETH' || asset === 'ARBunshETH'){
    dollarPerYearInvested = totalLocked * ethPrice;
  }

  //calculate the apy
  let ush_apy = parseFloat(dollarPerYearReward / dollarPerYearInvested * 100);

  if(totalLiquidityLocked && lock_max_multiplier && totalCombinedWeight && totalLiquidityLocked.data && totalCombinedWeight.data && lock_max_multiplier.data) {
    // let averageMultiplier = parseFloat(totalCombinedWeight.data)/parseFloat(totalLiquidityLocked.data);
    // let maxMultiplier = parseFloat(web3.utils.fromWei(lock_max_multiplier.data._hex,'ether'));

    aprs.unshift({name:'USH Farm Rewards',value:parseFloat(ush_apy).toFixed(2) });
  }
  else {
    aprs.unshift({name:'USH Farm Rewards',value:ush_apy.toFixed(2) });
  }

  if(rewardAsset2 == "ARB") {
    let ARBPerYear = rewardRates.data && rewardRates.data[1] ? parseFloat(web3.utils.fromWei(rewardRates.data[1]._hex,'ether')) * 86400 * 365 : 0;
    let ARBdollarPerYearReward = ARBPerYear * arbPrice;
    let arb_apy = parseFloat(ARBdollarPerYearReward / dollarPerYearInvested * 100);
    aprs.unshift({name: 'ARB Farm Rewards',value:parseFloat(arb_apy).toFixed(2) });
  }


  let totalApy = 0;
  aprs.forEach((apr) => {
    totalApy += parseFloat(apr.value);
  });

  totalApy = totalApy.toFixed(2);
  
  return {totalApy, aprs};
}