import { useState } from 'react';
import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, useNetwork } from 'wagmi';
import Dropdown from './unstakeDropdown';
import web3 from 'web3';
import { useDispatch } from 'react-redux';
import { setBalanceWatch, setPoolWatch } from '../../../redux/watch';
import { bnbChainId, bnbId, ethId } from '../../../constants';
import { Button, Typography } from '../../shared';

export default function UnstakePool({ pool }) {
  const dispatch = useDispatch();
  let { chain } = useNetwork();
  let chainId = chain ? chain.id : ethId;
  let { name, asset, contractAddress, abi, parsedStake, fullStakedAmount, type, rewardAsset } = pool;
  let [selected, setSelected] = useState(0);
  const [gasLimit, setGasLimit] = useState(null);

  let canUnstake = parsedStake[selected]?.canUnstake;

  let overrides = {};
  if (gasLimit) overrides.gasLimit = gasLimit;

  //prepare the unstaking
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi,
    functionName: 'withdrawLocked',
    args: [canUnstake && parsedStake[selected] ? web3.utils.hexToBytes(parsedStake[selected]?.kek_id) : 0],
    overrides,
    onSuccess(data, err) {
      if (canUnstake && parsedStake[selected] && !gasLimit && data && data.request && data.request.gasLimit) {
        setGasLimit(data.request.gasLimit.mul(200).div(100));
      }
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log({ err });
      toast.error('Unstaking Failed!');
    },
    onSuccess(data, err) {
      setSelected(0);
      dispatch(setBalanceWatch(asset));
      dispatch(setPoolWatch(name + 'staked'));
      dispatch(setBalanceWatch(rewardAsset));
      console.log({ data, err });
      toast.info('Unstake Tx Submitted!');
    },
  });

  const onUnstake = () => {
    write();
  };
  //TODO read the staked amount and the claim amount from the contract
  if (parsedStake.length === 0) canUnstake = true;

  let disabled = !write || !canUnstake;

  return (
    <div className="grid gap-4">
      <div className="flex flex-row justify-between items-center">
        <Typography variant="bodySm" className="text-lightGray" weight="medium">
          Staked {asset}
        </Typography>
        <Typography variant="bodySm" className="text-lightGray" weight="medium">
          {fullStakedAmount}
        </Typography>
      </div>
      <div className="flex flex-row justify-between items-center">
        {parsedStake.length === 0 ? (
          <Typography variant="bodyXs" className="text-white/60">
            No Staked Assets
          </Typography>
        ) : (
          <Dropdown staked={parsedStake} selected={selected} setSelected={setSelected} />
        )}
        <Button disabled={disabled} onClick={onUnstake}>
          Unstake
        </Button>
      </div>
    </div>
  );
}
