import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import moment from 'moment';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example({staked, selected, setSelected}) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-1 w-full">
            <Listbox.Button className="w-full rounded-md border border-transparent bg-transparent py-2 pr-10 text-left shadow-sm focus:none focus:outline-none focus:ring-0 focus:none sm:text-sm">
              <span className="inline-flex w-full truncate justify-between">
                <span className={`w-[90px] md:w-[70px] lg:w-[100px] leading-8 items-center truncate text-gray-300`}>{staked[selected] && parseFloat(parseFloat(staked[selected].amount).toFixed(5))}</span>
                <span className={"ml-2 truncate text-gray-400 h-8 text-xs text-center"}>
                  Multiplier<br/>
                  x{staked[selected] && parseFloat(staked[selected].multiplier).toFixed(2)}
                </span>
                <span className="block md:hidden lg:block ml-2 truncate text-gray-500 text-xs h-8">
                  {moment(staked[selected].startDate).format('MM/DD/YY HH:SS')} <br/>
                  {moment(staked[selected].endDate).format('MM/DD/YY HH:SS')}
                </span>
                <span className="hidden md:block lg:hidden ml-2 truncate text-gray-500 text-xs h-8">
                  {moment(staked[selected].startDate).format('MM/DD/YY')} <br/>
                  {moment(staked[selected].endDate).format('MM/DD/YY')}
                </span>

              </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-slate-700 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {staked.map((stake,i) => stake && (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-slate-600' : 'text-gray-200',
                        'relative select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={i}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="inline-flex w-full truncate justify-between">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'w-[90px] md:w-[70px] lg:w-[100px] truncate leading-8')}>
                            {parseFloat((parseFloat(stake.amount).toFixed(5)))}
                          </span>
                          <span className={"ml-2 truncate text-gray-400 h-8 text-xs text-center"}>
                            Multiplier<br/>
                            x{parseFloat(stake.multiplier).toFixed(2)}
                          </span>
                          <span className={classNames(active ? 'text-indigo-200' : 'text-gray-400', 'truncate text-xs block md:hidden lg:block')}>
                            {moment(stake.startDate).format('MM/DD/YY HH:SS')} <br/>
                            {moment(stake.endDate).format('MM/DD/YY HH:SS')}
                          </span>
                          <span className={classNames(active ? 'text-indigo-200' : 'text-gray-400', 'truncate text-xs hidden md:block lg:hidden')}>
                            {moment(stake.startDate).format('MM/DD/YY')} <br/>
                            {moment(stake.endDate).format('MM/DD/YY')}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-sky-300',
                              'absolute inset-y-0 -mr-2 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
