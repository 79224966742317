import { setInput, resetAll, setLoading, selectLoading,selectMaxDuration, selectInputDisabled, setCalculating, setCalc, selectDuration, select8020BPTInput, selectUSHInput, selectSushiInput, selectAmountOut } from '../../../../redux/vdUSH';
import { useDispatch, useSelector } from 'react-redux';
import { setDuration } from '../../../../redux/vdUSH';
import { useProvider, useAccount } from 'wagmi';
import calcvdUSH from '../../../../actions/calcvdUSH';
import { useContext } from 'react';
import { VDUSHContext } from '../../../../context-providers/VDUSH';
import { BalancesContext } from '../../../../context-providers/Balances';
import { useState } from 'react';

let timeText = 'Weeks';

export default function Duration(){
    const account = useAccount();
    const provider = useProvider();
    const dispatch = useDispatch();
    const {
        my_locked
    } = useContext(VDUSHContext);
    const balances = useContext(BalancesContext);
    let myVDUSH = balances.vdUSH;
    let [presetDuration, setPresetDuration] = useState(null);

    if(my_locked.data && parseFloat(my_locked.data.end)!=0 ){
        let end = new Date(parseFloat(my_locked.data.end)*1000);
        //find the difference between now and the end date in terms of weeks
        if(!presetDuration){
            let preset = ((end.getTime() - Date.now())/1000/60/60/24/7);
            setPresetDuration(preset);
            dispatch(setDuration(preset))
        }
    }

    let duration = useSelector(selectDuration);
    let max_duration = useSelector(selectMaxDuration);
    let amountUSH = useSelector(selectUSHInput);
    let amountsushiSwapLP = useSelector(selectSushiInput);
    let amount8020BPT = useSelector(select8020BPTInput);
    const inputDisabled = useSelector(selectInputDisabled);

    let setDurationInput = (async (input)=>{
        if(inputDisabled) return;
        dispatch(setDuration(input))
        dispatch(setCalculating());
        try {
            let output = await calcvdUSH({myVDUSH, my_locked, amountUSH, amount8020BPT, amountsushiSwapLP, duration:input, provider, address: account.address});
            if (output === null) {
                dispatch(resetAll());
            } else {
                dispatch(setCalc(output))
            }
        } catch (err) {
            console.log(err);
            dispatch(resetAll());
        }
    });
    const durationChange = (e) => {
        setDurationInput(e.target.value);
    }

    return (
        <div className="relative pt-1 mb-4">
            <div className="flex mb-2 mt-2 items-center justify-between">
                <div>
                <span className="text-xs font-semibold inline-block py-1 text-gray-300">
                Lock Duration: {parseFloat(duration).toFixed(2)} {timeText}
                </span>
                </div>
            </div>
            <input type="range"
                disabled={false}
                className=" form-range w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                onChange={durationChange}
                min={presetDuration ? presetDuration : max_duration-48} max={53} step="1" value={duration}
            />
        </div>
    )
}
