import { Fragment, useEffect, useMemo } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Item from './item';
import Chosen from './chosen';
import { useDispatch, useSelector } from 'react-redux'
import { selectChainFrom, selectChainTo, selectChains, selectItems } from '../../../redux/bridge';
import { useNetwork } from 'wagmi';
import { ethId, bnbId } from '../../../constants';

export default function Dropdown({isFromChain}) {
  const {chain} = useNetwork();
  const items = useSelector(selectChains);
  const chainIn = useSelector(selectChainFrom);
  const chainOut = useSelector(selectChainTo);

  let chosenChain = isFromChain ? chainIn : chainOut;
  let otherChain = isFromChain ? chainOut : chainIn;
  
  let arr = Object.keys(items).map((initial)=> {
    return items[initial];
  }).filter((item) => item.initials !== otherChain);

  let chosen = arr.find((item) => item.initials === chosenChain);


  return (
    <Menu as={"div"} className="text-left bg-surface-default cursor-default focus:outline-none overflow-hidden rounded-lg border border-slate-600  h-12 items-center">
      <Chosen item={chosen} />
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-[200px] absolute z-10 mt-2 origin-top-right rounded-lg bg-slate-700 shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
          {arr.map((item,i) => (
            <Item key={i} item={item} isFromChain={isFromChain}  />
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
