// import Dropdown from './dropdown';
import { selectInput, setInput, setOutput } from '../../redux/withdrawV1';
import { useDispatch, useSelector } from 'react-redux';
import calcOutput from './calcOutput';

export default function Input({vaultBalances, totalSupplyUnshethV1}) {
    const dispatch = useDispatch();
    const amount = useSelector(selectInput);

    let disabled = false;
    if(!totalSupplyUnshethV1.data) {
        disabled = true;
    }
    Object.keys(vaultBalances).map((v)=>{
        if(!vaultBalances[v].data) {
            disabled = true;
        }
    });

    const onChange = (e) => {
        try{
            let amount2 = calcOutput(e.target.value, vaultBalances, totalSupplyUnshethV1);
            dispatch(setOutput(amount2));
            dispatch(setInput(e.target.value));
        }
        catch(e) {
            console.log(e);
        }
    }

    return (
        <div className="flex w-full">
            <div className="flex flex-1 text-left items-center relative ">
                <input onChange={onChange.bind(this)} disabled={disabled} placeholder={0} className="bg-slate-800 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500" type="number" inputMode="decimal" value={amount} />
            </div>
            <div className="text-left bg-surface-default cursor-none focus:outline-none overflow-hidden rounded-r-lg border border-slate-600  h-full items-center border-l-0">
                <button className="flex items-center max-w-[200px] hover:bg-surface-subdued h-full cursor-default" id="headlessui-combobox-button-321" type="button" tabIndex="-1" aria-haspopup="true" aria-expanded="false">
                    <div className="flex w-full px-4 justify-between items-center rounded-l-lg">
                        <div className="flex min-w-full items-center justify-between">
                            <div className="flex w-[135px] items-center space-x-3">
                                <div className="relative" id="network-icon-1-ETH"><img src="/unshETH.svg" alt="ETH" className="w-8 h-8 rounded-full object-cover"/></div>
                                <div className="shrink items-center truncate">
                                    <div className=" text-slate-100 text-lg font-medium">unshETH v1</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}
    