import { useContext } from 'react';
import { VDUSHContext } from '../../../context-providers/VDUSH';
import { ExternalFarmsContext } from '../../../context-providers/ExternalFarms';
import { PricesContext } from '../../../context-providers/Prices';
import { Typography } from '../../shared';

function numberWithCommas(number) {
  try {
    // Convert the number to a string
    let numberString = number.toFixed(2).toString();

    // Use a regular expression to add commas as thousands separators
    let numberWithCommas = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Return the resulting string
    return numberWithCommas;
  } catch (error) {
    console.log({ number, error });
    return number;
  }
}

export default function TotalUSH() {
  let { total8020BPTLocked, totalUSHLocked, totalsushiSwapLPLocked, totalVDUSH } = useContext(VDUSHContext);
  let { ethFarms } = useContext(ExternalFarmsContext);
  let prices = useContext(PricesContext);

  function convert(num) {
    if (!num) return 0;
    if (!num.data) return 0;
    if (num.data && num.data.formatted) {
      num = parseFloat(parseFloat(num.data.formatted).toFixed(2));
    } else if (num.data) {
      num = parseFloat((parseFloat(num.data) / 1e18).toFixed(2));
    } else {
      num = 0;
    }
    return num;
  }

  //calculate the tvls of the locked assets
  let sushiTVL = 0;
  if (prices['sushiSwapLP']) {
    sushiTVL = parseFloat(convert(totalsushiSwapLPLocked)) * parseFloat(prices['sushiSwapLP']);
  }

  let bptTVL = 0;
  if (prices['80USH20unshETH']) {
    bptTVL = parseFloat(convert(total8020BPTLocked)) * parseFloat(prices['80USH20unshETH']);
  }

  let ushTVL = 0;
  if (prices.USH) {
    ushTVL = parseFloat(convert(totalUSHLocked)) * parseFloat(prices.USH);
  }

  const stats = [
    { name: 'Total vdUSH in Farm', value: convert(totalVDUSH) },
    { name: 'Total USH Locked', tvl: ushTVL, value: convert(totalUSHLocked) },
    {
      name: 'Total 8020BPT Locked',
      tvl: bptTVL,
      value: convert(total8020BPTLocked),
      apr: ethFarms[2] && ethFarms[2].aprBase,
    },
    {
      name: 'Total sushiSwapLP Locked',
      tvl: sushiTVL,
      value: convert(totalsushiSwapLPLocked),
      apr: ethFarms[1].aprBase,
    },
  ];

  return (
    <>
      {stats.map((stat) => (
        <div key={stat.name} className="px-4 py-4 sm:px-6 lg:px-8 grid gap-1 h-fit">
          <Typography variant="bodyXs" className="text-lightGray" weight="medium">
            {stat.name}
          </Typography>
          <Typography variant="bodyLg" className="text-white" weight="bold">
            {numberWithCommas(stat.value)}
          </Typography>
          {stat.tvl ? (
            <Typography variant="bodyXs" className="text-lightGray">
              ${numberWithCommas(stat.tvl)}{' '}
            </Typography>
          ) : null}
        </div>
      ))}
    </>
  );
}
