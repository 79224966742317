import { selectOutput } from '../../redux/withdraw';
import { useSelector } from 'react-redux';
import { Typography } from '../shared';

export default function Output({ vaultBalances }) {
  let outputs = useSelector(selectOutput);
  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full my-4">
        {Object.keys(vaultBalances).map((p, i) => (
          <div
            key={i}
            className={`text-gray-400 py-2 font-medium justify-start md:justify-center flex items-center px-5 md:px-0`}
          >
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0 h-full">
                <img className="h-6 w-6 rounded-full" src={p === 'WETH' ? 'weth.png' : p + '.png'} alt="" />
              </div>
              <div>
                <Typography variant="bodyXs" className="text-mainCTAs" weight="medium">
                  {p}
                </Typography>
                <Typography variant="bodyXs" className="text-white" weight="medium">  
                  {outputs[p].toFixed(5)}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
