import contractAddresses from "../contract_addresses";
import { useBalance, useContractRead, useNetwork } from "wagmi";
import { API_HOST, arbId, ethId } from "../constants";
import { createContext } from "react";
import VDUSHABI from '../ABI/vdUSH.json';
import VDUSHFarmABI from '../ABI/ARBvdUSHFarm.json';
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { selectVDUSHWatch } from "../redux/watch";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { PricesContext } from "./Prices";
import { BalancesContext } from "./Balances";
import { ethers } from "ethers";
import ArbFarmABI from '../ABI/ArbFarm.json';


export const ARBVDUSHContext = createContext();

export function ARBVDUSHProvider({ children }) {
  const account = useAccount();
  let vdUSHWatch = useSelector(selectVDUSHWatch);
  const [ARBVDUSHData, setVDUSHData] = useState({});

  let {chain} = useNetwork();
  let chainId = chain ? chain.id : ethId;

  let watch = chainId === arbId;

  let enabled = true;

  const prices = useContext(PricesContext);
  const balances = useContext(BalancesContext);

  // Define the URL where you want to fetch the VDUSH data
  const VDUSHApiUrl = `${API_HOST}/arbvdush`; // Replace this with the actual API URL
  // const VDUSHApiUrl = "http://localhost:3001/VDUSH"; // Replace this with the actual API URL

  useEffect(() => {
    async function fetchVDUSHData() {
      try {
        const response = await fetch(VDUSHApiUrl);
        const data = await response.json();
        setVDUSHData(data);
      } catch (error) {
        console.log("Error fetching VDUSH data:", error);
      }
    }
    fetchVDUSHData();
  }, []); // The empty array `[]` ensures that the effect only runs once on component mount

  let data = {
    ARBVDUSHData,
    isBlocked: useContractRead({enabled, watch: watch && false, address: contractAddresses.ARBvdUSHFarm, functionName: 'isBlocked', args: [account.address], chainId: arbId, abi: VDUSHFarmABI, scopeKey:'isBlocked'}),
    totalARBVDUSH: useContractRead({enabled, watch: watch && vdUSHWatch.includes('arb_my_locked'), address: contractAddresses.ARBvdUSHFarm, functionName: 'getVdUshTotalSupplyInFarm', args: [], chainId: arbId, abi: VDUSHFarmABI, scopeKey:'totalARBVDUSH'}),
    totalARBUSHLocked: useBalance({enabled, watch: watch && vdUSHWatch.includes('arb_my_locked'),token: contractAddresses.ARBUSH, chainId: arbId, scopeKey:'totalUSHLocked',address: contractAddresses.ARBvdUSH}),
    totalARBcamelotLPLocked: useBalance({enabled, watch: watch && vdUSHWatch.includes('arb_my_locked'), token: contractAddresses['ARBcamelotLP'], chainId: arbId,scopeKey:'totalCamelotVD',address: contractAddresses.ARBvdUSH}),
    boostARBcamelotLP: useContractRead({enabled,address: contractAddresses.ARBvdUSH, functionName: 'pool2_boost_weight', args: [], chainId: arbId, abi: VDUSHABI, watch: watch && false, scopeKey:'boostCamelot'}),
    arb_my_locked: useContractRead({enabled,watch: watch && vdUSHWatch.includes('arb_my_locked'), abi: VDUSHABI,  address: contractAddresses.ARBvdUSH, functionName: 'locked', args: [account.address], chainId: arbId, scopeKey:'myLockedARB'}), //TODO add watch
    alreadyStaking: useContractRead({enabled,watch:  watch && vdUSHWatch.includes('arb_alreadyStaking'), abi: VDUSHFarmABI, address: contractAddresses.ARBvdUSHFarm, functionName: 'isInMatrix', args: [account.address], chainId: arbId, scopeKey:'myStakingStatusARB'}),//TODO add watch
    earnedARBUSH: useContractRead({enabled,watch:  watch && vdUSHWatch.includes('arb_claimed_ush'), abi: VDUSHFarmABI, address: contractAddresses.ARBvdUSHFarm, functionName: 'earned', args: [account.address], chainId: arbId,  scopeKey:'myEarnedGovFarmARB'}),//TODO add watch
    camelotWeightedAmount : useContractRead({enabled, abi: VDUSHABI, watch:false, address: contractAddresses.ARBvdUSH, functionName: 'weighted_amount', args: [0,ethers.utils.parseEther('1'),0], chainId: arbId, scopeKey:'camelotWeightedAmount'}),//TODO add watch
    ARBUSHWeightedAmount: useContractRead({enabled, abi: VDUSHABI, watch:false, address: contractAddresses.ARBvdUSH, functionName: 'weighted_amount', args: [0,0,ethers.utils.parseEther('1')], chainId: arbId, scopeKey:'ARBUSHWeightedAmount'}),//TODO add watch
    earnedARB: useContractRead({enabled, watch: enabled && vdUSHWatch.includes('claimed_arb'), abi: ArbFarmABI, address: contractAddresses.ArbFarm, functionName: 'earned', args: [account.address], chainId: arbId, scopeKey: 'myEarnedArbFarm' }),
    // arbPerSecond: useContractRead({ enabled,watch:enabled && vdUSHWatch.includes('arbPerSecond'), abi: ANKRFarmABI,  address: contractAddresses.ArbFarm, functionName: 'rewardPerSecond', args: [], chainId: ethId, scopeKey:'arbPerSecond'}),
  }

  //get the ratio of the users locked ush to the total locked ush
  data.myVDUSHLockedRatio = balances && balances.ARBvdUSH.data && data.totalARBVDUSH.data && parseFloat(balances.ARBvdUSH.data.formatted)/(parseFloat(data.totalARBVDUSH.data)/1e18);

  if(data.arb_my_locked.data && prices.USH && prices.ARBcamelotLP){
    //get the amount of locked ush and arb
    let myARBcamelotLPLocked = parseFloat((parseFloat(data.arb_my_locked.data.amountB)/1e18.toFixed(2)));
    let myUSHLocked = parseFloat((parseFloat(data.arb_my_locked.data.amountC)/1e18.toFixed(2)));


    //get the tvl of the user if the data is available
    data.myTVL = (myARBcamelotLPLocked*prices.ARBcamelotLP)+(myUSHLocked*prices.USH);


    //get the individual tvl ratio for the each asset for the user
    data.myARBcamelotLPTVLRatio = (myARBcamelotLPLocked*prices.ARBcamelotLP)/data.myTVL;
    data.myUSHTVLRatio = (myUSHLocked*prices.USH)/data.myTVL;
    

    //get the ratio of the users tvl vs total tvl
    if(ARBVDUSHData && ARBVDUSHData.TVL) {
      data.myTVLRatio = data.myTVL/ARBVDUSHData.TVL;
    }
  }

  // if(data.arbPerSecond.data && prices['ARB']) {
  //   let arbUSDPerSec = parseFloat(data.arbPerSecond.data)/1e18*prices.arb;
  //
  //   if(ARBVDUSHData && ARBVDUSHData.TVL) {
  //     //calculate the apr for the arb farm
  //     data.arbAPR = (arbUSDPerSec * 60 * 60 * 24 * 30* 12)/ARBVDUSHData.TVL * 100;
  //   }
  // }
  //

  if(data.myTVLRatio && data.myVDUSHLockedRatio) {
    //get the ratio of the users tvl vs total tvl
    data.aprRatio = data.myVDUSHLockedRatio/data.myTVLRatio;
  }

  //calculate the apr ratio for sushi swap lp and 80/20 bpt
  if(data.ARBUSHWeightedAmount.data && data.camelotWeightedAmount.data && prices.ARBcamelotLP && prices.USH) {

    data.camelotTVLRatio = prices.ARBcamelotLP/ARBVDUSHData.TVL;
    data.ARBUSHTVLRatio = prices.USH/ARBVDUSHData.TVL;

    data.camelotVDUSHRatio = (parseFloat(data.camelotWeightedAmount.data))/(parseFloat(data.totalARBVDUSH.data));
    data.ARBUSHVDUSHRatio = (parseFloat(data.ARBUSHWeightedAmount.data))/(parseFloat(data.totalARBVDUSH.data));


    data.camelotRatio = data.camelotVDUSHRatio/data.camelotTVLRatio;
    data.ARBUSHRatio = data.ARBUSHVDUSHRatio/data.ARBUSHTVLRatio;
  }

  //calculate the ratio of total camelot and total arbush to the total tvl of the farm
  if(data.totalARBcamelotLPLocked.data && data.totalARBUSHLocked.data && ARBVDUSHData && ARBVDUSHData.TVL && prices.ARBcamelotLP) {
    data.totalCamelotTVLRatio = parseFloat(data.totalARBcamelotLPLocked.data.formatted)*prices.ARBcamelotLP/ARBVDUSHData.TVL;
    data.totalARBUSHTVLRatio = parseFloat(data.totalARBUSHLocked.data.formatted)*prices.USH/ARBVDUSHData.TVL;
  }

  return (
    <ARBVDUSHContext.Provider value={data}>
      {children}
    </ARBVDUSHContext.Provider>
  );
}
