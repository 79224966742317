import { useSelector } from 'react-redux';
import { selectChainTo, selectChains } from '../../redux/bridge';
import { Switch } from '@headlessui/react';
import { Typography } from '../shared';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Address({ enabledAddress, setEnabledAddress, addressInput, setAddressInput }) {
  let chains = useSelector(selectChains);
  let chainTo = useSelector(selectChainTo);

  const onChange = (e) => {
    try {
      setAddressInput(e.target.value);
    } catch (e) {
      console.log(e);
    }
  };

  function isEthereumAddress(address) {
    const pattern = /^(0x)?[0-9a-fA-F]{40}$/;
    return pattern.test(address);
  }

  let inputClassName =
    'bg-slate-800 pl-6 py-4 w-full h-full rounded-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500';

  if (!isEthereumAddress(addressInput) && addressInput.length > 0) {
    inputClassName =
      'bg-slate-800 pl-6 py-4 w-full h-full rounded-lg border border-red-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500';
  }

  return (
    <div className="flex flex-col w-full items-center mb-8">
      <Typography variant="bodyMd" className="text-white/80 mb-2" weight="bold">
        Send to Custom Address?
      </Typography>
      <div className="flex justify-between">
        <Typography variant="bodySm" className="text-white/80 mx-4" weight="medium">
          No
        </Typography>
        <Switch
          checked={enabledAddress}
          onChange={setEnabledAddress}
          className={classNames(
            enabledAddress ? 'bg-cyan-700' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabledAddress ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
        <Typography variant="bodySm" className="text-white/80 mx-4" weight="medium">
          Yes
        </Typography>
      </div>
      {enabledAddress && (
        <div className="flex flex-col w-full items-center mt-4">
          <input
            onChange={onChange.bind(this)}
            disabled={false}
            placeholder={'Enter Address'}
            className={inputClassName}
            type="string"
            inputMode="decimal"
            value={addressInput}
          />
        </div>
      )}
    </div>
  );
}
