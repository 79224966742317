import contractAddresses from "../contract_addresses";
import { useContractRead, useAccount, erc20ABI } from "wagmi";
import { useSelector } from "react-redux";
import communalABI from '../ABI/CommunalFarm.json';
import web3 from "web3";
import getPoolAPY from './helpers/getPoolAPY';
import getPoolNoClaimsAndStake from "./helpers/getPoolNoClaimsAndStake";
import getPoolParsedStake from './helpers/getPoolParsedStake';
import {ethId, bnbId, arbId} from '../constants';
import { useContext, useEffect } from "react";
import {BlockTimestampContext} from "./BlockTimestamp";
import {createContext} from 'react';
import { useLocation } from "react-router-dom";
import { selectPoolWatch } from "../redux/watch";
import { APRContext } from "./APR";
import {PricesContext} from "@/context-providers/Prices";


const formattedUSD = (num) => {
  try {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
  }
  catch (e) {
    return num;
  }
}


export const PoolsContext = createContext();

export function PoolsProvider({ children }) {
  let currentDate = useContext(BlockTimestampContext);

  let aprs = useContext(APRContext);
  let prices = useContext(PricesContext);
  const ushPrice = prices['USH'];
  const arbPrice = prices['ARB'];
  const ethPrice = prices['ETH'];


  // let tillShanghai = web3.utils.toHex(Math.max(0,parseInt(shanghaiV1/1000 - date)));

  const account = useAccount();
  let address = account.address;
  let enabled = address !== undefined;


  let unshETHDuration = useSelector(state => state.stake[`unshETHDuration`]);
  let BNBunshETHDuration = useSelector(state => state.stake[`unshETH (BNB)Duration`]);
  let ARBunshETHDuration = useSelector(state => state.stake[`unshETH (Arbitrum)Duration`]);
  let dualARBunshETHDuration = useSelector(state => state.stake[`unshETH (Dual ARB)Duration`]);

  let poolWatch = useSelector(selectPoolWatch);


  let location = useLocation();
  let watch = true;
  if(location.pathname !== '/stake'){
    watch = false;
    enabled = false;
  }

  let pools = [
    //ETH Chain Pools 
    {
      name : 'unshETH', 
      asset:'unshETH',
      aprs: [{name:'ETH Staking',value:aprs.unshETH},{name:'Swap Fees', value:aprs.swap},{name: 'Deposit Fees',value:aprs.deposit}, {name: 'Redemption Fees',value:aprs.redemption},{name: 'Partner Farm Rewards',value:0}],
      rewardAsset:"USH",
      linkToBuy:'https://unsheth.xyz/deposit',
      linkText: 'Mint',
      abi: communalABI,
      type: "Communal",
      chainId:ethId,
      max: useContractRead({scopeKey:'unshETHMax',chainId:ethId,abi:communalABI, address: contractAddresses['unshETH-farm'], functionName: 'lock_time_for_max_multiplier', args:[], watch:false}),
      timeText: "Days",
      totalCombinedWeight: useContractRead({scopeKey:'unshETHTotalCombinedWeight',chainId:ethId,abi: communalABI, address: contractAddresses['unshETH-farm'], functionName: 'totalCombinedWeight', args:[], watch:false}),
      lock_max_multiplier: useContractRead({scopeKey:'unshETHLockMaxMultiplier',chainId:ethId,abi: communalABI, address: contractAddresses['unshETH-farm'], functionName: 'lock_max_multiplier', args:[], watch:false}),
      periodFinish: useContractRead({scopeKey:'unshETHPeriodFinish',chainId:ethId,abi:communalABI, address: contractAddresses['unshETH-farm'], functionName: 'periodFinish', args:[], watch:false}),
      includeInTVL: false,
      expired: false,
      contractAddress: contractAddresses['unshETH-farm'],
      multiplier: useContractRead({scopeKey:'UnshETHMultiplier',chainId:ethId,abi:communalABI, address: contractAddresses['unshETH-farm'], functionName: 'lockMultiplier', args:[web3.utils.toHex(unshETHDuration * 86400)], watch:false}),
      rewardRates: useContractRead({scopeKey:'unshETHRewardRate',chainId:ethId,abi: communalABI, address: contractAddresses['unshETH-farm'], functionName: 'getAllRewardRates', args:[], watch:false}),
      staked: useContractRead({scopeKey:'unshETHStaked',chainId:ethId,abi: communalABI, address: contractAddresses['unshETH-farm'], functionName: 'lockedStakesOf',enabled, args:[address],
			watch: watch && poolWatch.includes('unshETHstaked')  }),
      earned: useContractRead({scopeKey:'unshETHEarned',chainId:ethId,abi: communalABI, address: contractAddresses['unshETH-farm'], functionName: 'earned',enabled, args:[address],
			watch}),
      totalLiquidityLocked: useContractRead({scopeKey:'unshETHtotalLiquidityLocked',chainId:ethId,abi: communalABI, address: contractAddresses['unshETH-farm'], functionName: 'totalLiquidityLocked', args:[],watch}),
      allowance: useContractRead({scopeKey:'unshETHAllowancePool',chainId:ethId,abi: erc20ABI, address: contractAddresses['unshETH'], functionName: 'allowance',enabled, args:[address, contractAddresses['unshETH-farm']],
			watch: watch && poolWatch.includes('unshETHallowance') }),
    },
    //BNB Chain Pools
    {
      name : 'unshETH (BNB)', 
      asset:'BNBunshETH',
      rewardAsset:"BNBUSH",
      linkText: 'Mint',
      linkToBuy:'https://unshETH.xyz/deposit',
      abi: communalABI,
      aprs: [{name:'ETH Staking',value:aprs.unshETH},{name: 'Deposit Fees',value:aprs.deposit}, {name: 'Redemption Fees',value:aprs.redemption},{name:'Swap Fees', value:aprs.swap},{name: 'Partner Farm Rewards',value:0}],
      type: "Communal",
      chainId:bnbId,
      max: useContractRead({scopeKey:'BNBunshETHMax',chainId:bnbId,abi:communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'lock_time_for_max_multiplier', args:[], watch:false}),
      timeText: "Days",
      // finalDate: shanghaiV2/1000,
      totalCombinedWeight: useContractRead({scopeKey:'BNBunshETHTotalCombinedWeight',chainId:bnbId,abi: communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'totalCombinedWeight', args:[], watch:false}),
      lock_max_multiplier: useContractRead({scopeKey:'BNBunshETHLockMaxMultiplier',chainId:bnbId,abi: communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'lock_max_multiplier', args:[], watch:false}),
      periodFinish: useContractRead({scopeKey:'BNBunshETHPeriodFinish',chainId:bnbId,abi:communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'periodFinish', args:[], watch:false}),
      includeInTVL: false,
      expired: false,
      contractAddress: contractAddresses['BNBunshETH-farm'],
      multiplier: useContractRead({scopeKey:'BNBunshETHMultiplier',chainId:bnbId,abi:communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'lockMultiplier', args:[web3.utils.toHex(BNBunshETHDuration * 86400)], watch:false}),
      rewardRates: useContractRead({scopeKey:'BNBunshETHRewardRate',chainId:bnbId,abi: communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'getAllRewardRates', args:[], watch:false}),
      staked: useContractRead({scopeKey:'BNBunshETHStaked',chainId:bnbId,abi: communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'lockedStakesOf',enabled, args:[address],
			watch: watch && poolWatch.includes('unshETH (BNB)staked') }),
      earned: useContractRead({scopeKey:'BNBunshETHEarned',chainId:bnbId,abi: communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'earned',enabled, args:[address],
			watch }),
      totalLiquidityLocked: useContractRead({scopeKey:'BNBunshETHtotalLiquidityLocked',chainId:bnbId,abi: communalABI, address: contractAddresses['BNBunshETH-farm'], functionName: 'totalLiquidityLocked', args:[],watch}),
      allowance: useContractRead({scopeKey:'BNBunshETHAllowancePool',chainId:bnbId,abi: erc20ABI, address: contractAddresses['BNBunshETH'], functionName: 'allowance',enabled, args:[address, contractAddresses['BNBunshETH-farm']],
			watch: watch && poolWatch.includes('unshETH (BNB)allowance') }),
    },
    { 
      name : 'unshETH (Arbitrum)',
      asset:'ARBunshETH',
      rewardAsset:"ARBUSH",
      linkText: 'Mint',
      linkToBuy:'https://unshETH.xyz/deposit',
      abi: communalABI,
      aprs: [{name:'ETH Staking',value:aprs.unshETH},{name: 'Deposit Fees',value:aprs.deposit}, {name: 'Redemption Fees',value:aprs.redemption},{name:'Swap Fees', value:aprs.swap}],
      type: "Communal",
      totalCombinedWeight: useContractRead({scopeKey:'ARBunshETHTotalCombinedWeight',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'totalCombinedWeight', args:[], watch:false}),
      lock_max_multiplier: useContractRead({scopeKey:'ARBunshETHLockMaxMultiplier',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'lock_max_multiplier', args:[], watch:false}),
      chainId:arbId,
      max: useContractRead({scopeKey:'ARBunshETHMax',chainId:arbId,abi:communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'lock_time_for_max_multiplier', args:[], watch:false}),
      timeText: "Days",
      periodFinish: useContractRead({scopeKey:'ARBunshETHPeriodFinish',chainId:arbId,abi:communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'periodFinish', args:[], watch:false}),
      includeInTVL: false,
      expired: false,
      contractAddress: contractAddresses['ARBunshETH-farm'],
      multiplier: useContractRead({scopeKey:'ARBunshETHMultiplier',chainId:arbId,abi:communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'lockMultiplier', args:[web3.utils.toHex(ARBunshETHDuration * 86400)], watch:false}),
      rewardRates: useContractRead({scopeKey:'ARBunshETHRewardRate',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'getAllRewardRates', args:[], watch:false}),
      staked: useContractRead({scopeKey:'ARBunshETHStaked',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'lockedStakesOf',enabled, args:[address],
            watch: watch && poolWatch.includes('unshETH (Arbitrum)staked') }),
      earned: useContractRead({scopeKey:'ARBunshETHEarned',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'earned',enabled, args:[address],
            watch }),
      totalLiquidityLocked: useContractRead({scopeKey:'ARBunshETHtotalLiquidityLocked',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-farm'], functionName: 'totalLiquidityLocked', args:[],watch}),
      allowance: useContractRead({scopeKey:'ARBunshETHAllowancePool',chainId:arbId,abi: erc20ABI, address: contractAddresses['ARBunshETH'], functionName: 'allowance',enabled, args:[address, contractAddresses['ARBunshETH-farm']],
            watch: watch && poolWatch.includes('unshETH (Arbitrum)allowance') }),
    },
    { 
      name : 'unshETH (Dual ARB)',
      asset:'ARBunshETH',
      rewardAsset:"ARBUSH",
      rewardAsset2: "ARB",
      linkText: 'Mint',
      linkToBuy:'https://unsheth.xyz/deposit',
      abi: communalABI,
      aprs: [{name:'ETH Staking',value:aprs.unshETH},{name:'Swap Fees', value:aprs.swap},{name: 'Deposit Fees',value:aprs.deposit}, {name: 'Redemption Fees',value:aprs.redemption}],
      type: "Communal",
      totalCombinedWeight: useContractRead({scopeKey:'DualARBunshETHTotalCombinedWeight',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'totalCombinedWeight', args:[], watch:false}),
      lock_max_multiplier: useContractRead({scopeKey:'DualARBunshETHLockMaxMultiplier',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'lock_max_multiplier', args:[], watch:false}),
      chainId:arbId,
      max: useContractRead({scopeKey:'DualARBunshETHMax',chainId:arbId,abi:communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'lock_time_for_max_multiplier', args:[], watch:false}),
      timeText: "Days",
      periodFinish: useContractRead({scopeKey:'DualARBunshETHPeriodFinish',chainId:arbId,abi:communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'periodFinish', args:[], watch:false}),
      includeInTVL: false,
      expired: false,
      contractAddress: contractAddresses['ARBunshETH-dualfarm'],
      multiplier: useContractRead({scopeKey:'DualARBunshETHMultiplier',chainId:arbId,abi:communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'lockMultiplier', args:[web3.utils.toHex(dualARBunshETHDuration * 86400)], watch:false}),
      rewardRates: useContractRead({scopeKey:'DualARBunshETHRewardRate',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'getAllRewardRates', args:[], watch:false}),
      staked: useContractRead({scopeKey:'DualARBunshETHStaked',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'lockedStakesOf',enabled, args:[address],
			watch: watch && poolWatch.includes('unshETH (Dual ARB)staked')  }),
      earned: useContractRead({scopeKey:'DualARBunshETHEarned',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'earned',enabled, args:[address],
			watch}),
      totalLiquidityLocked: useContractRead({scopeKey:'DualARBunshETHtotalLiquidityLocked',chainId:arbId,abi: communalABI, address: contractAddresses['ARBunshETH-dualfarm'], functionName: 'totalLiquidityLocked', args:[],watch}),
      allowance: useContractRead({scopeKey:'DualARBunshETHAllowancePool',chainId:arbId,abi: erc20ABI, address: contractAddresses['ARBunshETH'], functionName: 'allowance',enabled, args:[address, contractAddresses['ARBunshETH-dualfarm']],
			watch: watch && poolWatch.includes('unshETH (Dual ARB)allowance') }),
    },
  ];

  pools = pools.map((pool)=>{
    pool.multiplier = parseFloat(pool.multiplier.data ? web3.utils.fromWei( pool.multiplier.data._hex,'ether') : 0).toFixed(2);
    let {totalApy, aprs} = getPoolAPY(pool, ushPrice, arbPrice, ethPrice);
    pool.apy = totalApy;
    pool.aprs = aprs;
    pool.max = parseFloat(pool.max.data) / 86400;
    pool.noClaimsAndStake = getPoolNoClaimsAndStake(pool);
    let {bal,staked} = getPoolParsedStake(pool,currentDate);
    pool.fullStakedAmount = bal;
    pool.parsedStake = staked;
    
    return pool;
  })

  let {poolSum, poolText} = getPoolSum(pools);

  return (
    <PoolsContext.Provider value={{pools,poolSum,poolText}}>
      {children}
    </PoolsContext.Provider>
  );
}



function getPoolSum(pools) {

  let poolSum = 0;
  let poolText = '';

  for(let pool of pools) {
    if(pool.includeInTVL){
      let totalLiquidityLocked = pool.totalLiquidityLocked && pool.totalLiquidityLocked.data
      ? parseFloat(web3.utils.fromWei(pool.totalLiquidityLocked.data.toString()))
      : 0;
      if(totalLiquidityLocked === 0) continue;
      let usdTotal = 0;
      pool.tvlUSD = usdTotal; //TODO: confirm
      poolText += pool.name + ': ' + formattedUSD(usdTotal) + '</br>';
    }
  }

  return {poolSum, poolText};
}