import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  input: '',
  output: '',
};

export const migrateSlice = createSlice({
  name: 'migrate',
  initialState,
  reducers: {
    setInput: (state,action) => {
      state.input = action.payload;
    },
    setOutput: (state,action) => {
      state.output = action.payload;
    }
  },
});

export const {setInput, setOutput} = migrateSlice.actions;
export const selectInput = (state) => state.migrate.input;
export const selectOutput = (state) => state.migrate.output;

export default migrateSlice.reducer;
