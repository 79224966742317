import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction } from 'wagmi';
import contractAddresses from '../../../contract_addresses';
import ABI from '../../../ABI/vdAMM.json';
import { useSelector } from 'react-redux';
import {
  resetAll,
  setMonitor,
  selectArgs,
  selectFunctionName,
  selectMsgValue,
  selectGasLimit,
  selectInputAmount,
  selectInputChosen,
  selectOutputChosen,
} from '../../../redux/swap';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAllowanceWatch, setBalanceWatch } from '../../../redux/watch';
import { AllowancesContext } from '../../../context-providers/Allowances';
import { BalancesContext } from '../../../context-providers/Balances';
import { Button } from '../../shared';

export default function Submit() {
  const allowances = useContext(AllowancesContext);
  const balances = useContext(BalancesContext);
  const dispatch = useDispatch();
  const inputChosen = useSelector(selectInputChosen);
  const outputChosen = useSelector(selectOutputChosen);

  const amount = useSelector(selectInputAmount);

  const functionName = useSelector(selectFunctionName);
  const args = useSelector(selectArgs);
  const gasLimit = useSelector(selectGasLimit);
  const msgValue = useSelector(selectMsgValue);
  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const [swapTxHash, setSwapTxHash] = useState(null);

  const approvalWait = useWaitForTransaction({ hash: approvalTxHash });
  const swapWait = useWaitForTransaction({ hash: swapTxHash });

  let bal = balances[inputChosen].data ? parseFloat(balances[inputChosen].data.formatted) : 0;
  let approvalRequired =
    allowances[`${inputChosen}Swap`].data !== undefined
      ? parseInt(allowances[`${inputChosen}Swap`].data) < amount * 1e18
      : true;

  const overrides = {};
  if (msgValue) {
    overrides.value = msgValue;
  }
  if (gasLimit) {
    overrides.gasLimit = gasLimit;
  }
  //Swap Prep
  const { config } = usePrepareContractWrite({
    address: contractAddresses.vdAMM,
    abi: ABI,
    functionName: approvalRequired ? '' : functionName,
    args,
    overrides,
    onError: (err) => {
      console.log({ err });
    },
  });

  //Approval Prep
  const approvePrepare = usePrepareContractWrite({
    address: contractAddresses[inputChosen],
    abi: erc20ABI,
    functionName: 'approve',
    args: [contractAddresses.vdAMM, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'],
    onError: (err) => {
      console.log('sushi');
      console.log({ err });
    },
  });

  const approveWrite = useContractWrite({
    ...approvePrepare.config,
    onError(err) {
      toast.error('Approval Failed!');
    },
    onSuccess(data, err) {
      setApprovalTxHash(data.hash);
      dispatch(setAllowanceWatch(`${inputChosen}Swap`));
      console.log({ data, err });
      toast.info('Approval Tx Submitted!');
      //dispatch events to clear the input and output elements in redux state.swap
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Swap Failed!');
    },
    onSuccess(data, err) {
      dispatch(setBalanceWatch(inputChosen));
      dispatch(setBalanceWatch(outputChosen));
      dispatch(setMonitor(true));
      setSwapTxHash(data.hash);
      toast.info('Swap Tx Submitted!');
      dispatch(resetAll());
    },
  });

  let amountIncorrect = !amount || parseFloat(amount) <= 0;

  let notEnoughBal = parseFloat(amount) > bal;

  let approveButtonClass = `block w-1/2 rounded-lg bg-gradient-to-r py-3 px-4 font-medium text-white shadow from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  let swapDisabled = notEnoughBal || amountIncorrect || !write;
  let swapButtonClass = `block w-1/2 rounded-lg h-full bg-gradient-to-r ${
    !swapDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    if (approvalRequired) {
      approveWrite.write();
      return;
    } else {
      write();
    }
  };

  return (
    <>
      <div className="flex justify-center text-center w-full mt-4">
        {approvalWait.isLoading ? (
          <Button variant="gradient" size="big" block disabled={true}>
            Approving...
          </Button>
        ) : approvalRequired ? (
          <Button variant="gradient" size="big" block disabled={!approveWrite.write} onClick={onClick}>
            Approve
          </Button>
        ) : swapWait.isLoading ? (
          <Button variant="gradient" size="big" block disabled={true}>
            Swapping...
          </Button>
        ) : (
          <Button variant="gradient" size="big" block disabled={swapDisabled} onClick={onClick}>
            {notEnoughBal ? 'Insufficient Balance' : 'Swap'}
          </Button>
        )}
      </div>
    </>
  );
}
