import { _zapSlippageBps, _pancakeSlippageBps, _stargateSlippageBps } from "../../constants";
import { ethChainId, arbChainId, ethPoolId, bnbChainId, bnbPoolId, arbPoolId, bnb_usdt_decimals, eth_usdt_decimals } from "../../constants";
import contract_addresses from "../../contract_addresses";
import StargateRouterABI from '../../ABI/StargateRouter.json';
import SGReceiverAbi from '../../ABI/SGReceiver.json';
import PancakeSwapAbi from '../../ABI/PancakeSwapAbi.json';
import SGSenderABI from '../../ABI/BNBSGSender.json';
import { ethers } from 'ethers';
import { pickPathId } from "./pickPathId";
import OFTCoreABI from '../../ABI/OFTCore.json';
import {univ3_quoteExactInputSingle} from './univ3quoter';

// *************************************************************************
let _dstChainId = ethChainId;
let _functionType = 1;
// *************************************************************************

const minimum_lz_quote = ethers.utils.parseEther("0.1");

function divideBy(bigAmount, exponent=18) {
    const str = Math.floor(ethers.utils.formatUnits(bigAmount, exponent));
    return ethers.BigNumber.from(str);
}

function multiplyAndDivideBigNumbers(a, b) {
  // Convert the BigNumber values to FixedNumber with 18 decimal places
  const aFixed = ethers.FixedNumber.fromValue(a, 18);
  const bFixed = ethers.FixedNumber.fromValue(b, 18);

  // Multiply the two FixedNumber values
  const product = aFixed.mulUnsafe(bFixed);

  // Define the divisor 10^18 as a FixedNumber
  const divisor = ethers.FixedNumber.fromValue("1000000000000000000", 18);

  // Divide the product by 10^18
  const quotientFixed = product.divUnsafe(divisor);

  // Convert the result to a human-readable string with 18 decimal places
  const resultString = quotientFixed.toString();

  // Convert the result from string to BigNumber
  const resultBigNumber = ethers.utils.parseUnits(resultString, 18);

  return resultBigNumber;
}

export default async function calcBNB({input, initials, provider, address, eth_provider, darknet}) {


    const amountIn = ethers.utils.parseUnits(input, bnb_usdt_decimals);

    let bnbToSwap;
    if(initials === 'BNBBNB') {
        bnbToSwap = amountIn;
    } else {
        bnbToSwap = 0;
    }

    const pancakeSwapRouter = new ethers.Contract(contract_addresses.BNBPancakeSwapRouter, PancakeSwapAbi, provider);

    console.log({amountIn:amountIn.toString()});
    let expected_usdt_pancake;
    let min_amount_pancake;

    if(initials === 'BNBUSDT') {
        min_amount_pancake = amountIn;
        expected_usdt_pancake = amountIn;
    } else {
        console.log({initials});
        let addr = initials === 'BNBBNB' ? 'WBNB' : initials;
        console.log({amountIn, addr});
        console.log(amountIn, [contract_addresses[addr], contract_addresses.BNBUSDT]);
        expected_usdt_pancake = (await pancakeSwapRouter.getAmountsOut(amountIn, [contract_addresses[addr], contract_addresses.BNBUSDT]))[1];
        console.log({expected_usdt_pancake})
    }
    console.log({expected_usdt_pancake: expected_usdt_pancake.toString()});

    if(initials !== 'BNBUSDT') {
        min_amount_pancake = expected_usdt_pancake.mul(10000-_pancakeSlippageBps).div(10000);
        console.log({min_amount_pancake: min_amount_pancake.toString()});
    }

    //TODO: pretty sure this should work as well to estimate the fees, let's just assume 10 bps slippage for now
    // const stargateFeeLibrary = new ethers.Contract(contract_addresses.BNBStargateFee, StargateFeeLibraryABI, provider);
    //
    // let _srcPoolId = bnbPoolId;
    // let _dstPoolId = ethPoolId;
    //
    // function convertUnits(amount, currDecimal, targetDecimal) {
    //     return ethers.utils.parseUnits(ethers.utils.formatUnits(amount, currDecimal), targetDecimal);
    // }
    //
    // const _amountSD = convertUnits(expected_usdt_pancake, bnb_usdt_decimals, eth_usdt_decimals);
    //
    // const s = stargateFeeLibrary.getFees(_srcPoolId, _dstPoolId, _dstChainId, address, _amountSD);
    //
    //const expected_amount_usdt_stargate = convertUnits(_amountSD.sub(s.eqFee).sub(s.protocolFee).sub(s.lpFee), eth_usdt_decimals, bnb_usdt_decimals);

    const expected_amount_usdt_stargate = expected_usdt_pancake.mul(10000-_stargateSlippageBps).div(10000);
    console.log({expected_amount_usdt_stargate: expected_amount_usdt_stargate.toString()});

    const min_amount_usdt_stargate =  min_amount_pancake.mul(10000-_stargateSlippageBps).div(10000);
    console.log({min_amount_usdt_stargate: min_amount_usdt_stargate.toString()});

    const expected_amount_weth = await univ3_quoteExactInputSingle(contract_addresses.USDT, contract_addresses.WETH, divideBy(min_amount_usdt_stargate,bnb_usdt_decimals-eth_usdt_decimals), eth_provider);
    console.log({expected_amount_weth: expected_amount_weth.toString()});

    let { lsd_to_deposit, pathId, weth_lsd_amount_out, eligible_lsd_bought, should_buy, depositFee, protocolFee } = await pickPathId(expected_amount_weth, eth_provider, darknet);
    console.log({lsd_to_deposit, pathId, weth_lsd_amount_out});

    const min_amount_unshethZap = should_buy ? 
        eligible_lsd_bought.mul(10000-_zapSlippageBps).div(10000) : 
        weth_lsd_amount_out;

    const expected_amount_unsheth = multiplyAndDivideBigNumbers(weth_lsd_amount_out.sub(depositFee), darknet[lsd_to_deposit].data);
    let min_amount_unsheth = multiplyAndDivideBigNumbers((weth_lsd_amount_out.sub(depositFee)).mul(10000 - (should_buy ? _zapSlippageBps : 0)).div(10000), darknet[lsd_to_deposit].data)
    
    console.log({min_amount_unshethZap: min_amount_unshethZap.toString()});

    console.log({expected_amount_unsheth:expected_amount_unsheth.toString()});

    console.log({min_amount_unsheth});

    const _transferAndCallPayload = ethers.utils.defaultAbiCoder.encode(["address", "uint256", "uint256"], [address, min_amount_unshethZap, pathId]);

    let {quoteLzFee, dstGasForCall, dstNativeAmount} = await getLayerZeroInfo(amountIn, _transferAndCallPayload, address, min_amount_unsheth, eth_provider, provider);

    let msg_value;
    let lz_fee_to_use = quoteLzFee.gt(minimum_lz_quote) ? quoteLzFee : minimum_lz_quote;

    if(initials === 'BNBBNB') {
        //get the min of quoteLzFee or minimum_lz_quote and add to amountIn and set to msg_value
        msg_value = amountIn.add(lz_fee_to_use);
    } else {
        msg_value = lz_fee_to_use
    }

    const sgSender = new ethers.Contract(contract_addresses.BNBSGSender, SGSenderABI, provider);

    let params;
    let _gasLimit = 1000000;

    if(initials === 'BNBUSDT') {
        params = [amountIn, min_amount_usdt_stargate, min_amount_unshethZap, dstGasForCall, dstNativeAmount, pathId];
        // _gasLimit = await sgSender.estimateGas.mint_unsheth_with_usdt(...params, {value: msg_value});
        return { lsdOut: weth_lsd_amount_out,expectedOutput: expected_amount_unsheth, msgValue: msg_value, args: params, functionName: 'mint_unsheth_with_usdt', minOutput: min_amount_unsheth, gasLimit: _gasLimit, depositFee, protocolFee, }
    } else if(initials === 'BNBBNB') {
        params = [amountIn, min_amount_pancake, min_amount_usdt_stargate, min_amount_unshethZap, dstGasForCall, dstNativeAmount, pathId];
        // _gasLimit = await sgSender.estimateGas.mint_unsheth_with_bnb(...params, {value: msg_value});
        return { lsdOut: weth_lsd_amount_out,expectedOutput: expected_amount_unsheth, msgValue: msg_value, args: params, functionName: 'mint_unsheth_with_bnb', minOutput: min_amount_unsheth, gasLimit: _gasLimit, depositFee, protocolFee, }
    } else if(initials === 'BNBETH') {
        params = [amountIn, min_amount_pancake, min_amount_usdt_stargate, min_amount_unshethZap, dstGasForCall, dstNativeAmount, pathId];
        // _gasLimit = await sgSender.estimateGas.mint_unsheth_with_bnb(...params, {value: msg_value});
        return { lsdOut: weth_lsd_amount_out,expectedOutput: expected_amount_unsheth, msgValue: msg_value, args: params, functionName: 'mint_unsheth_with_eth', minOutput: min_amount_unsheth, gasLimit: _gasLimit, depositFee, protocolFee, }
    }

}


async function getLayerZeroInfo(amountUSDT, _transferAndCallPayload, address, unshethAmount, eth_provider, provider) {
    try {
        const sgReceiver = new ethers.Contract(contract_addresses.SGReceiver, SGReceiverAbi, eth_provider);

        const params = [
            bnbChainId,
            "0x",
            0,
            contract_addresses.USDT,
            amountUSDT,
            _transferAndCallPayload
        ]
    
        // const sgReceiverGasEstimate = await sgReceiver.estimateGas.sgReceive(...params);
    
        // const sgReceiverGasEstimate = ethers.BigNumber.from(1000000); //TODO change this for ethereum
    
        // const sgReceiverGasLimitBufferBps = 2500; //25% buffer
    
        // const dstGasForCall = sgReceiverGasEstimate.mul(10000 + sgReceiverGasLimitBufferBps).div(10000);
        const dstGasForCall = 1000000;

        // console.log({dstGasForCall});
        // console.log({unshethAmount:unshethAmount.toString()})
    
        const oftCoreContract = new ethers.Contract(contract_addresses.unshETHProxy, OFTCoreABI, eth_provider);
    
        let quoteArgs = [
            bnbChainId, // layerzero chainId to send to - always bnb
            ethers.utils.solidityPack(['address'], [address]), // Correctly encode the address to bytes
            unshethAmount, // The amount of unsheth to send back to BNB
            false, // Use Zro
            '0x'//ethers.utils.toUtf8Bytes('') // Adapter Params
        ];

        const dstNativeAmount = ethers.utils.parseEther("0.0015");

        const _lzTxParams =  {
            dstGasForCall: dstGasForCall,
            dstNativeAmount: dstNativeAmount,
            dstNativeAddr: ethers.utils.defaultAbiCoder.encode(["address"], [contract_addresses.SGReceiver])
        }
    
        const quoteLzFeeParams = [_dstChainId, _functionType, address, _transferAndCallPayload, _lzTxParams];

        const stargateRouter = new ethers.Contract(contract_addresses.BNBStargateRouter, StargateRouterABI, provider);
        const [quoteLzFee] = await stargateRouter.quoteLayerZeroFee(...quoteLzFeeParams);

        return {
            quoteLzFee,
            dstGasForCall: dstGasForCall,
            dstNativeAmount: dstNativeAmount,
            dstNativeAddr:ethers.utils.defaultAbiCoder.encode(["address"], [contract_addresses.SGReceiver])
        }
    }
    catch(err){
        console.log(err);
        throw new Error(err);
    }
}

