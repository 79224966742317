import React, { ElementType, HTMLAttributes } from 'react';
import clsx from 'classnames';

type Variant =
  | 'headingXxl'
  | 'headingXl'
  | 'headingLg'
  | 'bodyLg'
  | 'bodyMd'
  | 'bodySm'
  | 'bodyXs'
  | 'actionCta'
  | 'footerTitle'
  | 'footerBody';
type Weight = 'black' | 'bold' | 'medium' | 'regular';

const fontStyles: Record<Variant, string> = {
  headingXxl: 'text-headingXl lg:text-headingXxl font-heading leading-headingSm',
  headingXl: 'text-headingLg lg:text-headingXl font-heading leading-headingSm',
  headingLg: 'text-bodyLg lg:text-headingLg font-heading leading-headingLg',
  bodyLg: 'text-bodyMd lg:text-bodyLg font-body leading-body tracking-body',
  bodyMd: 'text-bodySm lg:text-bodyMd font-body leading-body tracking-body',
  bodySm: 'text-bodyXs lg:text-bodySm font-body leading-body tracking-body',
  bodyXs: 'text-bodyXs font-body leading-body tracking-body',
  actionCta: 'lg:text-actionCta font-body leading-action tracking-body',
  footerTitle: 'lg:text-footerHeading font-footer leading-headingSm tracking-body',
  footerBody: 'lg:text-bodySm font-footer leading-body tracking-body',
};

const fontWeights: Record<Weight, string> = {
  black: 'font-black',
  bold: 'font-bold',
  medium: 'font-medium',
  regular: 'font-regular',
};

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  variant?: Variant;
  weight?: Weight;
  as?: ElementType;
}

export const Typography: React.FC<TypographyProps> = ({
  as: Tag = 'p',
  variant = 'bodyMd',
  weight = 'regular',
  children,
  className,
  ...rest
}) => {
  return (
    <Tag className={clsx(fontStyles[variant], fontWeights[weight], className)} {...rest}>
      {children}
    </Tag>
  );
};
