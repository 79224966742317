import { toast } from 'react-toastify';
import { usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction } from 'wagmi';
import contractAddresses from '../../../../contract_addresses';
import { useSelector } from 'react-redux';
import {
  resetAll,
  selectArgs,
  selectFunctionName,
  selectBNBUSHInput,
  selectBNBpancakeSwapLPInput,
  selectContractAddress,
  selectAbi,
} from '../../../../redux/BNBvdUSH';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAllowanceWatch, setBalanceWatch, setVDUSHWatch } from '../../../../redux/watch';
import { AllowancesContext } from '../../../../context-providers/Allowances';
import { BalancesContext } from '../../../../context-providers/Balances';
import { BNBVDUSHContext } from '../../../../context-providers/BNBVDUSH';
import APR from './apr';
import { Button } from '../../../shared';

export default function Submit() {
  const allowances = useContext(AllowancesContext);
  const balances = useContext(BalancesContext);
  const { bnb_my_locked } = useContext(BNBVDUSHContext);
  const dispatch = useDispatch();

  const amountBNBUSH = useSelector(selectBNBUSHInput);
  const amountBNBpancakeSwapLP = useSelector(selectBNBpancakeSwapLPInput);

  const functionName = useSelector(selectFunctionName);
  const args = useSelector(selectArgs);
  const contract_address = useSelector(selectContractAddress);
  const abi = useSelector(selectAbi);

  let increaseStake = false;
  if (bnb_my_locked.data && parseFloat(bnb_my_locked.data.end) != 0) {
    increaseStake = true;
  }

  const [approvalTxHash, setApprovalTxHash] = useState(null);
  const [lockTxHash, setSwapTxHash] = useState(null);

  const approvalWait = useWaitForTransaction({ hash: approvalTxHash });
  const lockWait = useWaitForTransaction({ hash: lockTxHash });

  let balBNBUSH = balances['BNBUSH'].data ? parseFloat(balances['BNBUSH'].data.formatted) : 0;
  let balBNBpancakeSwapLP = balances['BNBpancakeSwapLP'].data
    ? parseFloat(balances['BNBpancakeSwapLP'].data.formatted)
    : 0;

  let allowBNBUSH = allowances['BNBUSHvdUSH'].data ? parseFloat(allowances['BNBUSHvdUSH'].data) / 1e18 : 0;
  let allowBNBpancakeSwapLP = allowances['BNBpancakeSwapLPvdUSH'].data
    ? parseFloat(allowances['BNBpancakeSwapLPvdUSH'].data) / 1e18
    : 0;

  let currentApproval = {
    asset: '',
    contract: '',
  };

  if (parseFloat(amountBNBUSH) > 0 && parseFloat(amountBNBUSH) > allowBNBUSH) {
    currentApproval.asset = 'BNBUSH';
    currentApproval.contract = contractAddresses.BNBvdUSH;
  } else if (parseFloat(amountBNBpancakeSwapLP) > 0 && parseFloat(amountBNBpancakeSwapLP) > allowBNBpancakeSwapLP) {
    currentApproval.asset = 'BNBpancakeSwapLP';
    currentApproval.contract = contractAddresses.BNBvdUSH;
  }

  let approvalRequired = currentApproval.asset !== '' && currentApproval.contract !== '';

  //Lock Prep
  const { config } = usePrepareContractWrite({
    address: contract_address,
    abi,
    functionName: approvalRequired ? '' : functionName,
    args,
    overrides: {
      // gasLimit: 600000
    },
    onError: (err) => {
      console.log({ functionName, args, err });
    },
  });

  //Approval Prep
  const approvePrepare = usePrepareContractWrite({
    address: contractAddresses[currentApproval.asset],
    abi: erc20ABI,
    functionName: 'approve',
    args: [currentApproval.contract, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'],
    onError: (err) => {
      console.log(`err is ${err}`);
    },
  });

  const approveWrite = useContractWrite({
    ...approvePrepare.config,
    onError(err) {
      toast.error('Approval Failed!');
    },
    onSuccess(data, err) {
      setApprovalTxHash(data.hash);
      dispatch(setAllowanceWatch(`${currentApproval.asset}vdUSH`));
      console.log({ data, err });
      toast.info('Approval Tx Submitted!');
      // dispatch(resetAll());
    },
  });

  const { write } = useContractWrite({
    ...config,
    onError(err) {
      console.log(err);
      toast.error('Lock Failed!');
    },
    onSuccess(data, err) {
      console.log({ data, err });
      dispatch(setVDUSHWatch('bnb_my_locked'));
      dispatch(setBalanceWatch('BNBvdUSH'));
      dispatch(setBalanceWatch('BNBpancakeSwapLP'));
      dispatch(setBalanceWatch('BNBUSH'));
      setSwapTxHash(data.hash);
      toast.info('Lock Tx Submitted!');
      dispatch(resetAll());
    },
  });

  let notEnoughBal = amountBNBUSH > balBNBUSH || amountBNBpancakeSwapLP > balBNBpancakeSwapLP;

  let approveButtonClass = `block w-1/2 rounded-lg bg-gradient-to-r py-3 px-4 font-medium text-white shadow from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  let lockDisabled = notEnoughBal || !write;
  let lockButtonClass = `block w-3/4 rounded-lg h-full bg-gradient-to-r ${
    !lockDisabled
      ? 'from-blue-500 to-cyan-600  hover:from-blue-600 hover:to-cyan-700'
      : 'from-gray-500 to-gray-600  hover:from-gray-600 hover:to-gray-700'
  }  py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900`;

  const onClick = () => {
    if (approvalRequired) {
      approveWrite.write();
      return;
    } else {
      write();
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center text-center gap-4 w-full mb-4">
        {approvalWait.isLoading ? (
          <Button variant="gradient" block size="big" disabled={true}>
            Approving...
          </Button>
        ) : approvalRequired ? (
          <Button variant="gradient" block size="big" disabled={!approveWrite.write} onClick={onClick}>
            Approve{' ' + currentApproval.asset}
          </Button>
        ) : lockWait.isLoading ? (
          <Button variant="gradient" block size="big" disabled={true}>
            Locking...
          </Button>
        ) : (
          <Button variant="gradient" block size="big" disabled={lockDisabled} onClick={onClick}>
            {notEnoughBal ? 'Insufficient Balance' : increaseStake ? 'Increase Lock' : 'Lock'}
          </Button>
        )}
        <APR />
      </div>
    </>
  );
}
