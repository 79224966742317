import contractAddresses from "../contract_addresses";
import { useBalance, useContractRead, useNetwork } from "wagmi";
import { API_HOST, bnbId, ethId } from "../constants";
import { createContext } from "react";
import VDUSHABI from '../ABI/vdUSH.json';
import VDUSHFarmABI from '../ABI/vdUSHFarm.json';
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { selectVDUSHWatch } from "../redux/watch";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { PricesContext } from "./Prices";
import { BalancesContext } from "./Balances";
import { ethers } from "ethers";


export const BNBVDUSHContext = createContext();

export function BNBVDUSHProvider({ children }) {
  const account = useAccount();
  let address = account.address;
  let vdUSHWatch = useSelector(selectVDUSHWatch);
  const [BNBVDUSHData, setVDUSHData] = useState({});

  const prices = useContext(PricesContext);
  const balances = useContext(BalancesContext);


  let chain = useNetwork();
  let chainId = chain ? chain.id : ethId;

  let watch = chainId === bnbId;


  let enabled = true;

  // Define the URL where you want to fetch the VDUSH data
  const VDUSHApiUrl = `${API_HOST}/bnbvdush`; // Replace this with the actual API URL
  // const VDUSHApiUrl = "http://localhost:3001/VDUSH"; // Replace this with the actual API URL

  useEffect(() => {
    async function fetchVDUSHData() {
      try {
        const response = await fetch(VDUSHApiUrl);
        const data = await response.json();
        setVDUSHData(data);
      } catch (error) {
        console.log("Error fetching VDUSH data:", error);
      }
    }
    fetchVDUSHData();
  }, []); // The empty array `[]` ensures that the effect only runs once on component mount

  let data = {
    BNBVDUSHData,
    totalBNBVDUSH: useContractRead({enabled,watch:enabled &&vdUSHWatch.includes('bnb_my_locked'), address: contractAddresses.BNBvdUSHFarm, functionName: 'getVdUshTotalSupplyInFarm', args: [], chainId: bnbId, abi: VDUSHFarmABI, scopeKey:'totalBNBVDUSH'}),
    totalBNBUSHLocked: useBalance({ enabled,watch:enabled &&vdUSHWatch.includes('bnb_my_locked'),token: contractAddresses.BNBUSH, chainId: bnbId, scopeKey:'totalUSHLocked',address: contractAddresses.BNBvdUSH}),
    totalBNBpancakeSwapLPLocked: useBalance({enabled,watch:enabled &&vdUSHWatch.includes('bnb_my_locked'), token: contractAddresses['BNBpancakeSwapLP'], chainId: bnbId,scopeKey:'totalPancakeVD',address: contractAddresses.BNBvdUSH}),
    boostBNBpancakeSwapLP: useContractRead({ address: contractAddresses.BNBvdUSH, functionName: 'pool2_boost_weight', args: [], chainId: bnbId, abi: VDUSHABI, enabled,watch:enabled &&false, scopeKey:'boostPancake'}),
    bnb_my_locked: useContractRead({enabled,watch:enabled &&vdUSHWatch.includes('bnb_my_locked'), abi: VDUSHABI, address: contractAddresses.BNBvdUSH, functionName: 'locked', args: [account.address], chainId: bnbId, scopeKey:'myLockedBNB'}), //TODO add watch
    alreadyStaking: useContractRead({ enabled,watch:enabled && vdUSHWatch.includes('bnb_alreadyStaking'), abi: VDUSHFarmABI,  address: contractAddresses.BNBvdUSHFarm, functionName: 'isInMatrix', args: [account.address], chainId: bnbId, scopeKey:'myStakingStatusBNB'}),//TODO add watch
    earnedBNBUSH: useContractRead({enabled,watch:enabled && vdUSHWatch.includes('bnb_claimed_ush'), abi: VDUSHFarmABI,  address: contractAddresses.BNBvdUSHFarm, functionName: 'earned', args: [account.address], chainId: bnbId,  scopeKey:'myEarnedGovFarmBNB'}),//TODO add watch
    pancakeWeightedAmount : useContractRead({enabled, abi: VDUSHABI, address: contractAddresses.BNBvdUSH, functionName: 'weighted_amount', args: [0,ethers.utils.parseEther('1'),0], chainId: bnbId, scopeKey:'pancakeWeightedAmount'}),//TODO add watch
    BNBUSHWeightedAmount: useContractRead({enabled, abi: VDUSHABI, address: contractAddresses.BNBvdUSH, functionName: 'weighted_amount', args: [0,0,ethers.utils.parseEther('1')], chainId: bnbId, scopeKey:'BNBUSHWeightedAmount'}),//TODO add watch
  }


  //get the ratio of the users locked ush to the total locked ush
  data.myVDUSHLockedRatio = balances && balances.BNBvdUSH.data && data.totalBNBVDUSH.data && parseFloat(balances.BNBvdUSH.data.formatted)/(parseFloat(data.totalBNBVDUSH.data)/1e18);

  if(data.bnb_my_locked.data && prices.USH && prices.BNBpancakeSwapLP){
    //get the amount of locked ush and bnb
    let myBNBpancakeSwapLPLocked = parseFloat((parseFloat(data.bnb_my_locked.data.amountB)/1e18.toFixed(2)));
    let myUSHLocked = parseFloat((parseFloat(data.bnb_my_locked.data.amountC)/1e18.toFixed(2)));


    //get the tvl of the user if the data is available
    data.myTVL = (myBNBpancakeSwapLPLocked*prices.BNBpancakeSwapLP)+(myUSHLocked*prices.USH);


    //get the individual tvl ratio for the each asset for the user
    data.myBNBpancakeSwapLPTVLRatio = (myBNBpancakeSwapLPLocked*prices.BNBpancakeSwapLP)/data.myTVL;
    data.myUSHTVLRatio = (myUSHLocked*prices.USH)/data.myTVL;
    

    //get the ratio of the users tvl vs total tvl
    if(BNBVDUSHData && BNBVDUSHData.TVL) {
      data.myTVLRatio = data.myTVL/BNBVDUSHData.TVL;
    }
  }

  if(data.myTVLRatio && data.myVDUSHLockedRatio) {
    //get the ratio of the users tvl vs total tvl
    data.aprRatio = data.myVDUSHLockedRatio/data.myTVLRatio;
  }

  //calculate the apr ratio for sushi swap lp and 80/20 bpt
  if(data.BNBUSHWeightedAmount.data && data.pancakeWeightedAmount.data && prices.BNBpancakeSwapLP && prices.USH) {

    data.pancakeTVLRatio = prices.BNBpancakeSwapLP/BNBVDUSHData.TVL;
    data.BNBUSHTVLRatio = prices.USH/BNBVDUSHData.TVL;

    data.pancakeVDUSHRatio = (parseFloat(data.pancakeWeightedAmount.data))/(parseFloat(data.totalBNBVDUSH.data));
    data.BNBUSHVDUSHRatio = (parseFloat(data.BNBUSHWeightedAmount.data))/(parseFloat(data.totalBNBVDUSH.data));


    data.pancakeRatio = data.pancakeVDUSHRatio/data.pancakeTVLRatio;
    data.BNBUSHRatio = data.BNBUSHVDUSHRatio/data.BNBUSHTVLRatio;
  }

  //calculate the ratio of total pancake and total bnbush to the total tvl of the farm
  if(data.totalBNBpancakeSwapLPLocked.data && data.totalBNBUSHLocked.data && BNBVDUSHData && BNBVDUSHData.TVL && prices.BNBpancakeSwapLP) {
    data.totalPancakeTVLRatio = parseFloat(data.totalBNBpancakeSwapLPLocked.data.formatted)*prices.BNBpancakeSwapLP/BNBVDUSHData.TVL;
    data.totalBNBUSHTVLRatio = parseFloat(data.totalBNBUSHLocked.data.formatted)*prices.USH/BNBVDUSHData.TVL;
  }

  return (
    <BNBVDUSHContext.Provider value={data}>
      {children}
    </BNBVDUSHContext.Provider>
  );
}
