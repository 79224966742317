import { ethers } from "ethers";
import contract_addresses from "../../contract_addresses";
import VDUSHABI from '../../ABI/ARBvdUSH.json';
import { toast } from "react-toastify";

const WEEK = 604800;

export default async function calcARBvdUSH({myARBVDUSH, amountARBUSH, amountARBcamelotLP, duration, provider, arb_my_locked, address}){
    try {
        duration = parseFloat(duration);
        if(!amountARBUSH) amountARBUSH = '0';
        if(!amountARBcamelotLP) amountARBcamelotLP = '0';

        amountARBUSH = amountARBUSH.toString();
        amountARBcamelotLP = amountARBcamelotLP.toString();

        const ARBvdUSH = new ethers.Contract(contract_addresses.ARBvdUSH, VDUSHABI, provider);

        const weightedAmount = await ARBvdUSH.weighted_amount(ethers.utils.parseEther('0'), ethers.utils.parseEther(amountARBcamelotLP), (ethers.utils.parseEther(amountARBUSH)));

        let currentDuration = 0;
        //if we are already staking, set the duration based upon the lock end date
        if(arb_my_locked.data && parseFloat(arb_my_locked.data.end)!=0 ){
            let end = new Date(parseFloat(arb_my_locked.data.end)*1000);
            //find the difference between now and the end date in terms of weeks
            currentDuration  = ((end.getTime() - Date.now())/1000/60/60/24/7);
        }

        let amountOut = ethers.utils.formatEther(weightedAmount) * duration / 53;
        let endTime = Math.ceil(WEEK * duration) + Math.floor(Date.now() / 1000);

        if(arb_my_locked.data && parseFloat(arb_my_locked.data.end)!=0){
             //if we are already staking, we want to increase the amount and time
            if(duration > currentDuration + 1){
                let extraVDUSHDueToTime = parseFloat(myARBVDUSH.data.formatted)*(Math.floor(duration-currentDuration)/currentDuration);
                amountOut = amountOut + extraVDUSHDueToTime;
            }
            else {
                endTime = 0;
            }

            return {
                contract_address: contract_addresses.ARBvdUSH,
                amountOut,
                minAmountOut: amountOut,
                abi: VDUSHABI,
                args: [ethers.utils.parseEther('0'), (ethers.utils.parseEther(amountARBcamelotLP)),(ethers.utils.parseEther(amountARBUSH)),endTime],
                functionName: 'increase_amount_and_time',
            }
        }
            
        return {
            contract_address: contract_addresses.ARBvdUSH,
            amountOut,
            minAmountOut: amountOut,
            abi: VDUSHABI,
            args: [ethers.utils.parseEther('0'), (ethers.utils.parseEther(amountARBcamelotLP)),(ethers.utils.parseEther(amountARBUSH)), endTime],
            functionName: 'create_lock',
        }
    }
    catch(err){
        console.log({err});
        toast.error(err.reason);
        return null;
    }
}
