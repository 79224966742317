
import web3 from 'web3';

export default function getNoClaimsAndStake(pool) {

  let noClaims = pool.earned.data && pool.earned.data[0]._hex.toString() === '0x00';

  if(pool.rewardAsset2 === "ARB") {
    noClaims = noClaims && (pool.earned.data[1]._hex.toString() === '0x00');
  }

  let amountStake = pool.staked.data ? pool.staked.data.reduce((a,b)=>{
    return parseFloat(a)+parseFloat(web3.utils.fromWei(b.liquidity._hex,'ether'))
  },0) : 0;

  return noClaims && amountStake === 0;
}
