// import Unstake from './unstake';
import Title from './title';
import Input from './input';
import Output from './output';
import OutputBalance from './Balances/output';
import Submit from './Submit';
import Duration from './input/duration';
import { GradientCard } from '../../v2';

export default function Pool() {
  return (
    <GradientCard>
      <Title />

      <div className="">
        <Duration />
        <Input asset={'BNBUSH'} />
        <div className="text-white text-2xl flex relative h-4 mt-4 mb-4 w-full justify-center items-center">+</div>
        <Input asset={'BNBpancakeSwapLP'} />
      </div>

      <div className="flex relative h-4 mt-4 mb-8 w-full items-center">
        {/* Arrow Pointing Down */}
        <svg
          className="h-5 w-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>

      <div className="flex relative h-16">
        <Output />
      </div>
      <div className={`mt-2 flex justify-end relative h-4`}>
        <OutputBalance />
      </div>

      <div className="flex justify-center mt-8">
        <Submit />
      </div>
      {/* <div className="border-t border-slate-700 my-4"></div>

      <div className="flex justify-center">

      </div> */}
    </GradientCard>
  );
}
