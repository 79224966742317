import { _pancakeSlippageBps, _stargateSlippageBps } from "../../constants";
import contract_addresses from "../../contract_addresses";
import { ethers } from 'ethers';
import { pickPathId } from "./pickPathId";
import LSDVaultABI from '../../ABI/LSDVault.json';
import { _zapSlippageBps, bnb_usdt_decimals } from "../../constants";

function multiplyAndDivideBigNumbers(a, b) {

  // Convert the BigNumber values to FixedNumber with 18 decimal places
  const aFixed = ethers.FixedNumber.fromValue(a, 18);
  const bFixed = ethers.FixedNumber.fromValue(b, 18);

  // Multiply the two FixedNumber values
  const product = aFixed.mulUnsafe(bFixed);

  // Define the divisor 10^18 as a FixedNumber
  const divisor = ethers.FixedNumber.fromValue("1000000000000000000", 18);

  // Divide the product by 10^18
  const quotientFixed = product.divUnsafe(divisor);

  // Convert the result to a human-readable string with 18 decimal places
  const resultString = quotientFixed.toString();

  // Convert the result from string to BigNumber
  const resultBigNumber = ethers.utils.parseUnits(resultString, 18);

  return resultBigNumber;
}

export default async function calcETH({input, eth_provider, darknet}) {
    const expected_amount_weth = ethers.utils.parseUnits(input, bnb_usdt_decimals);

    let { lsd_to_deposit, pathId, weth_lsd_amount_out, eligible_lsd_bought, should_buy, depositFee, protocolFee } = await pickPathId(expected_amount_weth, eth_provider, darknet);

    const min_amount_unshethZap = should_buy ? 
        eligible_lsd_bought.mul(10000-_zapSlippageBps).div(10000) : 
        weth_lsd_amount_out;

    const expected_amount_unsheth = multiplyAndDivideBigNumbers(weth_lsd_amount_out.sub(depositFee), darknet[lsd_to_deposit].data);

    let min_amount_unsheth = multiplyAndDivideBigNumbers((weth_lsd_amount_out.sub(depositFee)).mul(10000 - (should_buy ? _zapSlippageBps : 0)).div(10000), darknet[lsd_to_deposit].data)
    
    let args= [
        min_amount_unshethZap,
        pathId,
    ];
    
    return {
        lsdOut: weth_lsd_amount_out,
        depositFee, 
        protocolFee,
        functionName: 'mint_unsheth_with_eth',
        args,
        msgValue: expected_amount_weth,
        minOutput: min_amount_unsheth, 
        expectedOutput: expected_amount_unsheth
    }
}