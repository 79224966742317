import { useSelector } from 'react-redux';
import { selectMinAmountOut, selectFees, selectOutputChosen, selectOutputAmount } from '../../../redux/swap';
import { vdamm_tolerance } from '../../../constants';
import { Typography } from '../../shared';

export default function MinAmount() {
  const minAmountOut = useSelector(selectMinAmountOut);
  const outputChosen = useSelector(selectOutputChosen);
  let fees = useSelector(selectFees);
  // let outputChosen = useSelector(selectOutputChosen);
  let outputAmount = useSelector(selectOutputAmount);

  //calculate sum of fees

  let sum = 0;
  for (let i = 0; i < fees.length; i++) {
    sum += parseFloat(fees[i].value);
  }
  console.log({ sum });

  return minAmountOut ? (
    <div className="text-left">
      <Typography as="span" variant="bodyXs" className="text-lightGray">
        Min Amount: {parseFloat(parseFloat((minAmountOut * (10000 - vdamm_tolerance)) / 10000).toFixed(5))}{' '}
        {outputChosen}
      </Typography>
      <Typography as="span" variant="bodyXs" className="text-lightGray">
        Slippage: {vdamm_tolerance / 100}%
      </Typography>
      {fees.map(({ name, value }) => (
        <Typography as="span" variant="bodyXs" className="text-lightGray">
          {name}
          {': '}
          {((value * 100) / (sum + parseFloat(outputAmount))).toFixed(2)}%{' '}
        </Typography>
      ))}
    </div>
  ) : (
    <div></div>
  );
}
