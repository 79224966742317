import { useContext } from 'react';
import { ARBVDUSHContext } from '../../../context-providers/ARBVDUSH';
import { ExternalFarmsContext } from '../../../context-providers/ExternalFarms';
import { PricesContext } from '../../../context-providers/Prices';
import { Typography } from '../../shared';

function numberWithCommas(number) {
  try {
    // Convert the number to a string
    let numberString = number.toFixed(2).toString();

    // Use a regular expression to add commas as thousands separators
    let numberWithCommas = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Return the resulting string
    return numberWithCommas;
  } catch (error) {
    console.log({ number, error });
    return number;
  }
}

export default function TotalUSH() {
  let { totalARBcamelotLPLocked, totalARBUSHLocked, totalARBVDUSH } = useContext(ARBVDUSHContext);

  let prices = useContext(PricesContext);

  function convert(num) {
    if (!num) return 0;
    if (!num.data) return 0;
    if (num.data && num.data.formatted) {
      num = parseFloat(parseFloat(num.data.formatted).toFixed(2));
    } else if (num.data) {
      num = parseFloat((parseFloat(num.data) / 1e18).toFixed(2));
    } else {
      num = 0;
    }
    return num;
  }

  //calculate the tvls
  let arbTVL = 0;
  if (prices['USH']) {
    arbTVL = parseFloat(convert(totalARBUSHLocked)) * parseFloat(prices['USH']);
  }

  let camelotTVL = 0;
  if (prices['ARBcamelotLP']) {
    camelotTVL = parseFloat(convert(totalARBcamelotLPLocked)) * parseFloat(prices['ARBcamelotLP']);
  }

  const stats = [
    { name: 'Total ARB vdUSH in Farm', value: convert(totalARBVDUSH) },
    { name: 'Total ARB USH Locked', value: convert(totalARBUSHLocked), tvl: arbTVL },
    { name: 'Total Camelot LP (USH-ETH) Locked', value: convert(totalARBcamelotLPLocked), tvl: camelotTVL },
  ];

  return (
    <>
      {stats.map((stat) => (
        <div key={stat.name} className="px-4 py-4 sm:px-6 lg:px-8 grid gap-1 h-fit">
          <Typography variant="bodyXs" className="text-lightGray" weight="medium">
            {stat.name}
          </Typography>
          <Typography variant="bodyLg" className="text-white" weight="bold">
            {numberWithCommas(stat.value)}
          </Typography>
          {stat.tvl ? (
            <Typography variant="bodyXs" className="text-lightGray">
              ${numberWithCommas(stat.tvl)}
            </Typography>
          ) : null}
        </div>
      ))}
    </>
  );
}
