import { setAmount } from '../../redux/bridge';
import { useDispatch } from 'react-redux';
import { Typography } from '../shared';

export default function Balance({ bal, showMax }) {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setAmount(bal.data.formatted));
  };

  return (
    <div className={`py-1 text-right w-full`}>
      {bal.isLoading ? (
        <Typography as="span" variant="bodyXs" className="text-white/60">
          Balance: Loading...
        </Typography>
      ) : !bal.data ? (
        <Typography as="Typography" variant="bodyXs" className="text-white/60">
          Balance: 0
        </Typography>
      ) : (
        <div className="flex items-center gap-1 justify-end">
          <Typography as="span" variant="bodyXs" className="text-white/60">
            Balance: {parseFloat(parseFloat(bal.data.formatted).toFixed(5))}
          </Typography>
          {showMax && (
            <Typography
              as="span"
              variant="bodyXs"
              onClick={onClick}
              className="text-mediumBlue cursor-pointer"
              weight="bold"
            >
              Max
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
