import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Item from './item';
import Chosen from './chosen';
import { useSelector } from 'react-redux'
import { selectInputChosen, selectOutputItems } from '../../../../redux/swap';
import { BalancesContext } from '../../../../context-providers/Balances';

export default function Dropdown() {
  const items = useSelector(selectOutputItems);
  const inputChosen = useSelector(selectInputChosen);
  const balances = useContext(BalancesContext);

  let arr = Object.keys(items).map((initial)=> {
    return items[initial];
  }).filter((item)=> {
    if(item.initials === 'WETH' && inputChosen === 'ETH') return false;
    if(item.initials === 'ETH' && inputChosen === 'WETH') return false;
    return item.initials !== inputChosen;
  });
  
  return (
    <Menu as="div" className="text-left bg-surface-default cursor-default focus:outline-none overflow-hidden rounded-r-lg border border-slate-600  h-full items-center border-l-0">
      <Chosen/>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-[300px] absolute right-0 z-10 mt-2 origin-top-right rounded-lg bg-slate-700 shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
          {arr.map((item,i) => (
            <Item key={i} item={item} balance={balances[item.initials]}/>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
