import contractAddresses from "../contract_addresses";
import LSDVaultABI from "../ABI/LSDVault.json";
import { useContractRead } from "wagmi";
import { ethId } from "../constants";

export function useConversions() {
  //used to keep track of all the conversions between assets during depositing
  let conversions = {
    ETH: {data:1e18},
    unshETH: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'stakedETHperunshETH', args:[], watch:false, scopeKey:'unshETHConversion',chainId:ethId}),
    WETH: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'getPrice', args:[contractAddresses.WETH], watch:false, scopeKey:'2WETH',chainId:ethId}),
    wstETH: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'getPrice', args:[contractAddresses.wstETH], watch:false, scopeKey:'2cwstETH',chainId:ethId}),
    rETH: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'getPrice', args:[contractAddresses.rETH], watch:false, scopeKey:'2crETH',chainId:ethId}),
    cbETH: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'getPrice', args:[contractAddresses.cbETH], watch:false, scopeKey:'2ccbETH',chainId:ethId}),
    sfrxETH: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'getPrice', args:[contractAddresses.sfrxETH], watch:false, scopeKey:'2csfrxETH',chainId:ethId}),
    ankrETH: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'getPrice', args:[contractAddresses.ankrETH], watch:false, scopeKey:'2cankrETH',chainId:ethId}),
    swETH: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'getPrice', args:[contractAddresses.swETH], watch:false, scopeKey:'2cswETH',chainId:ethId}),
    ETHx: useContractRead({abi: LSDVaultABI, address: contractAddresses.LSDVault, functionName: 'getPrice', args:[contractAddresses.ETHx], watch:false, scopeKey:'2cETHx',chainId:ethId}),
  }

  
  return conversions;
}