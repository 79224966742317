import { createSlice } from '@reduxjs/toolkit';
import {ethId} from '../constants';

let inputItems = {
  'ETH': { name: 'Ethereum', initials: 'ETH', imageUrl:'eth.svg', chainId:ethId},
  'WETH': { name: 'Wrapped Ethereum', initials: 'WETH', imageUrl:'weth.png', chainId:ethId},
  'sfrxETH': { name: 'Frax', initials: 'sfrxETH', imageUrl:'frxETH.png', chainId:ethId},
  'rETH': { name: 'Rocket Pool', initials: 'rETH', imageUrl:'rETH.png', chainId:ethId},
  'wstETH': { name: 'Lido', initials: 'wstETH', imageUrl:'wstETH.png', chainId:ethId},
  'cbETH': { name: 'Coinbase', initials: 'cbETH', imageUrl:'cbETH.png',chainId:ethId},
  'ankrETH': { name: 'Ankr', initials: 'ankrETH', imageUrl:'ankrETH.svg',chainId:ethId},
  'swETH': { name: 'Swell', initials: 'swETH', imageUrl:'swETH.png',chainId:ethId},
  'ETHx': { name: 'Stader', initials: 'ETHx', imageUrl:'ETHx.png',chainId:ethId},
};

let outputItems = {
  'ETH': { name: 'Ethereum', initials: 'ETH', imageUrl:'eth.svg', chainId:ethId},
  'WETH': { name: 'Wrapped Ethereum', initials: 'WETH', imageUrl:'weth.png', chainId:ethId},
  'sfrxETH': { name: 'Frax', initials: 'sfrxETH', imageUrl:'frxETH.png', chainId:ethId},
  'rETH': { name: 'Rocket Pool', initials: 'rETH', imageUrl:'rETH.png', chainId:ethId},
  'wstETH': { name: 'Lido', initials: 'wstETH', imageUrl:'wstETH.png', chainId:ethId},
  'cbETH': { name: 'Coinbase', initials: 'cbETH', imageUrl:'cbETH.png',chainId:ethId},
  'ankrETH': { name: 'Ankr', initials: 'ankrETH', imageUrl:'ankrETH.svg',chainId:ethId},
  'swETH': { name: 'Swell', initials: 'swETH', imageUrl:'swETH.png',chainId:ethId},
  'ETHx': { name: 'Stader', initials: 'ETHx', imageUrl:'ETHx.png',chainId:ethId},
};

const initialState = {
  inputItems,
  outputItems,
  inputChosen:'wstETH',
  outputChosen:'ETH',
  inputAmount: '',
  outputAmount: '',

  functionName: '',
  args: [],
  msgValue: '',
  gasLimit: '',

  fees: {},
  minAmountOut:'',

  inputDisabled: false,
  loading: false,
  monitor: false,
};

export const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    setInputChosen: (state,action) => {
      state.inputChosen = action.payload;
    },
    setOutputChosen: (state,action) => {
      state.outputChosen = action.payload;
    },
    setInputAmount: (state,action) => {
      state.inputAmount = action.payload;
    },
    setOutputAmount: (state,action) => {
      state.outputAmount = action.payload;
    },
    setMonitor: (state,action) => {
      state.monitor = action.payload;
    },
    setFunctionName: (state, action) => {
      state.functionName = action.payload;
    },
    setArgs: (state, action) => {
      state.args = action.payload;
    },
    setMsgValue: (state, action) => {
      state.msgValue = action.payload;
    },
    setGasLimit: (state, action) => {
      state.gasLimit = action.payload;
    },

    setFees: (state, action) => {
      state.fees = action.payload;
    },
    setMinAmountOut: (state, action) => {
      state.minAmountOut = action.payload;
    },
    setLoading : (state, action) => {
      state.loading = action.payload;
    },
    setInputDisabled: (state,action) => {
      state.inputDisabled = action.payload;
    },
    calculatingSwap: (state, action) => {
      state.inputDisabled = true;
      state.loading = true;
      state.outputAmount = '';

      state.functionName = '';
      state.args = [];
      state.msgValue = '';
      state.gasLimit = '';

      state.fees = {};
      state.minAmountOut = '';
    },

    setCalcSwap: (state, action) => {
      let {
        outputAmount,
        minAmountOut,
        functionName,
        args,
        msgValue,
        // gasLimit,
        fees
      } = action.payload;

      state.outputAmount = outputAmount;
      state.minAmountOut = minAmountOut;
      state.functionName = functionName;
      state.args = args;
      state.msgValue = msgValue;
      // state.gasLimit = gasLimit;
      state.fees = fees;

      state.inputDisabled = false;
      state.loading = false;
    },

    resetAll: (state, action) => {
      state.inputAmount = '';
      state.outputAmount = '';

      state.functionName = '';
      state.args = [];
      state.msgValue = '';
      state.gasLimit = '';

      state.fees = {};
      state.minAmountOut = '';

      state.inputDisabled = false;
      state.loading = false;
    }
  }
});

export const {
  setMonitor,
  setInputChosen,
  setOutputChosen, 
  setInputAmount, 
  setOutputAmount, 

  setFunctionName, 
  setArgs, 
  setMsgValue,
  setGasLimit, 

  setFees,
  setMinAmountOut,

  setLoading,
  setInputDisabled,
  setCalcSwap,

  calculatingSwap,
  resetAll
} = swapSlice.actions;



export const selectInputItems = (state) => state.swap.inputItems;
export const selectOutputItems = (state) => state.swap.outputItems;
export const selectInputChosen = (state) => state.swap.inputChosen;
export const selectOutputChosen = (state) => state.swap.outputChosen;
export const selectInputAmount = (state) => state.swap.inputAmount;
export const selectOutputAmount = (state) => state.swap.outputAmount;

export const selectFunctionName = (state) => state.swap.functionName;
export const selectArgs = (state) => state.swap.args;
export const selectMsgValue = (state) => state.swap.msgValue;
export const selectGasLimit = (state) => state.swap.gasLimit;

export const selectFees = (state) => state.swap.fees;
export const selectMinAmountOut = (state) => state.swap.minAmountOut;

export const selectLoading = (state) => state.swap.loading;
export const selectInputDisabled = (state) => state.swap.inputDisabled;
export const selectMonitor = (state) => state.swap.monitor;

export default swapSlice.reducer;
