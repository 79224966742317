import Dropdown from './dropdown';
import OutputAmount from './outputAmount';

export default function Input() {
    return (
        <>
            <OutputAmount/>
            <Dropdown/>
        </>
    )
}
