import { useDispatch, useSelector } from "react-redux";
import { useContext, useRef } from "react";
import { useAccount, useProvider } from "wagmi";
import calcBNBvdUSH from "../../../../actions/calcBNBvdUSH";
import { BNBVDUSHContext } from "../../../../context-providers/BNBVDUSH";
import { BalancesContext } from "../../../../context-providers/Balances";
import {
  setInput,
  resetAll,
  selectInputDisabled,
  setCalculating,
  setCalc,
  selectDuration,
  selectBNBUSHInput,
  selectBNBpancakeSwapLPInput,
  selectAmountOut,
} from "../../../../redux/BNBvdUSH";

export default function InputBalance({ asset }) {
  const provider = useProvider();
  const account = useAccount();
  const dispatch = useDispatch();
  const { bnb_my_locked } = useContext(BNBVDUSHContext);
  const balances = useContext(BalancesContext);
  let myBNBVDUSH = balances.BNBvdUSH;

  let amountBNBUSH = useSelector(selectBNBUSHInput);
  let amountBNBpancakeSwapLP = useSelector(selectBNBpancakeSwapLPInput);
  const outputAmount = useSelector(selectAmountOut);

  const inputDisabled = useSelector(selectInputDisabled);
  const duration = useSelector(selectDuration);

  const debounceRef = useRef(null);
  let bal = balances[asset];

  const handleMax = () => {
    if (inputDisabled) return;
    const amount = bal.data.formatted;
    dispatch(setInput({ asset, value: amount }));
    const newAmountBNBUSH =
      asset === "BNBUSH" ? amount.toString() : amountBNBUSH;
    const newAmountBNBpancakeSwapLP =
      asset === "BNBpancakeSwapLP" ? amount.toString() : amountBNBpancakeSwapLP;
    if (debounceRef.current) clearTimeout(debounceRef.current);

    // Set a new debounce timer
    debounceRef.current = setTimeout(async () => {
      await lock();
    }, 500); // 1000 milliseconds (1 seconds) debounce time

    let lock = async () => {
      console.log({ amount });
      if (parseFloat(amount) === 0) {
        if (outputAmount) dispatch(resetAll());
        return;
      } else {
        console.log("hello");
        dispatch(setCalculating());
        try {
          let output = await calcBNBvdUSH({
            myBNBVDUSH,
            amountBNBUSH: newAmountBNBUSH,
            amountBNBpancakeSwapLP: newAmountBNBpancakeSwapLP,
            duration,
            provider,
            bnb_my_locked,
            address: account.address,
          });
          console.log({ output });
          if (output === null) {
            dispatch(resetAll());
          } else {
            dispatch(setCalc(output));
          }
        } catch (err) {
          console.log(err);
          dispatch(resetAll());
        }
      }
    };
  };

  return (
    <div className={`text-right mr-1`}>
      {bal.isLoading ? (
        <span className="text-sm text-gray-400">Balance: Loading...</span>
      ) : !bal.data ? (
        <span className="text-sm text-gray-400">Balance: 0</span>
      ) : (
        <div>
          <span className="text-sm text-gray-400">
            Balance: {parseFloat(parseFloat(bal.data.formatted).toFixed(2))}
          </span>
          &nbsp;
          <span
            className="text-sm text-blue-500 cursor-pointer"
            onClick={handleMax}
          >
            Max
          </span>
        </div>
      )}
    </div>
  );
}
