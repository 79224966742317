import { Tooltip } from 'react-tooltip';
import { Typography } from '../../shared';

export default function Title({ pool }) {
  let {
    name,
    linkToBuy,
    multiplier,
    apy,
    aprs,
    asset,
    linkText,
    lock_max_multiplier,
    totalLiquidityLocked,
    totalCombinedWeight,
  } = pool;

  let poolText = aprs
    .map(({ name, value }) => {
      return name + ': ' + value + '%</br>';
    })
    .reduce((text, a) => {
      return text + a;
    });

  let averageMultiplier = null;

  if (
    totalLiquidityLocked &&
    lock_max_multiplier &&
    totalCombinedWeight &&
    totalLiquidityLocked.data &&
    totalCombinedWeight.data &&
    lock_max_multiplier.data
  ) {
    averageMultiplier = parseFloat(totalCombinedWeight.data) / parseFloat(totalLiquidityLocked.data);
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <Typography variant="bodyLg" weight="medium" className="text-white" as="h1">
          Stake {name}
        </Typography>
        {!pool.expired && (
          <Typography
            as="h1"
            variant="bodySm"
            className="text-lightGray"
            weight="medium"
            data-tooltip-id={name + 'tooltip'}
            data-tooltip-html={poolText}
          >
            Average APR: {apy}%{' '}
          </Typography>
        )}
        {!pool.expired && poolText && <Tooltip id={name + 'tooltip'} style={{ zIndex: 100 }} place="bottom" />}
      </div>
      <div className="flex flex-row justify-between items-center">
        <div>
          {!pool.vdUSH && !pool.expired && linkToBuy && (
            <a href={linkToBuy} target="_blank" rel="noopener noreferrer">
              <Typography as="span" variant="bodySm" className="text-mediumBlue" weight="medium">
                {linkText} {name.split(" ")[0]}
              </Typography>
            </a>
          )}
          {!pool.vdUSH && pool.expired && <br />}
        </div>
        <p>
          {!pool.vdUSH && !pool.expired && (
            <Typography as="span" variant="bodySm" className="text-white" weight="medium">
              Average Multiplier: {(averageMultiplier || 0).toFixed(2)}x
            </Typography>
          )}
        </p>
      </div>
      <div className="flex flex-row justify-between items-center">
        <div></div>
        <p>
          {!pool.vdUSH && !pool.expired && (
            <Typography as="span" variant="bodySm" className="text-gradientTeal" weight="medium">
              Current Multiplier: {multiplier}x
            </Typography>
          )}
        </p>
      </div>
      <div className="border-b border-lightGray/20 w-full mt-4 my-2"></div>
    </>
  );
}
