import { useSelector } from 'react-redux';
import { selectOutputChosen } from '../../../redux/swap';
import { useContext } from 'react';
import { BalancesContext } from '../../../context-providers/Balances';
import { Typography } from '../../shared';

export default function OutputBalance() {
  const balances = useContext(BalancesContext);
  const outputChosen = useSelector(selectOutputChosen);
  let bal = balances[outputChosen];

  return (
    <Typography as="span" variant="bodyXs" className="text-lightGray">
      {bal.isLoading
        ? 'Balance: Loading...'
        : !bal.data
        ? 'Balance: 0'
        : `Balance: ${parseFloat(parseFloat(bal.data.formatted).toFixed(5))}`}
    </Typography>
  );
}
