import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  connectClicked: null,
  assetTab: 'convert',
  historyTab:'transaction'
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setClick: (state,action) => {
      state.connectClicked = action.payload;
    },
    setAssetTab: (state,action) => {
      state.assetTab = action.payload;
    },
    setHistoryTab: (state,action) => {
      state.historyTab = action.payload;
    }
  },
});

export const {setClick, setAssetTab, setHistoryTab} = uiSlice.actions;
export const selectConnectClicked = (state) => state.ui.connectClicked;
export const selectAssetTab = (state) => state.ui.assetTab;
export const selectHistoryTab = (state) => state.ui.historyTab;

export const setConnectClicked = (clicked) => async (dispatch, getState) => {
  dispatch(setClick(clicked));
};

export const setAssetTabClicked = (tab) => async (dispatch, getState) => {
  dispatch(setAssetTab(tab));
};

export const setHistoryTabClicked = (tab) => async (dispatch, getState) => {
  dispatch(setHistoryTab(tab));
};

export default uiSlice.reducer;
