import contractAddresses from "../contract_addresses";
import { useBalance } from "wagmi";
import { ethId } from "../constants";
import { createContext } from "react";

export const VaultBalancesContext = createContext();

export function VaultBalancesProvider({ children }) {
  
  let old_balances = {
    wstETH: useBalance({ address: contractAddresses.LSDVaultV1, token: contractAddresses.wstETH, watch: false, scopeKey:'lsdvaultwstETH', chainId:ethId}),
    rETH: useBalance({ address: contractAddresses.LSDVaultV1, token: contractAddresses.rETH, watch: false, scopeKey:'lsdvaultrETH', chainId:ethId}),
    cbETH: useBalance({ address: contractAddresses.LSDVaultV1, token: contractAddresses.cbETH, watch: false, scopeKey:'lsdvaultcbETH', chainId:ethId}),
    sfrxETH: useBalance({ address: contractAddresses.LSDVaultV1, token: contractAddresses.sfrxETH, watch: false, scopeKey:'lsdvaultsfrxETH', chainId:ethId})
  };

  let new_balances = {
    WETH: useBalance({ address:contractAddresses.LSDVault, token: contractAddresses.WETH, watch: false, scopeKey:'lsdvaultWETH', chainId:ethId}),
    wstETH: useBalance({ address:contractAddresses.LSDVault, token: contractAddresses.wstETH, watch: false, scopeKey:'lsdvaultwstETH', chainId:ethId}),
    rETH: useBalance({ address:contractAddresses.LSDVault, token: contractAddresses.rETH, watch: false, scopeKey:'lsdvaultrETH', chainId:ethId}),
    cbETH: useBalance({ address:contractAddresses.LSDVault, token: contractAddresses.cbETH, watch: false, scopeKey:'lsdvaultcbETH', chainId:ethId}),
    sfrxETH: useBalance({ address:contractAddresses.LSDVault, token: contractAddresses.sfrxETH, watch: false, scopeKey:'lsdvaultsfrxETH', chainId:ethId}),
    ankrETH: useBalance({ address:contractAddresses.LSDVault, token: contractAddresses.ankrETH, watch: false, scopeKey:'lsdvaultankrETH', chainId:ethId}),
    swETH: useBalance({ address:contractAddresses.LSDVault, token: contractAddresses.swETH, watch: false, scopeKey:'lsdvaultswETH', chainId:ethId}),
    ETHx: useBalance({ address:contractAddresses.LSDVault, token: contractAddresses.ETHx, watch: false, scopeKey:'lsdvaultETHx', chainId:ethId}),
  };

  let value = {new_balances, old_balances}

  return (
    <VaultBalancesContext.Provider value={value}>
      {children}
    </VaultBalancesContext.Provider>
  );
}
