import { useSelector } from 'react-redux';
import { selectAmountOut, selectARBUSHInput, selectLoading,
 selectARBcamelotLPInput
} from '../../../../redux/ARBvdUSH';
import { useContext, useState } from 'react';
import { ARBVDUSHContext } from '../../../../context-providers/ARBVDUSH';
import { PricesContext } from '../../../../context-providers/Prices';
import { APRContext } from '../../../../context-providers/APR';
import { ExternalFarmsContext } from '../../../../context-providers/ExternalFarms';
import { Tooltip } from 'react-tooltip';

export default function APR() {

    const { ARBVDUSHData, totalARBVDUSH, arb_my_locked} = useContext(ARBVDUSHContext);
    const {arbFarms} = useContext(ExternalFarmsContext);

    const amountARBUSH = useSelector(selectARBUSHInput);
    const amountARBcamelotLP = useSelector(selectARBcamelotLPInput);
    let amount = useSelector(selectAmountOut);
    const prices = useContext(PricesContext);
    let aprs = useContext(APRContext);
    const loading = useSelector(selectLoading);


    //calcualte the total tvl of the amounts being locked
    let myTVL = 0;
    if(amountARBUSH > 0 && prices['USH']){
        myTVL += parseFloat(amountARBUSH) * prices['USH'];
    }
    if(amountARBcamelotLP > 0 && prices['ARBcamelotLP']){
        myTVL += parseFloat(amountARBcamelotLP) * prices['ARBcamelotLP'];
    }
    //calculate the ratio my tvl vs the tvl of the entire pool
    let tvl_ratio = 0;
    if(myTVL && ARBVDUSHData.TVL){
        tvl_ratio = myTVL / ARBVDUSHData.TVL;
    }

    //calculate the ratio of the amount vs the total vdUSH
    let amount_ratio = 0;
    if(amount > 0 && totalARBVDUSH.data){
        amount_ratio = amount / (parseFloat(totalARBVDUSH.data)/1e18);
    }

    //calculate the apr ratio
    let aprRatio = 0;
    if(amount_ratio && tvl_ratio){
        aprRatio = amount_ratio / tvl_ratio;
    }

    //calculate the apr
    let apr = 0;
    if(aprs && aprRatio && !loading){
        apr = parseFloat(aprs['arbvdush'] * aprRatio).toFixed(2);
    }
    else {
        apr = 0;
    }

    //get the percentages that each token contributes to the total tvl
    let ushPercent = 0;
    let camelotPercent = 0;
    if(myTVL && prices){
        ushPercent = parseFloat(amountARBUSH * prices['USH'] / myTVL);
        camelotPercent = parseFloat(amountARBcamelotLP * prices['ARBcamelotLP'] / myTVL);
    }

    let text = '';
    //calculate the apr by multiplying the apr of each token by the percentage of the total tvl that token represents
    let aprBase = 0;
    if(arbFarms[1] && (camelotPercent)){
        aprBase += parseFloat(arbFarms[1].aprBase) * camelotPercent;
        text = `ARBcamelotLP Swap APR: ${(parseFloat(arbFarms[1].aprBase) * camelotPercent).toFixed(2)}%<br/>`;
    }

    let rand = Math.random();

    text = 'ARB vdUSH Farm APR: ' + apr + '%<br/>' + text;

    return (arb_my_locked.data && parseFloat(arb_my_locked.data.end) === 0 && apr) ? (
        <>
            <p className='text-gray-400' data-tooltip-id={rand}  data-tooltip-html={text}>
                Effective APR: {(parseFloat(apr) + parseFloat(aprBase)).toFixed(2)}%
            </p>
            {text && <Tooltip id={rand} style={{zIndex:100,lineHeight:'25px', textAlign:'left',fontSize:'12px'}} place='bottom'/>}
        </>
    ) : null
}
