import { createSlice } from '@reduxjs/toolkit';
import { ethId,bnbId,arbId } from '../constants';

let items = {
  'ETH': { name: 'Ethereum', initials: 'ETH', imageUrl:'eth.svg', chainId:ethId},
  'WETH': { name: 'Wrapped Ethereum', initials: 'WETH', imageUrl:'weth.png', chainId:ethId},
  'sfrxETH': { name: 'Frax', initials: 'sfrxETH', imageUrl:'frxETH.png', chainId:ethId},
  'rETH': { name: 'Rocket Pool', initials: 'rETH', imageUrl:'rETH.png', chainId:ethId},
  'wstETH': { name: 'Lido', initials: 'wstETH', imageUrl:'stETH.png', chainId:ethId},
  'cbETH': { name: 'Coinbase', initials: 'cbETH', imageUrl:'cbETH.png',chainId:ethId},
  'ankrETH': { name: 'Ankr', initials: 'ankrETH', imageUrl:'ankrETH.svg',chainId:ethId},
  'swETH': { name: 'Swell', initials: 'swETH', imageUrl:'swETH.png',chainId:ethId},
  'ETHx': { name: 'Stader', initials: 'ETHx', imageUrl:'ETHx.png',chainId:ethId},
  
  'BNBBNB': { name: 'Binance', initials: 'BNBBNB', imageUrl:'bnb-bnb.svg', chainId:bnbId},
  'BNBUSDT': { name: 'USDT', initials: 'BNBUSDT', imageUrl:'usdt-bnb.png', chainId:bnbId},
  // 'BNBETH': { name: 'ETH', initials: 'BNBETH', imageUrl:'eth-bnb.png', chainId:bnbId},
  'ARBETH': { name: 'Ethereum', initials: 'ARBETH', imageUrl:'eth.svg', chainId:arbId},
};

export const initialState = {
  current: 'ETH',
  chainId:ethId,
  items
};

export const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {
    setCurrent: (state,action) => {
      state.current = action.payload;
    }
  },
});

export const {setCurrent} = dropdownSlice.actions;
export const selectItems = (state) => state.dropdown.items;
export const selectCurrent = (state) => state.dropdown.current;

export default dropdownSlice.reducer;
