import { useContext } from 'react';
import Input from './Input';
import Output from './Output';
import Submit from './Submit';
import Switch from './Switch';
import InputBalance from './Balances/input';
import OutputBalance from './Balances/output';
import Headers from './Headers';
import Rankings from './Rankings';
// import InputPrice from './Prices/input';
// import OutputPrice from './Prices/output';
import Fees from './Fees';
import { ethId } from '../../constants';
import { useNetwork } from 'wagmi';
import { GradientCard } from '../v2';
import { Typography } from '../shared';

export default function Deposit() {
  let { chain } = useNetwork();

  let chainId = chain ? chain.id : ethId;
  return (
    chainId === ethId && (
      <>
        <div className="mt-4 mx-auto sm:max-w-xl sm:text-center lg:items-center lg:px-0 lg:text-left ">
          <GradientCard>
            <Typography as="h1" variant="bodyLg" className="text-white" weight="black">
              Swap
            </Typography>
            <div className="mt-2">
              <div className="space-y-3">
                <div>
                  <div className="flex flex-col relative">
                    <div className="flex relative h-16">
                      <Input />
                    </div>

                    <div className={`flex justify-end py-1`}>
                      <InputBalance />
                    </div>

                    <div className="flex relative h-4 mt-2 mb-5 w-full justify-center items-center">
                      <Switch />
                    </div>

                    <div className="flex relative h-16">
                      <Output />
                    </div>

                    <div className="flex justify-between relative mt-2">
                      <div>
                        <Fees />
                      </div>
                      <OutputBalance />
                    </div>
                  </div>
                </div>
              </div>
              <Submit />
            </div>
          </GradientCard>

        </div>
      </>
    )
  );
}
