import { useContext } from 'react';
import { BalancesContext } from '../../../../context-providers/Balances';

export default function OutputBalance() {
    const balances = useContext(BalancesContext);
    let bal = balances.vdUSH;
    
    return (
        <div className="text-right mr-1 text-sm text-gray-400">
            {(bal.isLoading) ? "Balance: Loading..." : 
                (!bal.data) ? "Balance: 0" :
            `Balance: ${parseFloat(parseFloat(bal.data.formatted).toFixed(2))}`
            }
        </div>
    )
}
