import { setInput, setOutput } from '../../../redux/withdraw';
import { useDispatch } from 'react-redux';
import calcOutput from '../calcOutput';
import { Typography } from '../../shared';

export default function UnshethBalance({ balances, totalSupplyUnsheth, vaultBalances, redeemFee }) {
  const dispatch = useDispatch();
  let bal = balances['unshETH'];

  let disabled = false;
  if (!totalSupplyUnsheth.data) {
    disabled = true;
  }
  Object.keys(vaultBalances).map((v) => {
    if (!vaultBalances[v].data) {
      disabled = true;
    }
  });

  const onClick = () => {
    if (disabled) return;
    let amount = calcOutput(parseFloat(bal.data.formatted), vaultBalances, totalSupplyUnsheth, redeemFee);
    dispatch(setInput(bal.data.formatted));
    dispatch(setOutput(amount));
  };

  return (
    <div className={`text-right`}>
      {bal.isLoading ? (
        <Typography variant="bodyXs" className="text-lightGray">
          Balance: Loading...
        </Typography>
      ) : !bal.data ? (
        <Typography variant="bodyXs" className="text-lightGray">
          Balance: 0
        </Typography>
      ) : (
        <div className="flex gap-1">
          <Typography variant="bodyXs" className="text-lightGray">
            Balance: {parseFloat(parseFloat(bal.data.formatted).toFixed(5))}
          </Typography>
          <Typography variant="bodyXs" onClick={onClick} className="text-mediumBlue cursor-pointer" weight="medium">
            Max
          </Typography>
        </div>
      )}
    </div>
  );
}
