import web3 from 'web3';

export default function getPoolParsedStake(pool, blockTimestamp) {

  let staked = pool.staked.data ? pool.staked.data.map(({liquidity, ending_timestamp, start_timestamp, kek_id, lock_multiplier}) => {
    return {
      amount: web3.utils.fromWei(liquidity._hex,'ether'),
      multiplier: parseFloat(parseFloat(web3.utils.fromWei(lock_multiplier._hex,'ether'))).toFixed(2),
      endDate: parseInt(ending_timestamp._hex,16)*1000,
      startDate: parseInt(start_timestamp._hex,16)*1000,
      canUnstake: parseInt(ending_timestamp._hex,16)*1000 < blockTimestamp,
      kek_id: web3.utils.toHex(kek_id)
    }
  }).filter(({endDate})=>{
    return endDate !== 0;
  }) : [];

  let bal = staked.reduce((a,b)=>{
    return parseFloat(a)+parseFloat(b.amount)
  },0);

  return {bal, staked}
}
