import Dropdown from './dropdown';
import InputAmount from './inputAmount';

export default function Input() {
    return (
        <>
            <InputAmount/>
            <Dropdown/>
        </>
    )
}
