import { createSlice } from '@reduxjs/toolkit';
import { ethId,bnbId } from '../constants';

let items = {
  'BNBUSH': { name: 'BNBUSH', initials: 'BNBUSH', imageUrl:'USH.svg', chainId:bnbId, buy:'https://swap.defillama.com/?chain=bsc&from=0x0000000000000000000000000000000000000000&to=0x91d6d6af7635b7b23a8ced9508117965180e2362'},
  'BNBpancakeSwapLP': { name: 'Pancake Swap V2 LP (USH-BNB)', initials: 'BNBpancakeSwapLP', imageUrl:'pancake.svg', chainId:bnbId, mint:'https://pancakeswap.finance/add/BNB/0x91d6d6aF7635B7b23A8CED9508117965180e2362/2500?chain=bsc' },
};


let week = 7 * 24 * 60 * 60;

let endDuration = (((Math.floor((Math.floor(Date.now()/1000) + 53 * week)/week)*week) - Date.now()/1000)/week);

export const initialState = {
  items,
  current: 'USH',
  chainId:ethId,
  BNBUSHInput: '',
  BNBpancakeSwapLPInput: '',
  loading: false,
  duration: endDuration,
  maxDuration: endDuration,
  contract_address: '',
  amountOut: '',
  minAmountOut: '',
  abi: '',
  args: [],
  functionName: '',
  inputDisabled: false,
};

export const BNBvdUSHSlice = createSlice({
  name: 'BNBvdUSH',
  initialState,
  reducers: {
    setCurrent: (state,action) => {
      state.current = action.payload;
    },
    setInput: (state,action) => {
      state[`${action.payload.asset}Input`] = action.payload.value;
    },
    setDuration : (state, action) => {
      state.duration = action.payload;
    },
    setLoading : (state, action) => {
      state.loading = action.payload;
    },
    setCalc: (state, action) => {
      state.contract_address = action.payload.contract_address;
      state.amountOut = action.payload.amountOut;
      state.minAmountOut = action.payload.minAmountOut;
      state.abi = action.payload.abi;
      state.args = action.payload.args;
      state.functionName = action.payload.functionName;
      state.inputDisabled = false;
      state.loading = false;
    },
    setCalculating: (state, action) => {
      state.inputDisabled = true;
      state.loading = true;
      state.output = '';
      state.contract_address = '';
      state.amountOut = '';
      state.minAmountOut = '';
      state.abi = '';
      state.args = [];
      state.functionName = '';
    },
    resetAll: (state, action) => {
      state.BNBUSHInput = '';
      state.BNBpancakeSwapLPInput = '';
      state.output = '';
      state.loading = false;
      state.contract_address = '';
      state.amountOut = '';
      state.minAmountOut = '';
      state.abi = '';
      state.args = [];
      state.functionName = '';
      state.inputDisabled = false;
    }
  },
});

export const {
  setCurrent, 
  setInput,
  setDuration,
  setLoading,
  setCalculating,
  setCalc,
  resetAll,
} = BNBvdUSHSlice.actions;

export const selectItems = (state) => state.BNBvdUSH.items;
export const selectCurrent = (state) => state.BNBvdUSH.current;
export const selectBNBUSHInput = (state) => state.BNBvdUSH.BNBUSHInput;
export const selectBNBpancakeSwapLPInput = (state) => state.BNBvdUSH.BNBpancakeSwapLPInput;
export const selectDuration = (state) => state.BNBvdUSH.duration;
export const selectLoading = (state) => state.BNBvdUSH.loading;
export const selectContractAddress = (state) => state.BNBvdUSH.contract_address;
export const selectAmountOut = (state) => state.BNBvdUSH.amountOut;
export const selectMinAmountOut = (state) => state.BNBvdUSH.minAmountOut;
export const selectAbi = (state) => state.BNBvdUSH.abi;
export const selectArgs = (state) => state.BNBvdUSH.args;
export const selectFunctionName = (state) => state.BNBvdUSH.functionName;
export const selectInputDisabled = (state) => state.BNBvdUSH.inputDisabled;
export const selectMaxDuration = (state) => state.vdUSH.maxDuration;

export default BNBvdUSHSlice.reducer;