import { createSlice } from '@reduxjs/toolkit';
import { ethId,bnbId } from '../constants';

let items = {
  'USH': { name: 'USH', initials: 'USH', imageUrl:'USH.svg', chainId:ethId, buy:'https://swap.defillama.com/?chain=ethereum&from=0x0000000000000000000000000000000000000000&to=0xe60779cc1b2c1d0580611c526a8df0e3f870ec48'},
  '8020BPT': { name: 'Balancer LP (80USH-20unshETH)', initials: '8020BPT', imageUrl:'bal.png', chainId:'ethId',mint:'https://app.balancer.fi/#/ethereum/pool/0xd689abc77b82803f22c49de5c8a0049cc74d11fd000200000000000000000524'},
  'sushiSwapLP': { name: 'Sushi Swap LP (USH-ETH)', initials: 'sushiSwapLP', imageUrl:'sushiSwapLP.svg', chainId:ethId, mint:'https://www.sushi.com/earn/eth:0xaaf448d30f01b429fb6e7f9af6a8ff66e694f312/add' },
};

let week = 7 * 24 * 60 * 60;

let endDuration = (((Math.floor((Math.floor(Date.now()/1000) + 53 * week)/week)*week) - Date.now()/1000)/week);

export const initialState = {
  items,
  current: 'USH',
  chainId:ethId,
  USHInput: '',
  '8020BPTInput': '',
  sushiSwapLPInput: '',
  loading: false,
  duration: endDuration,
  maxDuration: endDuration,
  contract_address: '',
  amountOut: '',
  minAmountOut: '',
  abi: '',
  args: [],
  functionName: '',
  inputDisabled: false,
};

export const vdUSHSlice = createSlice({
  name: 'vdUSH',
  initialState,
  reducers: {
    setCurrent: (state,action) => {
      state.current = action.payload;
    },
    setInput: (state,action) => {
      state[`${action.payload.asset}Input`] = action.payload.value;
    },
    setDuration : (state, action) => {
      state.duration = action.payload;
    },
    setLoading : (state, action) => {
      state.loading = action.payload;
    },
    setCalc: (state, action) => {
      state.contract_address = action.payload.contract_address;
      state.amountOut = action.payload.amountOut;
      state.minAmountOut = action.payload.minAmountOut;
      state.abi = action.payload.abi;
      state.args = action.payload.args;
      state.functionName = action.payload.functionName;
      state.inputDisabled = false;
      state.loading = false;
    },
    setCalculating: (state, action) => {
      state.inputDisabled = true;
      state.loading = true;
      state.output = '';
      state.contract_address = '';
      state.amountOut = '';
      state.minAmountOut = '';
      state.abi = '';
      state.args = [];
      state.functionName = '';
    },
    resetAll: (state, action) => {
      state.USHInput = '';
      state['8020BPTInput'] = '';
      state.SushiInput = '';
      state.output = '';
      state.loading = false;
      state.contract_address = '';
      state.amountOut = '';
      state.minAmountOut = '';
      state.abi = '';
      state.args = [];
      state.functionName = '';
      state.inputDisabled = false;
    }
  },
});

export const {
  setCurrent, 
  setInput,
  setDuration,
  setLoading,
  setCalculating,
  setCalc,
  resetAll,
} = vdUSHSlice.actions;

export const selectItems = (state) => state.vdUSH.items;
export const selectCurrent = (state) => state.vdUSH.current;
export const selectUSHInput = (state) => state.vdUSH.USHInput;
export const select8020BPTInput = (state) => state.vdUSH['8020BPTInput'];
export const selectSushiInput = (state) => state.vdUSH.sushiSwapLPInput;
export const selectDuration = (state) => state.vdUSH.duration;
export const selectLoading = (state) => state.vdUSH.loading;
export const selectContractAddress = (state) => state.vdUSH.contract_address;
export const selectAmountOut = (state) => state.vdUSH.amountOut;
export const selectMinAmountOut = (state) => state.vdUSH.minAmountOut;
export const selectAbi = (state) => state.vdUSH.abi;
export const selectArgs = (state) => state.vdUSH.args;
export const selectFunctionName = (state) => state.vdUSH.functionName;
export const selectInputDisabled = (state) => state.vdUSH.inputDisabled;
export const selectMaxDuration = (state) => state.vdUSH.maxDuration;

export default vdUSHSlice.reducer;