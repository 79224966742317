import 'react-tooltip/dist/react-tooltip.css';
import { useContext } from 'react';
import { VDAMMContext } from '../../context-providers/VDAMM';
import { Tooltip } from 'react-tooltip';
import { Typography } from '@/components/shared/Typography';

export default function TVL() {
  let { totalVolume, volumePerPair } = useContext(VDAMMContext);

  let tooltipText = '';

  if (volumePerPair) {
    Object.entries(volumePerPair).forEach(([key, value]) => {
      tooltipText += key.replace('_', '-') + ': ' + formattedUSD(parseFloat(value).toFixed(2)) + '</br>';
    });
  }

  return (
    <>
      <div className="flex gap-2" data-tooltip-html={tooltipText} data-tooltip-id="my-volume-tooltip">
        <Typography variant="bodySm" className="text-mediumBlue" weight="bold">
          Swap Volume:
        </Typography>
        <Typography variant="bodySm" className="text-lightGray" weight="bold">
          {formattedUSD(parseFloat(totalVolume).toFixed(2))}
        </Typography>
      </div>
      {tooltipText && <Tooltip style={{ zIndex: 100, textAlign: 'left' }} id="my-volume-tooltip" place="bottom" />}
    </>
  );
}

const formattedUSD = (num) => {
  try {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
  } catch (e) {
    return num;
  }
};
