import { selectOutputAmount, selectLoading } from '../../../redux/swap';
import { useSelector } from 'react-redux';


export default function OutputAmount() {
    const amount = useSelector(selectOutputAmount);
    const loading = useSelector(selectLoading);
    return (
        <div className="flex flex-1 text-left items-center relative ">
            <input disabled={true} className="bg-slate-800 pl-6 py-2 w-full h-full rounded-l-lg border border-slate-600  text-lg bg-surface-default text-white focus:ring-1 focus:ring-midnight-400 focus:ring-midnight-500" 
            value={loading ? 'Loading...' : (amount ? parseFloat(parseFloat(amount).toFixed(5)) : '')} 
            />
        </div>
    );
}