import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  input: '',
  output: {
    sfrxETH:0,
    rETH:0,
    wstETH:0,
    cbETH:0
  },
};

export const withdrawV1Slice = createSlice({
  name: 'withdrawV1',
  initialState,
  reducers: {
    setInput: (state,action) => {
      state.input = action.payload;
    },
    setOutput: (state,action) => {
      state.output = action.payload;
    }
  },
});

export const {setInput, setOutput} = withdrawV1Slice.actions;
export const selectInput = (state) => state.withdrawV1.input;
export const selectOutput = (state) => state.withdrawV1.output;

export default withdrawV1Slice.reducer;
