import { API_HOST } from "@/constants";
import React, { createContext, useState, useEffect } from "react";

export const APRContext = createContext();

export function APRProvider({ children }) {
  const [aprData, setAprData] = useState({});

  // Define the URL where you want to fetch the APR data
  const aprApiUrl = `${API_HOST}/apr`; // Replace this with the actual API URL

  useEffect(() => {
    async function fetchAprData() {
      try {
        const response = await fetch(aprApiUrl);
        const data = await response.json();
        setAprData(data);
      } catch (error) {
        console.error("Error fetching APR data:", error);
      }
    }
    fetchAprData();
  }, []); // The empty array `[]` ensures that the effect only runs once on component mount

  return (
    <APRContext.Provider value={aprData}>
      {children}
    </APRContext.Provider>
  );
}
