import GlobalStats from './globalStats';
import MyStats from './myStats';

export default function Example() {
  return (
    <div className="bg-gradientDark mx-auto max-w-5xl rounded-3xl">
      <div className="grid grid-cols-2 gap-px  md:grid-cols-4">
        <GlobalStats />
        <MyStats />
      </div>
    </div>
  );
}
