import { useSelector } from 'react-redux';
import { selectAmountOut, selectUSHInput, selectSushiInput, select8020BPTInput, selectLoading } from '../../../../redux/vdUSH';
import { useContext, useState } from 'react';
import { VDUSHContext } from '../../../../context-providers/VDUSH';
import { PricesContext } from '../../../../context-providers/Prices';
import { APRContext } from '../../../../context-providers/APR';
import { ExternalFarmsContext } from '../../../../context-providers/ExternalFarms';
import { Tooltip } from 'react-tooltip';

export default function APR() {

    const { VDUSHData, totalVDUSH, my_locked} = useContext(VDUSHContext);

    const {ethFarms} = useContext(ExternalFarmsContext);

    const amountUSH = useSelector(selectUSHInput);
    const amountsushiSwapLP = useSelector(selectSushiInput);
    const amount8020BPT = useSelector(select8020BPTInput);
    const loading = useSelector(selectLoading);
    let amount = useSelector(selectAmountOut);
    const prices = useContext(PricesContext);
    let aprs = useContext(APRContext);


    //calcualte the total tvl of the amounts being locked
    let myTVL = 0;
    if(amountUSH > 0 && prices['USH']){
        myTVL += parseFloat(amountUSH) * prices['USH'];
    }
    if(amountsushiSwapLP > 0 && prices['sushiSwapLP']){
        myTVL += parseFloat(amountsushiSwapLP) * prices['sushiSwapLP'];
    }
    if(amount8020BPT > 0 && prices['80USH20unshETH']){
        myTVL += parseFloat(amount8020BPT) * prices['80USH20unshETH'];
    }

    //get the percentages that each token contributes to the total tvl
    let ushPercent = 0;
    let sushiPercent = 0;
    let eightyTwentyPercent = 0;
    if(myTVL){
        ushPercent = parseFloat(amountUSH * prices['USH'] / myTVL);
        sushiPercent = parseFloat(amountsushiSwapLP * prices['sushiSwapLP'] / myTVL);
        eightyTwentyPercent = parseFloat(amount8020BPT * prices['80USH20unshETH'] / myTVL);
    }

    let text = '';
    //calculate the apr by multiplying the apr of each token by the percentage of the total tvl that token represents
    let aprBase = 0;
    if(ethFarms[1] && ethFarms[2] && (sushiPercent || eightyTwentyPercent)){
        aprBase += parseFloat(ethFarms[1].aprBase) * sushiPercent;
        text = `sushiSwapLP Swap APR: ${(parseFloat(ethFarms[1].aprBase) * sushiPercent).toFixed(2)}%<br/>`;
        aprBase += parseFloat(ethFarms[2].aprBase) * eightyTwentyPercent;
        text += ` 8020BPT Swap APR: ${(parseFloat(ethFarms[2].aprBase) * eightyTwentyPercent).toFixed(2)}%<br/>`;
    }

    //calculate the ratio my tvl vs the tvl of the entire pool
    let tvl_ratio = 0;
    if(myTVL && VDUSHData.TVL){
        tvl_ratio = myTVL / VDUSHData.TVL;
    }

    //calculate the ratio of the amount vs the total vdUSH
    let amount_ratio = 0;
    if(amount > 0 && totalVDUSH.data){
        amount_ratio = amount / (parseFloat(totalVDUSH.data)/1e18);
    }

    //calculate the apr ratio
    let aprRatio = 0;
    if(amount_ratio && tvl_ratio){
        aprRatio = amount_ratio / tvl_ratio;
    }
    

    //calculate the apr
    let apr = 0;
    if(aprs && aprRatio && !loading){
        apr = parseFloat(aprs['vdush'] * aprRatio + aprs.vdushANKR * aprRatio).toFixed(2) + aprs.vdushANKR * aprRatio
    }
    else {
        apr = 0;
    }

    let rand = Math.random();

    text = 'vdUSH Farm APR: ' + apr + '%<br/>' + text;


    return (my_locked.data && parseFloat(my_locked.data.end) === 0 && apr) ? (
        <>
        <p className='text-gray-400' data-tooltip-id={rand}  data-tooltip-html={text}>
            Effective APR: {(parseFloat(apr) + parseFloat(aprBase)).toFixed(2)}%
        </p>
        {text && <Tooltip id={rand} style={{zIndex:100, lineHeight:'25px', textAlign:'left',fontSize:'12px'}} place='bottom'/>}
        </>
    ) : null;
}
