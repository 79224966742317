import web3 from 'web3';
import { useContext } from 'react';
import { ProjectsContext } from '../../context-providers/Projects';
import { Typography } from '@/components/shared';

export default function ProjectRatios() {
  let projects = useContext(ProjectsContext);

  function numberWithCommas(number) {
    try {
      // Convert the number to a string
      let numberString = number.toFixed(2).toString();

      // Use a regular expression to add commas as thousands separators
      let numberWithCommas = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Return the resulting string
      return numberWithCommas;
    } catch (error) {
      console.log({ number, error });
      return number;
    }
  }

  let arr = projects.map((p) => {
    let target = p.target;
    if (p.target && p.target.data && p.target.data[0]) {
      target = parseFloat(web3.utils.fromWei(p.target.data[0]._hex, 'wei')) / 100;
    } else target = 25;
    return {
      ...p,
      target: target,
    };
  });

  return (
    <>
      {arr.map((p, i) => (
        <div className="flex items-center justify-center w-1/3 md:w-1/4 mb-2">
          <div className="h-6 w-6">
            <img className="h-6 w-6 rounded-full" src={p.imageUrl} alt="" />
          </div>
          <div className="ml-2 md:ml-4">
            <Typography variant="bodySm" className="text-mediumBlue" weight="bold">
              {p.initials}
            </Typography>
            <Typography variant="bodyXs">
              <Typography as="span" variant="bodyXs" className="text-teal-300" weight="bold">
                {numberWithCommas(p.balance)}
              </Typography>
              <br/>
              <Typography as="span" variant="bodyXs" className="text-lightGray" weight="bold">
                {isNaN(p.dominance) ? '0' : p.dominance}%
              </Typography>
              &nbsp;
              <Typography as="span" variant="bodyXs" className="text-lightGray" weight="bold">
                |
              </Typography>
              &nbsp;
              <Typography as="span" variant="bodyXs" className="text-lightGray" weight="bold">
                {p.target}%
              </Typography>
            </Typography>
          </div>
        </div>
      ))}
    </>
  );
}
