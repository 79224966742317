import { useContext } from 'react';
import { ExternalFarmsContext } from '../../context-providers/ExternalFarms';
import { Typography, Button } from '../shared';
import { GradientCard } from '../v2';

export default function Pool() {
  return (
    <GradientCard>
      <Title />
    </GradientCard>
  );
}

function Title() {
  let { externalFarms, unshETHFarms, ethFarms, bnbFarms, arbFarms } = useContext(ExternalFarmsContext);
  let minTVLForExternalFarms = 2500;

  return (
    <>
      <Typography as="h1" variant="bodyLg" className="text-white" weight="black">
        Farms
      </Typography>
      <div className="border-b border-gray-700 w-full mt-4 my-2"></div>
      <div className="overflow-x-auto">
        <table className="w-full rounded-lg px-4">
          <Headers />
          <tbody className="flex flex-col w-full">
            {unshETHFarms
              .concat(ethFarms)
              .concat(bnbFarms)
              .concat(arbFarms)
              .concat(externalFarms.filter((item)=>( item.tvl > minTVLForExternalFarms)))
              .map((item, index) => (
                <Item key={index} {...item} />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

function Headers() {
  return (
    <thead className="w-full flex flex-col">
      <tr className="flex flex-row">
        <th className="md:px-6 py-3.5 w-1/5">
          <Typography variant="bodyXs" weight="medium" className="text-white">
            Protocol
          </Typography>
        </th>
        <th className="py-3.5 w-2/5 md:w-1/6">
          <Typography variant="bodyXs" weight="medium" className="text-white">
            Type
          </Typography>
        </th>
        <th className="py-3.5 hidden md:block w-1/6">
          <Typography variant="bodyXs" weight="medium" className="text-white">
            Chain
          </Typography>
        </th>
        <th className="py-3.5 hidden md:block md:w-1/6">
          <Typography variant="bodyXs" weight="medium" className="text-white">
            Base APR
          </Typography>
        </th>
        <th className="py-3.5 hidden md:block md:w-1/6">
          <Typography variant="bodyXs" weight="medium" className="text-white">
            Reward APR
          </Typography>
        </th>
        <th className="py-3.5 w-1/5 md:hidden">
          <Typography variant="bodyXs" weight="medium" className="text-white">
            APR
          </Typography>
        </th>
        <th className="py-3.5 w-1/5 md:w-1/6">
          <Typography variant="bodyXs" weight="medium" className="text-white">
            TVL
          </Typography>
        </th>
        <th className="py-3.5 hidden md:block md:w-1/6">
          <Typography variant="bodyXs" weight="medium" className="text-white text-right">
            Actions
          </Typography>
        </th>
      </tr>
    </thead>
  );
}

function numberWithCommas(number) {
  // Convert the number to a string
  let numberString = number.toString();

  // Use a regular expression to add commas as thousands separators
  let numberWithCommas = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Return the resulting string
  return numberWithCommas;
}

function Item({
  protocolImg,
  protocol,
  tvl,
  protocolLink,
  rewardToken,
  type,
  chain,
  actions,
  apr,
  aprReward,
  aprBase,
}) {
  return (
    <>
      <tr className="flex flex-row w-full pt-4 md:py-8 items-center text-left border-t border-slate-500">
        {/*Protocol*/}
        <td className="whitespace-nowrap md:px-6 text-center text-sm text-white w-1/5 md:w-1/6">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <a href={protocolLink} target="_blank" rel="noopener noreferrer">
                <img className="h-10 w-10 rounded-full" src={protocolImg} alt={protocol} />
              </a>
            </div>
          </div>
        </td>

        {/*Type*/}
        <td className="text-sm text-white w-2/5 md:w-1/6 pr-2">
          <Typography variant="bodyXs" weight="medium">
            {/*if type contains a parenthesis, split it and display the first part and then break and then show the second part*/}
            {type.includes('(') ? (
              <>
                {type.split('(')[0]}
                <br />
                ({type.split('(')[1].replace(')', '')})
              </>
            ) : (
              type
            )}
          </Typography>
        </td>

        {/*Chain*/}
        <td className="whitespace-nowrap text-sm text-white hidden md:block md:w-1/6">
          <img className="h-10 w-10 rounded-full" src={chain} alt="" />
        </td>

        {/*APR*/}
        <td className="whitespace-nowrap  text-sm text-white w-1/5 md:hidden">
          <Typography variant="bodySm" weight="medium">
            {apr ? apr : '0%'}
          </Typography>
        </td>

        {/*Base APR*/}
        <td className="whitespace-nowrap  text-sm text-white w-1/6 hidden md:block">
          <Typography variant="bodySm" weight="medium">
            {aprBase ? aprBase : '0%'}
          </Typography>
        </td>

        {/*Reward APR*/}
        <td className="whitespace-nowrap  text-sm text-white w-1/6 hidden md:block">
          <Typography variant="bodySm" weight="medium">
            {aprReward ? `${aprReward}` : '0%'}
          </Typography>
          {aprReward ? (
            <Typography variant="bodyXs" weight="medium" className="text-lightGray text-2xs">
              {rewardToken}
            </Typography>
          ) : null}
        </td>

        {/*TVL*/}
        <td className="whitespace-nowrap text-left text-sm text-white w-1/5 md:w-1/6">
          <Typography variant="bodySm" weight="medium">
            {tvl ? `$${numberWithCommas(parseFloat(tvl).toFixed(0))}` : ''}
          </Typography>
        </td>

        {/*Actions*/}
        <td className="whitespace-nowrap text-sm text-white hidden md:block md:w-1/6">
          <div className="flex flex-col items-end gap-4">
            {actions.map(({ link, text }) => (
              <a href={link}>
                <Button variant='text' className=''>
                <span className='text-xs'>{text} </span>
                </Button>
              </a>
            ))}
          </div>
        </td>
      </tr>
      <tr className="flex flex-row md:hidden items-center pt-4 pb-6">
        <td className="whitespace-nowrap text-sm text-white w-full">
          <div className="flex flex-row  gap-4">
            {actions.map(({ link, text }) => (
              <a href={link}>
                <Button variant='text' className=''>
                <span className='text-xs'>{text} </span>
                </Button>
              </a>
            ))}
          </div>
        </td>
      </tr>
    </>
  );
}
