
import { Button } from '../../shared';
import { Link } from 'react-router-dom';


export default function SubmitOnArb() {
  return (
    <>
      <div className="flex justify-center text-center w-full mt-8 mb-4">
        <Link to="https://swap.defillama.com/?chain=arbitrum&from=0x0000000000000000000000000000000000000000&to=0x0ae38f7e10a43b5b2fb064b42a2f4514cba909ef" target="_blank">
        <Button 
        className="w-full py-8 !rounded-full mt-[-20px]"
        >
          Buy unshETH on Llamaswap 
        </Button>
          </Link>
      </div>
    </>
  );
}
